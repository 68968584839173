import { Grid, Stack } from "@mui/material";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import DetailDescriptionCredential from "./DetailDescriptionCredential";




const DetailPetCredentialArray = ({ ...props }) => {


  return (
    <>
      <div style={{ width: '100%' }}>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >

          {
            props.preOrder.addPets.length > 1 ?
              props.preOrder.addPets.map((item, index) => {
                return (
                  <section id={"page2el"} key={index}>
                    <DetailDescriptionCredential pet={item} />
                  </section>
                )
              })
              :
              <>
                <Stack>
                  {
                    props.preOrder.addPets.length === 1 &&
                    <DetailDescriptionCredential pet={props.preOrder.addPets[0]} />
                  }
                </Stack>
              </>
          }
        </Grid>
      </div>
    </>
  );
}

const mapState = (state) => {
  return {
    preOrder: state.preOrder
  }
}
export default connect(mapState, { addInfoToPreOrder })(DetailPetCredentialArray);
