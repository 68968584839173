import { SvgIcon } from "@mui/material";

const IconWht = () => {
  return (
    <SvgIcon sx={{ width: "14px" }}>
      <svg
        width="auto"
        height="auto"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.86386 15.1513C6.58225 15.1524 5.32339 14.8127 4.21631 14.167L0.844472 15.0865L1.94736 12.0878C1.00949 10.7613 0.544321 9.15825 0.626466 7.53576C0.708611 5.91327 1.33331 4.36539 2.40036 3.14039C3.46741 1.91539 4.91498 1.08428 6.51086 0.780353C8.10675 0.476431 9.75847 0.717315 11.2011 1.46437C12.6437 2.21142 13.7936 3.42135 14.4663 4.90007C15.1391 6.3788 15.2957 8.04063 14.911 9.619C14.5264 11.1974 13.6227 12.6008 12.3451 13.6042C11.0674 14.6076 9.48975 15.1528 7.86519 15.1523L7.86386 15.1513ZM4.34846 13.2369L4.50768 13.3359C5.5158 13.9599 6.67821 14.29 7.86386 14.2888C11.3816 14.2888 14.2437 11.4268 14.2437 7.90872C14.2437 4.39067 11.3803 1.52888 7.86386 1.52888C4.3474 1.52888 1.48243 4.39093 1.48243 7.90925C1.47851 9.299 1.93252 10.6513 2.77427 11.7572L2.91677 11.945L2.22998 13.8133L4.34846 13.2369Z"
          fill="#039ECC"
        />
        <path
          d="M5.16867 4.18295C5.25145 4.15671 5.33919 4.14718 5.42615 4.15498C5.51312 4.16279 5.59735 4.18774 5.67335 4.22823C5.76454 4.27555 5.83396 4.34999 5.90746 4.41777L7.03142 5.4742C7.1482 5.58227 7.21458 5.72946 7.21598 5.88347C7.21817 6.0097 7.17933 6.13356 7.10465 6.23851C7.05778 6.29839 7.00541 6.35428 6.94813 6.40554C6.76683 6.57667 6.58391 6.74626 6.4037 6.91713C6.45488 7.02098 6.5213 7.11716 6.57928 7.21743C6.63726 7.3177 6.70422 7.41542 6.76983 7.51262C7.00109 7.86442 7.27746 8.18821 7.59273 8.4767C7.88071 8.74398 8.20038 8.97931 8.54547 9.17809C8.74909 9.30317 8.96223 9.41316 9.17647 9.52162C9.37863 9.3308 9.58143 9.14023 9.78486 8.94992C9.85873 8.87735 9.95002 8.82244 10.0511 8.78979C10.1287 8.76309 10.2112 8.75122 10.2937 8.75486C10.3763 8.75851 10.4572 8.7776 10.5318 8.81102C10.6489 8.8586 10.7327 8.95222 10.8217 9.03408C11.1918 9.384 11.562 9.73265 11.9324 10.08C12.0471 10.1894 12.1132 10.3357 12.1172 10.4893V10.5164C12.1107 10.6059 12.086 10.6934 12.0445 10.7742C11.9653 10.9216 11.8216 11.0224 11.7015 11.1385C11.6471 11.1868 11.5992 11.2464 11.5292 11.2776C11.3504 11.4023 11.1509 11.4983 10.9388 11.5618C10.8405 11.5974 10.736 11.613 10.6336 11.6322C10.5204 11.6533 10.4043 11.6579 10.2895 11.6457C10.1448 11.6413 10.0006 11.6269 9.85809 11.6025C9.73124 11.5877 9.60874 11.5513 9.48352 11.5273C9.35994 11.4907 9.23336 11.4628 9.11331 11.4165C8.95788 11.3736 8.81224 11.3065 8.66198 11.2505C8.52778 11.1907 8.39521 11.1283 8.26292 11.0651C8.08026 10.9658 7.89488 10.8712 7.72176 10.7581C7.50616 10.632 7.30799 10.4826 7.10465 10.3404C6.88143 10.1706 6.66421 9.99407 6.45787 9.80657C6.26405 9.64107 6.08875 9.45818 5.90582 9.28245C5.38235 8.75526 4.88611 8.19226 4.53359 7.5474C4.35747 7.21487 4.21755 6.86367 4.16066 6.49584C4.11258 6.23394 4.10433 5.96696 4.13616 5.70288C4.166 5.34633 4.30068 5.00459 4.52516 4.71577C4.54503 4.68763 4.55646 4.65413 4.58314 4.63059C4.6775 4.54175 4.77169 4.45299 4.86569 4.36431C4.95144 4.28399 5.0508 4.21288 5.16867 4.18295Z"
          fill="#039ECC"
        />
      </svg>
    </SvgIcon>
  );
};
export default IconWht;
