import * as React from "react";
import {

    Box, Button,
    Dialog,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import './ModalEditPet.css'
import {useFormik} from "formik";
import { listNumbers, OnlyAlpha } from '../Utils/utils'
import {useEffect, useState} from "react";
import {ValidationModalEditPet} from "./ValidationModalEditPet";
import {connect} from "react-redux";
import {addInfoToPreOrder} from "../../redux/preOrderDuck";
import AvatarCat from "../../assets/img/fotoAlterna/avatar-gato.svg"
import AvatarDog from "../../assets/img/fotoAlterna/avatar-perro.svg"
import {getRaces} from "../../redux/configDuck";

const MAXIMO_TAMANIO_BYTES = 1000000; // 1MB = 1 millón de bytes
const filter = createFilterOptions();

const ModalEditPet = ({isAdd = false, ...props}) => {
    const [disable, setDisable] = useState(false);

    const espaceModal = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
    const hiddenFileInput = React.useRef(null);
    const [file, setFile] = useState(null)

    const [error, setError] = useState(false)
    const [errorFormat, setErrorFormart] = useState(false)


    const validatorPet = (meses, anios, typePet, name) => {
        let error = false;
        let anios_ = parseInt(anios)
        let meses_ = parseInt(meses)

        if (anios_ === 0 && meses_ < 2) {
            props.setErrorModal(true)
            props.setNamePet(name)
            props.setTypePet(typePet === "Perro" ? 1 : 2)
            error = true
        } else if (anios_ >= 9 && meses_ > 0) {
            props.setErrorModal(true)
            props.setNamePet(name)
            props.setTypePet(typePet === "Perro" ? 1 : 2)
            error = true
        } else {
            error = false
            props.setErrorModal(false)
            props.setNamePet(null)
            props.setTypePet(null)
        }

        return error
    }


    const getCodeRace = (raza = "") => {
        let response = ""
        if (raza !== "") {
            let arr_races = props.config.races;
            let elementIndex = arr_races.findIndex((obj => obj.txt_desc_raza === raza));
          if (elementIndex ===-1){
            let code_raza_new = props.pet.especie === "Gato" ? "311" : "265"
            response = code_raza_new;
          }else {
            response = arr_races[elementIndex].cod_raza
          }
        }
        return response;
    }

    const formik = useFormik({
        initialValues: {
            id: '',
            nombre: '',
            especie: '',
            sexo: '',
            raza: '',
            meses: '',
            anios: '',
            enfermedades: '',
            comidaFavorita: '',
            descripcionMascota: '',
            pedigreeOrChip: []
        },
        validationSchema: ValidationModalEditPet,
        onSubmit: (values) => {
            if (validatorPet(values.meses, values.anios, values.especie, values.nombre)) {
                return true
            }
            let cod_raza = getCodeRace(values.raza);
            values.cod_raza = cod_raza
            values.referencia = ""

            let valEdit = {
                ...values,
                foto: file ? file : props.pet.foto,
            }
            replaceItemArray(valEdit)

        },
    });


    const replaceItemArray = (values) => {
        let osArray = props.preOrder.addPets;
        let elementIndex = osArray.findIndex((obj => obj.id === props.pet.id));
        osArray[elementIndex] = values;
        props.addInfoToPreOrder({addPets: osArray})
        handleClickClose();
    }


    const handleClickClose = () => {
        props.setOpen(false);
    };

    const setDataPet = () => {
        let tipo = props.pet.especie === "Gato" ? 2 : 1
        formik.setFieldValue("id", props.pet.id)
        formik.setFieldValue("nombre", props.pet.nombre)
        formik.setFieldValue("especie", props.pet.especie)
        formik.setFieldValue("sexo", props.pet.sexo)
        formik.setFieldValue("raza", props.pet.raza)
        formik.setFieldValue("meses", props.pet.meses)
        formik.setFieldValue("anios", props.pet.anios)
        formik.setFieldValue("enfermedades", props.pet.enfermedades)
        formik.setFieldValue("comidaFavorita", props.pet.comidaFavorita)
        formik.setFieldValue("descripcionMascota", props.pet.descripcionMascota)
        formik.setFieldValue("pedigreeOrChip", props.pet.pedigreeOrChip ? props.pet.pedigreeOrChip : [])
        if (props.pet.foto) {
            setFile(props.pet.foto)
        }
        getRaces_(tipo)
    }


    const getRaces_ = (typePet) => {
        props.getRaces(typePet)
    }


    useEffect(() => {
        setDataPet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pet])

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const validateUpload = (e) =>
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "image/jpeg";


    const upload = (e) => {
        let isError = false
        setError(false)
        if (e.target.files[0].size <= MAXIMO_TAMANIO_BYTES) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onload = function (e) {
                setFile(e.target.result)
            }
            reader.readAsDataURL(file)
            isError = false;
        } else {
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            setError(true)
            isError = true;
        }

        return isError
    }


    const handleChangeFile = event => {
        setErrorFormart(false)
        let fileUploaded = event.target.files;
        if (fileUploaded.length === 1 && validateUpload(event)) {
            setErrorFormart(false)
            upload(event)
            setDisable(!upload(event) ? false : true);
        } else {
            event.target.value = ""
            setErrorFormart(true)
        }

    };

    return (
        <>
            <Dialog open={props.open} onClose={() => handleClickClose()}
                    disableEscapeKeyDown={true}
                    maxWidth={"sm"} fullWidth={false}>
                <>
                    <Box sx={{mt: 0}}>
                        <Grid
                            className='modal-individual-padding'
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                <span className='title-modal-pet'>
                  {isAdd && espaceModal} {isAdd && "Actualizar datos de " + props.pet.nombre} {isAdd && espaceModal} {!isAdd && "Actualizar datos de " + formik.values.nombre}
                </span>
                                <Box sx={{mt: 2, width: '100%'}}>
                                    <Stack
                                        style={{width: '100%'}}
                                        direction="column"
                                        spacing={2}
                                    >
                                        <div style={{width: '100%', marginTop: '2%'}}>
                                            <Stack
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={2}
                                                direction={{xs: 'row'}}>
                                                <div style={{display: 'block'}}>
                                                    {
                                                        file ?
                                                            <img style={{
                                                                width: 127,
                                                                borderRadius: 127,
                                                                objectFit: "cover",
                                                                height: 127
                                                            }} src={file} alt='LogoInter'/>
                                                            :
                                                            <Grid textAlign={"center"}
                                                                  style={{alignSelf: 'center'}}
                                                                  justifyContent={"center"}
                                                                  xs={12} sm={12} md={9}>
                                                                <img className={'size-img-modal'}
                                                                     src={props.pet.foto ? props.pet.foto !== "" ? props.pet.foto : props.pet.especie === 'Perro' ? AvatarDog : AvatarCat : props.pet.especie === 'Perro' ? AvatarDog : AvatarCat}
                                                                     alt='LogoInter'/>
                                                            </Grid>

                                                    }
                                                </div>

                                                <input
                                                    ref={hiddenFileInput}
                                                    type="file" style={{display: 'none'}}
                                                    accept={"image/jpeg, image/png, image/jpg"}
                                                    onChange={handleChangeFile}
                                                />
                                                <div onClick={handleClick}>
                          <span style={{
                              color: '#039ECC',
                              fontFamily: 'Montserrat',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 18
                          }}>Cambiar foto</span>
                                                </div>

                                            </Stack>
                                            {
                                                error &&
                                                <div>
                          <span style={{
                              color: 'red', fontSize: 14,
                              fontFamily: 'Montserrat',
                              fontStyle: 'normal',
                              fontWeight: 300
                          }}>{`El tamaño máximo es ${MAXIMO_TAMANIO_BYTES / 1000000} MB`}</span>

                                                </div>
                                            }
                                            {
                                                errorFormat &&
                                                <div style={{textAlign: 'center'}}>
                          <span style={{
                              color: 'red', fontSize: 14,
                              fontFamily: 'Montserrat',
                              fontStyle: 'normal',
                              fontWeight: 300
                          }}>{`El tipo de formato de la imagen no es valida.`}</span>
                                                </div>
                                            }
                                        </div>

                                        <form onSubmit={formik.handleSubmit}>
                                            <InputLabel
                                                htmlFor={"name"}
                                                size={"normal"}
                                                variant="standard"
                                                style={{marginBottom: 10, color: 'black'}}>
                                                {"¿Cómo se llama tu mascota?*"}
                                            </InputLabel>
                                            <TextField
                                              onKeyPress={(event) => OnlyAlpha(event)}
                                              onKeyUp={(event) => OnlyAlpha(event)}
                                                fullWidth
                                                id="nombre"
                                                name="nombre"
                                                placeholder="Nombre"
                                                value={formik.values.nombre}
                                                onChange={(type) => {
                                                    formik.setFieldValue("nombre", type.target.value)
                                                }}
                                                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                                helperText={formik.touched.nombre && formik.errors.nombre}
                                            />
                                            <div style={{width: '100%', marginTop: '4%'}}>
                                                <InputLabel
                                                    htmlFor={"breed"}
                                                    size={"normal"}
                                                    variant="standard"
                                                    style={{marginBottom: 10, color: 'black'}}>
                                                    {"¿Qué raza es?*"}
                                                </InputLabel>
                                                <Autocomplete
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                    disablePortal
                                                    noOptionsText={'Raza no encontrada.'}
                                                    options={props.config.races.map((option) => option.txt_desc_raza)}
                                                    value={formik.values.raza}
                                                    onChange={(e, value) => {
                                                        if (typeof value === 'string') {
                                                            formik.setFieldValue('raza', value)

                                                        } else if (value && value.inputValue) {
                                                            // Create a new value from the user input
                                                            formik.setFieldValue('raza', value)

                                                        } else {
                                                            formik.setFieldValue('raza', value)

                                                        }
                                                    }}
                                                    filterOptions={(options, params) => {
                                                        const filtered = filter(options, params);

                                                        const {inputValue} = params;
                                                        // Suggest the creation of a new value
                                                        const isExisting = options.some((option) => inputValue === option);
                                                        if (inputValue !== '' && !isExisting) {
                                                            filtered.push(inputValue);
                                                        }

                                                        return filtered;
                                                    }}
                                                    getOptionLabel={(option) => {
                                                        // Value selected with enter, right from the input
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        // Add "xxx" option created dynamically
                                                        if (option.inputValue) {
                                                            return option.inputValue;
                                                        }
                                                        // Regular option
                                                        return option;
                                                    }}
                                                    renderOption={(props, option) => <li {...props}>{option}</li>}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            id="raza"
                                                            name="raza"
                                                            {...params}
                                                            label=""
                                                            error={formik.touched.raza && Boolean(formik.errors.raza)}
                                                            helperText={formik.touched.raza && formik.errors.raza}
                                                            {...props}
                                                        />
                                                    )}/>
                                            </div>

                                            <div style={{width: '100%', marginTop: '4%'}}>
                                                <InputLabel
                                                    htmlFor={"monthAge"}
                                                    size={"normal"}
                                                    variant="standard"
                                                    style={{marginBottom: 10, color: 'black'}}>
                                                    {"¿Cúal es su edad?*"}
                                                </InputLabel>

                                                <Stack
                                                    direction={{xs: 'row'}}>
                                                    <FormControl sx={{minWidth: '50%'}}
                                                                 error={formik.touched.anios && Boolean(formik.errors.anios)}>
                                                        <Select
                                                            style={{
                                                                borderTopRightRadius: 0,
                                                                borderBottomRightRadius: 0
                                                            }}
                                                            labelId="anios"
                                                            id="anios"
                                                            name={"anios"}
                                                            value={formik.values.anios}
                                                            onChange={formik.handleChange}
                                                            displayEmpty
                                                            renderValue={
                                                                formik.values.anios !== "" ? undefined : () => <div
                                                                    style={{
                                                                        color: '#c5c5c5',
                                                                        fontFamily: "Montserrat"
                                                                    }}>Años</div>
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                disabled>
                                                                Años
                                                            </MenuItem>
                                                            {
                                                                listNumbers(8).map((y, index) => {
                                                                    return (
                                                                        <MenuItem key={index} value={y}>{y}</MenuItem>)
                                                                })
                                                            }
                                                        </Select>
                                                        <FormHelperText>{formik.touched.anios && formik.errors.anios}</FormHelperText>
                                                    </FormControl>
                                                    <FormControl sx={{minWidth: '50%'}}
                                                                 error={formik.touched.meses && Boolean(formik.errors.meses)}>
                                                        <Select
                                                            style={{
                                                                borderTopLeftRadius: 0, borderBottomLeftRadius: 0
                                                            }}
                                                            labelId="meses"
                                                            id="meses"
                                                            name={"meses"}
                                                            displayEmpty
                                                            value={formik.values.meses}
                                                            onChange={formik.handleChange}
                                                            renderValue={
                                                                formik.values.meses !== "" ? undefined : () => <div
                                                                    style={{
                                                                        color: '#c5c5c5',
                                                                        fontFamily: "Montserrat"
                                                                    }}>Meses</div>
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                disabled>
                                                                Meses
                                                            </MenuItem>
                                                            {
                                                                listNumbers().map((m, index) => {
                                                                    return (
                                                                        <MenuItem key={index} value={m}>{m}</MenuItem>)
                                                                })
                                                            }
                                                        </Select>
                                                        <FormHelperText>{formik.touched.meses && formik.errors.meses}</FormHelperText>
                                                    </FormControl>
                                                </Stack>
                                            </div>
                                            <div style={{width: '100%', marginTop: '4%'}}>
                                                <Stack
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={{xs: 2, sm: 2, md: 4}}
                                                    direction={{xs: "column", sm: "column", md: "row"}}>
                                                    <Button
                                                        style={{
                                                            fontFamily: 'Montserrat',
                                                            fontStyle: 'normal',
                                                            fontWeight: 600,
                                                            fontSize: 16,
                                                            height: 48
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        onClick={() => {
                                                            handleClickClose()
                                                            window.dataLayer.push({
                                                                event : "mascota_seguro_seguir_update_info_mascota",
                                                                plan_elegido: props.preOrder.selectedPlan.PlanNombreInter,
                                                                no_cotizacion: props.preOrder.folio,
                                                                boton_contacto: 'Cancelar',
                                                            })
                                                        }}
                                                    >Cancelar</Button>

                                                    <Button
                                                        type={'submit'}
                                                        disabled={disable}
                                                        style={{
                                                            fontFamily: 'Montserrat',
                                                            fontStyle: 'normal',
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            height: 48
                                                        }}
                                                        fullWidth
                                                        variant="contained"
                                                        onClick={() => {
                                                            window.dataLayer.push({
                                                                event : "mascota_seguro_seguir_update_info_mascota",
                                                                plan_elegido: props.preOrder.selectedPlan.PlanNombreInter,
                                                                no_cotizacion: props.preOrder.folio,
                                                                boton_contacto: 'Actualizar',
                                                            })
                                                        }}>Actualizar</Button>
                                                </Stack>
                                            </div>

                                        </form>
                                    </Stack>
                                </Box>
                            </Stack>
                            <div>

                            </div>
                        </Grid>
                    </Box>
                </>
            </Dialog>
        </>
    )
}
const mapStateToProps = state => ({
    preOrder: state.preOrder,
    config: state.config
})
export default connect(mapStateToProps, {addInfoToPreOrder, getRaces})(ModalEditPet);
