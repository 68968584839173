import React from "react";
import { Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { ReactComponent as LogoInter } from "../../assets/img/footer/LogoInter-w.svg";
import { ReactComponent as LogoApple } from "../../assets/img/footer/App_Store.svg";
import { ReactComponent as LogoGoogle } from "../../assets/img/footer/Google_play.svg";
import { ReactComponent as LFace } from "../../assets/img/footer/FaceBook.svg";
import { ReactComponent as LInst } from "../../assets/img/footer/Instagram.svg";
import { ReactComponent as LIn } from "../../assets/img/footer/LinkedIn.svg";
import { ReactComponent as LTik } from "../../assets/img/footer/Tik_Tok.svg";
import { ReactComponent as LYou } from "../../assets/img/footer/YouTube.svg";
import { ReactComponent as LTwi } from "../../assets/img/footer/X.svg";
import "../../components/Commons/commons.css";
import { UtmURls } from "../../components/Utils/utils";

export default function Footer(props) {
  //social networks buttons:
  const handleFacebook = (e) => {
    window.dataLayer.push({
      event: 'ev_social_networks_cs',
      option: 'Facebook'
    });
  };

  const handleInstagram = (e) => {
    window.dataLayer.push({
      event: 'ev_social_networks_cs',
      option: 'Instagram'
    });
  };

  const handleLinkedin = (e) => {
    window.dataLayer.push({
      event: 'ev_social_networks_cs',
      option: 'LinkedIn'
    });
  };

  const handleTiktok = (e) => {
    window.dataLayer.push({
      event: 'ev_social_networks_cs',
      option: 'Tiktok'
    });
  };

  const handleYoutube = (e) => {
    window.dataLayer.push({
      event: 'ev_social_networks_cs',
      option: 'Youtube'
    });
  };

  const handleTwitter = (e) => {
    window.dataLayer.push({
      event: 'ev_social_networks_cs',
      option: "Twitter",
    });
  };

  //footer button:
  const handleAppStore = (e) => {
    window.dataLayer.push({
      event: 'ev_footer_button_cs',
      option: 'App Store'
    });
  };

  const handleGooglePlay = (e) => {
    window.dataLayer.push({
      event: 'ev_footer_button_cs',
      option: 'Play Store'
    });
  };

  const handleContactoTelefono = (e) => {
    window.dataLayer.push({
      event: 'ev_footer_button_cs',
      option: 'Telefono'
    });
  };

  const handleContactoCorreo = (e) => {
    window.dataLayer.push({
      event: 'ev_footer_button_cs',
      option: 'email'
    });
  };

  const handleCocheSeguro = (e) => {
    window.dataLayer.push({
      event: 'ev_footer_button_cs',
      option: 'Coche Seguro'
    });
  };

  const handleMedicoSeguro = (e) => {
    window.dataLayer.push({
      event: 'ev_footer_button_cs',
      option: 'Medico Seguro'
    });
  };

  const handleAvisoPrivacidad = (e) => {
    window.dataLayer.push({
      event: 'ev_footer_button_cs',
      option: "Aviso de privacidad", 
    });
  };

  const handleTerminosyCondiciones = (e) => {
    window.dataLayer.push({
      event: 'ev_footer_button_cs',
      option: "Terminos y condiciones", 
    });
  };

  return (
    <>
      <footer className="relative bg-footer">
        <div className="container">
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <Box className="paddingLogo"
                sx={{pl:{xs:'24px',sm:'24px',md:'16px',lg:'24px'}, pr:{xs:'24px',sm:'24px',md:'24px',lg:'40px'},pt:'24px' }}>
                <Link to="/">
                  <LogoInter></LogoInter>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={3} sx={{pb:{md:'0px'}}}>
              <Box className="header-footer paddingProducts" 
                sx={{ pl:{xs:'24px',sm:'24px',md:'0px',lg:'0px'},pr:{xs:'24px',sm:'24px',md:'24px',lg:'40px'},
                      pt:'24px',pb:'24px'}}>
                Productos
              </Box>
              <Box className="paddingProducts"
                  sx={{ pl:{xs:'24px',sm:'24px',md:'0px',lg:'0px'},
                        pr:{xs:'24px',sm:'24px',md:'24px',lg:'40px'} 
                  }}>
                <a
                  href={UtmURls('https://cocheseguro.inter.mx/inicio')}
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleCocheSeguro}
                >
                  <div className="body-footer" style={{ lineHeight: "19.5px" }}>
                    Coche Seguro
                  </div>
                </a>
                <a
                  href={UtmURls('https://medicoseguro.inter.mx/')}
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleMedicoSeguro}
                >
                  <div className="body-footer" style={{ lineHeight: "19.5px" }}>
                    Médico Seguro
                  </div>
                </a>
                <a
                  href={UtmURls('https://motoseguro.inter.mx/')}
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleCocheSeguro}
                >
                  <div className="body-footer" style={{ lineHeight: "19.5px" }}>
                    Moto Seguro
                  </div>
                </a>
                {/*<a href='https://productos.inter.mx/vida-segura' target='_blank' rel="noreferrer"  >
                                    <div className='body-footer' >Vida Seguro</div>
                                </a>*/}
                {/* <a href='https://productos.inter.mx/bien-seguro' target='_blank' rel="noreferrer"  >
                                    <div className='body-footer' >Bien Seguro</div>
                                </a>
                                <a href='https://vigo.inter.mx/landing' target='_blank' rel="noreferrer"  >
                                    <div className='body-footer' >Viaje Seguro</div>
                                </a> */}
              </Box>
              <Box className="paddingClients" 
                sx={{pt:'24px',pl:{xs:'24px',sm:'24px',md:'0px',lg:'0px'},
                  pr:{xs:'24px',sm:'24px',md:'24px',lg:'40px'},
                  pb:{xs:'24px',sm:'24px',md:'0px'}}}>
                <Box className="header-footer" 
                  sx={{pb: {xs: "24px",sm: "24px",md: "24px",lg: "24px"}}}
                >
                  Clientes
                </Box>
                <Box className="body-footer">
                  <a
                    href="https://inter.mx/aviso-de-privacidad"
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleAvisoPrivacidad}
                  >
                    <div
                      className="body-footer"
                      style={{ lineHeight: "19.5px" }}
                    >
                      Aviso de privacidad
                    </div>
                  </a>
                </Box>
                <Box className="body-footer">
                  <a
                    href="https://inter.mx/terminos-condiciones"
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleTerminosyCondiciones}
                  >
                    <div
                      className="body-footer"
                      style={{ lineHeight: "19.5px" }}
                    >
                      Términos y condiciones
                    </div>
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Box className="paddingContactUs"
                sx={{pl:{xs:'24px',sm:'24px',md:'0px',lg:'0px'},pr:{xs:'24px',sm:'24px',md:'16px',lg:'40px'},
                        pt: {xs: "0px",sm: "0px",md: "24px",lg: "24px",xl: "24px",
                        pb:{md:'0px'}}}}>
                <Box className="header-footer" sx={{ pb: "24px" }}>
                  Contáctanos
                </Box>
                <Box className="body-footer pt-6">
                  <a
                    href="tel:+525544246837"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      lineHeight: "19.5px",
                      color: "white",
                      textDecoration: "none",
                    }}
                    onClick={handleContactoTelefono}
                  >
                    55 442 46837
                  </a>
                </Box>
                <Box className="body-footer">
                  <a
                    href="mailto:hola@inter.mx"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      lineHeight: "19.5px",
                      color: "white",
                      textDecoration: "none",
                    }}
                    onClick={handleContactoCorreo}
                  >
                    hola@inter.mx
                  </a>
                </Box>
                <Box
                  className="body-footer pt-6"
                  sx={{ pt: "24px" }}
                >
                  <p
                    href=""
                    target="_blank"
                    rel="noreferrer"
                    style={{ lineHeight: "19.5px", margin: 0 }}
                  >
                    Miguel de Cervantes Saavedra No. 301, Torre Sur Piso 17,
                    Colonia Granada, Delegación Miguel Hidalgo, C.P. 11520,
                    Ciudad de México, México
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} className="position-Flex"
              sx={{display: "flex",flexDirection: {xs: "column",sm: "column",md: "row",lg: "column",},
                justifyContent: {xs: "flex-start",sm: "flex-end",md: "flex-end"}
              }}
            >
              <Grid item xs={12} sm={4} md={12}>
                <Box className="paddingDownOApp"
                  sx={{pl:{xs:'24px',sm:'24px',md:'0px',lg:'0px'},
                    pt: {xs: "24px",sm: "24px",md: "40px",lg: "24px",xl: "24px"}}} pr={3}>
                  <Box
                    className="header-footer paddingAppleG"
                    sx={{ pb: {xs:'24px',sm:'24px',md:'16px',lg:'24px'} }}
                  >
                    Descarga nuestra app
                  </Box>
                  <Box className="logos"
                    sx={{display: "flex",flexDirection:{md:"row"},alignItems: "center",justifyContent: "space-between"}}
                  >
                    <div>
                      <a
                        href="https://apps.apple.com/mx/app/intermx/id1548367767?l=en"
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleAppStore}
                      >
                        <LogoApple></LogoApple>
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://play.google.com/store/apps/details?id=grut.inter.mx"
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleGooglePlay}
                      >
                        <LogoGoogle></LogoGoogle>
                      </a>
                    </div>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={12}>
                <Box className="paddingFollowUs"
                  sx={{pl:{xs:'24px',sm:'24px',md:'0px',lg:'0px'},
                    pr:{xs:'24px',sm:'24px',md:'16px',lg:'24px'},
                    pt:{xs: "24px",sm: "24px",md: "40px",lg:"24px"}}}>
                  <Box
                    className="header-footer pbSocialNet"
                    sx={{pb:"24px"}}
                  >
                    Síguenos en redes sociales
                  </Box>
                  <Box
                    className="logos-footer"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <a
                      href="https://www.facebook.com/intermx"
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleFacebook}
                    >
                      <LFace></LFace>
                    </a>
                    <a
                      href="https://www.instagram.com/intermx/"
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleInstagram}
                    >
                      <LInst></LInst>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/interproteccion/"
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleLinkedin}
                    >
                      <LIn></LIn>
                    </a>
                    <a
                      href="https://www.tiktok.com/@inter.mx"
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleTiktok}
                    >
                      <LTik></LTik>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCZ8wH2JLzDYvvrN-JiG3roA"
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleYoutube}
                    >
                      <LYou></LYou>
                    </a>
                    <a
                      href="https://twitter.com/intermx"
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleTwitter}
                    >
                      <LTwi></LTwi>
                    </a>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container columns={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
              sx={{ textAlign: { xs: "start", sm: "start", md: "center" } }}
            >
              <Box className="footer-footer paddingCopyright" 
                sx={{ padding: "24px",
                pt:{xs:'24px',sm:'24px',md:'40px',lg:'40px'},
                pl:{xs:'24px',sm:'24px',md:'16px',lg:'24px'},
                pr:{xs:'24px',sm:'24px',md:'16px',lg:'24px'}}}>
                Copyright {/* {new Date().getFullYear()} */}2023 ® Interprotección
              </Box>
            </Grid>
          </Grid>
        </div>
      </footer>
    </>
  );
}
