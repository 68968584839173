import React from 'react';
import {Backdrop, CircularProgress} from "@mui/material";


const Loading = (props) => {
    return (
    <div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
        <CircularProgress style={{textAlign:"center",color:'white'}} disableShrink />
        </Backdrop>
    </div>)

}
export default Loading;