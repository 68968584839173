import ApiWeb from "../lib/apiConfig/apiWeb";
import axios from "axios";
import { urlFull } from "../lib/apiConfig/axiosApi";
import { getLocalStorageData, setLocalStorageData, planPercentage } from "../components/Utils/utils";
import {PORCENTAJE_DECUENTO} from "../components/Utils/Constantes";

const client_id = process.env.REACT_APP_PROD==="1"
    ? process.env.REACT_APP_CLIENT_ID
    : process.env.REACT_APP_CLIENT_ID_QA;

const client_secret =process.env.REACT_APP_PROD==="1"
    ? process.env.REACT_APP_CLIENT_SECRET
    : process.env.REACT_APP_CLIENT_SECRET_QA;

let initialData = {
    fetching: false,
    token: null,

    fetchingSpecies: false,
    species: [],

    fetchingPlans: false,
    plans: [],

    error: '',
    isError: false,

    fetchingZipCode: false,
    zipCode: [],

    fetchingTax: false,
    tax: [],

    fetchingFavoriteFood: false,
    favoriteFood: [],

    fetchingRaces:false,
    races:[]

}

//Type
const TOKEN_LOAD = 'TOKEN_LOAD';
const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
const TOKEN_SUCCESS_EXISTS = 'TOKEN_SUCCESS_EXISTS';
const TOKEN_ERROR = 'TOKEN_ERROR';

const SPECIES_LOAD = 'SPECIES_LOAD';
const SPECIES_SUCCESS = 'SPECIES_SUCCESS';
const SPECIES_ERROR = 'SPECIES_ERROR';

const LOAD_PLANS = 'LOAD_PLANS';
const LOAD_PLANS_SUCCESS = 'LOAD_PLANS_SUCCESS';
const LOAD_PLANS_ERROR = 'LOAD_PLANS_ERROR';

const ZIPCODE_LOAD = 'ZIPCODE_LOAD';
const ZIPCODE_SUCCESS = 'ZIPCODE_SUCCESS';
const ZIPCODE_ERROR = 'ZIPCODE_ERROR';

const TAX_LOAD = 'TAX_LOAD';
const TAX_SUCCESS = 'TAX_SUCCESS';
const TAX_ERROR = 'TAX_ERROR';

const FavoriteFood_LOAD = 'FavoriteFood_LOAD';
const FavoriteFood_SUCCESS = 'FavoriteFood_LOAD_SUCCESS';
const FavoriteFood_ERROR = 'FavoriteFood_LOAD_ERROR';

const RACES_LOAD = "RACES_LOAD";
const RACES_SUCCESS = "RACES_SUCCESS";
const RACES_ERROR = "RACES_ERROR";

//REDUCER
const configReducer = (state = initialData, action) => {
    switch (action.type) {
        /**** init get Species ****/
        case TOKEN_LOAD:
            return { ...state, fetching: true, isError: false, error: '' };
        case TOKEN_SUCCESS:
            return { ...state, fetching: false, token: action.payload }
        case TOKEN_SUCCESS_EXISTS:
            return { ...state, fetching: false }
        case TOKEN_ERROR:
            return { ...state, fetching: false, error: action.payload, isError: true }
        /**** end get Species ****/



        /**** init get Species ****/
        case SPECIES_LOAD:
            return { ...state, fetchingSpecies: true, isError: false, error: '' };
        case SPECIES_SUCCESS:
            return { ...state, fetchingSpecies: false, species: action.payload }
        case SPECIES_ERROR:
            return { ...state, fetchingSpecies: false, error: action.payload, isError: true }
        /**** end get Species ****/

        /**** init get Planes ****/
        case LOAD_PLANS:
            return { ...state, fetchingPlans: true, isError: false, error: '' };
        case LOAD_PLANS_SUCCESS:
            return { ...state, fetchingPlans: false, plans: action.payload }
        case LOAD_PLANS_ERROR:
            return { ...state, fetchingPlans: false, error: action.payload, isError: true }
        /**** end get Planes ****/

        case ZIPCODE_LOAD:
            return { ...state, fetchingZipCode: true, isError: false, error: '' };
        case ZIPCODE_SUCCESS:
            return { ...state, fetchingZipCode: false, zipCode: action.payload };
        case ZIPCODE_ERROR:
            return { ...state, fetchingZipCode: false, zipCode:[], error: action.payload, isError: true }

        case TAX_LOAD:
            return { ...state, fetchingTax: true, isError: false, error: '' };
        case TAX_SUCCESS:
            return { ...state, fetchingTax: false, tax: action.payload };
        case TAX_ERROR:
            return { ...state, fetchingTax: false, error: action.payload, isError: true }

        case FavoriteFood_LOAD:
            return { ...state, fetchingFavoriteFood: true, isError: false, error: '' };
        case FavoriteFood_SUCCESS:
            return { ...state, fetchingFavoriteFood: false, favoriteFood: action.payload };
        case FavoriteFood_ERROR:
            return { ...state, fetchingFavoriteFood: false, error: action.payload, isError: true }

        /**** init get Races ****/
        case RACES_LOAD:
            return { ...state, fetchingRaces: true, isError: false, error: '' };
        case RACES_SUCCESS:
            return { ...state, fetchingRaces: false, races: action.payload }
        case RACES_ERROR:
            return { ...state, fetchingRaces: false, error: action.payload, isError: true }
        /**** end get Races ****/

        default:
            return state;
    }
    ;
};
export default configReducer;

//ACCIONES
export let getRaces =(type=1)=> async (dispatch,getState) =>{
    dispatch({ type: RACES_LOAD });
  try {
      let params = {
          "business": "medipet",
          "insurance": "gmx",
          "service":"pets",
          "util":"race",
          "EspecieId": type+""
      }
      let response = await ApiWeb.genericApi(params);
      if (response.data.success) {
          dispatch({ type: RACES_SUCCESS, payload: response.data.data })
      } else {
          dispatch({ type: RACES_ERROR, payload: "error" })
      }
  }  catch (e) {
      dispatch({ type: RACES_ERROR, payload: "error" })
  }
};

export let getFavoriteFood = () => async (dispatch, getState) => {
    dispatch({ type: FavoriteFood_LOAD })
    try {
        let params = {
            "business": "medipet",
            "insurance": "gmx",
            "service": "pets",
            "util": "food"
        }
        let response = await ApiWeb.genericApi(params);
        if (response.data.success) {
            dispatch({ type: FavoriteFood_SUCCESS, payload: response.data.data })
        } else {
            dispatch({ type: FavoriteFood_ERROR, payload: "error" })
        }
    } catch (error) {
        dispatch({ type: FavoriteFood_ERROR, payload: "error" })
    }
}

export let getTax = () => async (dispatch, getState) => {
    dispatch({ type: TAX_LOAD })
    try {
        let params = {
            "business": "medipet",
            "insurance": "gmx",
            "service": "tax",
            "util": "types"
        }
        let response = await ApiWeb.genericApi(params);

        if (response.data.success) {
            dispatch({ type: TAX_SUCCESS, payload: response.data.data })
        } else {
            dispatch({ type: TAX_ERROR, payload: "error" })
        }

    } catch (e) {
        dispatch({ type: TAX_ERROR, payload: "error" })
    }
}

export let getZipCode = (code) => async (dispatch, getState) => {
    dispatch({ type: ZIPCODE_LOAD })
    try {
        let params = {
            "business": "medipet",
            "insurance": "gmx",
            "service": "zipCode",
            "util": "search",
            "codPost": code

        }
        let response = await ApiWeb.genericApi(params);
        if (response.data.success) {
            dispatch({ type: ZIPCODE_SUCCESS, payload: response.data.data })
            return {success:true,data: response.data.data}

        } else {
            dispatch({ type: ZIPCODE_ERROR, payload: "error" })
            return {error:true ,data: []}
        }
    } catch (e) {
        dispatch({ type: ZIPCODE_ERROR, payload: "error" })
        return {error:true ,data: []}

    }
}


export let getSpecies = () => async (dispatch, getState) => {
    dispatch({ type: SPECIES_LOAD })
    try {
        let params = {
            "business": "medipet",
            "insurance": "gmx",
            "service": "pets",
            "util": "species"
        }
        let response = await ApiWeb.genericApi(params);
        if (response.data.success) {
            dispatch({ type: SPECIES_SUCCESS, payload: response.data.data })
        } else {
            dispatch({ type: SPECIES_ERROR, payload: "error" })
        }
    } catch (e) {
        dispatch({ type: SPECIES_ERROR, payload: "error" })
    }

}
export let getPlansAction = () => async (dispatch, getState) => {
    dispatch({ type: LOAD_PLANS });
    try {
        let params = {
            "business": "medipet",
            "insurance": "gmx",
            "producto": "1"
        };

        let response = await ApiWeb.getPlans(params);
        if (response.data.success) {
            let array  = response.data.data;
                for(let i = 0; i < array.length; i++){
                    array[i].PlanPrimaTotal2 = array[i].PlanPrimaTotal
                    array[i].PlanPrimaTotal = planPercentage(array[i].PlanPrimaTotal,PORCENTAJE_DECUENTO).cobro
            }
            dispatch({ type: LOAD_PLANS_SUCCESS, payload: array})
        } else {
            dispatch({ type: LOAD_PLANS_ERROR, payload: "error" })
        }
    } catch (err) {
        console.log("error:", err)
        dispatch({ type: LOAD_PLANS_ERROR, payload: "error" })

    }
}

let saveLocalStoreToken = async (storage) => {
    try {
        await setLocalStorageData('token', storage);
    } catch (error) {
        // Error saving data
        console.error(error)
    }
};

export let getTokenAction = () => async (dispatch, getState) => {
    dispatch({ type: TOKEN_LOAD });
    try {
        let token = await getLocalStorageData("token");
        if (token) {
            setTimeout(() => {
                dispatch({ type: TOKEN_SUCCESS_EXISTS })
                dispatch(getSpecies());
                dispatch(getPlansAction());
                dispatch(getRaces());
            }, 1000)

        } else {
            let response = await axios.post(urlFull + '/authorization/token', {
                "client_id": client_id,
                "client_secret": client_secret
            })
            if (response.data.success) {
                await dispatch({ type: TOKEN_SUCCESS, payload: response.data.data })
                await saveLocalStoreToken(response.data.data)
                setTimeout(() => {
                    dispatch(getSpecies());
                    dispatch(getPlansAction());
                    dispatch(getRaces());
                }, 500)
            } else {
                dispatch({ type: TOKEN_ERROR, payload: "Error" });
            }
        }
    } catch (e) {
        dispatch({ type: TOKEN_ERROR, payload: "Error" });
    }
}

