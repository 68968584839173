import { Box, Button, Container, Grid, } from "@mui/material";
import React from "react";
import './Quote.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Form, Formik } from 'formik';
import { validationFormQuote } from "./ValidationQuoteNumber";
import CustomTextField from '../CustomFields/CustomTextField';

export default function QuoteNumber(props) {

  const handleGuardar = (values, setSubmitting) => {
    let fromFolio = {
      email: values.correo,
      folio: values.folio,
    }
    props.sendData(fromFolio)

  }

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          folio: '',
          correo: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleGuardar(values, setSubmitting);
        }}
        validationSchema={validationFormQuote}>
        {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError, touched, setFieldTouched, }) => (
          <Form>
            <div className='div-azul-cotiza'>
              <Box sx={{ py: 6 }}>
                <Container maxWidth="md" className="content-cotiza">

                  <Box
                    sx={{
                      bgcolor: '#FFFFFF',
                      height: '27.0625em',
                      boxShadow: '4px 6px 16px rgba(33, 33, 33, 0.2)',
                      borderRadius: '8px'
                    }} >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box sx={{ pt: 3 }}>
                        <div className="cotiza">
                          <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <span className="titulo-cotiza ">&iexcl;Qu&eacute; cool que est&eacute;s de regreso!</span>
                          </Grid>
                          <Box sx={{ p: 2 }}>
                            <Grid container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <span className="preguntas-cotiza">&iquest;C&uacute;al es tu n&uacute;mero de cotizaci&oacute;n?</span>
                              <CustomTextField id="folio" name="folio" type="number" placeholder="No. de cotización" />
                            </Grid>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <Grid container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <span className="preguntas-cotiza">Y &iquest;tu correo electr&oacute;nico?</span>
                              <CustomTextField id="correo" name="correo" type="text" placeholder="Correo" />
                            </Grid>
                          </Box>
                          <Grid container direction="column" alignItems="flex-end" justifyContent="flex-end">
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                              <Button onClick={() => { props.addInfoToPreOrder({ isFolio: false }) }} className="no-cotiza" style={{ textDecorationLine: "underline", marginRight: '18px' }}>
                                &iquest;No tienes un n&uacute;mero de cotizaci&oacute;n?
                              </Button>
                            </Grid>
                          </Grid>
                          <Box sx={{ p: 2 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                              <Button
                                onClick={submitForm}
                                fullWidth variant="contained" endIcon={<ArrowForwardIcon />
                                } className="cotizando">Seguir cotizando

                              </Button>
                            </Grid>
                          </Box>
                        </div>
                      </Box>
                    </Grid>
                  </Box>
                </Container>
              </Box>
            </div>
          </Form>
        )}
      </Formik>
    </>

  );
}