import React, { useCallback, useEffect, useState } from "react";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { currencyFormat, planPercentage } from "../Utils/utils";
import { PORCENTAJE_DECUENTO } from "../Utils/Constantes";
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}));
const CouponForm = (props) => {

  const [openCode, setOpenCode] = useState(false);
  const [codeNumber, setCodeNumber] = useState('');
  const [successCodeNumber, setSuccessCodeNumber] = useState(false);
  const [isErrorCodeNumber, setIsErrorCodeNumber] = useState(false);


  const [coupon, setCoupon] = useState('');
  const [successCoupon, setSuccessCoupon] = useState(false);
  const [isErrorCoupon, setIsErrorCoupon] = useState(false);

  const useStylesCoupon = makeStyles({
    root: {
      '& label.Mui-focused': {
        color: successCoupon ? 'green' : isErrorCoupon ? 'red' : "77CAE3",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: successCoupon ? 'green' : isErrorCoupon ? 'red' : "77CAE3"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: successCoupon ? 'green' : isErrorCoupon ? 'red' : "77CAE3"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: successCoupon ? 'green' : isErrorCoupon ? 'red' : "77CAE3"
      },
    },
    rootCode: {
      '& label.Mui-focused': {
        color: successCodeNumber ? 'green' : isErrorCodeNumber ? 'red' : "77CAE3",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: successCodeNumber ? 'green' : isErrorCodeNumber ? 'red' : "77CAE3"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: successCodeNumber ? 'green' : isErrorCodeNumber ? 'red' : "77CAE3"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: successCodeNumber ? 'green' : isErrorCodeNumber ? 'red' : "77CAE3"
      },
    }
  });
  const classes = useStylesCoupon();


  const handleChangeCoupon = (event) => {
    debounceCoupon(event.target.value.toUpperCase());
    setCoupon(event.target.value.toUpperCase())
  };

  const debounceCoupon = useCallback(
    _.debounce(async (_searchVal) => {
      await searchCoupon(_searchVal);
      // send the server request here
    }, 1000),
    []
  );

  const searchCoupon = async (text) => {
    try {
      if (text.length > 5) {
        setSuccessCoupon(true)
        setIsErrorCoupon(false)
        props.setOpenAlert(true)
      } else {
        setIsErrorCoupon(true)
        setSuccessCoupon(false)
        props.setOpenAlert(false)

      }
    } catch (error) {
      console.log("Error", error)
    }
  }

  useEffect(() => {
    if (props.preOrder.coupon) {
      setSuccessCoupon(true)
      setIsErrorCoupon(false)
      setCoupon(props.preOrder.coupon.coupon)
      props.setOpenAlert(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preOrder.coupon, props.preOrder.coupon])


  useEffect(() => {
    if (props.preOrder.collaborator_number) {
      setCodeNumber(props.preOrder.collaborator_number)
      setOpenCode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preOrder.collaborator_number])



  const handleChangeCodeNumber = (event) => {
    debounceCodeNumber(event.target.value.toUpperCase());
    setCodeNumber(event.target.value.toUpperCase());
    props.addInfoToPreOrder({collaborator_number:event.target.value.toUpperCase()})

  };

  const debounceCodeNumber = useCallback(
    _.debounce(async (_searchVal) => {
      await searchCodeNumber(_searchVal);
      // send the server request here
    }, 1000),
    []
  );

  const searchCodeNumber = async (text) => {
    try {
      if (text.length > 0) {
        setSuccessCodeNumber(true)
        setIsErrorCodeNumber(false)
      } else {
        setIsErrorCodeNumber(false)
        setSuccessCodeNumber(false)
      }
    } catch (error) {
      console.log("Error", error)
    }
  }


  /** Ajuste de conversion de costos Irving Lara***/
  const resetPorcentaje = (descuento, costoTotal) => {
    let total_real = props.preOrder.selectedPlan.PlanPrimaTotal2;
    let total_pets = props.preOrder.addPets.length;
    let pets = props.preOrder.addPets
    let contador = 0;
    for (let i = 0; i < total_pets; i++) {
      if (pets[i]?.isPago !== true) {
        contador = contador + 1;
      }
    }
    let restante = total_real * contador;
    return restante
  }

  const validatePetsPrices = (costoPlan, planMidificado, cantidad) => {
    let total_pets = cantidad;
    let pets = props.preOrder.addPets
    let contador = 0;
    for (let i = 0; i < total_pets; i++) {
      if (pets[i]?.isPago !== true) {
        contador = contador + 1;
      }
    }

    let baseTotal = 0
    let resultado = contador > 0 ? planMidificado : 0;
    if (contador > 0) {
      baseTotal = costoPlan * contador
      resultado = planPercentage(baseTotal, PORCENTAJE_DECUENTO).cobroReal
    }

    return resultado
  }

  const validatePets = (PlanReal) => {
    let total_pets = props.preOrder.addPets.length;
    let pets = props.preOrder.addPets
    let contador = 0;
    for (let i = 0; i < total_pets; i++) {
      if (pets[i]?.isPago !== true) {
        contador = contador + 1;
      }
    }

    let resultado = contador > 0 ? PlanReal : 0;

    return resultado
  }

  return (<>
    <div style={{ margin: 17 }}>
      <TextField
        disabled={true}
        fullWidth
        id="coupon"
        name="coupon"
        error={isErrorCoupon}
        className={classes.root}
        style={{
          marginTop: 20,
          fontFamily: 'Montserrat',
          fontSize: 16,
        }}
        placeholder={"Escribe tu código de promoción"}
        label={successCoupon ? "Código de promoción" : isErrorCoupon ? "Error" : ""}
        value={coupon}
        onChange={handleChangeCoupon}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
            >
              {successCoupon ? <CheckIcon style={{ color: 'green' }} />
                :
                isErrorCoupon && <CloseIcon style={{ color: 'red' }} />
              }
            </IconButton>
          </InputAdornment>,
        }}
      />
      <div style={{ marginTop: 20, marginBottom: 10 }}>
        <span
          onClick={() => {
            setOpenCode(!openCode)
          }}
          style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 12,
            color: '#039ECC'
          }}>{'¿Eres colaborador de interprotección?'}</span>
      </div>

      {
        openCode &&
        <div style={{
          display: 'block',
          padding: 17,
          backgroundColor: '#F2FAFC',
          borderRadius: 10,
          width: 'auto',
          height: 'auto'
        }}>
          <span style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 14,
            /* identical to box height */
            textTransform: 'uppercase',
            /* Black 75% */
            color: '#595959'
          }}>{"¡ATENCIÓN!"}</span>
          <br />
          <br />
          <span style={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 14,
            color: '#212121'
          }}>{"¿Eres colaborador de inter?"}</span>
          <br />
          <br />
          <span style={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 12,
            color: '#304659'
          }}>{"¡Genial! Compártenos tu número de colaborador, porque tenemos beneficios especiales para ti. 😉"}</span>
          <br />
          <br />
          <TextField
            fullWidth
            id="codeNumber"
            name="codeNumber"
            error={isErrorCodeNumber}
            className={classes.rootCode}
            style={{
              fontFamily: 'Montserrat',
              fontSize: 16,
            }}
            placeholder={"Escribe tu número de colaborador"}
            label={successCodeNumber ? "Número de colaborador" : isErrorCodeNumber ? "Error" : ""}
            value={codeNumber}
            onChange={handleChangeCodeNumber}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  {successCodeNumber ? <CheckIcon style={{ color: 'green' }} />
                    :
                    isErrorCodeNumber && <CloseIcon style={{ color: 'red' }} />
                  }
                </IconButton>
              </InputAdornment>,
            }}
          />
        </div>
      }

      {
        successCoupon &&
        <div>
          <div style={{
            marginTop: 20,
            marginBottom: 20,
            width: '100%',
            height: .5,
            backgroundColor: '#0A3949',
            opacity: 0.3,
          }} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Item elevation={0} className={'title-detail-text'}>Descuento</Item>
            </Grid>
            <Grid item xs={6}>
              <Item elevation={0}
                className={'title-detail-description'}>{props.preOrder.coupon && props.preOrder.coupon.value + "%"}</Item>
            </Grid>

            <Grid item xs={6}>
              <Item elevation={0} className={'title-detail-text'}>Total a pagar</Item>
            </Grid>
            <Grid item xs={6}>
              <Item elevation={0}
                className={'title-detail-description'}>
                {props.preOrder.selectedPlan &&
                  currencyFormat(validatePetsPrices(props.preOrder.selectedPlan.PlanPrimaTotal2, props.preOrder.selectedPlan.PlanPrimaTotal2, props.preOrder.addPets.length), 2)}</Item>
            </Grid>

            {props.preOrder.addPets.length > 1 &&
              <>
                <Grid item xs={6}>
                  <Item elevation={0} className={'title-detail-text'}>Pago por mascota</Item>
                </Grid>
                <Grid item xs={6}>
                  <Item elevation={0}
                    className={'title-detail-description'}>
                    {props.preOrder.selectedPlan &&
                      currencyFormat(validatePets(props.preOrder.selectedPlan.PlanPrimaTotal2), 2)}</Item>
                </Grid>
              </>
            }
          </Grid>
        </div>
      }
    </div>
  </>)
}
const mapStateToProps = state => ({
  preOrder: state.preOrder,
})
export default connect(mapStateToProps, { addInfoToPreOrder })(CouponForm);
