import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import preOrderDuck, { getLocalStorageAction } from "./preOrderDuck";
import configDuck, { getTokenAction } from "./configDuck";
import temporalDuck from "./temporalDuck";

let rootReducer = combineReducers({
    preOrder: preOrderDuck,
    config: configDuck,
    temporal: temporalDuck
});
export default function generateStore() {
    let store = createStore(rootReducer, applyMiddleware(thunk))
    getLocalStorageAction()(store.dispatch) // esto nos ayuda a recuperar la preOrder
    getTokenAction()(store.dispatch) // obtener el token del sitio
    return store;
}