

export const PORCENTAJE_DECUENTO  = 20
//validaciones
export const VACIO = "";
export const UN_CRITERIO = "Debe proporcionar al menos un crtierio de búsqueda.";
export const VALOR_NECESARIO = "Ingrese un valor";
export const VALOR_NAME = "Ingrese el nombre";
export const FECHA_VALIDA = "Ingrese una fecha válida";
export const CMB_NECESARIO = "Seleccione una opción válida";
export const VALOR_POSITIVO = "Ingrese un valor positivo";
export const VALOR_NUMERO = "Ingrese un número";
export const VALOR_ENTERO = "Ingrese un número entero";
export const CORREO_INVALIDO = "Ingrese un correo válido";
export const EMAIL_INVALIDO = "¡Oh, no! Email incorrecto.";
export const TELEFONO_INVALIDO = "Ingrese un número válido";
export const TELEFONO_INVALIDO_ = "¡Oops! Debes ingresar un teléfono válido.";
export const CP_INVALIDO = "Ingrese un código postal válido";
export const EDAD_INVALIDA = "Ingrese una edad válida";
export const CMB_MENOS_UNO = -1;
export const CMB_NULL = null;
export const EMPTY = "";
export const MIN_LETRAS_DES_AUTO = 3;
export const MAX_LENGTH_CP = 5;
export const CHECK_NECESARIO = "Seleccione una opción";
export const MAX_LENGTH_NUM_TARJETA = 16;
export const MAX_LENGTH_CVV = 4;
export const ERROR_MSG = "Te pedimos una disculpa, hubo un error al realizar tu consulta.";
export const ERROR_TITULO_MSG = "¡Sucedió un Error!";
export const ERROR_MSG_1 = "El número de serie ya esta amparado en otra póliza";
export const ERROR_TITULO_MSG_1 = "Validación de número de serie";
export const MAX_LENGTH_NO_SERIE = 17;
export const MAX_LENGTH_PLACAS = 7;
export const MAX_LENGTH_RFC = 13;
export const MAX_LENGTH_RFC_M = 12;
export const MAYOR_EDAD = "Debe ser mayor de edad";
export const AUTOCOMPLETE = "Debe escribir para buscar su auto";
export const REQUERIDO = "Se requiere aceptar Aviso de Privacidad";
export const MSG_FALTA_INFO = "¡Ayúdanos a revisar el formulario!, parece que falta información";

export const ALPHA = "/^[a-zA-Z ]+$/"
