import { Grid, Stack } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import lineaDisabled from '../../assets/img/lineaAmarilla/decorativeDisabled.svg'

const TitleStepTwoDisabled = (props) => {

  return (
    <>

      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className="mX-25">
      <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
          >
        <div className="num-disabled">2</div>
        <div className="linea-amarilla">
          <img src={lineaDisabled} alt='' />
          <div className="text-disabled">Comp&aacute;rtenos una foto de {props.preOrder.petName && props.preOrder.petName}</div>
          <div className="text-asi-disabled">Queremos poner su foto en la credencial de su seguro.</div>
        </div>
        </Stack>
      </Grid>
      <hr />
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className="mX-25">
      <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
          >
        <div className="num-disabled">3</div>
        <div className="linea-amarilla">
          <img src={lineaDisabled} alt='' />
          <div className="text-disabled">&iexcl;Ahora queremos conocerte a ti!</div>
          <div className="text-asi-disabled">Para que podamos seguir con tu contrataci&oacute;n</div>
        </div>
        </Stack>
      </Grid>
      <hr/>
    </>
  );
}

const mapStateToProps = state => ({
  preOrder: state.preOrder
});
export default connect(mapStateToProps)(TitleStepTwoDisabled)