import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Grid } from '@material-ui/core';
import './Inicio.css';
import { Box, Link, List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Grid}`,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  backgroundColor: 'transparent'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  color: '#595959',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(-1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, 0)',

}));

export default function PreguntasFrecuentes() {

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }

  const tab = <>&nbsp;&nbsp;</>;
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="textPF">
          Preguntas frecuentes
        </div>
      </Grid>
      <div style={{ paddingTop: '30px' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={expanded === `panel1` ? <RemoveIcon /> : <AddIcon />}
            aria-controls="panel1d-content" id="panel1d-header"
          >
            <Typography>
              <div>
                <span className='acordeon-titulo'>&iquest;C&oacute;mo hago uso de mi plan de mascota?</span>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className='acordeon-content'>
                &iexcl;Esperamos que no suceda!, pero si tu mascota llega a tener un accidente o enfermedad, debes notificar a la aseguradora en un lapso no mayor a 24 horas despu&eacute;s de que se presente su enfermedad o tenga su accidente. Ellos te indicar&aacute;n lo que deber&aacute;s solicitarle a tu veterinario y c&oacute;mo aplicar el reembolso correspondiente.
                Ac&aacute; el n&uacute;mero al que deber&aacute;s llamar en esos casos: <b>55 442 46837</b>.{tab}
                Agenda tu video consulta en el <b>55 442 46837</b> o solicita el reembolso de cada una de tus asistencias, fallecimiento o eutanasia.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={expanded === `panel2` ? <RemoveIcon /> : <AddIcon />}
            aria-controls="panel2d-content" id="panel2d-header"
          >
            <Typography>
              <span className='acordeon-titulo'>
                &iquest;Contra qu&eacute; riesgos y/o enfermedades cubren los planes de mascota segura?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className='div-respuesta2'>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <div className='pregunta2'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='div-pregunta2'>
                          <span className='text-div-pregunta2'>Accidentes </span>
                        </div>
                      </Grid>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <List>
                          <ListItem>
                            <ListItemText>
                              <ListItemIcon>
                                <div>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Atropellamiento</span>
                                </div>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Ca&iacute;das</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Ataques de otras mascotas o animales</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Envenenamiento accidental</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Ingesta de cuerpos extraños</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Golpe de calor</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Cualquier hecho derivado de una causa violenta, s&uacute;bita, imprevista, externa y no intencional que produzca daños a la mascota</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <ListItemIcon>
                                <div>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Eutanasia</span>
                                </div>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <ListItemIcon>
                                <div>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Gastos funerarios</span>
                                </div>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <ListItemIcon>
                                <div>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Responsabilidad civil por tenencia de mascota</span>
                                </div>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Lesiones corporales</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Enfermedades</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Muerte</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Destrucci&oacute;n de bienes</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Stack>
                    </Grid>
                  </div>

                  <div className='div-gmm'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className='div-pregunta2'>
                        <span className='text-div-pregunta2'>Gastos M&eacute;dicos Mayores</span>

                      </div>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <List>
                          <ListItem>
                            <ListItemText>
                              <ListItemIcon>
                                <div>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                    {tab}
                                    <span className='text-content-pregunta2'>Enfermedades y Accidentes</span>
                                  </Grid>
                                </div>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Honorarios M&eacute;dicos</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>An&aacute;lisis</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Estudios</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Radiograf&iacute;as</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText inset>
                              <ListItemIcon>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                  {tab}
                                  <span className='text-content-pregunta2'>Medicamentos</span>
                                </Grid>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <ListItemIcon>
                                <div>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                    {tab}
                                    <span className='text-content-pregunta2'>Daños a terceros</span>
                                  </Grid>
                                </div>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <ListItemIcon>
                                <div>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                    {tab}
                                    <span className='text-content-pregunta2'>Atenci&oacute;n M&eacute;dica</span>
                                  </Grid>
                                </div>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <ListItemIcon>
                                <div>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                    {tab}
                                    <span className='text-content-pregunta2'>Atenci&oacute;n Preventiva</span>
                                  </Grid>
                                </div>
                              </ListItemIcon>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Stack>
                    </Grid>
                  </div>

                </Stack>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={expanded === `panel3` ? <RemoveIcon /> : <AddIcon />}
            aria-controls="panel3d-content" id="panel3d-header"
          >
            <Typography>
              <span className='acordeon-titulo'>
                &iquest;Qu&eacute; no cubre el plan de Mascota segura?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className='div-respuesta2'>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <div className='pregunta-tres'>
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Mascotas no declaradas</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Cualquier perrito de trabajo, cacer&iacute;a, guardia y/o protecci&oacute;n y/&oacute; b&uacute;squeda y rescate de personas</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Animalitos cuya vivienda o ambiente sea deficiente o de baja calidad higi&eacute;nica.</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Enfermedades cong&eacute;nitas y hereditarias</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Distocia, ces&aacute;rea o parto</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Esterilizaci&oacute;n o problemas de fertilizaci&oacute;n</span>
                      </Grid>
                    </Stack>
                  </div>

                  <div className='pregunta-tres'>
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-star"
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Lesiones por peleas intencionales</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Tratamientos y/o padecimientos o enfermedades de tu michi o lomito previas al contrato del seguro.</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Tratamiento para enfermedades del comportamiento</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Maltrato animal y/o culpa grave de los dueños de la mascota asegurada</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                        {tab}
                        <span className='text-content-pregunta2'>Actos dolosos o intencionales contra la mascota asegurada</span>
                      </Grid>
                    </Stack>
                  </div>
                </Stack>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={expanded === `panel4` ? <RemoveIcon /> : <AddIcon />}
            aria-controls="panel4d-content" id="panel4d-header"
          >
            <Typography>
              <span className='acordeon-titulo'>
                &iquest;A qu&eacute; veterinario o cl&iacute;nica puedo llevar a mi mascota?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span className='acordeon-content'>
                &iexcl;Quien te guste a ti nos gusta a nosotros! <b>Ve con tu veterinario o cl&iacute;nica de confianza</b> con toda comodidad. Nada m&aacute;s <b>no olvides pedir tu factura</b> por el tratamiento o procedimientos hechos para poder solicitar tu reembolso.
              </span>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ mt: 6 }}>
          <Grid container direction="row" alignItems="center" justifyContent="center">
              <span className='seccion-preguntas'>Para m&aacute;s info consulta nuestra secci&oacute;n de</span>{tab}
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigate('/questions');
                }}
              >
                <span className='preguntas-link'>preguntas frecuentes</span>
              </Link>
          </Grid>
        </Box>
      </div>
    </>
  );
}
