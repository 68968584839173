
import Select from '@mui/material/Select';
import { useField } from "formik";
import React from "react";
import CustomErrorMessage from "./CustomErrorMessage";

export default function CustomSelect({ placeholder, ...props }) {
  const [field, meta, validate] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <Select {...field} {...props} validate={validate} error={!!errorText} />
      <CustomErrorMessage name={field.name}/>
    </>
  );
}
