import * as React from 'react';
import './PreguntasFrecuentesView.css';
import { Box, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import preguntasDesk from '../../../assets/img/preguntasFrecuentes/PreguntasFrecuentes-Desk.png';
import preguntasMobile from '../../../assets/img/preguntasFrecuentes/PreguntasFrecuentes-Mobile.png';
import preguntasTablet from '../../../assets/img/preguntasFrecuentes/PreguntasFrecuentes-Tablet.png';
import CircleIcon from '@mui/icons-material/Circle';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Vacuna from '../../../assets/img/preguntasFrecuentes/icono-vacuna.svg';
import Hospedaje from '../../../assets/img/preguntasFrecuentes/icono-hospedaje.svg';
import Bano from '../../../assets/img/preguntasFrecuentes/icono-baño.svg';
import Estetica from '../../../assets/img/preguntasFrecuentes/icono-estetica.svg';
import TablaPreguntas from '../../../components/TablaPreguntas/TablaPreguntas';
import Gato from '../../../assets/img/preguntasFrecuentes/Vector-gato.svg';
import Perro from '../../../assets/img/preguntasFrecuentes/Vector-perro.svg';
import Aseguradora from '../../../assets/img/preguntasFrecuentes/logo-GMX-azul.svg';
import Inter from '../../../assets/img/preguntasFrecuentes/Logo-Inter-azul.svg';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Grid}`,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  backgroundColor: '#ffffff',
  boxShadow: '4px 6px 8px rgba(33, 33, 33, 0.05)',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  color: '#fff',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(-1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '2px solid rgba(119, 119, 119, 1) ',

}));


export default function PreguntasFrecuentesView(props) {

  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState('');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }

  const tab = <>&nbsp;&nbsp;</>;

  const handleRegresarInicio = () => {
    navigate('/home')
  }

  return (
    <>
      <div style={{ position: 'relative' }}>

        <div className='div-preguntas'>
          <Container maxWidth="lg" >
            <div className='circulo-preguntas-gde'></div>
            <div className='circulo-preguntas'></div>
            <div className='circulo-gde-preguntas'></div>
            <div className='circulo-chico-preguntas'></div>
            <div style={{ position: 'relative' }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <div className='titulo-img'>
                  <div className='img-abajo'>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <div className='div-titulo'>
                        <IconButton className="back-button" onClick={handleRegresarInicio}>
                          <ArrowBackIcon />
                        </IconButton>
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <span className='titulo-preguntas'>
                              Nos han hecho muchas preguntas y aqu&iacute; te dejamos las m&aacute;s frecuentes para que puedas resolver tus dudas
                            </span>
                          </Grid>
                        </Stack>
                      </div>

                      <div className='div-img'>
                        <Grid direction="column"
                          justifyContent="center"
                          alignItems="center">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className=''>
                              <img src={preguntasDesk}
                                srcSet={`${preguntasDesk} 1440w,${preguntasTablet} 768w,${preguntasMobile} 360w`} alt='' />
                            </div>

                          </Grid>
                        </Grid>
                      </div>

                    </Stack>
                  </div>
                  <div className='img-arriba'>
                    <Stack
                      direction={{ xs: 'column', md: 'column', lg: 'row' }}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <div className='div-tablet-img2'>
                        <Grid direction="column"
                          justifyContent="center"
                          alignItems="center">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className=''>
                              <img src={preguntasDesk}
                                srcSet={`${preguntasDesk} 1440w,${preguntasTablet} 768w,${preguntasMobile} 360w`} alt='' />
                            </div>

                          </Grid>
                        </Grid>
                      </div>
                      <div className='div-tablet-titulo2'>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >

                          <IconButton className="back-button" onClick={handleRegresarInicio}>
                            <ArrowBackIcon />
                          </IconButton>
                        </Grid>
                        <Grid direction="column"
                          justifyContent="center"
                          alignItems="center">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className=''>
                              <span className='titulo-preguntas'>
                                Nos han hecho muchas preguntas y aqu&iacute; te dejamos las m&aacute;s frecuentes para que puedas resolver tus dudas
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Stack>
                  </div>
                </div>
              </Grid>
            </div>


            <Box className='py'>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                style={{
                  zIndex: 1000,
                  position: ' relative',
                }}
              >
                <AccordionSummary
                  expandIcon={expanded === `panel1` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  aria-controls="panel1d-content" id="panel1d-header"
                  className='mx'
                >
                  <Typography>
                    <div>
                      <span className='acordeon-titulo'>
                        &iquest;Tengo deducible?
                      </span>
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className='mx'
                >
                  <Typography>
                    <p className='acordeon-content'>
                      &iexcl;Tremendas noticias! Con que superes los $1 000 pesos de reclamo por accidente o enfermedad, te cubrimos desde el primer peso <b>SIN DEDUCIBLE</b>.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel2` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel1d-content" id="panel1d-header"
                    className='mx'
                  >
                    <Typography>
                      <div>
                        <span className='acordeon-titulo'>
                          &iquest;C&oacute;mo decido el mejor plan?
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <p className='acordeon-content'>
                        <b>Considera que la suma asegurada es el monto m&aacute;ximo que te cubre la aseguradora y s&oacute;lo se re-instala como
                          refill para otros accidentes o enfermedades nuevas que tenga tu mascota</b>; nosotros recomendamos como paquete
                        b&aacute;sico el paquete de 20,000 pero si amas a tu perro y quieres cuidar tu econom&iacute;a elige el paquete de 30,000 pesos,
                        no imaginas lo que puedes gastar en un tema que requiera estudios y hospitalización m&eacute;dica para tu mejor amigo.
                      </p>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel3'}
                  onChange={handleChange('panel3')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel3` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel2d-content" id="panel2d-header"
                    className='mx'
                  >
                    <Typography>
                      <span className='acordeon-titulo'>
                        &iquest;Contra qu&eacute; riesgos y/o enfermedades cubren los planes de mascota segura?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <div className='div-respuesta2'>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          justifyContent="center"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <div className='div-gmm'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className='div-pregunta2-gmm'>
                                  <span className='text-div-pregunta2'>Accidentes </span>
                                </div>
                              </Grid>
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-start"
                                style={{ padding: 5 }}
                              >
                                <List>
                                  <ListItem>
                                    <ListItemText>
                                      <ListItemIcon>
                                        <div>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Atropellamiento</span>
                                        </div>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Ca&iacute;das</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Ataques de otras mascotas o animales</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Envenenamiento accidental</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Ingesta de cuerpos extraños</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Golpe de calor</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Cualquier hecho derivado de una causa violenta, s&uacute;bita, imprevista, externa y no intencional que produzca daños a la mascota</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText>
                                      <ListItemIcon>
                                        <div>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Eutanasia</span>
                                        </div>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText>
                                      <ListItemIcon>
                                        <div>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Gastos funerarios</span>
                                        </div>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText>
                                      <ListItemIcon>
                                        <div>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Responsabilidad civil por tenencia de mascota</span>
                                        </div>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Lesiones corporales</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Enfermedades</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Muerte</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Destrucci&oacute;n de bienes</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                </List>
                              </Stack>
                            </Grid>
                          </div>

                          <div className='div-gmm'>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className='div-pregunta2-gmm'>
                                  <span className='text-div-pregunta2'>Gastos M&eacute;dicos Mayores</span>
                                </div>
                              </Grid>
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-start"
                                style={{ padding: 5 }}
                              >
                                <List>
                                  <ListItem>
                                    <ListItemText>
                                      <ListItemIcon>
                                        <div>
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                            {tab}
                                            <span className='text-content-pregunta2'>Enfermedades y Accidentes</span>
                                          </Grid>
                                        </div>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Honorarios M&eacute;dicos</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>An&aacute;lisis</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Estudios</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Radiograf&iacute;as</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem disablePadding>
                                    <ListItemText inset>
                                      <ListItemIcon>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                          {tab}
                                          <span className='text-content-pregunta2'>Medicamentos</span>
                                        </Grid>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText>
                                      <ListItemIcon>
                                        <div>
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                            {tab}
                                            <span className='text-content-pregunta2'>Daños a terceros</span>
                                          </Grid>
                                        </div>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText>
                                      <ListItemIcon>
                                        <div>
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                            {tab}
                                            <span className='text-content-pregunta2'>Atenci&oacute;n M&eacute;dica</span>
                                          </Grid>
                                        </div>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText>
                                      <ListItemIcon>
                                        <div>
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                            {tab}
                                            <span className='text-content-pregunta2'>Atenci&oacute;n Preventiva</span>
                                          </Grid>
                                        </div>
                                      </ListItemIcon>
                                    </ListItemText>
                                  </ListItem>
                                </List>
                              </Stack>
                            </Grid>
                          </div>

                        </Stack>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel4'}
                  onChange={handleChange('panel4')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel4` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel1d-content" id="panel1d-header"
                    className='mx'
                  >
                    <Typography>
                      <div>
                        <span className='acordeon-titulo'>
                          &iquest;C&oacute;mo hago uso de mi plan de mascota?
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <p className='acordeon-content'>
                        &iexcl;Esperamos que no suceda!, pero si tu lomito o michi llega a tener un accidente o enfermedad, debes notificar
                        a la aseguradora en un lapso no mayor a 24 horas despu&eacute;s de que se presente su enfermedad o tenga su accidente.
                        Ellos te indicar&aacute;n lo que deber&aacute;s solicitarle a tu veterinario y c&oacute;mo aplicar el reembolso correspondiente.
                        Ac&aacute; el n&uacute;mero al que deber&aacute;s llamar en esos casos: <b>55 442 46837</b>.
                        Agenda tu video consulta en el <b>55 442 46837</b> o solicita el reembolso de cada una de tus asistencias, fallecimiento
                        o eutanasia.
                      </p>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel5` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel3d-content" id="panel3d-header"
                    className='mx'
                  >
                    <Typography>
                      <span className='acordeon-titulo'>
                        &iquest;Qu&eacute; no cubre el plan de Mascota segura?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <div className='div-respuesta2'>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <div className='pregunta-tres'>
                            <Stack
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                            >
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Mascotas no declaradas</span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Cualquier perrito de trabajo, cacer&iacute;a, guardia y/o protecci&oacute;n y/&oacute; b&uacute;squeda y rescate de personas</span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Animalitos cuya vivienda o ambiente sea deficiente o de baja calidad higi&eacute;nica.</span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Enfermedades cong&eacute;nitas y hereditarias</span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Distocia, ces&aacute;rea o parto</span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Esterilizaci&oacute;n o problemas de fertilizaci&oacute;n</span>
                              </Grid>
                            </Stack>
                          </div>

                          <div className='pregunta-tres'>
                            <Stack
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-star"
                            >
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Lesiones por peleas intencionales</span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Tratamientos y/o padecimientos o enfermedades de tu michi o lomito previas al contrato del seguro.</span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Tratamiento para enfermedades del comportamiento</span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Maltrato animal y/o culpa grave de los dueños de la mascota asegurada</span>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CircleIcon sx={{ fontSize: 8, color: grey[900] }} />
                                {tab}
                                <span className='text-content-pregunta2'>Actos dolosos o intencionales contra la mascota asegurada</span>
                              </Grid>
                            </Stack>
                          </div>
                        </Stack>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <div className='circulo-preguntas2-gde '></div>
              <div className='circulo-preguntas2'></div>
              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel6'}
                  onChange={handleChange('panel6')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel6` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel4d-content" id="panel4d-header"
                    className='mx'
                  >
                    <Typography>
                      <span className='acordeon-titulo'>
                        &iquest;A qu&eacute; veterinario o cl&iacute;nica puedo llevar a mi mascota?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <span className='acordeon-content'>
                        &iexcl;Quien te guste a ti nos gusta a nosotros! <b>Ve con tu veterinario o cl&iacute;nica de confianza</b> con toda comodidad. Nada m&aacute;s <b>no olvides pedir tu facturita</b> por el tratamiento o procedimientos hechos para poder solicitar tu reembolso.
                      </span>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel7'}
                  onChange={handleChange('panel7')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel7` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel1d-content" id="panel1d-header"
                    className='mx'
                  >
                    <Typography>
                      <div>
                        <span className='acordeon-titulo'>
                          &iquest;Que excluye mi servicio?
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <span className='acordeon-content'>
                        Enfermedades que no cubran el plazo de espera establecido
                        Enfermedades preexistentes
                        Si el Asegurado no proporciona o facilita la obtención del expediente cl&iacute;nico o historia cl&iacute;nica de la mascota.
                        Enfermedades cong&eacute;nitas y hereditarias, tales como:
                        <Box sx={{ pl: 8 }}>
                          <p>• Displasia de codo</p>
                          <p>• Displasia de cadera</p>
                          <p>• Luxaci&oacute;n patelar cong&eacute;nita</p>
                          <p>• S&iacute;ndrome del braquic&eacute;falo</p>
                          <p>• Criptorquidismo</p>
                        </Box>
                        Limpieza dental est&eacute;tica y/o preventiva
                        Enfermedades del comportamiento y dem&aacute;s especificadas en las condiciones generales de la p&oacute;liza.
                      </span>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel8'}
                  onChange={handleChange('panel8')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel8` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel1d-content" id="panel1d-header"
                    className='mx'
                  >
                    <Typography>
                      <div>
                        <span className='acordeon-titulo'>
                          &iquest;C&oacute;mo funcionan las asistencias?
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <p className='acordeon-content'>
                        &iexcl;Las asistencias de Mascota Segura son lo m&aacute;ximo! <b>&uacute;salas una vez al año sin gastar un solo peso de la suma de tu cobertura
                          destinada a accidentes</b>, enfermedades, travesuras o gastos de fallecimiento.<br /><b>&iquest;C&oacute;mo est&aacute; la cosa?</b>, dir&aacute;s. &iexcl;Es s&uacute;per f&aacute;cil!:
                        tu eliges a tu proveedor del servicio y si, por ejemplo, el costo del baño para un perro de 15 KG es de $350 MXN,
                        te reembolsaremos $280 (checa el tabulador para cada caso particular).
                      </p>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <div className='tabulador'>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                          >
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              spacing={1}
                            >
                              <div className='regresan1'>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <img src={Vacuna} alt='' align='center' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <span>Vacuna antirr&aacute;bica o desparacitaci&oacute;n</span>
                                </Grid>
                              </div>
                            </Stack>
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              spacing={1}
                            >
                              <div className='regresan2'>
                                <Box sx={{ pt: 2 }}>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <span className='vacuna'>TE REGRESAN HASTA</span>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <span className='precio'>$200</span>
                                  </Grid>
                                </Box>
                              </div>
                            </Stack>
                          </Stack>
                        </div>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className='tabulador'>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              spacing={3}
                            >
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <div className='regresan'>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <img src={Hospedaje} alt='' align='center' />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <span>Hospedaje por si
                                      te hospitalizan</span>
                                  </Grid>
                                </div>
                              </Stack>

                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <div className='regresan2'>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <span className='vacuna'>TE REGRESAN HASTA</span>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <span className='precio'>$200</span>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <span className='vacuna'>POR DÍA</span>
                                  </Grid>
                                </div>
                              </Stack>
                            </Stack>
                          </div>

                        </Grid>
                      </Stack>


                      <Box sx={{ pt: 4 }}>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <div className='tabulador'>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <div className='baño-img'>
                                  <Box sx={{ pt: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <img src={Bano} alt='' align='center' />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <span className='baño-titulo'>Baño</span>
                                    </Grid>
                                  </Box>
                                </div>
                              </Stack>
                              <div className='baño-descripcion'>
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  spacing={1}
                                >

                                  <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={0}
                                  >
                                    <div className='div-kg'>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='baño-text-descripcion'>Menos de 10kg</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='baño-text-descripcion'>de 11 a 20kg</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='baño-text-descripcion'>de 21 a 40kg</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='baño-text-descripcion'>Más de 40kg</span>
                                      </Grid>
                                    </div>
                                  </Stack>

                                  <div className='div-costo'>
                                    <Stack
                                      direction="column"
                                      justifyContent="center"
                                      alignItems="center"
                                      spacing={0}
                                    >
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='costo-baño'>$200</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='costo-baño'>$280</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='costo-baño'>$330</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='costo-baño'>$390</span>
                                      </Grid>
                                    </Stack>
                                  </div>
                                </Stack>
                              </div>
                            </Stack>
                          </div>

                          <div className='tabulador'>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <div className='baño-img'>
                                  <Box sx={{ pt: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <img src={Estetica} alt='' align='center' />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <span className='baño-titulo'>Est&eacute;tica</span>
                                    </Grid>
                                  </Box>
                                </div>
                              </Stack>
                              <div className='baño-descripcion'>
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={0}
                                  >
                                    <div className='div-kg'>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='baño-text-descripcion'>Menos de 10kg</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='baño-text-descripcion'>de 11 a 20kg</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='baño-text-descripcion'>de 21 a 40kg</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='baño-text-descripcion'>M&aacute;s de 40kg</span>
                                      </Grid>
                                    </div>
                                  </Stack>

                                  <div className='div-costo'>
                                    <Stack
                                      direction="column"
                                      justifyContent="center"
                                      alignItems="center"
                                      spacing={0}
                                    >
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='costo-baño'>$280</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='costo-baño'>$320</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='costo-baño'>$380</span>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className='costo-baño'>$530</span>
                                      </Grid>
                                    </Stack>
                                  </div>
                                </Stack>
                              </div>
                            </Stack>
                          </div>
                        </Stack>
                      </Box>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel9'}
                  onChange={handleChange('panel9')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel9` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel1d-content" id="panel1d-header"
                    className='mx'
                  >
                    <Typography>
                      <div>
                        <span className='acordeon-titulo'>
                          &iquest;Qu&eacute; es un periodo de espera?
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <p className='acordeon-content'>
                        Todos los planes cuentan con un lapso de tiempo que debe pasar para que puedas recibir los s&uacute;per beneficios
                        del seguro una vez que haya iniciado la vigencia del plan que hayas contratado.
                        Para todos los planes, en caso de accidente, hay un periodo de espera de 7 d&iacute;as una vez contratada tu p&oacute;liza.
                        <br /><b>Como los periodos de tiempo varían para cada plan en casos de padecimientos, enfermedades o tratamientos,
                          te damos esta tablita:</b>
                      </p>
                      <div>
                        <TablaPreguntas />
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel10'}
                  onChange={handleChange('panel10')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel10` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel1d-content" id="panel1d-header"
                    className='mx'
                  >
                    <Typography>
                      <div>
                        <span className='acordeon-titulo'>
                          &iquest;Con qu&eacute; medios de pagos cuentan?
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <p className='acordeon-content'>
                        &iexcl;No queremos que tengas ning&uacute;n problema con tu pago! Por eso, puedes pagar con tarjetas de cr&eacute;dito,
                        d&eacute;bito o PayPal. <b>Y si tu banco est&aacute; inclu&iacute;do en nuestra lista de convenios, puedes elegir la opci&oacute;n
                          de hacer tus compra a meses sin intereses.</b>
                      </p>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box sx={{ py: 2 }}>
                <Accordion
                  expanded={expanded === 'panel11'}
                  onChange={handleChange('panel11')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel11` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel1d-content" id="panel1d-header"
                    className='mx'
                  >
                    <Typography>
                      <div>
                        <span className='acordeon-titulo'>
                          &iquest;C&oacute;mo s&eacute; si puedo asegurar a mi mascota?
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>

                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <div className='div-michis-lomito'>
                            <Box sx={{ pt: 2 }}>
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <div className='div-img-gato'>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <img src={Gato} alt='' align='center' />
                                  </Grid>
                                </div>
                              </Stack>
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <Box sx={{ pt: 4 }}>
                                  <div className='body-text-gato'>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <span className='titulo-michi'>
                                        En el caso de michis (gatitos):
                                      </span>
                                    </Grid>
                                    <Box sx={{ pt: 0 }}>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{ pl: 2 }}>
                                          <p className='text-michi'>• Que tengan entre dos meses y nueve años cumplidos a la fecha de inicio de vigencia de la p&oacute;liza.</p>
                                          <p className='text-michi'>•  Que vivan exclusivamente en casa habitaci&oacute;n. No podemos asegurar gatitos trotamundos</p>
                                        </Box>
                                      </Grid>
                                    </Box>
                                  </div>
                                </Box>
                              </Stack>
                            </Box>
                          </div>
                        </Stack>

                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <div className='div-michis-lomito'>
                            <Box sx={{ pt: 2 }}>
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <div className='div-img-perro'>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <img src={Perro} alt='' align='center' />
                                  </Grid>
                                </div>
                              </Stack>
                            </Box>
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              spacing={1}
                            >
                              <Box sx={{ pt: 4 }}>
                                <div className='body-text-perro'>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <span className='titulo-michi'>
                                      Para lomitos (perritos):
                                    </span>
                                  </Grid>
                                  <Box sx={{ pt: 0 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <Box sx={{ pl: 2 }}>
                                        <p className='text-michi'>• Que tengan entre dos meses y nueve años cumplidos a la fecha de inicio de vigencia de la p&oacute;liza.</p>
                                        <p className='text-michi'>• Como los gatitos, que vivan exclusivamente en casa habitaci&oacute;n.</p>
                                        <p className='text-michi'>• No aseguramos perritos chambeadores ):, de modo
                                          que no deber&aacute;n realizar actividades de trabajo, caza, guardia, protecci&oacute;n o b&uacute;squeda y rescate de personas.</p>
                                      </Box>
                                    </Grid>
                                  </Box>
                                </div>
                              </Box>
                            </Stack>
                          </div>
                        </Stack>
                      </Stack>
                      <Box sx={{ mt: 2 }}>
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <div className='div-desc-michis-lomito'>
                            <span>Además de esto, tanto perritos como gatitos no deberán tener enfermedades como insuficiencia renal o cáncer al momento del contrato.</span>
                          </div>
                        </Stack>
                      </Box>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel12'}
                  onChange={handleChange('panel12')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel12` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel1d-content" id="panel1d-header"
                    className='mx'
                  >
                    <Typography>
                      <div>
                        <span className='acordeon-titulo'>
                          &iquest;Qu&eacute; aseguradora me respalda?
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                      >
                        <div className='div-img-aseguradora'>
                          <Box sx={{ pt: 0, mb: 4 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <img src={Aseguradora} alt='' align='center' />
                            </Grid>
                          </Box>
                        </div>
                        <div className='div-aseguradora'>

                          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ pt: 1, }}>
                              <span className='body-asguradora'>
                                <b>GMX es una s&uacute;per empresa mexicana de seguros especializada en Responsabilidad Civil y Daños,
                                  regulada y avalada por la CONDUSEF</b>. &iexcl;No podemos pensar en un aliado mejor para ti! Ya que GMX
                                cuenta con m&aacute;s de 20 años de trayectoria en el mercado de los seguros y se ha distinguido por crear
                                m&uacute;ltiples productos que se adaptan a distintas situaciones de riesgo, estamos seguros de que ser&aacute;n
                                la aseguradora que estabas buscando.
                              </span>
                            </Box>
                          </Grid>
                        </div>

                      </Stack>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box py={2}>
                <Accordion
                  expanded={expanded === 'panel13'}
                  onChange={handleChange('panel13')}
                  style={{
                    zIndex: 1000,
                    position: ' relative',
                  }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel13` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    aria-controls="panel1d-content" id="panel1d-header"
                    className='mx'
                  >
                    <Typography>
                      <div>
                        <span className='acordeon-titulo'>
                          &iquest;A d&oacute;nde puedo llamar en caso de dudas sobre mi p&oacute;liza?
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className='mx'
                  >
                    <Typography>
                      <Stack
                        direction={{ xs: 'column', md: 'row', }}
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <img src={Inter} alt='' align='center' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className='div-inter'>
                            <span className='text-inter-preguntas'>Llamanos o escribenos
                              55 442 46837
                              hola@inter.mx</span>
                          </div>
                        </Grid>
                      </Stack>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Container >
        </div >
      </div >
    </>
  );

}
