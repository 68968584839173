import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import Loading from "../../components/Loading/Loading";
import { getUrlParams, preInfoToPreOrder, TagManagerLayer } from "../../components/Utils/utils";
import apiWeb from "../../lib/apiConfig/apiWeb";
import moment from "moment/moment";

const ConfigPayment = (props) => {
  const navigate = useNavigate();
  let params = (new URL(document.location)).hash;
  const [loading, setLoading] = useState(true)


  const sendTagManagerArgs = async (data) => {
    const D = new Date();
    let dd = D.getDate().toString();
    let mm = (D.getMonth() + 1).toString();
    D.setFullYear(D.getFullYear() + 1)
    let yyyy = D.getFullYear().toString();
    const result = (dd[1] ? dd : "0" + dd[0]) + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + yyyy;

    let tagManagerArgs = {
      event: "mascota_seguro_pago_finalizado",      //Static data,
      no_poliza: data.poliza.Poliza,
      vigencia: result,
      boton_finalizado: "Descargar póliza"
    }
    await TagManagerLayer(tagManagerArgs)
  }

  const sendPayment = (data) => {

    const D = new Date();
    let dd = D.getDate().toString();
    let mm = (D.getMonth() + 1).toString();
    D.setFullYear(D.getFullYear() + 1)
    let yyyy = D.getFullYear().toString();
    let today = new Date()
    let initDate = moment(today).format("DD/MM/YYYY")
    let endDate = (dd[1] ? dd : "0" + dd[0]) + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + yyyy;

    let params_ = {
      "business": "medipet",
      "insurance": "gmx",
      "reference": data.poliza.ReferenciaSSV,
      "encReference": data.poliza.Referencia,
      "startDate": initDate + "",
      "endDate": endDate + "",
      'collaborator_number':props.preOrder.collaborator_number,
      "correo": props.preOrder.email === "hola@inter.mx" ?
        props.preOrder.fromContratante ?
          props.preOrder.fromContratante.roleType === 'si' ?
            props.preOrder.fromContratante.email
            :
            props.preOrder.fromContratante.emailOwner === "" ? props.preOrder.fromContratante.email : props.preOrder.fromContratante.emailOwner
          : props.preOrder.email : props.preOrder.email,
      "nombrePersona": props.preOrder.fromContratante.roleType === "si" ? props.preOrder.fromContratante.nombres : props.preOrder.fromContratante.nombresOwner,
      "nombreMascota": data.nombre,
      "planElegido": props.preOrder.selectedPlan.PlanNombreInter,
      folio: props.preOrder.folio
    }

    apiWeb.postPayments(params_).then(response => {
    }).catch(e => {
    });
  }


  const findPet = async (queryString, pets) => {
    let osArray = pets;
    let elementIndex = osArray.findIndex((obj => obj.referencia === queryString.ReferenciaSSV));
    osArray[elementIndex].isPago = queryString.isPago === "true" ? true : false;
    osArray[elementIndex].poliza = queryString;
    await sendTagManagerArgs(osArray[elementIndex])
    await props.addInfoToPreOrder({ addPets: osArray }, saveLeadLocal(osArray[elementIndex]))
  }
  function disableBackButton() {
    setTimeout(() => {
      navigate("/foundation-inter")
      setLoading(false)
    }, 5000)

  }

  const saveData = () => {
    if (props.preOrder) {
      let pre_order = {
        ...props.preOrder,
        ...{
          "etapa": "1",
          "subEtapa": "1",
          "pantalla": "5",
          isEmailNeeded: false,
          'collaborator_number':props.preOrder.collaborator_number,
          "email": props.preOrder.email === "hola@inter.mx" ?
            props.preOrder.fromContratante ?
              props.preOrder.fromContratante.roleType === 'si' ?
                props.preOrder.fromContratante.email
                :
                props.preOrder.fromContratante.emailOwner === "" ? props.preOrder.fromContratante.email : props.preOrder.fromContratante.emailOwner
              : props.preOrder.email : props.preOrder.email
        }
      }
      let params_ = preInfoToPreOrder(pre_order)
      apiWeb.saveLead(params_).then(res => {
        if (res.data.code === 200) {
        }
        disableBackButton()
      }).catch(e => {
        console.log(e)
      });
    }
  }

  const saveLeadLocal = (data) => {
    setTimeout(() => {
      sendPayment(data)
      saveData();
    }, 10000)
  }


  useEffect(() => {
    if (props.preOrder.addPets.length === 1) {
      if (getUrlParams(params).success) {
        if (getUrlParams(params).response.isPago === "true") {
          findPet(getUrlParams(params).response, props.preOrder.addPets)
        } else {
          navigate("/confirm-payment")
        }
      }
    } else if (props.preOrder.addPets.length > 1) {
      if (getUrlParams(params).success) {
        if (getUrlParams(params).response.isPago === "true") {
          findPet(getUrlParams(params).response, props.preOrder.addPets)
        } else {
          navigate("/confirm-payment")
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preOrder.addPets])



  return (<>
    {
      loading &&
      <Loading />
    }
  </>)
}
const mapStateToProps = state => ({
  preOrder: state.preOrder,
  config: state.config
});
export default connect(mapStateToProps, { addInfoToPreOrder })(ConfigPayment);
