import { Box, Button, Checkbox, FormControlLabel, FormHelperText, Grid, IconButton, Stack } from '@mui/material';
import * as React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import './ModalFundacion.css'
import fundacion from '../../assets/modalimg/Popup-Fundacion.png';
import fundacionMobile from '../../assets/modalimg/manos-mobile.png';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";


export default function ModalFundacion({ item = null, ...props }) {

  const navigate = useNavigate();

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);

  }


  const handleClickModal = () => {
    props.handleClose();
  }

  const { handleSubmit, register, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    let verdadero = data.checkbox;
    navigate('/ya-seguro');
    /* if (verdadero === true) {
       navigate('/ya-seguro');
     }*/
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IconButton
          aria-label="close"
          onClick={handleClickModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelIcon />
        </IconButton>
        <Box sx={{ mt: 8, px: 2, pb: 4 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div className='div-titulo-text-f'>
              <span className='span-titulo-text-f'>
                &iexcl;Gracias por sumarte al cambio!
              </span>
            </div>
            <Box sx={{ mt: 3 }}>
              <div className='div-image-f '>
                <picture>
                  <source media="(min-width: 1199px)" srcSet={fundacion} />
                  <source media="(min-width: 1440px)" srcSet={fundacion} />
                  <source media="(min-width: 1024px)" srcSet={fundacion} />
                  <source media="(min-width: 768px)" srcSet={fundacion} />
                  <source media="(min-width: 320px)" srcSet={fundacionMobile} />
                  <img src={fundacion} alt="fundacion" />
                </picture>
              </div>
            </Box>
            <Box sx={{ mt: 3 }}>
              <div className='div-desc-f'>
                <span className='span-text-desc-f'>
                  En un momento te enviaremos por correo tu certificado de apoyo a <b>{item && item.name}</b>.
                  Ah&iacute; tambi&eacute;n podr&aacute;s enterarte de todo sobre esta iniciativa y lo que hacemos en <b>Fundaci&oacute;n INTER.</b>
                </span>
              </div>
            </Box>
            <Box sx={{ mt: 6 }}>
              <div className='div-text-news-f'>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={-3}
                >
                  <FormControlLabel
                    control={
                      <Checkbox name="checkbox"
                        /*{...register("checkbox", { required: "Se requiere seleccionar la casilla de verificación" })} */ />
                    }
                  />
                  <span className='span-text-news-f '>Quiero suscribirme al newsletter de <b>Fundaci&oacute;n INTER</b> para
                    conocer m&aacute;s del voluntariado y formar parte de la comunidad Inter.</span>
                </Stack>
              </div>
            </Box>
            <FormHelperText style={{ color: '#d32f2f' }}>{errors.checkbox?.message}</FormHelperText>
          </Grid>
          <Box sx={{ mt: 0 }}>
            <div>
              <Button style={{ height: 48 }} fullWidth variant="contained" disableElevation type="submit" >
                Entendido
              </Button>
            </div>
          </Box>
        </Box>
      </form >
    </>
  );
}