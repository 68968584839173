import React, { useState } from "react";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import DetailPetPayment from "./DetailPetPayment";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Grid}`,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  backgroundColor: 'transparent'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  color: '#595959',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(-1),
  },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, 0)',

}));
const PetAccordionArray = ({ vistatresphotos = false, ...props }) => {

  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : props.preOrder.addPets.length + 1);
  }

  const selectedPet = (pet) => {
    props.setOpen(true)
    props.selectedPet(pet)
  }

  return (
    <>
      {vistatresphotos === true ?
        <div style={{ width: '95%', marginBottom: 0 }}>
          {
            props.preOrder.addPets.length > 1 ?
              props.preOrder.addPets.map((item, index) => {
                return (
                  <Accordion key={index} expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={expanded === `panel${index}` ?
                        <ExpandLessIcon />
                        :
                        <ExpandMoreIcon />}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <div style={{ display: 'flex', width: 'auto', height: '100%' }}>
                            <div style={{
                              paddingLeft: 10,
                              display: 'block',
                              width: '50%',
                              height: '100%'
                            }}>
                              <span className={'title-accord-pay'}>{item.nombre}</span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DetailPetPayment vistatres={true} pet={item} onClick={() => selectedPet(item)} />
                    </AccordionDetails>
                  </Accordion>
                )
              })
              :
              (
                props.preOrder.addPets.length === 1 &&
                <div style={{ marginTop: 20 }}>
                  <DetailPetPayment vistatres={true} pet={props.preOrder.addPets[0]} onClick={() => selectedPet(props.preOrder.addPets[0])} />
                </div>
              )

          }
        </div>
        :
        <div style={{ width: '95%', marginBottom: 0 }}>
          {
            props.preOrder.addPets.length > 1 ?
              props.preOrder.addPets.map((item, index) => {
                return (
                  <Accordion key={index} expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={expanded === `panel${index}` ?
                        <ExpandLessIcon />
                        :
                        <ExpandMoreIcon />}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <div style={{ display: 'flex', width: 'auto', height: '100%' }}>
                            <div style={{
                              paddingLeft: 10,
                              display: 'block',
                              width: '50%',
                              height: '100%'
                            }}>
                              <span className={'title-accord-pay'}>{item.nombre} {item?.isPago ? "Pagado" : "Pendiente de pago"}</span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DetailPetPayment pet={item} onClick={() => selectedPet(item)} />
                    </AccordionDetails>
                  </Accordion>
                )
              })
              :
              (
                props.preOrder.addPets.length === 1 &&
                <div style={{ marginTop: 20 }}>
                  <DetailPetPayment pet={props.preOrder.addPets[0]} onClick={() => selectedPet(props.preOrder.addPets[0])} />
                </div>
              )

          }
        </div>
      }
    </>
  )
}
const mapStateToProps = state => ({
  preOrder: state.preOrder
})
export default connect(mapStateToProps)(PetAccordionArray);
