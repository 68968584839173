import axios from 'axios';
import {getLocalStorageData} from "../../components/Utils/utils";
export const domainApi = process.env.REACT_APP_PROD==="1"
    ? process.env.REACT_APP_DOMAIN_BASE
    : process.env.REACT_APP_DOMAIN_BASE_DEMO;

export const typeHttp = process.env.REACT_APP_HTTPS === "1"
    ? "https"
    : "http";

export const  urlFull = `${typeHttp}://${domainApi}`


export const config = {
    baseURL: `${urlFull}`,
    headers: {'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept"
    }
};

let APIAxios = axios.create(config);

APIAxios.interceptors.request.use (
    async function (config) {
        let token =   await  getLocalStorageData("token");
        if(token) config.headers.Authorization = `Bearer ${token.access_token}`;
        return config;
    },
    function (error) {
        return Promise.reject (error);
    }
);

// Add a response interceptor
APIAxios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    /**'Aqui interceptando el error'**/
    if(error.response.status === 403 || error.response.data.message === '401 Unauthorized'){
        window.location.href = "/";
    }
    return Promise.reject(error);
});

export default APIAxios;
