import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { Box, Button, CircularProgress, Modal, Stack } from "@mui/material";
import './PlansModal.css'
import React, { useEffect, useState } from "react";
import { currencyFormat } from "../Utils/utils";
import CheckIcon from '@mui/icons-material/Check';

export let PlanList = (props) => {

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (props.selectedPlan) {
      setSelected(props.selectedPlan.PlanCodigo)
    }
  }, [props.selectedPlan]);


  return (<>
    {
      props.loadingPlans &&
      <div style={{ width: '100%', marginTop: '15%', marginBottom: '15%' }}>
        <CircularProgress disableShrink />
        <br /><br />
        <span style={{
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 16
        }} >{'Cargando planes...'}</span>
      </div>

    }
    {
      props.plans && props.plans.map((p, i) => {
        return (
          <div key={i} className={'content-plan'} onClick={() => {
            props.setSelectChange(p)
          }}>
            <div style={{
              backgroundColor: props.selectChange ?
                props.selectChange.PlanCodigo === p.PlanCodigo ? '#039ECC' : "transparent"
                :
                selected === p.PlanCodigo ? '#039ECC' : "transparent"

            }} className={'card-plans-toolbar'}>
              <span
                style={{
                  color:
                    props.selectChange ?
                      props.selectChange.PlanCodigo === p.PlanCodigo ? 'white' : "black"
                      :
                      selected === p.PlanCodigo ? 'white' : "black"
                }}
                lassName='text-title-plan'>Plan </span>
              <span
                style={{
                  color:
                    props.selectChange ?
                      props.selectChange.PlanCodigo === p.PlanCodigo ? 'white' : "black"
                      :
                      selected === p.PlanCodigo ? 'white' : "black"
                }}
                className='text-title-plan2'>{p.PlanNombreInter}</span>
              {
                props.selectChange ?
                  props.selectChange.PlanCodigo === p.PlanCodigo &&
                  <div className={"check-select"}>
                    <CheckIcon style={{
                      color: 'white', width: "auto",
                      height: 'auto'
                    }} />
                  </div>
                  :
                  selected === p.PlanCodigo &&
                  <div className={"check-select"}>
                    <CheckIcon style={{
                      color: 'white', width: "auto",
                      height: 'auto'
                    }} />
                  </div>


              }


            </div>
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <span
                className='text-title-cost-plan'>{currencyFormat(p.PlanPrimaTotal, 0, true)}</span>
              <span className='text-title-plan-annual'>{"Anuales (IVA incluído)"}</span>
            </div>
          </div>
        )
      })
    }
  </>)
}


const PlanesModal = (props) => {
  const [selectChange, setSelectChange] = useState(null)

  return (<>
    <Modal
      open={props.visible}
      onClose={() => {
        setSelectChange(null)
        props.setVisible(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={'modal-content'}>

        <Stack spacing={2}>

          <span style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '24px',
            textAlign: 'center',
            marginBottom: 4
          }}>
            ¿Quieres cambiar de plan?
          </span>
        </Stack>

        <Stack
          textAlign={"center"}
          justifyContent={"center"}
          direction={{ xs: 'column', sm: 'column' }}
        >
          <PlanList visible={props.visible}
            plans={props.config.plans}
            loadingPlans={props.config.fetchingPlans}
            selectChange={selectChange}
            setSelectChange={setSelectChange}
            selectedPlan={props.selectedPlan} addInfoToPreOrder={props.addInfoToPreOrder} 
            folio={props.folio}/>
        </Stack>


        {

        }
        <Stack
          style={{ marginTop: "2%" }}
          textAlign={"center"}
          justifyContent={"center"}
          direction={{ xs: 'row', sm: 'row' }}
          spacing={{ xs: 6 }}
        >
          <Button
            style={{ height: 48 }}
            fullWidth
            variant="outlined"
            onClick={() => {
              setSelectChange(null)
              props.setVisible(false)
              const dataGTM = JSON.parse(sessionStorage.getItem('preOrder'));
              window.dataLayer.push({
                event : "mascota_seguro_cambiar_cotizacion",
                plan_elegido: selectChange.PlanNombreInter,
                no_cotizacion: dataGTM.folio,
                boton_contacto: 'Mejor no',                   
              })
            }}>Mejor no :)</Button>

          {
            !props.config.fetchingPlans && props.config.plans.length > 0 &&
            <Button
              style={{ height: 48 }}
              fullWidth
              variant="contained"
              onClick={() => {
                const dataGTM = JSON.parse(sessionStorage.getItem('preOrder'));
                props.setVisible(false)
                props.addInfoToPreOrder({ selectedPlan: selectChange })
                window.dataLayer.push({
                  event : "mascota_seguro_cambiar_cotizacion",
                  plan_elegido: selectChange.PlanNombreInter,
                  no_cotizacion: dataGTM.folio,
                  boton_contacto: 'Cambiar',                   
                })
              }}>{"Cambiar"}</Button>
          }

        </Stack>
      </Box>
    </Modal>
  </>)
}


const mapStateToProps = state => ({
  config: state.config
})

export default connect(mapStateToProps, { addInfoToPreOrder })(PlanesModal);