import { Box, Grid, IconButton, Stack } from '@mui/material';
import * as React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import '../../components/Commons/commons.css'
import QrCode from "qrcode"
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { connect } from "react-redux";
const PRODUCTION = true;

const IP_URL = process.env.REACT_APP_IP === "1" ? "http://192.168.1.65:3000" : "http://192.168.1.91:3000"

const ModalPhotosCel = (props) => {

  const [codigoQR, setCodigoQr] = React.useState("")

  const handleClickModal = () => {
    props.handleClose();
  }

  const getUrlPlatform = () => {
    if (window.location.hostname === 'localhost') {
      return IP_URL;
    } else {
      return 'https://' + window.location.hostname;
    }
  }

  const mobileUrl = `${getUrlPlatform()}/config-mobile/${props.folio}/${props.email}`

  React.useEffect(() => {
    QrCode.toDataURL(mobileUrl).then((string) => setCodigoQr(string))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <IconButton
        aria-label="close"
        onClick={handleClickModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 0,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CancelIcon />
      </IconButton>

      <Box sx={{ mt: 6 }}>
        <Grid
          className='modal-individual-padding'
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
          >

            <span className='text-modal-photos'>&iquest;Quieres tomarle una foto </span>
            <span className='text-modal-photos'>con tu cel?</span>
            <Box sx={{ mt: 2 }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <span className='text-pasos-modal-cel'>Sigue estos pasos:</span>
              </Stack>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <span className='text-pasos-modal'>1. Escanea este c&oacute;digo QR con la c&aacute;mara de tu celular.</span>
                <span className='text-pasos-modal'>2. Te llevaremos a una p&aacute;gina en el navegador de tu cel.</span>
                <span className='text-pasos-modal'>3. Toma una foto a tu mascota y s&uacute;bela desde tu galer&iacute;a.</span>
                <span className='text-pasos-modal-cel'>&iexcl;Y LISTO!</span>
              </Stack>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  {<img src={codigoQR} alt={""} />}
                </Box>
              </Stack>
            </Box>
          </Stack>
          <div>
          </div>
        </Grid>
      </Box>
    </>


  );
}
const mapStateToProps = state => ({
  preOrder: state.preOrder,
  config: state.config
})
export default connect(mapStateToProps, { addInfoToPreOrder })(ModalPhotosCel);
