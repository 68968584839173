import * as React from "react";
import { Box, Button, Grid, IconButton, Modal, Stack } from "@mui/material";
import './ModalPaymentType.css'
import CancelIcon from "@mui/icons-material/CancelOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import paypal from "../../assets/paypal-icon-logo.png";
import visa_master from "../../assets/icon-visa-master.png";

const payment_types = [{ id: 1, title: "Tarjeta de débito o crédito" }, { id: 2, title: "Paypal" }];


const PaymentTypesList = (props) => {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (props.selectedPayment) {
      setSelected(props.selectedPayment.id)
    }
  }, [props.selectedPayment]);
  return (<>
    {
      payment_types.map((p, i) => {
        return (
          <div key={i} className={'content-payment-type'} onClick={() => {
            props.setSelectChange(p)
          }}>
            <div style={{ width: '100%' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '70%', textAlign: 'left' }}>
                  <span className='text-title-payment-type'>{p.title}</span>
                </div>
                <div style={{ width: '30%', textAlign: 'right' }}>
                  {
                    p.title === "Paypal" ?
                      <img src={paypal} style={{ width: '23%' }} alt='' />
                      :
                      <img src={visa_master} style={{ width: '40%' }} alt='' />


                  }
                </div>

              </div>

              {
                props.selectChange ?
                  props.selectChange.id === p.id &&
                  <div className={"check-select-payment-type"}>
                    <CheckIcon style={{
                      color: 'white', width: "auto",
                      height: 'auto'
                    }} />
                  </div>
                  :
                  selected === p.id &&
                  <div className={"check-select-payment-type"}>
                    <CheckIcon style={{
                      color: 'white', width: "auto",
                      height: 'auto'
                    }} />
                  </div>
              }
            </div>
          </div>
        )
      })
    }

  </>)
}


const ModalPaymentType = (props) => {
  const [selectChange, setSelectChange] = useState(null)


  return (

    <Modal
      open={props.open}
      onClose={() => {
        props.close()
        setSelectChange(null)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={'modal-w-payment-type'}>

        <IconButton
          aria-label="close"
          onClick={() => {
            props.close()
            setSelectChange(null)

          }}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelIcon />
        </IconButton>
        <Box sx={{ mt: 6 }}>
          <Grid
            className='modal-individual-padding'
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <span className='title-modal-payment-type'>{'¿Cómo quieres pagar?'}</span>
              <Stack
                textAlign={"center"}
                justifyContent={"center"}
                direction={{ xs: 'column', sm: 'column' }}
              >
                <PaymentTypesList visible={props.visible}
                  selectChange={selectChange}
                  setSelectChange={setSelectChange}
                  selectedPayment={props.preOrder.paymentType}
                  addInfoToPreOrder={props.addInfoToPreOrder} />
              </Stack>

            </Stack>
            <Stack
              style={{ marginTop: "2%", width: '90%' }}
              textAlign={"center"}
              justifyContent={"center"}
              direction={{ xs: 'row', sm: 'row' }}
            >


              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  props.accept()
                  props.addInfoToPreOrder({ paymentType: selectChange })
                }}>{"¡VAMOS!"}</Button>
            </Stack>
          </Grid>
        </Box>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  config: state.config,
  preOrder: state.preOrder
})

export default connect(mapStateToProps, { addInfoToPreOrder })(ModalPaymentType);
