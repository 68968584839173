import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Container,
  AppBar
} from '@mui/material';
import * as React from 'react';
import '../../components/Commons/commons.css';
import logo from '../../assets/landing/LogoAZUL.svg';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { UtmURls } from '../../components/Utils/utils'


export default function Header(props) {

  const navigate = useNavigate();
  const [anchorEl2, setAnchorEl2] = React.useState();

  const handleOpenSeguros = e => {
    setAnchorEl2(e.currentTarget)
    window.dataLayer.push({
      event: 'main_menu_cs',
      option: 'Seguros'
    })
  };
  const handleCloseSeguros = () => {
    setAnchorEl2(null);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  //segndo
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl)
  const handleMovil = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const [openL, setOpenL] = React.useState(false);

  const handleClick = () => {
    setOpenL(!openL);
  };


  const handlePreguntas = () => {
    window.dataLayer.push({
      event: 'main_menu_cs',
      option: 'Preguntas frecuentes'
    })
    navigate('/questions');
  };

  return (
    <>
      <AppBar
        position="fixed"
        style={{ background: '#ffffff', boxShadow: '4px 6px 16px rgba(33, 33, 33, 0.05)' }}>
        <Toolbar >
          <Container maxWidth={'lg'}>
            <Box sx={{ pt: 2, display: 'grid', alignItems: 'center' }}>
              <Grid container>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Link
                      underline='none'
                      href='https://inter.mx'
                    >
                      <img src={logo} alt='' />
                    </Link>
                  </Grid>
                </Grid>

                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>

                  <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: 12 }} >
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <div style={{ padding: '0px', width: '250px', marginBottom: '0px', textAlign: 'right' }}>
                        <Link
                          underline='none'
                          target={"_blank"}
                          href={UtmURls('https://teregalamosunseguro.inter.mx/')}
                          onClick={() => window.dataLayer.push({
                            event: 'main_menu_cs',
                            option: 'Te Regalamos un Seguro'
                          })}>
                          <span className='text-header'>Te Regalamos un Seguro</span>
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Link
                        underline='none'
                        target={"_blank"}
                        href={UtmURls('https://cocheseguro.inter.mx/')}
                        onClick={() => window.dataLayer.push({
                          event: 'main_menu_cs',
                          option: 'Coche Seguro'
                        })}>
                        <span className='text-header'>Coche Seguro</span>
                      </Link>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Link
                        underline='none'
                        target={"_blank"}
                        href={UtmURls('https://medicoseguro.inter.mx/')}
                        onClick={() => window.dataLayer.push({
                          event: 'main_menu_cs',
                          option: 'Médi'
                        })}>
                        <span className='text-header'>Médico Seguro</span>
                      </Link>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Button
                        aria-controls='menu'
                        aria-haspopup='true'
                        onClick={handleOpenSeguros}
                      >
                        <span className='text-header' style={{ textTransform: 'capitalize' }}>Seguros</span>
                      </Button>
                      <Menu
                        style={{ marginTop: '5' }}
                        id='menu'
                        onClose={handleCloseSeguros}
                        anchorEl={anchorEl2}
                        open={Boolean(anchorEl2)}
                      >
                        <MenuList>
                          <MenuItem>
                            <Link
                              color='Black'
                              underline='none'
                              target={"_blank"}
                              href='https://inter.mx/about-us'>
                              <span>Quiénes Somos</span>
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <Link
                              color='Black'
                              underline='none'
                              target={"_blank"}
                              href='https://inter.mx/blog'>
                              Blog
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <Link
                              color='Black'
                              underline='none'
                              target={"_blank"}
                              href='https://inter.mx/promotions'>
                              Promociones
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <Link
                              color='Black'
                              underline='none'
                              href='https://mascotasegura.inter.mx/questions'>
                              Preguntas Frecuentes
                            </Link>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' } }}>
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{ marginBottom: 12 }}>
                    <IconButton
                      id="basic-button"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleMovil}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        <ListItemButton onClick={handleClick}>
                          <ListItemText primary="Seguros" />
                          {openL ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openL} timeout="auto" unmountOnExit className='collapse-list'>
                          <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  target={"_blank"}
                                  href={UtmURls('https://cocheseguro.inter.mx/')}>
                                  <span className="textMovil">Coche Seguro</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  target={"_blank"}
                                  href={UtmURls('https://medicoseguro.inter.mx/')}>
                                  <span className="textMovil">M&eacute;dico Seguro</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton>
                            {/* <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  href='https://productos.inter.mx/vida-segura'>
                                  <span className="textMovil">Vida Segura</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton> */}
                            {/* <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  href='https://productos.inter.mx/bien-seguro '>
                                  <span className="textMovil">Bien Seguro</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton> */}
                            {/* <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  href='https://inter.mx/viaje-seguro'>
                                  <span className="textMovil">Viaja Seguro</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton> */}
                          </List>
                        </Collapse>
                        <ListItemButton>
                          <ListItemText>
                            <Link
                              color='Black'
                              underline='none'
                              target={"_blank"}
                              href='https://inter.mx/about-us'>
                              <span className="textMovil">Qui&eacute;nes Somos</span>
                            </Link>
                          </ListItemText>
                        </ListItemButton>
                        <ListItemButton>
                          <ListItemText>
                            <Link
                              color='Black'
                              underline='none'
                              target={"_blank"}
                              href='https://inter.mx/blog'>
                              <span className="textMovil">Blog</span>
                            </Link>
                          </ListItemText>
                        </ListItemButton>
                        <ListItemButton>
                          <ListItemText>
                            <Link
                              underline='none'
                              target={"_blank"}
                              href='https://inter.mx/promotions'>
                              <span className="textMovil">Promociones</span>
                            </Link>
                          </ListItemText>
                        </ListItemButton>
                        <ListItemButton>
                          <ListItemText>
                            <div>
                              <Button sx={{ p: 0 }}
                                onClick={handlePreguntas}
                              >
                                <span className='textMovil case-none'>
                                  Preguntas Frecuentes
                                </span>
                              </Button>
                            </div>
                          </ListItemText>
                        </ListItemButton>
                      </List>
                    </Menu>

                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}
