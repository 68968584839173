import * as React from 'react';
import { Box, Button, Card, Container, Grid, Link, Stack } from "@mui/material";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import graciasDesk from '../../assets/img/yaSeguro/Gracias-desk.png';
import graciasTablet from '../../assets/img/yaSeguro/Gracias-tablet.png';
import graciasMobile from '../../assets/img/yaSeguro/Gracias-mobile.png';
import './YaEstaSeguro.css'
import DetailPetCredentialArray from "../../components/DetailPetCredential/DetailPetCredentialArray";
import html2pdf from 'html2pdf.js';
import { useNavigate } from 'react-router-dom';
import { removeLocalStorageAction } from "../../redux/preOrderDuck";
import { getTokenAction } from "../../redux/configDuck";
import { calcularEdad } from '../../components/Utils/utils';

const YaEstaSeguro = (props) => {

  const navigate = useNavigate();
  const lineaBreak = <><br /><br /></>;
  const handledPdf = () => {
    const element = document.getElementById('report');
    const opt = {
      filename: `mascotas_${props.preOrder.addPets.length}` + ".pdf",
      margin: [1, 20, 0, 20],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: 'mm', format: 'a2', orientation: 'portrait' },
    };
    html2pdf().from(element).set({
      ...opt,
    }).save();
  };

  const handleSalir = () => {
    sessionStorage.clear();
    props.removeLocalStorageAction();
    props.getTokenAction();
    navigate('/home');
  };

  const DateOfBirth = (date_) => {
    if(date_){
      let newData = date_.replace(/(\d+[/])(\d+[/])/, '$2$1');
      let data_ = new Date(newData);
      return calcularEdad(data_);
    } else {
      return null;
    }
  }

  const dataGTM = JSON.parse(sessionStorage.getItem('preOrder'));
  React.useEffect(() => {
    if (dataGTM) {
        window.dataLayer.push({
          event : "mascota_seguro_compra_exitosa",      //Static data,
          no_cotizacion: dataGTM?.folio,
          no_poliza: dataGTM?.addPets[0]?.poliza?.Poliza,
          plan_elegido: dataGTM?.selectedPlan?.PlanNombreInter,
          tipo_mascota: dataGTM?.addPets[0]?.especie,
          raza: dataGTM?.addPets[0]?.raza,
          edad_mascota: `${dataGTM?.addPets[0]?.anios} años y ${dataGTM?.addPets[0]?.meses} meses`,
          edad_contratante: DateOfBirth(dataGTM?.fromContratante?.fechanacimiento),
          sexo:dataGTM?.fromContratante?.genero,
          codigo_cupon: dataGTM?.coupon?.coupon,
          no_colaborador: dataGTM?.collaborator_number,
          descuento: dataGTM?.coupon?.value,
          pago_recibido: dataGTM?.selectedPlan?.PlanPrimaTotal2,
          correo: dataGTM?.fromContratante?.email,
          telefono: dataGTM?.fromContratante?.telefono
        })
    }
  }, [dataGTM])
  

  return (
    <>

      <div className="conten-seguro">
        <div className="circulo-gde"></div>
        <div className="circulo"></div>
        <Container maxWidth="xl" className="container-gracias ">
          <Box sx={{ mt: 6 }}>
            <Card className="card-gracias">

              <Grid container direction="row"
                justifyContent="center"
                alignItems="center">
                <div>
                  <img src={graciasDesk}
                    srcSet={`${graciasDesk} 1440w,${graciasTablet} 768w,${graciasMobile} 360w`}
                    alt='' />
                </div>
              </Grid>

              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <div className="felicidad">

                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >

                    <span
                      className="text-felicidad">&iexcl;Qu&eacute; felicidad, {props.preOrder.petName} ya est&aacute; seguro, seguro! Bienvenido a la familia de mascota segura <span
                        className="text-inter">inter.mx</span> </span>

                  </Stack>
                </div>
                <Box sx={{ mt: 4 }}>
                  <div className="descarga">
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <span className="text-descarga">&iexcl;<Link component="button"
                        variant="body2"
                        onClick={handledPdf}>
                        <span className="text-descarga" style={{ color: '#039ECC' }}><strong>Descarga aqu&iacute;</strong></span></Link> la credencial inter de tu amigo! Y en unos momentos, te mandaremos tu p&oacute;liza al mail que nos diste.</span>
                    </Stack>
                  </div>
                </Box>

              </Grid>
              <div id='report'>
                <DetailPetCredentialArray pets={props.preOrder.addPets} />
              </div>
              <div className="telefonazo">
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <span className="text-dudas">Recuerda que puedes echarnos un telefonazo si tienes cualquier duda o si necesitas ayuda:</span>

                </Stack>

              </div>
              <Box sx={{ mt: 0 }}>
                <div className="ayuda">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center" s
                    spacing={2}
                  >
                    <span
                      className="text-ayuda">55-55-84-17-36 o al 800-21-53-455</span>
                  </Stack>
                </div>
              </Box>
              <Box sx={{ mt: 0 }}>
                <div className="telefonazo">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <span className="text-atencion">En un horario de atenci&oacute;n de lunes a domingo de 8:00hrs a 20:00hrs</span>
                  </Stack>
                </div>
              </Box>
              <div className='div-button '>
                <Box sx={{ pb: 0 }}>
                  <Button
                    style={{ height: 48, fontSize: 16 }}
                    fullWidth
                    variant="contained"
                    onClick={handleSalir}
                  >
                    Terminar
                  </Button>
                </Box>
              </div>
            </Card>
          </Box>
          {lineaBreak}
        </Container>

      </div>
    </>

  );
}

const mapState = (state) => {
  return {
    preOrder: state.preOrder
  }
}
export default connect(mapState, {
  addInfoToPreOrder, removeLocalStorageAction,
  getTokenAction
})(YaEstaSeguro);
