
import * as yup from "yup";
import {

  CORREO_INVALIDO, REQUERIDO,
} from "../Utils/Constantes";


export const ValidationModalDiscountForm2 = yup.object().shape({
  name: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre"),
  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(1, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres'),
  terminos: yup
    .boolean()
    .required(REQUERIDO)
    .test(
      "terminos",
      REQUERIDO,
      (valor) => {
        return valor === true;
      }
    ),
});



