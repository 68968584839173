
import * as React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';


const data = [
  { enfermedad: 'Enfermedades parasitarias', periodo: '3 meses' },
  { enfermedad: 'Enfermedades hereditarias y congénitas de los ojos y piel', periodo: '4 meses' },
  { enfermedad: 'Defectos de la coagulación', periodo: '4 meses' },
  { enfermedad: 'Enfermedades hereditarias y congénitas del aparato genito urinario', periodo: '6 meses' },
  { enfermedad: 'Enfermedades degenerativas de columna y articulaciones', periodo: '6 meses' },
  { enfermedad: 'Enfermedades virales(moquillo, hepatitis, parvovirus, entre otras)', periodo: '6 meses' },
  { enfermedad: 'Alergias', periodo: '6 meses' },
  { enfermedad: 'Sida felino', periodo: '6 meses' },
  { enfermedad: 'Hernias hereditarias y congénitas', periodo: '8 meses' },
  { enfermedad: 'Cáncer y sus diferentes variedades*', periodo: '8 meses' },
  { enfermedad: 'Leucemia viral felina', periodo: '8 meses' },
  { enfermedad: 'Enfermedades hereditarias y congénitas de corazón y vasos sanguíneos', periodo: '9 meses' },
];

const styles = makeStyles({
  tablaMaterial: {
    maxWidth: 779,
  }
});

const headStyles = makeStyles({
  tablaHead: {
    background: '#77CAE3',
    fontSize: '16PX',
    fontWeight:'700'

  }
});

const rowStyles = makeStyles((theme) =>({
  tablaRow: {
    "&:hover": {
      backgroundColor: '#F2FAFC !important'
    },
    background: '#F4F4F4'
  }
}));

const textStyles = makeStyles({
  cellText: {
    fontSize: '16PX',
    fontWeight:'700'

  }
});


export default function TablaPreguntas(props) {
  const classes = styles();
  const classesHead = headStyles();
  const classesRow = rowStyles();
  const clasesText = textStyles();
  return (
    <>
      <TableContainer>
        <Table className={classes.tablaMaterial}>
          <TableHead >
            <TableRow className={classesHead.tablaHead}>
              <TableCell className={clasesText.cellText} align='centert'>Enfermedades, padecimientos y tratamientos
                sujetos a periodo de espera</TableCell>
              <TableCell className={clasesText.cellText} align='centert'>Periodo de espera</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(celda => (
              <TableRow  className={classesRow.tablaRow}>
                <TableCell>{celda.enfermedad}</TableCell>
                <TableCell>{celda.periodo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
