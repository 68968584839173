import { array_foundations_tablet, causeParams } from "../Utils/utils";
import './FoundationInterTablet.css'
import { Box, Button, Dialog, Grid,Stack } from "@mui/material";
import React, { useState } from "react";
import ModalFundacion from "../ModalsToActivate/ModalFundacion";
import ApiWeb from "../../lib/apiConfig/apiWeb";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const DetailFoundation = (props) => {

  const navigate = useNavigate();


  const handleClickModal = (event) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    handleClickOpen();

    ApiWeb.postCause(causeParams(props.preOrder, props.current.name)).then(response => {
    }).catch(e => {
      console.log("error", e)
    });

  }

    const dataGTM = JSON.parse(sessionStorage.getItem("preOrder"))

  const handleClickOpen = () => {
    props.setOpenPhotoModal(true);
    window.dataLayer.push({
      event: "mascota_seguro_fundacion",
      option: props.current.name.split("\n").join(" "),
      boton_contacto: "Apoyar causa",
      correo: dataGTM.fromContratante.email,
      telefono: dataGTM.fromContratante.telefono
    })
  };



  const download = (event, link) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    let element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('target', '_blank')
    element.setAttribute('download', 'download')
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  return (<>
    <div style={{ width: '95%', paddingLeft: '2.5%', paddingRight: '2.5%' }}>
      <Grid container>
        <Grid item>
          <div style={{ textAlign: "left", width: '100%' }}>
            {props.current.description}
          </div>
          <div style={{ width: '100%', textAlign: "right", marginTop: '3%', marginBottom: '3%' }}>
            <Button
              onClick={(event) => {
                download(event, props.current.url);
              }}
              style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12,
              }} variant="text">{'Visitar apgina web'}</Button>
          </div>
          <div style={{ width: '93%' }}>
            <Stack
              direction={{ xs: 'column', sm: 'column',md:'column' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Button fullWidth style={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 16,
              height: 48
            }} variant="contained" onClick={(e) => handleClickModal(e)}>{'Apoyar causa'}</Button>
              <Button fullWidth style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 16,
                height: 48
              }}variant="outlined" 
              onClick={() => {
                navigate('/ya-seguro')
                window.dataLayer.push({
                  event: "mascota_seguro_fundacion",
                  option: props.current.name.split("\n").join(" "),
                  boton_contacto: "Tal vez despues",
                  correo: dataGTM.fromContratante.email,
                  telefono: dataGTM.fromContratante.telefono
                })
              }}
              >{'Tal vez después'}</Button>
            </Stack>
          </div>
        </Grid>
      </Grid>
    </div>
  </>)
}

const FoundationInterTablet = (props) => {
  const [current, setCurrent] = useState(null)

  const [openPhotoModal, setOpenPhotoModal] = useState(false);

  const handleClickClose = (reason) => {
    if (reason !== 'backdropClick') {
      setOpenPhotoModal(false);
    }
  };

  return (<>
    <div style={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}>
      {
        array_foundations_tablet.map((item, index) => {
          return (
            <div
              style={{ display: 'block' }}
              className={'card-item-tablet'}>
              <div
                onClick={() => {
                  if (current && current.id === item.id) {
                    setCurrent(null)
                  } else {
                    setCurrent(item)
                  }
                }}
                style={{
                  display: 'flex',
                  paddingTop: 24,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingBottom: 24,
                  alignItems: 'center'
                }} key={index}
              >

                <Grid container spacing={3}>
                  <Grid item xs="auto">
                    <div
                      className={current ? current.id === item.id ? 'img-tablet-detail' : 'img-tablet' : 'img-tablet'}
                      style={{
                        backgroundSize: 'cover',
                        backgroundImage: `url(${current ? current.id === item.id ? current.image : item.image_detail : item.image_detail})`
                      }}
                    />
                  </Grid>
                  <Grid item xs={7} alignSelf={'center'}>
                    <Box sx={{
                      display: 'block',
                      width: '100%',
                      justifyContent: 'center',
                      textAlign: 'center'
                    }}>
                      <span style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: 20,
                      }}>
                        {item.name}
                      </span>

                      <div style={{ width: '100%', marginTop: '5%' }}>
                        <Button
                            onClick={() => {
                              if (current && current.id === item.id){
                                setCurrent(null)
                              }else {
                                setCurrent(item)
                              }
                            }}
                            fullWidth style={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: 14
                        }}
                            variant="text"
                            endIcon={
                              current ?
                                  current.id === item.id ?
                                      <KeyboardArrowUpIcon
                                          style={{ paddingLeft: 10, fontSize: 25 }} />
                                      :
                                      <KeyboardArrowDownIcon
                                          style={{ paddingLeft: 10, fontSize: 25 }} />
                                  :
                                  <KeyboardArrowDownIcon style={{ paddingLeft: 10, fontSize: 25 }} />
                            }>
                          {'Conocer más'}
                        </Button>
                      </div>

                      {
                        current &&
                        current.id === item.id &&
                        <DetailFoundation current={current} setOpenPhotoModal={setOpenPhotoModal} preOrder={props.preOrder} />

                      }
                    </Box>
                  </Grid>
                </Grid>
              </div>

              <Dialog open={openPhotoModal} onClose={(event, reason) => handleClickClose(event, reason)}
                disableEscapeKeyDown={true}
                maxWidth="sm" fullWidth={false}>
                <>
                  <ModalFundacion handleClose={handleClickClose} item={props.current} />
                </>
              </Dialog>
            </div>
          )
        })
      }
    </div>

  </>)
}

export default FoundationInterTablet;
