
import * as yup from "yup";
import {
  CORREO_INVALIDO,
  FECHA_VALIDA,
  MAYOR_EDAD, TELEFONO_INVALIDO,
  VALOR_NECESARIO
} from "../Utils/Constantes";
import { validaMayorEdad } from "../Utils/utils";


export const ValidationModalEditOwner = yup.object().shape({
  name: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, 'Debe tener como máximo 20 caracteres'),
  pLastName: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, 'Debe tener como máximo 20 caracteres'),
  mLastName: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, 'Debe tener como máximo 20 caracteres'),
  birthday: yup
    .date()
    .required(VALOR_NECESARIO)
    .typeError(FECHA_VALIDA)
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if (fecha instanceof Date && !isNaN(fecha)) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test(
      "mayorEdad",
      MAYOR_EDAD,
      (val) => {
        if (validaMayorEdad(val)) {
          return true;
        } else {
          return false;
        }
      }
    ),
  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(4, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres'),
  cellPhone: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO),
});


export const ValidationModalEditOwnerMenorEdad = yup.object().shape({
  name: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, 'Debe tener como máximo 20 caracteres'),
  pLastName: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, 'Debe tener como máximo 20 caracteres'),
  mLastName: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, 'Debe tener como máximo 20 caracteres'),
  birthday: yup
    .date()
    .required(VALOR_NECESARIO)
    .typeError(FECHA_VALIDA)
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if (fecha instanceof Date && !isNaN(fecha)) {
          return true;
        } else {
          return false;
        }
      }
    ),
  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(4, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres'),
  cellPhone: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO),
});



