import { Grid, Stack } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import './TitleSteps.css';
import lineaAmarilla from '../../assets/img/lineaAmarilla/decorative.svg'


const TitleStepTwo = (props) => {

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className="mX-25">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
        >
          <div className="numero">{props.preOrder.steps}</div>
          <div className="linea-amarilla">
            <img src={lineaAmarilla} alt='' />
            <div className="text-queremos">Comp&aacute;rtenos una foto de {props.preOrder.petName && props.preOrder.petName}</div>
            <div className="text-asi">Queremos poner su foto en la credencial de su seguro.</div>
          </div>
        </Stack>
      </Grid>
    </>
  );
}
const mapStateToProps = state => ({
  preOrder: state.preOrder
});
export default connect(mapStateToProps)(TitleStepTwo)

