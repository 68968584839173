import './App.css';
import Footer from './components/Commons/Footer';
import Header from './components/Commons/Header';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Pages from './components/Config/RoutesConfig';
import useWindowSize from './components/Utils/useWindowAize';
import "../src/pages/home/Home.css"
import "../src/pages/add_pets/AddPets.css"
import "../src/pages/insurance_details/InsuranceDetail.css"
import "../src/pages/home/questions/PreguntasFrecuentesView.css"
import { connect } from "react-redux";
import Loading from "./components/Loading/Loading";
import { ScrollToTop } from "./components/Utils/utils";
import TagManager from "react-gtm-module";
import { gtmId } from "./gtmId";
const theme = createTheme({
  palette: {
    primary: {
      light: '#0066ff',
      main: '#039ECC',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF',
    },
    error: {
      main: '#CD0505',
    },
    warning: {
      main: '#FFD60E',
    },
    background: {
      default: '#fff'
    }
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: 'Montserrat',
    button: {
      fontWeight: 700
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1023,
      xl: 1199,
    },
  },
});
TagManager.initialize(gtmId);
function App(props) {
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState((window.innerWidth < 1025) ? true : false);

  const setMobile = () => {
    setIsMobile((size.width < 1023) ? true : false);
  }

  useEffect(() => {
    setMobile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  return (

    <div style={{ width: '100%', position: props.config.fetching ? 'fixed' : 'relative', overflow: 'hidden' }}>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <Header isMobile={isMobile}></Header>
          <Pages isMobile={isMobile} />
          <Footer isMobile={isMobile}></Footer>
        </Router>
      </ThemeProvider>
      {
        props.config.fetching &&
        <Loading />
      }
    </div>

  );
}
const mapStateToProps = (state) => ({
  config: state.config
})
export default connect(mapStateToProps)(App);
