import React, { useEffect,useState } from 'react';
import PreguntasFrecuentes from '../../components/Inicio/PreguntasFrecuentes';
import { Box, Container, Grid, Snackbar, Stack } from "@mui/material";
import './Home.css';
import fondoDesk from '../../assets/landing/Mascotas-landing-desk.png';
import fondoTablet from '../../assets/landing/Mascotas-landing-tablet.png';
import fondoMobile from '../../assets/landing/Mascotas-landing-mobile.png';
import Cobertura1 from '../../assets/landing/Cobertura1.svg';
import cobertura2 from '../../assets/landing/cobertura2.svg';
import cobertura3 from '../../assets/landing/cobertura3.svg';

import beneficioDesk from '../../assets/landing/new_home_landing/beneficios-landing-desk.png';
import beneficioTablet from '../../assets/landing/new_home_landing/beneficios-landing-tablet.png'
import beneficioMobile from '../../assets/landing/new_home_landing/beneficios-landing-mobile.png';
import { ReactComponent as IconVineta } from '../../assets/landing/Ellipse 6.svg';
import requisitosDesk from '../../assets/landing/requisitos-landing-desk.png';
import requisitosTablet from '../../assets/landing/requisitos-landing-tablet.png';
import requisitosMobile from '../../assets/landing/requisitos-landing-mobile.png';
import { ReactComponent as RequisitosUno } from '../../assets/landing/icono-requisitos1.svg';
import { ReactComponent as RequisitosDos } from '../../assets/landing/icono-requisitos2.svg';
import { ReactComponent as RequisitosTres } from '../../assets/landing/icono-requisitos3.svg';
import mascota from '../../assets/landing/Logo-mascota.svg';
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import SelectPet from "../../components/PetQuote/SelectPet";
import InputName from "../../components/PetQuote/InputName";
import TypePetPlans from "../../components/PetQuote/TypePetPlans";
import { Element, scroller } from 'react-scroll';
import QuoteNumber from '../../components/PetQuote/QuoteNumber';
import { addInfoToPreOrder, removeLocalStorageAction } from '../../redux/preOrderDuck';
import {navigatePage, navigatePageForce, performanceData} from "../../components/Utils/utils";
import apiWeb from "../../lib/apiConfig/apiWeb";
import { useNavigate } from "react-router-dom";
import MuiAlert from '@mui/material/Alert';
import AntiFraudHome from "../../components/antiFraude/AntiFraudHome";
import Modal from "../../components/modalBanners/Modal";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Home = (props) => {
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [showModal, setShowModal] = useState(true);

  const handledScroll = () => {
    props.addInfoToPreOrder({
      isFolio: false
    })
    setTimeout(function () {
      scroller.scrollTo("selectPet"
        , {
          duration: 800,
          delay: 50,
          smooth: true,
          offset: -10,
        }
      )
    }, 200);
  }

  const handledScrollCotiza = () => {
    props.addInfoToPreOrder({
      isFolio: true
    })
    setTimeout(function () {
      scroller.scrollTo("numCotiza"
        , {
          duration: 800,
          delay: 50,
          smooth: true,
          offset: -10,
        }
      )
    }, 500);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const handleClick = (isType) => {
    setOpenError(isType);
    setOpen(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getData = (data) => {
    let params = {
      "business": "medipet",
      "insurance": "gmx",
      "folio": parseInt(data.folio),
      "correo": data.email
    }
    apiWeb.getLead(params).then(res => {
      if (res.data.success) {
        if (res.data.data.isPago) {
          handleClick(true);
          return true
        }
        structureData(res.data.data, data.email)
      } else if (res.data.code === 210) {
        handleClick(false);
      }
    }).catch(e => {
      handleClick(false);
      console.error(e)
    })
  }


  const structureData = (data, email) => {
    let responseNavigation = ""
    let response = performanceData(data, props.config.plans, email);
    props.addInfoToPreOrder({ ...response })
    if (response.fromContratante === null || response.fromContratante.isReturn === true ){
      responseNavigation = navigatePageForce("3");
    }else {
      responseNavigation = navigatePage(data);
    }

    navigate(responseNavigation)
  }

  const success = async (pos) => {
    var crd = pos.coords;
    let geolocalizacion = {
      "latitud": crd.latitude,
      "longitud": crd.longitude,
      "aceptacion": "1"
    }
    props.addInfoToPreOrder({ "geolocalizacion": geolocalizacion })
  }

  const errors = (err) => {
    let geolocalizacion = {
      "latitud": "19.440402",
      "longitud": "-99.202500",
      "aceptacion": "1"
    }
    props.addInfoToPreOrder({ "geolocalizacion": geolocalizacion })
  }

  const handlePermission = () => {
    try {
      navigator.geolocation.getCurrentPosition(
        position => {
          success(position);
          // this function is called only if the user grant permission so here you can handle the granted state
        },
        error => {
          errors()
          // this function is called if the user denied permission or some other errors occurs
        }
      );
    } catch (e) {
      errors();
      console.error(e)
    }
  }


  useEffect(() => {
    handlePermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <div className='conten'>
        <div className="circulo-gde"></div>
        <div className="circulo"></div>
        <Container maxWidth="xl" className="container-inicio">
          {(showModal) &&  (
            <Modal onClose={handleCloseModal} setShowModal={setShowModal} />
          )}
          <Box sx={{ mt: 5 }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid style={{ textAlign: 'center' }} item xs={11} sm={12} md={12} lg={12} xl={12}>
                <span className='text-title-home'>Porque m&aacute;s que tu mascota,</span>
              </Grid>
              <Grid item xs={11} sm={12} md={12} lg={12} xl={12}>
                <span className='text-title-home'><b>es tu familia...</b></span>
              </Grid>
            </Stack>
          </Box>
          <Grid container direction="row"
            justifyContent="center"
            alignItems="center">
            <img src={fondoDesk}
              srcSet={`${fondoDesk} 1440w,${fondoTablet} 768w,${fondoMobile} 360w`} alt='img' />
          </Grid>
        </Container>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box sx={{ mt: 0, textAlign: 'center' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <span className='seguro'>&iexcl;Tenemos el seguro perfecto!</span>
            </Grid>
          </Box>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <img src={mascota} alt='' align='center' />
          </Grid>
          <Grid item textAlign={'center'} xs={11} sm={11} md={12} lg={12} xl={12}>
            <span className='coberturas'>Conoce todas las coberturas especiales que tenemos para tu cuatropatitas.</span>
          </Grid>
          <div className='div-ver-planes'>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <div className='ver-planes'>
                <Button onClick={handledScroll} style={{ height: 48 }} variant="contained" fullWidth>QUIERO VER PLANES</Button>
              </div>
            </Grid>
          </div>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button onClick={handledScrollCotiza} className="text-num-cotiza" style={{ textDecorationLine: "underline", }}>
                &iquest;Ya tienes un n&uacute;mero de cotizaci&oacute;n?
              </Button>
            </Grid>
          </Grid>
        </Stack>
        <div className='div-azul'>
          <div className="circulo-beneficios-gde" style={{ zIndex: -1 }} />
          <div className="circulo-beneficios" style={{ zIndex: -1 }}></div>
          <Box sx={{ mt: 15 }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Box sx={{ mt: 6 }}>
                <span className='title-azul'>&iquest;Qu&eacute; cubre mi seguro?</span>
              </Box>
            </Stack>
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}

          >
            <div className='cubre'>
              <Box sx={{ mt: 4 }}>
                <Stack
                  direction={{ xs: 'column', md: 'column', lg: 'row' }}
                  spacing={4}
                >

                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Box sx={{ mt: 4 }}>
                      <img src={Cobertura1} alt='' />
                      <div className='coberturas-titulo'>
                        <Box mt={2}>
                          <span className='coberturas-titulo'>Si le duele algo</span>
                        </Box>
                        <Box mt={2}>
                          <span className='coberturas-decripcion'>Te reembolsamos hasta $30,000 MXN en honorarios m&eacute;dicos, estudios y medicamentos.</span>
                        </Box>
                      </div>

                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <img src={cobertura2} alt='' />
                    <div className='coberturas-titulo'>
                      <Box mt={2}>
                        <span className='coberturas-titulo'>Si hizo una travesura</span>
                      </Box>
                      <Box mt={2}>
                        <span className='coberturas-decripcion'>Cubrimos los daños que tu mascota cause a otras personas o propiedades hasta por $30,000MXN.</span>
                      </Box>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Box sx={{ mt: 4 }}>
                      <img src={cobertura3} alt='' />
                      <div className='coberturas-titulo'>
                        <Box mt={2}>
                          <span className='coberturas-titulo'>Si estir&oacute; la patita</span>
                        </Box>
                        <div style={{ zIndex: 5, position: 'relative' }}>
                          <Box mt={2} >
                            <span className='coberturas-decripcion'>Te apoyamos con $2,000 MXN para que puedas cubrir los gastos funerarios de tu mascota</span>
                          </Box>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                  <br />
                  <br />
                </Stack>
              </Box>
            </div>
          </Grid>
          <Box sx={{ pt: 6 }}></Box>
        </div >
        <Container maxWidth="lg">

          <Box sx={{ mt: 12 }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid textAlign={'center'} item xs={12} sm={9} md={12} lg={9} xl={12}>
                <span style={{ zIndex: 1, position: 'relative' }} className='tittulo-beneficios'>Adem&aacute;s, tenemos un mont&oacute;n de beneficios m&aacute;s
                  para que tu mascota siempre est&eacute; al cien:
                </span>
              </Grid>
            </Stack>
          </Box>
          <div className='tablet-desk'>
            <Box className='mX-30'>
              <Grid container direction="row" justifyContent="center" alignItems="center"
                sx={{ maxHeight: 'auto', maxWidth: '512', backgroundColor: '#FFFFFF' }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} textAlign={{ xs: 'center' }} direction={{ xs: 'column-reverse' }}>
                  <div className="">
                    <picture>
                      <source media="(min-width: 1199px)" srcSet={beneficioTablet} />
                      <source media="(min-width: 1440px)" srcSet={beneficioTablet} />
                      <source media="(min-width: 1024px)" srcSet={beneficioTablet} />
                      <source media="(min-width: 768px)" srcSet={beneficioTablet} />
                      <source media="(min-width: 320px)" srcSet={beneficioMobile} />
                      <img src={beneficioDesk} alt="Ejemplo" />
                    </picture>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="pl-vineta">
                  <Grid
                    container rowSpacing={3}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <div>
                        <span className="text-puntos">Vacuna antirr&aacute;bica y desparasitaci&oacute;n.</span>
                      </div>
                      <div>
                        <span>1 evento a elegir al a&ntilde;o.</span>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <div>
                        <span className="text-puntos">Servicio de est&eacute;tica y de ba&ntilde;o.</span>
                      </div>
                      <div>
                        <span>1 evento al a&ntilde;o de c/u, en la est&eacute;tica de tu preferencia.</span>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <div>
                        <span className="text-puntos">Asistencia m&eacute;dico-veterinaria.</span>
                      </div>
                      <div>
                        <span>Consulta v&iacute;a telef&oacute;nica o videollamada.</span>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <div>
                        <span className="text-puntos">Hospedaje de la mascota en caso de hospitalizaci&oacute;n del due&ntilde;o.
                        </span>
                      </div>
                      <div>
                        Hasta 4 d&iacute;as.
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <span className="text-puntos">Asesor&iacute;a legal ilimitada en caso de que tu mascota provoque alg&uacute;n da&ntilde;o a terceros.</span>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <span className="text-puntos">Acceso ilimitado a descuentos de productos y servicios.</span>
                    </Grid>
                    {/*  <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <span className="text-puntos">Accesos a las mejores promociones</span>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <span className="text-puntos">Sin deducible ni coaseguro</span>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className='movil'>
            <Box className='mX-30'>
              <Grid container direction="row" justifyContent="center" alignItems="flex-start"
                sx={{ maxHeight: 'auto', maxWidth: '512', backgroundColor: '#FFFFFF' }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="pl-vineta">
                  <Grid
                    container rowSpacing={3}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <div>
                        <span className="text-puntos">Vacuna antirr&aacute;bica y desparasitaci&oacute;n.</span>
                      </div>
                      <div>
                        1 evento a elegir al a&ntilde;o.
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <div>
                        <span className="text-puntos">Servicio de est&eacute;tica y de ba&ntilde;o.</span>
                      </div>
                      <div>
                        1 evento al a&ntilde;o de c/u, en la est&eacute;tica de tu preferencia.
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <div>
                        <span className="text-puntos">Asistencia m&eacute;dico-veterinaria.</span>
                      </div>
                      <div>
                        Consulta v&iacute;a telef&oacute;nica o videollamada.
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <div>
                        <span className="text-puntos">Hospedaje de la mascota en caso de hospitalizaci&oacute;n del due&ntilde;o.</span>
                      </div>
                      <div>Hasta 4 d&iacute;as.</div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <span className="text-puntos">Asesor&iacute;a legal ilimitada en caso de que tu mascota provoque alg&uacute;n da&ntilde;o a terceros.</span>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <span className="text-puntos">Acceso ilimitado a descuentos de productos y servicios.</span>
                    </Grid>
                    {/* <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <span className="text-puntos">Accesos a las mejores promociones</span>
                    </Grid>
                    <Grid item xs={1}>
                      <IconVineta />
                    </Grid>
                    <Grid item xs={11}>
                      <span className="text-puntos">Sin deducible ni coaseguro</span>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Box sx={{ mt: 4 }}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} textAlign={{ xs: 'center' }}>
                    <div className="">
                      <picture>
                        <source media="(min-width: 1199px)" srcSet={beneficioDesk} />
                        <source media="(min-width: 1440px)" srcSet={beneficioDesk} />
                        <source media="(min-width: 1024px)" srcSet={beneficioDesk} />
                        <source media="(min-width: 768px)" srcSet={beneficioTablet} />
                        <source media="(min-width: 320px)" srcSet={beneficioMobile} />
                        <img src={beneficioDesk} alt="Ejemplo" />
                      </picture>
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </div>
        </Container>
        <div style={{ textAlign: "center", content: "center" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={openError ? "info" : "error"} sx={{ width: '100%' }}>
              {
                openError ? "Cotización finalizada ya tienes una póliza asignada" : " Alguno de los valores ingresados no es correcto"
              }
            </Alert>

          </Snackbar>
        </div>
        <Box sx={{ textAlign: 'center' }}>
          {
            props.preOrder.isFolio === true &&
            <Element name="numCotiza">
              <QuoteNumber sendData={getData} addInfoToPreOrder={props.addInfoToPreOrder} />
            </Element>
          }

          {
            props.preOrder.isFolio !== true &&
            !props.preOrder.selectedTypePet &&
            <Element name="selectPet">
              <SelectPet species={props.config.species} handledScroll={handledScroll} />
            </Element>
          }

          {
            props.preOrder.isFolio !== true &&
            props.preOrder.selectedTypePet &&
            !props.preOrder.petName &&
            <Element name="selectPet">
              <InputName />
            </Element>
          }

          {
            props.preOrder.isFolio !== true &&
              props.preOrder.petName ?
              <Element name="selectPet">
                <TypePetPlans />
              </Element>
              :
              null
          }

        </Box>
        <Container maxWidth="xl">
          <Grid container direction="row" justifyContent="center" alignItems="flex-start">
            <div className='div-requisitos'>
              <Box sx={{ mt: 12 }}>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <div className='div-requisitos2'>
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <picture>
                          <source media="(min-width: 1199px)" srcSet={requisitosDesk} />
                          <source media="(min-width: 1440px)" srcSet={requisitosDesk} />
                          <source media="(min-width: 1024px)" srcSet={requisitosDesk} />
                          <source media="(min-width: 768px)" srcSet={requisitosTablet} />
                          <source media="(min-width: 320px)" srcSet={requisitosMobile} />
                          <img src={requisitosDesk} alt="Ejemplo" />
                        </picture>
                      </Grid>
                    </Stack>
                  </div>

                  <div className='div-requisitos2'>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid>
                        <p className='titulo-requisitos'>&iquest;C&oacute;mo s&eacute; si mi mascota es asegurable?</p>
                      </Grid>
                    </Stack>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={2}>
                        <span style={{ float: 'right' }}><RequisitosUno /></span>
                      </Grid>
                      <Grid item xs={10}>
                        <span className="text-requisitos">Debe tener una edad entre <b>2 meses y 9 años.</b></span>
                      </Grid>
                      <Grid item xs={2}>
                        <span style={{ float: 'right' }}><RequisitosDos /></span>
                      </Grid>
                      <Grid item xs={10}>
                        <span className="text-requisitos">Debe ser un gato o un perro <b>dom&eacute;stico.</b></span>
                      </Grid>
                      <Grid item xs={2}>
                        <span style={{ float: 'right' }}><RequisitosTres /></span>
                      </Grid>
                      <Grid item xs={10}>
                        <span className="text-requisitos"><b>No</b> debe realizar <b>actividades de b&uacute;squeda o rescate</b> de
                          personas.</span>
                      </Grid>

                    </Grid>
                  </div>
                </Stack>
              </Box>
            </div>
          </Grid>
        </Container>
        <AntiFraudHome/>
        <Container>
          <Box mb={12}>
            <PreguntasFrecuentes />
          </Box>
        </Container>
      </div>
    </>
  );
}
const mapState = (state) => {
  return {
    preOrder: state.preOrder,
    config: state.config
  }
}
export default connect(mapState, { addInfoToPreOrder, removeLocalStorageAction })(Home)
