const initialData = {
    business: "medipet",
    insurance: "gmx",
    contratante: {
        numeroCotizacion: "",
        correo: "",
        etapa: "",
        subEtapa: "",
        pantalla: "",
        plan: {
            producto: "",
            codigo: "",
            nombre: "",
            precio: ""
        },
        datosContratante: {
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            sexo: "",
            fechaNacimiento: "",
            pagador: "",
            correo: "",
            telefono: "",
            rfc: "",
            requiereFactura: "",
            regimenFiscal: ""
        },
        datosPagador: {
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            sexo: "",
            fechaNacimiento: "",
            correo: "",
            telefono: "",
            rfc: "",
            requiereFactura: "",
            regimenFiscal: ""
        },
        direccion: {
            codigoPostal: "",
            estado: "",
            municipio: "",
            colonia: "",
            calle: "",
            numeroExterior: "",
            numeroInterior: "",
            cod_colonia: "",
            cod_ciudad: "",
            cod_municipio: "",
            cod_dpto: ""
        }
    },
    mascotas: [],
    geolocalizacion: {
        latitud: "",
        longitud: "",
        aceptacion: ""
    },
    folio: null
}


//Type
const STRUCTURE_DATA = 'STRUCTURE_DATA';

const temporalReducer = (state = initialData, action) => {
    switch (action.type) {
        case STRUCTURE_DATA:
            return {...state, ...action.payload}
        default:
            return state;
    }
}

export default temporalReducer;

const array_structure = (array) => {
    let new_array = []
    if (array.length > 0) {
        array.map( ite => {
            new_array.push({
                id: ite.id,
                nombre: ite.nombre,
                especie: ite.especie,
                raza: ite.raza,
                meses: ite.meses,
                anios: ite.anios,
                descripcionMascota: ite.descripcionMascota,
                sexo: ite.sexo,
                enfermedades: ite.enfermedades,
                comidaFavorita: ite.comidaFavorita,
                pedigreeOrChip: ite.pedigreeOrChip,
                foto: ite.photo
            })

        })
    }
    return new_array
}

export const preInfoToPreOrder = async (preInfo) => {
    let preOrderPet = {
        business: "medipet",
        insurance: "gmx",
        contratante: {
            numeroCotizacion: "",
            correo: "",
            etapa: "",
            subEtapa: "",
            pantalla: "",
            plan: {
                producto: preInfo.selectedPlan ? preInfo.selectedPlan.ProductoCodigo : " ",
                codigo: preInfo.selectedPlan ? preInfo.selectedPlan.PlanCodigo : " ",
                nombre: preInfo.selectedPlan ? preInfo.selectedPlan.PlanNombreInter : " ",
                precio: preInfo.selectedPlan ? preInfo.selectedPlan.PlanPrimaTotal : " ",
            },
            datosContratante: {

                nombre: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.nombres : preInfo.fromContratante.nombresOwner : "",
                apellidoPaterno: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.paterno : preInfo.fromContratante.paternoOwner : "",
                apellidoMaterno: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.materno : preInfo.fromContratante.maternoOwner : "",
                sexo: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.genero : preInfo.fromContratante.generoOwner : "",
                fechaNacimiento: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.fechaNacimiento : preInfo.fromContratante.fechaNacimientoOwner : "",
                pagador: "",
                correo: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.email : preInfo.fromContratante.emailOwner : "",
                telefono: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.telefono : preInfo.fromContratante.telefonoOwner : "",
                rfc: "",
                requiereFactura: "",
                regimenFiscal: "",
            },
            datosPagador: {
                nombre: preInfo.fromContratante.nombres,
                apellidoPaterno: preInfo.fromContratante.paterno,
                apellidoMaterno: preInfo.fromContratante.materno,
                sexo: preInfo.fromContratante.genero,
                fechaNacimiento: preInfo.fromContratante.fechaNacimiento,
                correo: preInfo.fromContratante.email,
                telefono: preInfo.fromContratante.telefono,
                rfc: preInfo.fromContratante.rfc,
                requiereFactura: preInfo.fromContratante.isFactura,
                regimenFiscal: preInfo.fromContratante.fiscal
            },
            direccion: {
                codigoPostal: preInfo.fromContratante.cp,
                estado: preInfo.fromContratante.estado,
                municipio: preInfo.fromContratante.municipio,
                colonia: preInfo.fromContratante.colonia,
                calle: preInfo.fromContratante.calle,
                numeroExterior: preInfo.fromContratante.esterior,
                numeroInterior: preInfo.fromContratante.interior,
                cod_colonia: "",
                cod_ciudad: "",
                cod_municipio: "",
                cod_dpto: ""
            }
        },
        mascotas: array_structure(preInfo.addPets)
    }

    return preOrderPet;
}

export const validatePreOrderFun = (preInfo) => async (dispatch, getState) => {
    let preOrder = await preInfoToPreOrder(preInfo);
    await dispatch({type: STRUCTURE_DATA, payload: preOrder})
}
