import { Box, Button, Container, Dialog, Grid, IconButton, Stack } from "@mui/material";
import * as React from "react"
import { connect } from "react-redux";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { grey } from "@mui/material/colors";
import './PetPhotos.css'
import ModalPhotosCel from "./ModalPhotosCel";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { useEffect, useRef, useState } from "react";
import { navigatePage, performanceData, preInfoToPreOrder, TagManagerLayer, validatePetPhoto } from "../Utils/utils";
import ApiWeb from "../../lib/apiConfig/apiWeb";
import apiWeb from "../../lib/apiConfig/apiWeb";
import { useNavigate } from "react-router-dom";

const MAXIMO_TAMANIO_BYTES = 1000000; // 1MB = 1 millón de bytes

const PetPhoto = (props) => {
  const navigate = useNavigate()
  const countRef = useRef(null)


  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);

  const hiddenFileInput = React.useRef(null);

  const [disable, setDisable] = React.useState(false);

  const [buttonClicked, setButtonClicked] = React.useState(false);

  const [file, setFile] = React.useState("");
  const [currentPet, setCurrentPet] = React.useState(props.preOrder.addPets[0]);

  const [error, setError] = useState(false)
  const [errorFormat, setErrorFormart] = useState(false)




  const sendTagManagerArgs = (data, isPhoto = false) => {

    let leyendaAnios = data.anios === 1 ? data.anios + " año " : data.anios + " años"
    let leyendaMeses = data.meses === 1 ? data.meses + " mes " : data.meses + " meses"


    let tagManagerArgs = {
      event: "mascota_seguro_datos_contratante",
      boton_cargar_foto: isPhoto ? "Done" : "false",
      sexo: data.sexo,
      raza: data.raza,
      no_se_que_raza_es: "No Aplica",
      edad: leyendaAnios + "y " + leyendaMeses,
      color_pelo: "No Aplica",
      color_ojos: "No Aplica",
      enfermedades: data.enfermedades,
      otras_señas: "No Aplica",
      datos_adicionales: data.descripcionMascota,
      boton_mascota: "continuar",
      cotizacion: "No Aplica",

    }
    TagManagerLayer(tagManagerArgs)
  }


  const handleClickModal = () => {
    handleClickOpen();
  }

  const structureData = (data, email) => {
    let response = performanceData(data, props.config.plans, email);
    props.addInfoToPreOrder({ ...response })
    let responseNavigation = navigatePage(data);
    navigate(responseNavigation)
  }

  ///Obtener los datos del servicio de cotización
  const getData = () => {
    let params = {
      "business": "medipet",
      "insurance": "gmx",
      "folio": parseInt(props.preOrder.folio),
      "correo": props.preOrder.email
    }
    apiWeb.getLead(params).then(res => {
      if (res.data.success) {
        if (validatePetPhoto(res.data.data.mascotas)) {
          setOpenPhotoModal(false);
          structureData(res.data.data, props.preOrder.email);
          handlePauseInterval25Second();
        }
      }
    }).catch(e => {
      console.log(e)
    })
  }

  ///Timer  que le pega al servicio cada 25 segundos para obtener la infirmación nueva de la cotizaión
  const handleStartInterval25Second = () => {
    countRef.current = setInterval(() => {
      getData();
    }, 10000)
  }

  const handlePauseInterval25Second = () => {
    clearInterval(countRef.current)
  }


  const handleClickOpen = () => {
    setOpenPhotoModal(true);
    handleStartInterval25Second()
  };

  const handleClickClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenPhotoModal(false);
    }
  };

  const addPhoto = (photo, petItem) => {
    let osArray = props.preOrder.addPets;
    let elementIndex = osArray.findIndex((obj => obj.id === petItem.id));
    osArray[elementIndex].foto = photo;
    osArray[elementIndex].addPhoto = true;
    props.addInfoToPreOrder({ addPets: osArray })

  }

  const validateUpload = (e) =>
    e.target.files[0].type === "image/png" ||
    e.target.files[0].type === "image/jpg" ||
    e.target.files[0].type === "image/jpeg";


  const upload = (e) => {
    let isError = false;
    setError(false)
    if (e.target.files[0].size <= MAXIMO_TAMANIO_BYTES) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        setFile(e.target.result)
        addPhoto(e.target.result, currentPet)
      }
      reader.readAsDataURL(file)
      isError = false
    } else {
      setError(true)
      isError = true
    }

    return isError
  }

  const handleChangeFile = event => {
    setErrorFormart(false)
    let fileUploaded = event.target.files;
    if (fileUploaded.length === 1 && validateUpload(event)) {
      setErrorFormart(false)
      upload(event)
      setButtonClicked(!upload(event) ? true : false);
      setDisable(!upload(event) ? true : false);
    } else {
      event.target.value = ""
      setErrorFormart(true)
    }


  };


  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const setClick = () => {
    if (props.preOrder) {
      let pre_order = {
        ...props.preOrder,
        ...{
          "etapa": "1",
          "subEtapa": "3",
          "pantalla": "3",
          isEmailNeeded: false,
        }
      }
      let params = preInfoToPreOrder(pre_order)
      ApiWeb.saveLead(params).then(res => {
        if (res.data.code === 200) {
          //Acción al dar 200
        }
      }).catch(e => {
        console.log(e)
      })
    }
  }

  const handleButtonClick = () => {
    window.dataLayer.push({
      event: 'mascota_seguro_datos_foto',
      boton_contacto: 'Continuar',
      no_cotizacion: props.preOrder.folio,
  })
    if (buttonClicked === true) {
      sendTagManagerArgs(props.mascota, true)
      setClick();
      props.addInfoToPreOrder({ steps: props.preOrder.steps + 1 })

    }
  }

  const handleMejorNo = () => {
    window.dataLayer.push({
      event: 'mascota_seguro_datos_foto',
      boton_contacto: 'Mejor no',
      no_cotizacion: props.preOrder.folio,
  })
    setError(false);
    if (props.mascota?.foto && props.mascota.foto !== "") {
      addPhoto(props.mascota.foto, currentPet)
      sendTagManagerArgs(props.mascota, true)
    } else {
      addPhoto("", currentPet)
      sendTagManagerArgs(props.mascota, false)
    }
    setClick();
    props.addInfoToPreOrder({ steps: props.preOrder.steps + 1 });
  }



  const onclickSet = (pre_order) => {
    let params = preInfoToPreOrder(pre_order)
    apiWeb.saveLead(params).then(res => {
      handleClickModal();
    }).catch(e => {
      console.log(e)
    })
  }
  const generateData = () => {
    window.dataLayer.push({
      event: 'mascota_seguro_datos_foto',
      boton_contacto: 'Aqui Escanear Codigo',
      no_cotizacion: props.preOrder.folio,
  })
    let pre_order = {
      ...props.preOrder,
      ...{
        "etapa": "1",
        "subEtapa": "2",
        "pantalla": "3",
        isEmailNeeded: false,
      }
    }
    onclickSet(pre_order)
  }

  useEffect(() => {
    props.setClose(openPhotoModal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPhotoModal]);

  useEffect(() => {
    setOpenPhotoModal(props.close)
  }, [props.close]);

  useEffect(() => {
    if (props.preOrder.addPets.length === 1) {
      if (props.preOrder.addPets[0].foto) {
        if (props.preOrder.addPets[0].foto !== "") {
          setFile(props.preOrder.addPets[0].foto)
        }
      }
    }
  }, [props.preOrder]);


  return (
    <>
      <Dialog open={openPhotoModal} onClose={(event, reason) => handleClickClose(event, reason)}
        disableEscapeKeyDown={true}
        maxWidth="sm" fullWidth={false}>
        <>
          <ModalPhotosCel handleClose={handleClickClose} folio={props.preOrder.folio} email={props.preOrder.email} />
        </>
      </Dialog>
      <Container maxWidth="lg" className="container-pet-photos">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >

              <div className="">

                <Box sx={{ py: 3 }}>
                  <span className="sube-foto">
                    Sube su foto
                  </span>
                </Box>
                <Box sx={{ pt: 3 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {
                      file === "" && !props.mascota?.foto &&
                      <>
                        <div className="circle-photo">
                          <div className="div-circle">
                            <input
                              ref={hiddenFileInput}
                              type="file" style={{ display: 'none' }}
                              onChange={handleChangeFile}
                            />
                            <IconButton onClick={handleClick} disableRipple="true">
                              <AddAPhotoIcon style={{ color: grey[600], fontSize: 160 }} />
                            </IconButton>
                          </div>
                        </div>
                      </ >
                    }

                    {
                      file === "" && props.mascota?.foto && props.mascota.foto !== "" &&
                      <div className="mascotas-photos">
                        <img
                          onClick={handleClick}
                          style={{
                            width: "200px",
                            objectPosition: "center",
                            objectFit: "cover",
                            borderRadius: "50%",
                            height: "200px",
                          }}
                          src={props.mascota.foto} alt='LogoInter' />
                        <input
                          ref={hiddenFileInput}
                          type="file" accept={"image/*"} style={{ display: 'none' }}
                          onChange={handleChangeFile}
                        />
                      </div>
                    }

                    {
                      file !== "" &&
                      <div className="mascotas-photos">
                        <img
                          onClick={handleClick}
                          style={{
                            width: "200px",
                            objectPosition: "center",
                            objectFit: "cover",
                            borderRadius: "50%",
                            height: "200px",
                          }}
                          src={file} alt='LogoInter' />
                        <input
                          ref={hiddenFileInput}
                          type="file" accept={"image/*"} style={{ display: 'none' }}
                          onChange={handleChangeFile}
                        />
                      </div>
                    }
                  </Grid>
                  {
                    error &&
                    <div style={{ textAlign: 'center' }}>
                      <span style={{
                        color: 'red', fontSize: 14,
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 300
                      }}>{`El tamaño máximo es ${MAXIMO_TAMANIO_BYTES / 1000000} MB`}</span>
                    </div>
                  }

                  {
                    errorFormat &&
                    <div style={{ textAlign: 'center' }}>
                      <span style={{
                        color: 'red', fontSize: 14,
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 300
                      }}>{`El tipo de formato de la imagen no es valida.`}</span>
                    </div>
                  }
                </Box>
                <Box sx={{ mt: 4 }} className={'hideButtonDescktop'}>
                  <Stack
                    width={'100%'}
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Button
                      style={{ height: 48 }}
                      fullWidth variant="outlined" onClick={handleMejorNo}>MEJOR NO
                      🙈</Button>
                    <Button style={{ fontWeight: 700, height: 48 }} fullWidth disabled={!disable} onClick={handleButtonClick}
                      variant="contained">Continuar</Button>
                  </Stack>
                </Box>
                <Box sx={{ mt: 4 }}
                  className={'hidde-app-add-pet'}

                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="text-photos">&iquest;No tienes una foto a la mano? </span>
                    <span className="text-photos">Haz click
                      {
                        props.preOrder.step2GoBack ?
                          <Button variant="text" disabled={!disable} onClick={() => {
                            generateData()
                          }} style={{ textTransform: 'lowercase' }}>aqu&iacute;</Button>
                          :
                          <Button variant="text" onClick={() => {
                            generateData()
                          }} style={{ textTransform: 'lowercase' }}>aqu&iacute;</Button>
                      }
                      ,escanea el c&oacute;digo y sigue desde tu celular</span>
                  </Stack>

                </Box>
              </div>
            </Grid>
          </Stack>
        </Grid>
        <Box sx={{ mt: 4 }} className={'hideButtonMobile'}>
          <Stack
            width={'100%'}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Button style={{ height: 48 }} fullWidth variant="outlined" onClick={handleMejorNo}>MEJOR NO
              🙈</Button>
            <Button style={{ fontWeight: 700, height: 48 }} fullWidth disabled={!disable} onClick={handleButtonClick}
              variant="contained">Continuar</Button>
          </Stack>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  preOrder: state.preOrder,
  config: state.config
})
export default connect(mapStateToProps, { addInfoToPreOrder })(PetPhoto);