import React, { useState } from "react";
import { array_foundations, causeParams } from "../Utils/utils";
import './FoundationInterDesk.css'
import { Box, Button, Dialog, Grid, IconButton, Stack } from "@mui/material";
import ModalFundacion from "../ModalsToActivate/ModalFundacion";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import ApiWeb from "../../lib/apiConfig/apiWeb";
import { useNavigate } from "react-router-dom";
const DetailFoundation = (props) => {

  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const navigate = useNavigate();

  const handleClickModal = () => {
    handleClickOpen();
    ApiWeb.postCause(causeParams(props.preOrder, props.current.name)).then(response => {
    }).catch(e => {
      console.log("error", e)
    });

  }

  const dataGTM = JSON.parse(sessionStorage.getItem("preOrder"))

  const handleClickOpen = () => {
    setOpenPhotoModal(true);
    window.dataLayer.push({
      event: "mascota_seguro_fundacion",
      option: props.current.name.split("\n").join(" "),
      boton_contacto: "Apoyar causa",
      correo: dataGTM.fromContratante.email,
      telefono: dataGTM.fromContratante.telefono
    })
  };

  const handleClickClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenPhotoModal(false);
    }
  };

  const download = (link) => {
    let element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('target', '_blank')
    element.setAttribute('download', 'download')
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  return (<>
    <Dialog open={openPhotoModal} onClose={(event, reason) => handleClickClose(event, reason)}
      disableEscapeKeyDown={true}
      maxWidth="sm" fullWidth={false}>
      <>
        <ModalFundacion handleClose={handleClickClose} item={props.current} />
      </>
    </Dialog>
    <div className={'detail-card'}>
      <Stack direction="row">
        <Grid item xs={7}>
          <div
            className={'img-desk-detail'}
            style={{
              backgroundImage: `url(${props.current.image_detail})`
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <div style={{
            marginRight: 10,
            marginTop: -10,
            textAlign: 'right',
          }}>
            <IconButton
              aria-label="close"
              onClick={() => {
                props.setCurrent(null)
              }}
              sx={{
                justifyContent: 'flex-end',
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>

          <div style={{ width: '100%', marginBottom: '5%' }}>
            <span className={'detail-title'}>{props.current.name}</span>
          </div>
          <div style={{ width: '90%' }}>
            {props.current.description}
          </div>
          <div style={{ width: '93%', textAlign: "right", marginTop: '3%', marginBottom: '3%' }}>
            <Button
              onClick={() => {
                download(props.current.url);
              }}
              style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12,
              }} variant="text">{'Vistar pagina web'}</Button>
          </div>
          <div style={{ width: '93%' }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Button fullWidth style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 16,
                height: 48
              }}variant="outlined" 
              onClick={() => {
                navigate('/ya-seguro')
                window.dataLayer.push({
                  event: "mascota_seguro_fundacion",
                  option: props.current.name.split("\n").join(" "),
                  boton_contacto: "Tal vez despues",
                  correo: dataGTM.fromContratante.email,
                  telefono: dataGTM.fromContratante.telefono
                })
              }}
              >{'Tal vez después'}</Button>
              <Button fullWidth style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 16,
                height: 48
              }} variant="contained" onClick={handleClickModal}>{'Apoyar causa'}</Button>
            </Stack>
          </div>
        </Grid>
      </Stack>
    </div>
  </>)
}
const FoundationInterListDesk = (props) => {
  const [current, setCurrent] = useState(null)

  return (<>
    {
      current ?
        <DetailFoundation current={current} setCurrent={setCurrent} preOrder={props.preOrder} />
        :
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {
            array_foundations.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setCurrent(item)
                  }}
                  style={{ display: 'block', alignItems: 'center' }} key={index}
                  className={'card-item'}>
                  <Stack alignSelf={"center"} spacing={0}>
                    <Box sx={{ flexGrow: 1, overflow: 'hidden', textAlign: 'center' }}>
                      <Stack
                        textAlign={"center"}
                        justifyContent={'center'}
                        sx={{
                          alignItems: 'center',
                          paddingLeft: '15%',
                          paddingRight: '15%',
                          height: '70px',
                          marginBottom: '10%',
                          textAlign: 'center',
                        }}>

                        <Grid container wrap="nowrap" spacing={0}>
                          <Grid item xs zeroMinWidth>
                            <span style={{
                              fontFamily: 'Montserrat',
                              fontStyle: 'normal',
                              fontWeight: 700,
                              fontSize: 20,
                              textAlign: 'center'
                            }}>
                              {item.name}
                            </span>
                          </Grid>
                        </Grid>
                      </Stack>

                      <Grid item style={{ width: '100%' }}>
                        <div
                          className={'img-desk'}
                          style={{
                            backgroundImage: `url(${item.image})`
                          }}
                        />
                      </Grid>
                    </Box>
                  </Stack>

                </div>
              )
            })
          }
        </div>
    }
  </>)
}
export default FoundationInterListDesk;
