import APIAxios from "./axiosApi";

class ApiWeb {

  static ApisType = (url, method = 'post', params = {}) => {
    switch (method) {
      case "post":
        return APIAxios.post(url, params)
        break;
      case "get":
        return APIAxios.get(url)
        break;
      case "delete":
        return APIAxios.delete(url)
        break;
    }
  }

  /*** Api genérica para obtener  especies, raza, color y  genero de mascota.
   *  Igual el Código postal esto mediante los parametros que se envíen ***/
  static genericApi = (params) => {
    return ApiWeb.ApisType('/utilities', 'post', params);
  }

  static getPlans = (params) => {
    return ApiWeb.ApisType("/quote", "post", params)
  }

  static saveLead = (params) => {
    return ApiWeb.ApisType("/saveLead", "post", params)
  }

  static getLead = (params) => {
    return ApiWeb.ApisType("/getLead", "post", params)
  }

  static postEmission = (params) => {
    return ApiWeb.ApisType("/emission", "post", params)
  }

  static postPayments = (params) => {
    return ApiWeb.ApisType("/payments", "post", params)

  }

  static postCause = (params) => {
    return ApiWeb.ApisType("/cause", "post", params)
  }


}

export default ApiWeb;
