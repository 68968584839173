import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import apiWeb from "../../lib/apiConfig/apiWeb";
import { navigatePage, performanceData } from "../../components/Utils/utils";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import Loading from "../../components/Loading/Loading";

const ConfigMobile = (props) => {
  const navigate = useNavigate();
  const { folio, email } = useParams();
  const [loading, setLoading] = useState(true)
  const structureData = (data, email) => {
    let response = performanceData(data, props.config.plans, email);
    props.addInfoToPreOrder({ ...response })
    let responseNavigation = navigatePage(data);
    navigate(responseNavigation)
    setLoading(false)
  }

  const getData = () => {
    let params = {
      "business": "medipet",
      "insurance": "gmx",
    }
    if (folio) {
      params.folio = parseInt(folio)
    }
    if (email) {
      params.correo = email
    }
    apiWeb.getLead(params).then(res => {
      if (res.data.success) {
        structureData(res.data.data, email)
      } else {
        navigate("/")
      }
    }).catch(e => {
      console.log(e)
      navigate("/")
    })
  }


  useEffect(() => {
    if (props.config.plans.length > 0) {
      getData(folio, email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config.plans]);




  return (<>
    {
      loading &&
      <Loading />
    }
  </>)
}
const mapStateToProps = state => ({
  preOrder: state.preOrder,
  config: state.config
});
export default connect(mapStateToProps, { addInfoToPreOrder })(ConfigMobile);
