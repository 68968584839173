import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import './InsuranceDetail.css';
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import catDesk from "../../assets/landing/avatar-gato-vertical.png";
import catTablet from "../../assets/landing/avatar-gato-vertical.png";
import catMobile from "../../assets/landing/gato-landing-planes-mobile.png";
import planDesk from "../../assets/landing/avatar-perro-vertical.png";
import planTablet from "../../assets/landing/avatar-perro-vertical.png";
import planMobile from "../../assets/landing/perro-landing-planes-mobile.png";
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import {
  currencyFormat,
  imgAccidents,
  imgAttendances, preInfoToPreOrder
} from "../../components/Utils/utils";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import logo from "../../assets/landing/logo-GMX-azul.svg";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import arrow from "../../assets/landing/Vector.svg";
import arrow_blue from "../../assets/landing/arrow_icon_blue.svg";
import apiWeb from "../../lib/apiConfig/apiWeb";
import ModalDiscountForYou from "../../components/ModalsToActivate/ModalDiscountForYou";
import ModalDiscountForm2 from "../../components/ModalsToActivate/ModalDiscountForm2";
import AlertAntiFraudClose from "../../components/antiFraude/AlertAntiFraudClose";

const Item = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  paddingBottom: theme.spacing(1),
}));

const InsuranceDetails = (props) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(props.preOrder.selectedPlan ? props.preOrder.selectedPlan.PlanCodigo : 0)

  const [visibleModalCoupon, setVisibleModalCoupon] = useState(false)
  const [visibleModalForm, setVisibleModalForm] = useState(false)


  const inactivityTime = () => {
    let time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(dispatchModal, 300000);
    }
  }


  const dispatchModal = () => {
    if (visibleModalForm) {
      setVisibleModalCoupon(false)
      return
    }
    if (props.preOrder.coupon === null) {
      setVisibleModalCoupon(true)
    }
  }

  useEffect(
    () => {
      inactivityTime();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.preOrder.coupon],
  );


  const space = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

  const handleChange = (event) => {
    setSelected(event.target.value);
    selectPlan(event.target.value)
  };

  useEffect(() => {
    if (props.preOrder.selectedPlan) {
      setSelected(props.preOrder.selectedPlan.PlanCodigo)
    }
  }, [props.preOrder])


  const generateFolio = (type = 1) => {
    if (props.preOrder.folio === null) {
      let pre_order = {
        ...props.preOrder,
        ...{
          isEmailNeeded: false,
        }
      }
      let params = preInfoToPreOrder(pre_order)
      apiWeb.saveLead(params).then(res => {
        if (res.data.code === 200) {
          if (props.preOrder.folio === null) {
            props.addInfoToPreOrder({ folio: res.data.data.folio })
            onclickSet(res.data.data.folio, type)
          }
        }
      }).catch(e => {
        console.error(e)
      })
    } else {
      let pre_order = {
        ...props.preOrder,
        ...{
          "etapa": "1",
          "subEtapa": "1",
          "pantalla": "3",
          isEmailNeeded: false,
        }
      }
      let params = preInfoToPreOrder(pre_order)
      apiWeb.saveLead(params).then(res => {

      }).catch(e => {
        console.error(e)
      })
      if (type === 1) {
        navigate(`/add-pets`);
      }
    }
  }

  const go = () => {
    window.dataLayer.push({
      event: 'mascota_seguro_contratar',
      plan_elegido: props.preOrder.selectedPlan.PlanNombreInter,
      no_cotizacion: props.preOrder.folio,
      total_pago: props.preOrder.selectedPlan.PlanPrimaTotal
    })
    generateFolio(1);
  }

  const selectPlan = (id) => {
    let item = props.plans.find(item => item.PlanCodigo === id);
    if (item) {
      props.addInfoToPreOrder({ selectedPlan: item })
    }
  }


  useEffect(() => {
    generateFolio(2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataGTM = JSON.parse(sessionStorage.getItem('preOrder'));
    window.dataLayer.push({
      event: 'mascota_seguro_cotizar',
      plan_elegido: dataGTM.selectedPlan.PlanNombreInter,
      no_cotizacion: dataGTM.folio,
      total_pago: dataGTM.selectedPlan.PlanPrimaTotal
    })
  }, [])

  const returnNamePlan = (id) => {
    let item = props.plans.find(item => item.PlanCodigo === id);
    if (item) {
      return item.PlanNombreInter;
    } else {
      if (props.loadingPlan) {
        return (
          <div
            style={{
              color: '#c5c5c5',
              fontFamily: "Montserrat"
            }}>{"Cargando planes..."}</div>
        )
      } else {
        return (
          <div
            style={{
              color: '#c5c5c5',
              fontFamily: "Montserrat"
            }}>{"Selecciona un plan"}</div>
        )
      }
    }
  }


  const onclickSet = (folio, type = 1) => {
    let pre_order = {
      ...props.preOrder,
      folio,
      ...{
        "etapa": "1",
        "subEtapa": "1",
        "pantalla": "3",
        isEmailNeeded: false,
      }
    }
    let params = preInfoToPreOrder(pre_order)
    apiWeb.saveLead(params).then(res => {

    }).catch(e => {
      console.error(e)
    })
    if (type === 1) {
      navigate(`/add-pets`);
    }
  }

  return (<>
    <div className='content-insurance'>
      <div className="circulo-gde"></div>
      <div className="circulo"></div>
      <Container maxWidth="lg" className="container-insurance">
        <Box sx={{ mt: 6 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'column-reverse', lg: 'row' }}
              spacing={2}>
              <Stack
                className={'card-complete'}
                direction="column"
                alignItems="center"
                spacing={2}>

                <Stack style={{ width: '90%', marginTop: '5%' }}
                  direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
                  spacing={0}>
                  <Grid container
                    style={{ height: '100%', marginBottom: 10 }}
                    alignItems={"center"}
                    className={"content-pay1"} direction={"row"}>
                    <Grid item xs={6} sm={6} md={6}>
                      <Item elevation={0} style={{ textAlign: 'left' }}>
                        <img style={{ paddingTop: 0 }} src={logo} alt='' />
                      </Item>
                    </Grid>
                    <Grid className={"content-pay1"} item xs={6} sm={6} md={6}>
                      <Item elevation={0}>
                        <span
                          style={{ textAlign: 'left' }}
                          className='title-pago'>{"¿Cómo será el pago?"}</span>
                        <br />
                        <div style={{ textAlign: 'left' }}>
                          <span className='title-option'>{"De contado"}</span>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>

                  <Grid item className={"content-pay2"} xs={12} sm={12} md={6}>
                    <Item elevation={0} style={{ textAlign: 'center' }}>
                      <img style={{ paddingTop: 0 }} src={logo} alt='' />
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Item elevation={0}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-label">{props.loadingPlan ? "Cargando planes..." : "Planes"}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selected}
                          renderValue={(val) => {
                            return returnNamePlan(val)
                          }}
                          onChange={handleChange}
                          label={props.loadingPlan ? "Cargando planes..." : "Planes"}
                        >
                          {
                            props.loadingPlan &&
                            <MenuItem
                              value=""
                              disabled>
                              {"Cargando planes..."}
                            </MenuItem>
                          }
                          {props.plans.map((item, index) => {
                            return (<MenuItem key={index}
                              value={item.PlanCodigo}>{item.PlanNombreInter}</MenuItem>)
                          })}
                        </Select>
                      </FormControl>
                    </Item>
                  </Grid>
                  <Grid className={"content-pay2"} item xs={12} sm={12} md={6}>
                    <Item style={{ paddingLeft: 15, paddingRight: 15 }} elevation={0}>
                      <span
                        style={{ textAlign: 'left' }}
                        className='title-pago'>{"¿Cómo será el pago?"}</span>
                      <br />
                      <div style={{ textAlign: 'left' }}>
                        <span className='title-option'>{"De contado"}</span>
                      </div>
                    </Item>
                  </Grid>
                </Stack>

                {
                  props.preOrder.selectedPlan &&
                  <Stack className={'card-gray'} style={{ width: '90%' }}
                    direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
                    spacing={0}
                  >
                    {/***INICIO LISTADO DE ACCIDENTES ***/}

                    <Grid item style={{ backgroundColor: "#FAFBFC", padding: 10 }} xs={12} sm={12}
                      md={12}>
                      {props.preOrder.selectedPlan && props.preOrder.selectedPlan.PlanCoberturas.map((item, index) => {
                        return (<Grid key={index} container spacing={1}>
                          <Grid item xs={1} style={{ textAlign: 'right' }}>
                            <img src={imgAccidents(item.CoberturaCodigo)} alt='LogoInter' />
                          </Grid>
                          <Grid item xs={11} style={{ marginBottom: 20 }}>
                            <span
                              className={"title-accidents"}>{item.CoberturaNombre}</span>
                            <br />
                            <span
                              className={'description-accidents'}>{item.Descripcion}</span>
                          </Grid>
                        </Grid>)
                      })}
                    </Grid>

                    {/*** FIN LISTADO DE ACCIDENTES ***/}

                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      textAlign={"center"}
                      className={"card-tow-column"}
                      paddingTop={{ xs: 2, sm: 2, md: 0 }}
                      paddingBottom={{ xs: 2, sm: 2, md: 0 }}
                      xs={12} sm={12} md={6} lg={8}
                    >
                      <Grid
                        container
                        direction="row"
                        textAlign={"center"}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid container textAlign={"center"} alignItems={"center"}>
                          <Grid item xs textAlign={"left"}>
                            <img
                              style={{
                                textAlign: 'right'
                              }}
                              src={arrow} alt='' />
                          </Grid>
                          <Grid item xs={8} justifyItems={"center"} justifyContent="center"
                            textAlign={"center"}>
                            <div style={{ textAlign: 'center' }}>
                              <span
                                style={{ alignItems: 'center' }}
                                className='text-cobeturaanual-title'>{"COBERTURA ANUAL"}</span>
                              <br />
                              <div style={{ textAlign: 'center', marginTop: '5%' }}>
                                <span
                                  style={{ alignItems: 'center' }}
                                  className='text-cobeturaanual-const'>{props.preOrder.selectedPlan && currencyFormat(props.preOrder.selectedPlan.PlanSumaAsegurada, 0, true)}</span>
                              </div>

                              <div style={{
                                width: '100%', textAlign: 'center', marginTop: '5%'
                              }}>
                                <span
                                  className={"text-cobeturaanual-description"}
                                  style={{ alignItems: 'center' }}>{"¡Ojo! Este es el monto total por año. Eso significa que si tu mascota se lo termina en una sola travesura o en un mal día, deberás esperar hasta que se renueve tu póliza."}</span>
                              </div>
                            </div>

                          </Grid>
                          <Grid item xs textAlign={"right"}>
                            <img
                              style={{
                                transform: "rotate(180deg)", textAlign: 'right'
                              }}
                              src={arrow} alt='' />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Stack>
                }


                {
                  props.preOrder.selectedPlan &&
                  <div className={'content-attendance'}>
                    <div className={"card-content-attendance"} />
                    <div className={"card-content-attendance2"} />
                  </div>
                }


                {/***INICIO LISTADO DE ASISTENCIA ***/}

                {props.preOrder.selectedPlan && props.preOrder.selectedPlan.PlanAsistencias.map((item, index) => {
                  return (<Stack key={index} className={'card-blue'} style={{
                    width: '90%', marginBottom: 10,
                  }}
                    direction={{
                      xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row'
                    }}
                    spacing={0}>

                    <Grid item style={{
                      backgroundColor: "#F2FAFC", alignSelf: 'center', padding: 10,
                      borderRadius: 20
                    }} xs={12} sm={12}
                      md={12} lg={12}>

                      <Grid container
                        spacing={1}
                      >
                        <Grid item xs={1} style={{ textAlign: 'right', marginTop: 10 }}>
                          <img src={imgAttendances(item.AsistenciaCodigo)}
                            alt='LogoInter' />
                        </Grid>
                        <Grid item xs={11} style={{ marginBottom: 10, marginTop: 10 }}>
                          <span
                            className={"title-accidents"}>{item.AsistenciaNombre}</span>
                          <br />
                          <span
                            className={'description-accidents'}>{item.Descripcion}</span>
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      textAlign={"center"}
                      className={"card-tow-column-blue"}
                      style={{ paddingTop: 20, paddingBottom: 15 }}
                      xs={12} sm={12} md={8}>
                      <Grid
                        container
                        direction="row"
                        textAlign={"center"}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid container textAlign={"center"} alignItems={"center"}>
                          <Grid item xs textAlign={"left"}>
                            <img
                              style={{
                                textAlign: 'right'
                              }}
                              src={arrow_blue} alt='' />
                          </Grid>
                          <Grid item xs={8} md={9} justifyItems={"center"}
                            textAlign={"center"}>

                            {item.Precios.length === 1 ?

                              <Stack
                                justifyContent={"center"}
                                direction={{
                                  xs: 'row',
                                  sm: 'row',
                                  md: 'column',
                                  lg: 'column',
                                  xl: 'column'
                                }}
                              >
                                <div style={{ textAlign: 'center' }}>

                                  <span
                                    style={{ alignItems: 'center' }}
                                    className='text-cobeturaanual-title'>{item.Precios[0].Nombre}{space}</span>
                                  <br />
                                </div>
                                <div style={{
                                  textAlign: 'center',

                                }}>
                                  <span
                                    style={{ alignItems: 'center' }}
                                    className='text-cobeturaanual-const'> {currencyFormat(item.Precios[0].Total)}</span>
                                </div>
                              </Stack> : item.Precios.length > 0 ?

                                item.Precios.map((item_be, index_be) => {
                                  return (<Stack
                                    key={index_be}
                                    alignItems={"center"}
                                    textAlign={"center"}
                                    justifyContent={"center"}
                                    direction={{
                                      xs: 'row',
                                      sm: 'row',
                                      md: 'row',
                                      lg: 'row',
                                      xl: 'row'
                                    }}
                                    spacing={2}
                                  >
                                    <Stack style={{ width: '70%' }}
                                      justifyContent={"center"}
                                      textAlign={"left"}>
                                      <span
                                        className='text-bene-title'>{item_be.Nombre}</span>
                                    </Stack>

                                    <Stack style={{ width: '30%' }}
                                      textAlign={"center"}>
                                      <span style={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        fontSize: 17,
                                        color: '#212121'
                                      }}
                                        className=''>{currencyFormat(item_be.Total)}</span>
                                    </Stack>

                                  </Stack>)
                                })

                                : null}

                          </Grid>
                          <Grid item xs textAlign={"right"}>
                            <img
                              style={{
                                transform: "rotate(180deg)", textAlign: 'right'
                              }}
                              src={arrow_blue} alt='' />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Stack>


                  )
                })}

                {/*** FIN LISTADO DE ASISTENCIA ***/}

              </Stack>

              {/*** Detalle de tu seguro***/}
              <Stack
                direction="column"
                alignItems="center"
                className={''}
                spacing={2}>
                <Grid
                  container
                  style={{ justifyContent: 'flex-start' }}
                  direction="row"
                  className={'grid-card'}
                >
                  <Stack
                    alignItems={"center"}
                    direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}>
                    <div className={'card-detail-plan'}>
                      <span className={"title-detail"} style={{ paddingLeft: 12, paddingRight: 12 }}>Detalle de tu seguro</span>
                      <div style={{
                        marginTop: 20,
                        width: '94%',
                        height: 1,
                        backgroundColor: '#0A3949',
                        opacity: 0.3,
                        marginBottom: 20,
                        margin: '20px 0px 20px 12px',
                      }} />

                      <Grid container style={{ paddingLeft: 12, paddingRight: 12 }}>
                        <Grid item xs={6}>
                          <Item elevation={0} className={'title-detail-text'}>Plan</Item>
                        </Grid>
                        <Grid item xs={6}>
                          <Item elevation={0}
                            className={'title-detail-description'}>{props.preOrder.selectedPlan && props.preOrder.selectedPlan.PlanNombreInter}</Item>
                        </Grid>

                        <Grid item xs={6}>
                          <Item elevation={0} className={'title-detail-text'}>Total</Item>
                        </Grid>
                        <Grid item xs={6}>
                          <Item elevation={0}
                            className={'title-detail-description'}>{props.preOrder.selectedPlan && currencyFormat(props.preOrder.selectedPlan.PlanPrimaTotal, 2)}</Item>
                        </Grid>


                        <Grid item xs={6}>
                          <Item elevation={0} className={'title-detail-text'}>Modalidad de
                            pago</Item>
                        </Grid>
                        <Grid item xs={6}>
                          <Item elevation={0}
                            className={'title-detail-description'}>{"De contado"}</Item>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <AlertAntiFraudClose
                          customStyles={{
                            margin: {
                              xs: "5% 4% 5% 4%",
                              sm: "5% 4% 5% 4%",
                              md: "2% 4% 2% 4%",
                            },
                          }}
                        />
                      </Grid>


                      <Grid item style={{ marginTop: 15, paddingLeft: 12, paddingRight: 12 }}
                        xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          onClick={() => {
                            go()
                          }}
                          variant="contained" style={{ height: 48, fontWeight: 700 }}
                          size={"large"} fullWidth>{"CONTRATAR"}</Button>
                      </Grid>
                      <Box sx={{ mt: 3 }} />

                    </div>

                    <div className={"card-none-detail"}>
                      {props.preOrder.selectedTypePet === 2 ?
                        <picture>
                          <source media="(min-width: 1199px)" srcSet={catDesk} />
                          <source media="(min-width: 1440px)" srcSet={catDesk} />
                          <source media="(min-width: 1024px)" srcSet={catDesk} />
                          <source media="(min-width: 768px)" srcSet={catTablet} />
                          <source media="(min-width: 320px)" srcSet={catMobile} />
                          <img src={catDesk} alt="gato" />
                        </picture>
                        :
                        <picture>
                          <source media="(min-width: 1199px)" srcSet={planDesk} />
                          <source media="(min-width: 1440px)" srcSet={planDesk} />
                          <source media="(min-width: 1024px)" srcSet={planDesk} />
                          <source media="(min-width: 768px)" srcSet={planTablet} />
                          <source media="(min-width: 320px)" srcSet={planMobile} />
                          <img src={planDesk} alt="perro" />
                        </picture>
                      }
                    </div>

                    <div className={"card-none-detail2"}>
                      {props.preOrder.selectedTypePet === 2 ?
                        <picture>
                          <source media="(min-width: 1199px)" srcSet={catDesk} />
                          <source media="(min-width: 1440px)" srcSet={catDesk} />
                          <source media="(min-width: 1024px)" srcSet={catDesk} />
                          <source media="(min-width: 768px)" srcSet={catTablet} />
                          <source media="(min-width: 320px)" srcSet={catMobile} />
                          <img src={catDesk} alt="gato" />
                        </picture>
                        :
                        <picture>
                          <source media="(min-width: 1199px)" srcSet={planDesk} />
                          <source media="(min-width: 1440px)" srcSet={planDesk} />
                          <source media="(min-width: 1024px)" srcSet={planDesk} />
                          <source media="(min-width: 768px)" srcSet={planTablet} />
                          <source media="(min-width: 320px)" srcSet={planMobile} />
                          <img src={planDesk} alt="perro" />
                        </picture>
                      }
                    </div>

                  </Stack>
                </Grid>
              </Stack>


            </Stack>

          </Grid>
        </Box>

        {
          !visibleModalForm && visibleModalCoupon &&
          <ModalDiscountForYou
            isDiscount={true}
            accept={() => {
              setVisibleModalCoupon(false);
              setVisibleModalForm(true);

            }
            }

            typePet={props.preOrder.selectedTypePet} open={visibleModalCoupon}
            close={setVisibleModalCoupon} />
        }

        {
          !props.preOrder.discount && visibleModalForm &&
          <ModalDiscountForm2
            preOrder={props.preOrder}
            addInfoToPreOrder={props.addInfoToPreOrder}
            open={visibleModalForm} accept={() => {
              setVisibleModalForm(false);
              setVisibleModalCoupon(false);
            }} close={() => {
              setVisibleModalForm(false);
              setVisibleModalCoupon(false);
              props.addInfoToPreOrder({ coupon: null })
            }
            } />
        }

      </Container>
    </div>
  </>)
}
const mapState = (state) => {
  return {
    preOrder: state.preOrder,
    loadingPlan: state.config.fetchingPlans,
    plans: state.config.plans
  }
}
export default connect(mapState, { addInfoToPreOrder })(InsuranceDetails);

