import img1 from "../../assets/landing/icono-accidentesyenfermedades.svg";
import img2 from "../../assets/landing/icono-dañosytravesuras.svg";
import img3 from "../../assets/landing/icono-gastosfunerarios.svg";
import img4 from "../../assets/landing/icono-eutanasia.svg";


import imgAttendances1 from "../../assets/landing/icono-vacuna.svg";
import imgAttendances2 from "../../assets/landing/icono-hospedaje.svg";
import imgAttendances3 from "../../assets/landing/icono-baño.svg";
import imgAttendances4 from "../../assets/landing/icono-estetica.svg";
import foundation1 from "../../assets/img/foundation/ProBosque2.png";
import foundation2 from "../../assets/img/foundation/Somos-agua2.png";
import foundation3 from "../../assets/img/foundation/Nadie_se_rinde2.png";
import foundation4 from "../../assets/img/foundation/Owen2.png";

import foundation_1 from "../../assets/img/foundation/ProBosque1.png";
import foundation_2 from "../../assets/img/foundation/Somos-agua1.png";
import foundation_23 from "../../assets/img/foundation/Somos-agua3.png";
import foundation_3 from "../../assets/img/foundation/Nadie_se_rinde1.png";
import foundation_4 from "../../assets/img/foundation/Owen1.png";

import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import CryptoJS from "crypto-js";
import { ALPHA, EMAIL_INVALIDO, TELEFONO_INVALIDO, TELEFONO_INVALIDO_ } from './Constantes'
import TagManager from "react-gtm-module";


export const setLocalStorageData = async (name, data) => {
    try {
        await sessionStorage.setItem(name, JSON.stringify(data))
    } catch (e) {
        console.log("Error set LocalStorage::", e)
    }
}

export const getLocalStorageData = async (name) => {
    try {
        let responseLocal = await sessionStorage.getItem(name);
        responseLocal = JSON.parse(responseLocal)
        return responseLocal
    } catch (e) {
        console.log("Error get LocalStorage::", e)
    }
}

export const getLocalStorageDataUTM = async (name) => {
    try {
        let responseLocal = await localStorage.getItem(name);
        return responseLocal
    } catch (e) {
        console.log("Error get LocalStorage::", e)
    }
}

export const removeLocalStorageData = async (name) => {
    try {
        await sessionStorage.removeItem(name)
    } catch (e) {
        console.log("Error remove LocalStorage::", e)
    }
}

export const arrayPlan = [
    {
        id: 1,
        name: "Mascota",
        cost: '1300',
        cover: "Cobertura por año: $10,000 MXN que te reembolsa:",
        desc: 'Por si tiene un accidente o se pone malito\nPor si tiene un accidente o se pone malito\n'
    },
    {
        id: 2,
        name: "Garritas",
        cost: '2600',
        cover: "Cobertura por año: $10,000 MXN que te reembolsa:",
        desc: 'Por si tiene un accidente o se pone malito\nPor si tiene un accidente o se pone malito\n'
    },
    {
        id: 3,
        name: "Mejor amigo",
        cost: '3600',
        cover: "Cobertura por año: $10,000 MXN que te reembolsa:",
        desc: 'Por si tiene un accidente o se pone malito\nPor si tiene un accidente o se pone malito\n'
    },
];

export const array_foundations = [
    {
        id: 1,
        name: "Fideicomiso\nProbosque\nChapultepec",
        image: foundation1,
        image_detail: foundation_1,
        description: <p style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 20,
            /* Black */
            color: '#212121',
        }}>¿Sabías que el Bosque de Chapultepec es el pulmón verde más importante de la CDMX? Además es un lugar lleno
            de historia, con propuestas educativas increíbles, un espacio recreativo, cultural y de convivencia social
            que más de 20 millones de personas visitan al año <br/>¡Ayúdanos a preservarlo!</p>,
        url: "https://www.chapultepec.org.mx"
    },
    {
        id: 2,
        name: "Somos \nAgua\nI.A.P.",
        image: foundation2,
        image_detail: foundation_2,
        description: <p style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 20,
            /* Black */
            color: '#212121'
        }}>
            ¿Sabías que en México 3 de cada 10 personas no<br/>
            tienen agua potable<b> Fundación INTER </b> apoya esta<br/> causa llevando módulos de higiene a comunidades
            rurales, instalando sistemas para captar la lluvia y construyendo cisternas para almacenar agua potable.
            Así, más de 3000 habitantes en comunidades de Oaxaca ya pueden contar con este líquido vital.<br/>
            ¡Ayúdanos a que el agua llegue más lejos!</p>,
        url: "https://www.instagram.com/somosaguamx/"
    },
    {
        id: 3,
        name: "Aquí nadie \n" +
            "se rinde \n" +
            "I.A.P.",
        image: foundation3,
        image_detail: foundation_3,
        description: <p
            style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 20,
                /* Black */
                color: '#212121'
            }}
        >El cáncer es una de las enfermedades más duras, y más aún en niñas y niños.<br/>
            <b>Fundación INTER</b> se suma a programas y proyectos para recaudar fondos y donaciones en apoyo a familias
            en esta situación.<br/>
            ¡Ayúdanos a luchar contra el cáncer en niñas y niños!</p>,
        url: "https://www.aquinadieserinde.org.mx"
    }
    ,
    {
        id: 4,
        name: "Fundación Owen Care I.A.P.",
        image: foundation4,
        image_detail: foundation_4,
        description: <p style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 20,
            /* Black */
            color: '#212121'
        }}>¿Sabías que hay perritos que ayudan a personas con capacidades diferentes o condiciones médicas?<br/>
            Nos sumamos a este esta causa apoyando económicamente programas de atención y entrenamiento de perritos de
            asistencia.<br/>
            ¡Ayúdanos para que más personas puedan contar con un perro de asistencia!</p>,
        url: "https://owencare.org"
    }
];

export const array_foundations_tablet = [
    {
        id: 1,
        name: "Fideicomiso\nProbosque\nChapultepec",
        image: foundation1,
        image_detail: foundation_1,
        description: <p style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            /* Black */
            color: '#212121',
        }}>¿Sabías que el Bosque de Chapultepec es el pulmón verde más importante de la CDMX? Además es un lugar lleno
            de historia, con propuestas educativas increíbles, un espacio recreativo, cultural y de convivencia social
            que más de 20 millones de personas visitan al año <br/>¡Ayúdanos a preservarlo!</p>,
        url: "https://www.chapultepec.org.mx"
    },
    {
        id: 2,
        name: "Somos \nAgua\nI.A.P.",
        image: foundation2,
        image_detail: foundation_23,
        image_detail_2: foundation_2,
        description: <p style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            /* Black */
            color: '#212121'
        }}>
            ¿Sabías que en México 3 de cada 10 personas no<br/>
            tienen agua potable <b>Fundación INTER</b> apoya esta<br/>causa llevando módulos de higiene a comunidades
            rurales, instalando sistemas para captar la lluvia y construyendo cisternas para almacenar agua potable.
            Así, más de 3000 habitantes en comunidades de Oaxaca ya pueden contar con este líquido vital.<br/>
            ¡Ayúdanos a que el agua llegue más lejos!</p>,
        url: "https://www.instagram.com/somosaguamx/"
    },
    {
        id: 3,
        name: "Aquí nadie \n" +
            "se rinde \n" +
            "I.A.P.",
        image: foundation3,
        image_detail: foundation_3,
        description: <p
            style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                /* Black */
                color: '#212121'
            }}
        >El cáncer es una de las enfermedades más duras, y más aún en niñas y niños.<br/>
            <b>Fundación INTER</b> se suma a programas y proyectos para recaudar fondos y donaciones en apoyo a familias
            en esta situación.<br/>
            ¡Ayúdanos a luchar contra el cáncer en niñas y niños!</p>,
        url: "https://www.aquinadieserinde.org.mx"
    }
    ,
    {
        id: 4,
        name: "Fundación Owen Care I.A.P.",
        image: foundation4,
        image_detail: foundation_4,
        description: <p style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            /* Black */
            color: '#212121'
        }}>¿Sabías que hay perritos que ayudan a personas con capacidades diferentes o condiciones médicas?<br/>
            Nos sumamos a este esta causa apoyando económicamente programas de atención y entrenamiento de perritos de
            asistencia.<br/>
            ¡Ayúdanos para que más personas puedan contar con un perro de asistencia!</p>,
        url: "https://owencare.org"
    }
];

export const imgAccidents = (type) => {
    let response = ""
    switch (type) {
        case 1:
            response = img1;
            break;
        case 2:
            response = img2;

            break;
        case 3:
            response = img3;

            break
        case 4:
            response = img4;

            break;
        default:
            response = img1;
    }


    return response
}

export const imgAttendances = (type) => {
    let response = ""
    switch (type) {
        case 1:
            response = imgAttendances1;
            break;
        case 2:
            response = imgAttendances2;

            break;
        case 3:
            response = imgAttendances3;

            break
        case 4:
            response = imgAttendances4;

            break;
        default:
            response = imgAttendances1;
    }


    return response
}

export let listNumbers = (highEnd = 11) => {
    let lowEnd = 0;
    let highEnd_ = highEnd;
    let list = [];
    for (let i = lowEnd; i <= highEnd_; i++) {
        list.push(i);
    }

    return list
}

export const setIdleTimeout = (millis = 300000, onIdle) => {
    let timeout = 0;
    startTimer();

    function startTimer() {
        timeout = setTimeout(onExpires, millis);
        document.addEventListener("mousemove", onActivity);
        document.addEventListener("keydown", onActivity);
    }

    function onExpires() {
        timeout = 0;
        onIdle();
    }

    function onActivity() {
        if (timeout) clearTimeout(timeout);
        //since the mouse is moving, we turn off our event hooks for 1 second
        document.removeEventListener("mousemove", onActivity);
        document.removeEventListener("keydown", onActivity);
        setTimeout(startTimer, 1000);
    }
}

export function validaMayorEdad(fecha) {
    if (calcularEdad(fecha) >= 18) {
        return true;
    } else {
        return false;
    }
};

/**
 * recibe cumple como  fecha completa new Date()
 */
export function calcularEdad(cumple) {
    var hoy = new Date();
    var edad = hoy.getFullYear() - cumple.getFullYear();
    var m = hoy.getMonth() - cumple.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumple.getDate())) {
        edad--;
    }
    return edad;
}

/***
 * Función para el formato de moneda en pesos con cero decimales.
 ****/
export let currencyFormat = (number = "0", decimal = 0, isCode = false) => {
    let num = number;
    let str = num
    let parsed = parseFloat(str);
    let currency = Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: decimal
    }).format(parsed)

    let withCode = currency + " MXN";

    let finalCurrency = isCode ? withCode : currency;

    return finalCurrency;
}

export const dateFormat = (date) => {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);                 // {object Date}
    let MM = (D.getMonth() + 1).toString().padStart(2, "0");
    let DD = D.getDate().toString().padStart(2, "0"); // 30
    let YY = D.getFullYear()   // 2020

    return DD + "/" + MM + "/" + YY;
}

export const dateFormatObj = (date) => {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);                 // {object Date}
    let MM = (D.getMonth() + 1).toString().padStart(2, "0");
    let DD = D.getDate().toString().padStart(2, "0"); // 30
    let YY = D.getFullYear()   // 2020

    return {Day: DD, Month: MM, Year: YY}
}

const array_structure = (array) => {
    let new_array = []
    if (array.length > 0) {
        array.map(ite => {
            new_array.push({
                id: ite.id,
                nombre: ite.nombre,
                especie: ite.especie,
                cod_raza: ite.cod_raza ? ite.cod_raza : "265",
                raza: ite.raza,
                meses: ite.meses,
                anios: ite.anios,
                descripcionMascota: ite.descripcionMascota,
                sexo: ite.sexo,
                enfermedades: ite.enfermedades,
                comidaFavorita: ite.comidaFavorita,
                pedigreeOrChip: ite.pedigreeOrChip,
                foto: ite.foto,
                chip: ite.chip,
                referencia: ite.referencia,
                pedigree: ite.pedigree,
                ...ite
            })

        })
    }
    return new_array
}

export const preInfoToPreOrder = (preInfo) => {
    ///let  preOrderPet = preInfo;
    let preOrderPet = {
        business: "medipet",
        insurance: "gmx",
        url: preInfo?.url,
        isEmailNeeded: preInfo.isEmailNeeded,
        contratante: {
            numeroCotizacion: "",
            etapa: preInfo.etapa,
            subEtapa: preInfo.subEtapa,
            pantalla: preInfo.pantalla,
            plan: !preInfo.selectedPlan ? {} : {
                producto: preInfo.selectedPlan ? preInfo.selectedPlan.ProductoCodigo : "",
                codigo: preInfo.selectedPlan ? preInfo.selectedPlan.PlanCodigo : "",
                nombre: preInfo.selectedPlan ? preInfo.selectedPlan.PlanNombreInter : "",
                precio: preInfo.selectedPlan ? preInfo.selectedPlan.PlanPrimaTotal2 : "",
            },
            datosContratante: !preInfo.fromContratante ? {} : {
                nombre: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.nombres : preInfo.fromContratante.nombresOwner === "" ? preInfo.fromContratante.nombres : preInfo.fromContratante.nombresOwner : "",
                apellidoPaterno: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.paterno : preInfo.fromContratante.paternoOwner  === "" ? preInfo.fromContratante.paterno : preInfo.fromContratante.paternoOwner: "",
                apellidoMaterno: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.materno : preInfo.fromContratante.maternoOwner  === "" ? preInfo.fromContratante.materno : preInfo.fromContratante.maternoOwner : "",
                sexo: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.genero : preInfo.fromContratante.generoOwner  === "" ? preInfo.fromContratante.genero : preInfo.fromContratante.generoOwner : "",
                fechaNacimiento: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.fechanacimiento : preInfo.fromContratante.fechanacimientoOwner === "" ? preInfo.fromContratante.fechanacimiento : preInfo.fromContratante.fechanacimientoOwner : "",
                pagador: preInfo.fromContratante ? preInfo.fromContratante.roleType === "si" ? true : false : false,
                correo: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.email : preInfo.fromContratante.emailOwner === "" ? preInfo.fromContratante.email : preInfo.fromContratante.emailOwner : "",
                telefono: preInfo.fromContratante ? preInfo.fromContratante.roleType === 'si' ? preInfo.fromContratante.telefono : preInfo.fromContratante.telefonoOwner === "" ? preInfo.fromContratante.telefono : preInfo.fromContratante.telefonoOwner : "",
                rfc: preInfo.fromContratante.rfc.toUpperCase(),
                requiereFactura: preInfo.fromContratante.isfactura,
                regimenFiscal: preInfo.fromContratante.fiscal,
                idFiscal: preInfo.fromContratante.idFiscal

            },
            datosPagador: !preInfo.fromContratante ? {} : {
                nombre: preInfo.fromContratante.nombres,
                apellidoPaterno: preInfo.fromContratante.paterno,
                apellidoMaterno: preInfo.fromContratante.materno,
                sexo: preInfo.fromContratante.genero,
                fechaNacimiento: preInfo.fromContratante.fechanacimiento,
                correo: preInfo.fromContratante.email,
                telefono: preInfo.fromContratante.telefono,
                rfc: preInfo.fromContratante.rfc.toUpperCase(),
                requiereFactura: preInfo.fromContratante.isfactura,
                regimenFiscal: preInfo.fromContratante.fiscal,
                idFiscal: preInfo.fromContratante.idFiscal

            },
            direccion: !preInfo.fromContratante ? {} : {
                codigoPostal: preInfo.fromContratante.cp,
                estado: preInfo.fromContratante.estado,
                municipio: preInfo.fromContratante.municipio,
                colonia: preInfo.fromContratante.colonia,
                calle: preInfo.fromContratante.calle,
                numeroExterior: preInfo.fromContratante.exterior + "",
                numeroInterior: preInfo.fromContratante.interior === "" ? "NA" : preInfo.fromContratante.interior + "",
                cod_colonia: preInfo.fromContratante.cod_colonia,
                cod_ciudad: preInfo.fromContratante.cod_ciudad,
                cod_municipio: preInfo.fromContratante.cod_municipio,
                cod_dpto: preInfo.fromContratante.cod_dpto
            }
        },
        mascotas: preInfo.addPets.length > 0 ? array_structure(preInfo.addPets) : [],
        folio: preInfo.folio ? preInfo.folio : '',
        collaborator_number: preInfo.collaborator_number,
        geolocalizacion: preInfo.geolocalizacion
    }
    if (preInfo.email) {
        preOrderPet.contratante.correo = preInfo.email;
    }


    return preOrderPet;
}

export const navigatePage = (data) => {
    let response = ""
    switch (data.contratante.pantalla) {
        case "3":
            response = "/add-pets"
            break;
        case "4":
            response = "/confirm-payment"
            break;
        case "5":
            response = "/foundation-inter"
            break;
        case "6":
            response = "/ya-seguro"
            break;
    }

    return response;
}

export const navigatePageForce = (data) => {
    let response = ""
    switch (data) {
        case "3":
            response = "/add-pets"
            break;
        case "4":
            response = "/confirm-payment"
            break;
        case "5":
            response = "/foundation-inter"
            break;
        case "6":
            response = "/ya-seguro"
            break;
    }

    return response;
}

export const preInfoContratanteToPreOrder = (preInfoContratante) => {
    let preOrderContratante = {}
    preOrderContratante = !preInfoContratante.contratante ? null :
        preInfoContratante.contratante.datosPagador?.nombre ?
            {
                isReturn: preInfoContratante.contratante.datosContratante?.pagador === false ? preInfoContratante.contratante.datosContratante.nombre=== "" ? true :  false  : false,
                nombres: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosContratante?.nombre : preInfoContratante.contratante.datosPagador?.nombre,
                paterno: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosContratante?.apellidoPaterno : preInfoContratante.contratante.datosPagador?.apellidoPaterno,
                materno: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosContratante?.apellidoMaterno : preInfoContratante.contratante.datosPagador?.apellidoMaterno,
                rfc: preInfoContratante.contratante.datosContratante?.rfc ? preInfoContratante.contratante.datosContratante.rfc.toUpperCase() : "",
                genero: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosContratante?.sexo : preInfoContratante.contratante.datosPagador?.sexo,
                isfactura: preInfoContratante.contratante.datosContratante?.requiereFactura,
                fiscal: preInfoContratante.contratante.datosContratante?.regimenFiscal,
                idFiscal: preInfoContratante.contratante.datosContratante?.idFiscal,
                fechanacimiento: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosContratante?.fechaNacimiento : preInfoContratante.contratante.datosPagador?.fechaNacimiento,
                email: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosContratante?.correo : preInfoContratante.contratante.datosPagador?.correo,
                telefono: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosContratante?.telefono : preInfoContratante.contratante.datosPagador?.telefono,
                cp: preInfoContratante.contratante.direccion?.codigoPostal,
                estado: preInfoContratante.contratante.direccion?.estado,
                municipio: preInfoContratante.contratante.direccion?.municipio,
                colonia: preInfoContratante.contratante.direccion?.colonia,
                calle: preInfoContratante.contratante.direccion?.calle,
                exterior: preInfoContratante.contratante.direccion?.numeroExterior + "",
                interior: preInfoContratante.contratante.direccion?.numeroInterior + "",
                cod_colonia: preInfoContratante.contratante.direccion?.cod_colonia,
                cod_ciudad: preInfoContratante.contratante.direccion?.cod_ciudad,
                cod_municipio: preInfoContratante.contratante.direccion?.cod_municipio,
                cod_dpto: preInfoContratante.contratante.direccion?.cod_dpto,
                factura: preInfoContratante.contratante.datosContratante?.requiereFactura,
                terminos: "",
                roleType: preInfoContratante.contratante.datosContratante?.pagador ? "si" : "no",
                //dueño
                nombresOwner: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosPagador?.nombre : preInfoContratante.contratante.datosContratante?.nombre,
                paternoOwner: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosPagador?.apellidoPaterno : preInfoContratante.contratante.datosContratante?.apellidoPaterno,
                maternoOwner: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosPagador?.apellidoMaterno : preInfoContratante.contratante.datosContratante?.sexo,
                generoOwner: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosPagador?.sexo : preInfoContratante.contratante.datosContratante?.sexo,
                emailOwner: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosPagador?.correo : preInfoContratante.contratante.datosContratante?.correo,
                telefonoOwner: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosPagador?.telefono : preInfoContratante.contratante.datosContratante?.telefono,
                fechanacimientoOwner: preInfoContratante.contratante.datosContratante?.pagador === true ? preInfoContratante.contratante.datosPagador?.fechaNacimiento : preInfoContratante.contratante.datosContratante?.fechaNacimiento
            }
            :
            null;
    return preOrderContratante;

}

export const performancePlan = (id, plans) => {
    let array_plans = plans; //store.getState().config.plans;
    let item = array_plans.find(item => item.PlanCodigo === id);
    if (item) {
        return item;
    } else {
        return null;
    }
}


export const performanceData = (data, plans = [], email = "") => {
    let updateData = {
        isFolio: false,
        objectNumCotiza: null,
        selectedTypePet: data.mascotas.length > 0 ? data.mascotas[0].especie === "Perro" ? 1 : 2 : null,
        petName: data.mascotas.length > 0 ? data.mascotas[0].nombre : null,
        selectedPlan: performancePlan(data.contratante.plan.codigo, plans),
        addPets: data.mascotas,
        fromContratante: preInfoContratanteToPreOrder(data),
        steps: data.contratante.pantalla === "3" ? parseInt(data.contratante.subEtapa) : data.contratante.pantalla === "4" ? 3 : 1,
        folio: data.folio,
        collaborator_number: data.collaborator_number,
    }
    if (data && data.geolocalizacion) {
        updateData.geolocalizacion = data.geolocalizacion
    } else {
        updateData.geolocalizacion = {
            "latitud": "19.440402",
            "longitud": "-99.202500",
            "aceptacion": "1"
        }
    }

    if (email !== "") {
        updateData.email = email
    }
    return updateData;

}

export const emissionStructure = (info) => {
    let emission = {
        "business": "medipet",
        "insurance": "gmx",
        "producto": "1",
        contratante: {
            "folio": info.folio,
            "etapa": "1",
            "subEtapa": "1",
            "pantalla": "4",
            plan: !info.selectedPlan ? {} : {
                producto: info.selectedPlan ? info.selectedPlan.ProductoCodigo : "",
                codigo: info.selectedPlan ? info.selectedPlan.PlanCodigo : "",
                nombre: info.selectedPlan ? info.selectedPlan.PlanNombreInter : "",
                precio: info.selectedPlan ? info.selectedPlan.PlanPrimaTotal2 : "",
            },

            datosContratante: !info.fromContratante ? {} : {
                nombre: info.fromContratante ? info.fromContratante.roleType === 'si' ? info.fromContratante.nombres : info.fromContratante.nombresOwner === "" ? info.fromContratante.nombres : info.fromContratante.nombresOwner : "",
                apellidoPaterno: info.fromContratante ? info.fromContratante.roleType === 'si' ? info.fromContratante.paterno : info.fromContratante.paternoOwner === "" ? info.fromContratante.paterno : info.fromContratante.paternoOwner  : "",
                apellidoMaterno: info.fromContratante ? info.fromContratante.roleType === 'si' ? info.fromContratante.materno : info.fromContratante.maternoOwner === "" ? info.fromContratante.materno : info.fromContratante.maternoOwner: "",
                sexo: info.fromContratante ? info.fromContratante.roleType === 'si' ? info.fromContratante.genero : info.fromContratante.generoOwner === "" ? info.fromContratante.genero : info.fromContratante.generoOwner : "",
                fechaNacimiento: info.fromContratante ? info.fromContratante.roleType === 'si' ? info.fromContratante.fechanacimiento : info.fromContratante.fechanacimientoOwner === "" ? info.fromContratante.fechanacimiento : info.fromContratante.fechanacimientoOwner : "",
                pagador: info.fromContratante ? info.fromContratante.roleType === "si" ? true : false : false,
                correo: info.fromContratante ? info.fromContratante.roleType === 'si' ? info.fromContratante.email : info.fromContratante.emailOwner === "" ? info.fromContratante.email : info.fromContratante.emailOwner : "",
                telefono: info.fromContratante ? info.fromContratante.roleType === 'si' ? info.fromContratante.telefono : info.fromContratante.telefonoOwner === "" ? info.fromContratante.telefono : info.fromContratante.telefonoOwner : "",
                rfc: info.fromContratante.rfc.toUpperCase(),
                requiereFactura: info.fromContratante.isfactura,
                regimenFiscal: info.fromContratante.fiscal,
                idFiscal: info.fromContratante.idFiscal

            },
            datosPagador: !info.fromContratante ? {} : {
                nombre: info.fromContratante.nombres,
                apellidoPaterno: info.fromContratante.paterno,
                apellidoMaterno: info.fromContratante.materno,
                sexo: info.fromContratante.genero,
                fechaNacimiento: info.fromContratante.fechanacimiento,
                correo: info.fromContratante.email,
                telefono: info.fromContratante.telefono,
                rfc: info.fromContratante.rfc.toUpperCase(),
                requiereFactura: info.fromContratante.isfactura,
                regimenFiscal: info.fromContratante.fiscal,
                idFiscal: info.fromContratante.idFiscal
            },
            direccion: !info.fromContratante ? {} : {
                codigoPostal: info.fromContratante.cp,
                estado: info.fromContratante.estado,
                municipio: info.fromContratante.municipio,
                colonia: info.fromContratante.colonia,
                calle: info.fromContratante.calle,
                numeroExterior: info.fromContratante.exterior + "",
                numeroInterior: info.fromContratante.interior === "" ? "NA" : info.fromContratante.interior + "",
                "cod_colonia": info.fromContratante.cod_colonia,
                "cod_ciudad": info.fromContratante.cod_ciudad,
                "cod_municipio": info.fromContratante.cod_municipio,
                "cod_dpto": info.fromContratante.cod_dpto
            }
        },
        "mascotas": info.addPets.length > 0 ? array_structure(info.addPets) : [],
        "geolocalizacion": info.geolocalizacion

    }

    if (info.email) {
        emission.contratante.correo = info.email;
    }

    return emission;
}

export const hash_hmac = (params = "", private_key = "") => {
    try {
        let firma = CryptoJS.HmacSHA256(params, private_key);
        let base64 = firma.toString().toUpperCase();
        return base64;
    } catch (e) {
        return "error"
    }

}

export const planPercentage = (basePrice, basePercentage = 20) => {
    let decuento = basePercentage / 100;
    let cobro = basePrice / (1 - decuento);
    let ajuste = (cobro - basePrice) / basePrice;
    let cobroReal = cobro * (1 - decuento);

    return {decuento, cobro, ajuste, cobroReal}
}


export const getParameters_ = async (url) => {
    if (url !== "") {
        let urlString = url
        let paramString = urlString.split('?')[1];
        let queryString = new URLSearchParams(paramString);
        for (let pair of queryString.entries()) {
            await setLocalStorageData(pair[0], pair[1])
        }
    }
}

export const getUrlParams = (search = "") => {
    if (search !== "") {
        let hashes = search.slice(search.indexOf('?') + 1).split('&')
        let params = hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {[key]: decodeURIComponent(val)})
        }, {})
        return {success: true, response: params}
    } else {
        return {success: false}
    }
}

export const validatePhoneNumber = (phoneNumber) => {
    let error = TELEFONO_INVALIDO_


    if (isThereFourConsecutiveNumber(phoneNumber)) return error
    if (allEqual(Array.from(phoneNumber))) return error
    if (phoneNumber.includes('123456789') || phoneNumber.includes('987654321')) return error

    return ""
}

const allEqual = (arr) => {
    arr.every((val) => val === arr[0])
}

const isThereFourConsecutiveNumber = (phoneNumber) => {
    if (/(^|(.)(?!\2))(\d)\3{4,}(?!\3)/.test(phoneNumber)) {
        return true;
    }
    return false;
}

export const validateEmail = (email) => {
    const [beforeAt, afterAt] = email.split('@')
    const blacklist = ['gmail.mx', 'hotmail.mx', 'aol', 'hotmail.com.mx', 'gmail.com.mx', 'live.com.mx', 'gmail.co', 'ensamble.dev', 'outloot.com', 'yahoo.com.sg']
    if (beforeAt.length <= 3) return EMAIL_INVALIDO
    if (blacklist.includes(afterAt)) return EMAIL_INVALIDO
    return ''
}

export const validatePetPhoto = (array = []) => {
    let update = false;
    let max = array.length;
    let count = 0;
    if (array.length > 0) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].addPhoto === true) {
                count = count + 1
            }
        }
    }
    if (count === max) {
        update = true
    }

    return update;
}

export const ScrollToTop = () => {
    const {pathname} = useLocation();


    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        scrollTop()
    }, [pathname]);

    return null;
}

export const keyPressSoloLetrasNumeros = (event) => {
    if (
        event?.key === '#' ||
        event?.key === '$' ||
        event?.key === '%' ||
        event?.key === '=' ||
        event?.key === '@' ||
        event?.key === ':' ||
        event?.key === '[' ||
        event?.key === ']' ||
        event?.key === '`' ||
        event?.key === '+' ||
        event?.key === '~' ||
        event?.key === '{' ||
        event?.key === '}' ||
        event?.key === '^' ||
        event?.key === '¿' ||
        event?.key === '?' ||
        event?.key === '<' ||
        event?.key === '>' ||
        event?.key === 'ç' ||
        event?.key === '°' ||
        event?.key === '´' ||
        event?.key === '_' ||
        event?.key === '"' ||
        event?.key === '|' ||
        event?.key === '¬' ||
        event?.key === '¨' ||
        event?.key === 'á' ||
        event?.key === 'é' ||
        event?.key === 'í' ||
        event?.key === 'ó' ||
        event?.key === 'ú' ||
        event?.key === 'Á' ||
        event?.key === 'É' ||
        event?.key === 'Í' ||
        event?.key === 'Ó' ||
        event?.key === 'Ú' ||
        event?.key === '&' ||
        event?.key === '/' ||
        event?.keyCode === 33 ||
        event?.keyCode === 34 ||
        event?.keyCode === 36 ||
        event?.keyCode === 37 ||
        event?.keyCode === 38 ||
        event?.keyCode === 40 ||
        event?.keyCode === 41 ||
        event?.keyCode === 47 ||
        event?.keyCode === 35 ||
        event?.keyCode === 36 ||
        event?.keyCode === 58 ||
        event?.keyCode === 59 ||
        event?.keyCode === 61 ||
        event?.keyCode === 91 ||
        event?.keyCode === 92 ||
        event?.keyCode === 93 ||
        event?.keyCode === 95 ||
        event?.keyCode === 96 ||
        event?.keyCode === 43 ||
        event?.keyCode === 42 ||
        event?.keyCode === 44 ||
        event?.keyCode === 45 ||
        event?.keyCode === 46 ||
        event?.keyCode === 126 ||
        event?.keyCode === 125 ||
        event?.keyCode === 123 ||
        event?.keyCode === 94 ||
        event?.keyCode === 161 ||
        event?.keyCode === 167 ||
        event?.keyCode === 39 ||
        event?.keyCode === 191 ||
        event?.keyCode === 63 ||
        event?.keyCode === 60 ||
        event?.keyCode === 191 ||
        event?.keyCode === 239
    ) {
        event.preventDefault();
    } else {
        event.target.value =
            event.target.value;
    }
}

export const noPaste = (e) => {
    var clipboardData, pastedData;
    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();
    // Get pasted data via clipboard API
    clipboardData = e.clipboardData || window.clipboardData;
    clipboardData.getData('Text');
    // Do whatever with pasteddata
}

export const TagManagerLayer = (tagManagerArgs = null) => {
    console.log("TagManager", TagManager)
    if (tagManagerArgs) {
        TagManager.dataLayer(tagManagerArgs)
    }
}

export const causeParams = (params_causes, name) => {
    let params_cause = {
        "business": "medipet",
        "insurance": "gmx",
        'folio':params_causes.folio,
        "nombre": params_causes.fromContratante.roleType === "si" ? params_causes.fromContratante.nombres : params_causes.fromContratante.nombresOwner,
        "causa": name,
        "correo": params_causes.email === "hola@inter.mx" ?
            params_causes.fromContratante ?
                params_causes.fromContratante.roleType === 'si' ?
                    params_causes.fromContratante.email
                    :
                    params_causes.fromContratante.emailOwner === "" ? params_causes.fromContratante.email : params_causes.fromContratante.emailOwner
                : params_causes.email : params_causes.email,
    }
    return params_cause;
}


export const validateUpdatePet = (update, values) => {
    let isUpdate = false;

    if (update.nombre !== values.nombre) {
        isUpdate = true
    }

    if (update.especie !== values.especie) {
        isUpdate = true
    }

    if (update.sexo !== values.sexo) {
        isUpdate = true
    }

    if (update.raza !== values.raza) {
        isUpdate = true
    }

    if (update.meses !== values.meses) {
        isUpdate = true
    }

    if (update.anios !== values.anios) {
        isUpdate = true
    }

    if (update.enfermedades !== values.enfermedades) {
        isUpdate = true
    }

    if (update.descripcionMascota !== values.descripcionMascota) {
        isUpdate = true
    }

    return isUpdate;

}

export const validateFormat = (dayText) =>{
    let errorDate = false;
    var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
    if (dayText.match(reg)) {
      errorDate = true;
    }
    else {
      errorDate = false;
    }
return errorDate;
}

export const UtmURls=(url="")=>{
    let utm_url = "";
    if (window.localStorage.getItem('utm_source') &&  window.localStorage.getItem('utm_content')!== 'undefined' ){
        utm_url =
          url  +
          '?utm_source=' +
          window.localStorage.getItem('utm_source') +
          '&utm_medium=' +
          window.localStorage.getItem('utm_medium') +
          '&utm_campaign=' +
          window.localStorage.getItem('utm_campaign') +
          '&utm_content=' +
          window.localStorage.getItem('utm_content') +
          '&gclid=' +
          window.localStorage.getItem('gclid')
    }else {
        utm_url= url
    }

    return utm_url
}



export const OnlyAlpha = (event) => {
    let keyCode = (event.which) ? event.which : event.keyCode
    if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 123) && keyCode !== 32){
        event.preventDefault()
    }
}
