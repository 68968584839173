import { Box, Button, Dialog, Grid, IconButton, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import * as React from "react";
import './ModalErrorPet.css'
import imgCat from "../../assets/modalimg/gato-noasegurable.png";
import imgDog from "../../assets/modalimg/perro-noasegurable.png";

const ModalErrorPet = (props) => {

  return (<>
    <Dialog open={props.open}
      disableEscapeKeyDown={true}
      maxWidth={'xs'} fullWidth={false}>
      <div className={"modal-w"}>
        <IconButton
          aria-label="close"
          onClick={() => props.close()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelIcon />
        </IconButton>

        <Box sx={{ mt: 6 }}>
          <Grid
            className='modal-individual-padding'
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >

              <span className='title-modal-error-pet'>{'Oh, no :('}</span>
              <Box sx={{ mt: 0 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid textAlign={"center"}
                    justifyContent={"center"}
                    xs={12} sm={12} md={12}>
                    <img style={{
                      width: '100%',
                      height: 'auto'
                    }} src={props.typePet ? props.typePet === 1 ? imgDog : imgCat : imgDog} alt='Mascota' />
                  </Grid>
                </Stack>
              </Box>
              <Box sx={{ mt: 0 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <span className='text-description-error'>{`Lamentamos mucho decirte que por ahora no podemos asegurar a ${props.name}, porque no cumple con lo que la aseguradora nos pide.`}</span>
                  <span className='text-description-error'>{'Sabemos que no es la noticia que esperabas, pero si tienes alguna duda, llámanos a 55-44-24-68-37 y lo platicamos, ¿va?'}</span>
                </Stack>
              </Box>
            </Stack>

            <Box sx={{ mt: 3, width: '100%', mb: 2 }}>
              <Button
                onClick={() => props.close()}
                style={{
                  width: '100%',
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 16,
                }}
                fullWidth
                variant="contained"
              >{'BUENO, NI MODO :('}</Button>
            </Box>
          </Grid>
        </Box>
      </div>
    </Dialog>
  </>)
}
export default ModalErrorPet;