import { Grid, Stack } from "@mui/material";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import './DetailPetCredential.css';
import AvatarCat from "../../assets/img/fotoAlterna/avatar-gato.svg"
import AvatarDog from "../../assets/img/fotoAlterna/avatar-perro.svg"


const DetailPetCredential = ({ ...props }) => {

  return (
    <>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >

            <img style={{
              width: 130,
              height: 130,
              borderRadius: 130,
              objectFit: "cover",
              marginTop: 25
            }} src={props.pet.foto ? props.pet.foto !== "" ? props.pet.foto : props.pet.especie === 'Perro' ? AvatarDog : AvatarCat : props.pet.especie === 'Perro' ? AvatarDog : AvatarCat} alt='AvatarMascota' />
          </Stack>

          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >

            <span className="title-credential">{props.pet.nombre}</span>
            <div style={{
              marginTop: 20,
              marginBottom: 20,
              width: '100%',
              height: .5,
              backgroundColor: '#0A3949',
              opacity: 0.3,
            }} />

            <div className="div-credential-pet">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <span className="subTitle-credential">Tipo de mascota</span>
                <span className="title-credential">{props.pet.especie}</span>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <span className="subTitle-credential">Raza</span>
                <span className="title-credential">{props.pet.raza}</span>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <span className="subTitle-credential">Edad</span>
                <span className="title-credential">{props.pet.anios + " Años "}{props.pet.meses + " meses"}</span>
              </Stack>
            </div>

          </Stack>
        </Stack>
      </Grid>
    </>
  );

}

const mapState = (state) => {
  return {
    preOrder: state.preOrder
  }
}
export default connect(mapState, { addInfoToPreOrder })(DetailPetCredential);