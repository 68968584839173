import { Box, Button, Dialog, Grid, IconButton, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import * as React from "react";
import './ModalDiscountForYou.css'
import imgCat from "../../assets/modalimg/gato-descuento.png";
import imgDog from "../../assets/modalimg/perro-descuento.png";
import { useEffect, useState } from "react";
import ApiWeb from "../../lib/apiConfig/apiWeb";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";


const ModalDiscountForYou = ({ disableClose = false, isDiscount = false, ...props }) => {
  const [discount, setDiscount] = useState(0)
  const [objCoupon, setObjCoupon] = useState(null)

  const getCoupon = () => {
    let params = {
      "business": "medipet",
      "insurance": "gmx",
      "service": "coupon",
      "util": "create"
    }
    ApiWeb.genericApi(params).then(response => {
      if (response.data.code === 200) {
        setDiscount(response.data.data.value);
        setObjCoupon(response.data.data)
      } else {
        setDiscount(0)
      }
    }).catch(error => {
      console.log("error:", error)
      setDiscount(0)
    });
  }
  useEffect(() => {
    getCoupon()
  }, []);


  const setCoupon = () => {
    if (objCoupon !== null) {
      props.addInfoToPreOrder({ coupon: objCoupon });
      window.dataLayer.push({
        event : "mascota_seguro_aceptar_cupon",
        plan_elegido: props.preOrder.selectedPlan.PlanNombreInter,
        no_cotizacion: props.preOrder.folio,
        boton_contacto: 'Super si lo quiero', 
        codigo_cupon: objCoupon.coupon
        });
    }
  }

  return (<>
    <Dialog open={props.open} ///onClose={() => props.close()}
      disableEscapeKeyDown={true}
      maxWidth={'xs'} fullWidth={false}>
      <div className={"modal-w"}>

        {
          !disableClose &&
          <IconButton
            aria-label="close"
            onClick={() => props.close()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        }


        <Box sx={{ mt: 5, px: 1 }}>
          <Grid
            className='modal-individual-padding'
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >

              {
                isDiscount ?
                  <>
                    <span className='title-modal-discount'>¡Tenemos un descuento </span>
                    <span className='title-modal-discount'>especial para ti!</span>
                  </>
                  :
                  <span className='title-modal-discount'>{'¡No te vayas!'}</span>

              }
              {
                isDiscount ?
                  <span
                    className={'title-procentaje'}> {discount === 0 ? "00%" : discount + "%"}</span>
                  :
                  null
              }
              <Box sx={{ mt: 2 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid textAlign={"center"}
                    justifyContent={"center"}
                    xs={12} sm={12} md={12}>
                    <img style={{
                      width: '100%',
                      height: 'auto'
                    }} src={props.typePet ? props.typePet === 1 ? imgDog : imgCat : imgDog}
                      alt='LogoInter' />
                  </Grid>
                </Stack>
              </Box>
              <Box sx={{ mt: 5 }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  {
                    isDiscount ?
                      null
                      :
                      <>
                        <span className='title-modal-discount'>Tenemos un</span>
                        <span className='title-modal-discount'>de descuento para ti </span>
                      </>
                  }

                </Stack>
              </Box>
            </Stack>

            <Box sx={{ mt: 3, width: '100%', mb: 2 }}>
              <Button
                onClick={() => {
                  props.accept()
                  setCoupon();
                }}
                style={{
                  width: '100%',
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 16,
                  height: 48
                }}
                fullWidth
                variant="contained"
              >{
                  isDiscount ?
                    '¡SÚPER SÍ LO QUIERO!'
                    :
                    '¡LO QUIERO!'
                }</Button>
            </Box>
          </Grid>
        </Box>
      </div>
    </Dialog>
  </>)
}
const mapStateToProps = state => ({
  preOrder: state.preOrder
})
export default connect(mapStateToProps, { addInfoToPreOrder })(ModalDiscountForYou);
