import React from "react";
import {connect} from "react-redux";
import {Box, Grid, Stack} from "@mui/material";
import '../../pages/home/Home.css';
import {addInfoToPreOrder} from "../../redux/preOrderDuck";
import {TagManagerLayer} from "../Utils/utils";

const species_ =  [
    {
        "txt_desc_especie": "PERRO",
        "cod_especie": "1"
    },
    {
        "txt_desc_especie": "GATO",
        "cod_especie": "2"
    }
]

const SelectPet =({species= [],...props})=>{
    const sendTagManagerArgs = (type) => {
        let tagManagerArgs = {
            event: "mascota_seguro_contacto",      //Static data,
            tipo_mascota: type === 1 ? "Perro":"Gato",
            boton_contacto: "cotizar",
            cotizacion: "No Aplica"
        }

        TagManagerLayer(tagManagerArgs)
    }

    const addPat =(type)=>{
        sendTagManagerArgs(type)
        props.addInfoToPreOrder({selectedTypePet:type})
        props.handledScroll()
        window.dataLayer.push({
            event: 'mascota_seguro_contacto',
            tipo_mascota: type === 1 ? 'Perro' : 'Gato',
        })
    }

    return(
        <>
            <div className='div-azul2'>
                <Grid
                    style={{padding:'5%',display:'flex'}}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                        <div style={{textAlign:'center'}} className='primero-primero'>
                            <Box
                                textAlign={'center'}
                                sx={{mt: 2}}>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box sx={{mt: 6}}>
                                        <span className='titulo-primero'>&iexcl;Primero lo primero!</span>

                                    </Box>
                                    <Box sx={{mt: 6}} >
                                        <span className='titulo-primero'>&iquest;Qu&eacute; tipo de cuatropatitas tienes en casa?</span>

                                    </Box>
                                </Stack>
                            </Box>
                            <Box sx={{mt: 6,mb:6}}>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Stack direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row'}}
                                           justifyContent="center"
                                           alignItems="center"
                                           spacing={{ xs: 2, sm: 2, md: 5, lg:12, xl:15}}>
                                        {
                                            species.length > 0 ?
                                            species.map((item,index) =>{
                                                return(
                                                    <Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className={item.txt_desc_especie === "GATO"?  'icon-cat' : 'icon-dog'}
                                                             onClick={()=>{
                                                                 addPat(parseInt(item.cod_especie))
                                                             }}
                                                        />
                                                    </Grid>
                                                )
                                            }).reverse()
                                            :
                                                species_.map((item,index) =>{
                                                    return(
                                                        <Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <div className={item.txt_desc_especie === "GATO" ?  'icon-cat' : 'icon-dog'}
                                                                 onClick={()=>{
                                                                     addPat(parseInt(item.cod_especie))
                                                                 }}
                                                            />
                                                        </Grid>
                                                    )
                                                }).reverse()
                                        }
                                    </Stack>
                                </Grid>
                            </Box>
                        </div>
                </Grid>
            </div>
        </>
    )
}
const mapState =(state)=>{
    return{
        preOrder:state.preOrder
    }
}
export default connect(mapState,{addInfoToPreOrder})(SelectPet);
