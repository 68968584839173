
import * as yup from "yup";
import { CHECK_NECESARIO, VALOR_NAME, VALOR_NECESARIO } from "../Utils/Constantes";


export const validationFormAddPet = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NAME)
    .typeError(VALOR_NAME)
    .min(1, VALOR_NAME),

  especie: yup
    .string()
    .required(CHECK_NECESARIO)
    .typeError(CHECK_NECESARIO),

  sexo: yup
    .string()
    .required(CHECK_NECESARIO)
    .typeError(CHECK_NECESARIO),
  raza: yup
    .string()
    .required("Raza de la mascota requerida")
    .typeError("Ingrese la raza de la mascota")
    .min(1, VALOR_NECESARIO),

  meses: yup
    .string()
    .required("Seleccione los meses de su mascota")
    .typeError("Seleccione los meses de su mascota"),

  anios: yup
    .string()
    .required("Seleccione los años de su mascota")
    .typeError("Seleccione los años de su mascota"),

  enfermedades: yup
    .string()
    .required("Ingrese una enfermedad")
    .min(2, "Ingrese una enfermedad"),

  descripcionMascota: yup
    .string()
    .required("Campo obligatorio, no olvides llenarlo")
    .min(11, "¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 11. ")
    .max(50,"¡Oops! Solo puedes ingresar 50 caracteres.")

});

export const validationFormAddPetNoEnfermedad = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NAME)
    .typeError(VALOR_NAME)
    .min(1, VALOR_NAME),

  especie: yup
    .string()
    .required(CHECK_NECESARIO)
    .typeError(CHECK_NECESARIO),

  sexo: yup
    .string()
    .required(CHECK_NECESARIO)
    .typeError(CHECK_NECESARIO),
  raza: yup
    .string()
    .required("Raza de la mascota requerida")
    .typeError("Ingrese la raza de la mascota")
    .min(1, VALOR_NECESARIO),

  meses: yup
    .string()
    .required("Seleccione los meses de su mascota")
    .typeError("Seleccione los meses de su mascota"),

  anios: yup
    .string()
    .required("Seleccione los años de su mascota")
    .typeError("Seleccione los años de su mascota"),

  descripcionMascota: yup
    .string()
    .required("Campo obligatorio, no olvides llenarlo")
    .min(11, "¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 11. ")
    .max(50,"¡Oops! Solo puedes ingresar 50 caracteres.")

});



