
import * as yup from "yup";
import { CORREO_INVALIDO } from "../Utils/Constantes";


export const validationFormQuote = yup.object().shape({
  folio: yup
    .string()
    .required("Ingrese un número de cotización")
    .typeError("Ingrese un número de cotización")
    /*.min(10, "Ingrese un número de cotización correcto")
    .max(10, 'Debe tener como máximo 10 caracteres')*/,


  correo: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(4, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres'),

});



