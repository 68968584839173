import { Grid, Stack } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import './DetailPet.css'
import AvatarCat from "../../assets/img/fotoAlterna/avatar-gato.svg"
import AvatarDog from "../../assets/img/fotoAlterna/avatar-perro.svg"

const DetailPetPayment = ({ vistatres = false, ...props }) => {

    return (<>
        {vistatres === true ?

            <Stack

                direction={{ xs: 'column', sm: 'column', md: 'column' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}>
                <Grid textAlign={"center"}
                    justifyContent={"center"}
                    xs={12} sm={12} md={12}>
                    <img style={{
                        width: 127,
                        height: 127,
                        objectFit:'cover',
                        borderRadius: 127
                    }} src={ props.pet.foto ? props.pet.foto !==""?  props.pet.foto : props.pet.especie === 'Perro' ?  AvatarDog: AvatarCat : props.pet.especie === 'Perro' ?  AvatarDog: AvatarCat  } alt='LogoInter' />
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Stack style={{ marginTop: 20 }} direction={{ xs: 'row' }}>
                        <Grid
                            style={{ paddingTop: 3 }}
                            item xs={18} sm={22}>
                            <span className={'title-accord-pay'}>{props.pet.nombre}</span>
                        </Grid>
                        <Grid item
                            style={{ width: '100%', textAlign: 'right' }}
                            justifyContent={"center"}
                            xs={6} sm={2}>
                            <EditIcon className={'edit-button-pay-two'} onClick={props.onClick} color={'disabled'} />
                        </Grid>
                    </Stack>
                    <div style={{
                        marginTop: 20,
                        marginBottom: 20,
                        width: '100%',
                        height: .5,
                        backgroundColor: '#0A3949',
                        opacity: 0.3,
                    }} />
                    {/**** Detalle ****/}
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <span className={'subtitle-accord-pay'}>{'Tipo de mascota'}</span>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <span className={'title-accord-pay'}>{props.pet.especie}</span>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: 20 }} columns={12}>
                        <Grid item xs={6}>
                            <span className={'subtitle-accord-pay'}> {'Raza'}</span>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <span className={'title-accord-pay'}>{props.pet.raza}</span>
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: 20 }} container columns={12}>
                        <Grid item xs={6}>
                            <span className={'subtitle-accord-pay'}>{'Edad'}</span>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <span  className={'title-accord-pay'}>{props.pet.anios + " Años "}{props.pet.meses + " meses"}</span>
                        </Grid>
                    </Grid>

                </Grid>
            </Stack>
            :
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                style={{marginBottom:5}}
                spacing={{ xs: 1, sm: 2, md: 4 }}>
                <Grid textAlign={"center"}
                      style={{alignSelf:'center'}}
                    justifyContent={"center"}
                    xs={12} sm={12} md={9}>
                    <img className={'size-img'}  src={ props.pet.foto ? props.pet.foto !==""?  props.pet.foto : props.pet.especie === 'Perro' ?  AvatarDog: AvatarCat : props.pet.especie === 'Perro' ?  AvatarDog: AvatarCat  } alt='LogoInter' />
                </Grid>
                <Grid item xs={12} sm={12} md={15}>
                    <Stack style={{ marginTop: 20 }} direction={{ xs: 'row' }}>
                        <Grid
                            style={{ paddingTop: 3 }}
                            item xs={18} sm={22}>
                            <span className={'title-accord-pay'}>{props.pet.nombre}</span>
                        </Grid>
                        <Grid item
                            style={{ width: '100%', textAlign: 'right' }}
                            justifyContent={"center"}
                            xs={6} sm={2}>
                            <EditIcon className={'edit-button-pay-two'} onClick={props.onClick} color={'disabled'} />
                        </Grid>
                    </Stack>
                    <div style={{
                        marginTop: 20,
                        marginBottom: 20,
                        width: '100%',
                        height: .5,
                        backgroundColor: '#0A3949',
                        opacity: 0.3,
                    }} />
                    {/**** Detalle ****/}
                    <Grid container>
                        <Grid item xs={6}>
                            <span className={'subtitle-accord-pay'}>{'Tipo de mascota'}</span>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <span className={'title-accord-pay'}>{props.pet.especie}</span>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: 20 }}>
                        <Grid item xs={6}>
                            <span className={'subtitle-accord-pay'}> {'Raza'}</span>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <span className={'title-accord-pay'}>{props.pet.raza}</span>
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: 20 }} container>
                        <Grid item xs={6}>
                            <span className={'subtitle-accord-pay'}>{'Edad'}</span>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <span className={'title-accord-pay'}>{props.pet.anios + " Años "}{props.pet.meses + " meses"}</span>
                        </Grid>
                    </Grid>

                </Grid>
            </Stack>
        }
    </>)

}
export default DetailPetPayment;
