import React from "react";
import { connect } from "react-redux";
import './FoundationInter.css'
import { Box, Button, Container, Grid, Link, Stack } from "@mui/material";
import logoInter from "../../assets/landing/logofoundation.svg";
import FoundationInterListDesk from "../../components/FoundationInter/FoundationInterListDesk";
import FoundationInterTablet from '../../components/FoundationInter/FoundationInterTablet'
import { useNavigate } from "react-router-dom";
import FoundationInterMobile from "../../components/FoundationInter/FoundationInterMobile";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { calcularEdad } from '../../components/Utils/utils';

const TitleInter = (props) => {

  return (<>
    <Stack style={{ width: '77%' }} direction={{ xs: 'column', sm: 'column', md: 'row' }}
    >
      <Grid
        alignSelf={'center'}
        paddingBottom={{ xs: 3, md: 0 }}
        paddingRight={{ xs: 0, md: 3 }}
        textAlign={{ xs: 'center', sm: 'center', md: 'center' }}
        xs={12} sm={12} md={4} lg={4} xl={2}>
        <img style={{
          width: 139,
        }} src={logoInter} alt='logoInter' />
      </Grid>
      <Grid
        alignSelf={'center'}
        xs={12} sm={12} md={12} lg={20} xl={10}>
        <div style={{ width: '100%', height: '100%' }}>
          <span className={'text-title-foundation'}>{'¡Con tu compra también apoyas una buena causa!'}</span>
          <br />
          <br />
          <span className={'text-title-desc'}>¿Ya conoces <b>Fundación Inter</b>? Siempre que compras un seguro en <Link
            href="https://inter.mx"><span
              className={"text-link"}>inter.mx</span></Link>,
            una parte de la ganancia (esto no tiene costo para ti) la destinamos a una buena causa.</span>
          <br />
          <br />
          <span
            className={'text-title-des2'}>{'Cuando formas parte de nuestra comunidad, también eres parte de nuestros logros'}</span>
        </div>
      </Grid>
    </Stack>
  </>)
}

const FoundationInter = (props) => {
  const navigate = useNavigate();

  const DateOfBirth = (date_) => {
    let newData = date_.replace(/(\d+[/])(\d+[/])/, '$2$1');
    let data_ = new Date(newData);
    return calcularEdad(data_);
  }

  const dataGTM = JSON.parse(sessionStorage.getItem('preOrder'));
  React.useEffect(() => {
    if (dataGTM) {
        window.dataLayer.push({
          event : "mascota_seguro_fundacion_compra_exitosa",      //Static data,
          no_cotizacion: dataGTM.folio,
          no_poliza: dataGTM.addPets[0].poliza?.Poliza,
          plan_elegido: dataGTM.selectedPlan.PlanNombreInter,
          tipo_mascota: dataGTM.addPets[0].especie,
          raza: dataGTM.addPets[0].raza,
          edad_mascota: `${dataGTM.addPets[0].anios} años y ${dataGTM.addPets[0].meses} meses`,
          edad_contratante: DateOfBirth(dataGTM.fromContratante.fechanacimiento),
          sexo:dataGTM.fromContratante.genero,
          codigo_cupon: dataGTM.coupon?.coupon,
          no_colaborador: dataGTM.collaborator_number,
          descuento: dataGTM.coupon?.value,
          pago_recibido: dataGTM.selectedPlan.PlanPrimaTotal2,
          correo: dataGTM.fromContratante.email,
          telefono: dataGTM.fromContratante.telefono
        })
    }
  }, [dataGTM])

  return (<>
    <div className='content-foundations'>
      <div className="circulo-gde" />
      <div className="circulo" />
      <Container className="container-foundations">
        <Box sx={{ mt: 2 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Stack direction={{ xs: 'column' }}>
              <Stack
                className={'card-content-foundation'}
                direction="column">
                <TitleInter />

                <Stack className={"card-blue-content-list"}
                  direction="column"
                >
                  <Box sx={{ paddingTop: 3, paddingBottom: 2 }}>
                    <span className={'text-title-list'}>{'Vota por tu causa favorita es gratis'}</span>
                  </Box>

                  <div className={'desk-list-hidden'}>
                    <FoundationInterListDesk preOrder={props.preOrder} />
                  </div>
                  <div className={'tablet-list-hidden'}>
                    <FoundationInterTablet preOrder={props.preOrder} />
                  </div>

                  <div className={'mobile-list-hidden'}>
                    <FoundationInterMobile preOrder={props.preOrder} />
                  </div>

                  {/* <div style={{ width: '96%', textAlign: "right", marginTop: 10, marginBottom: 10 }}>
                    <Button style={{
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: 16,
                    }}
                      onClick={() => {
                        navigate('/ya-seguro')
                      }}
                      variant="text">{'¡LUEGO LO CHECO!'}</Button>
                  </div> */}
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Box>
      </Container>
    </div>
  </>)
};

const mapStateToProps = state => ({
  preOrder: state.preOrder,
  config: state.config
});

export default connect(mapStateToProps, { addInfoToPreOrder })(FoundationInter);
