import { Dialog, Grid, Stack } from "@mui/material";
import * as React from "react"
import './PetPhotos.css'
import ModalPhotosCel from "./ModalPhotosCel";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import lineaAmarillaPhoto from '../../assets/img/lineaAmarilla/lineaAmarillaPhoto.svg'
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion from "@mui/material/Accordion";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import PetPhoto from "./PetPhoto";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { useEffect, useRef } from "react";
import { navigatePage, performanceData, validatePetPhoto } from "../Utils/utils";
import apiWeb from "../../lib/apiConfig/apiWeb";
import { useNavigate } from "react-router-dom";
import PetPhotos from "./PetPhotos";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const MAXIMO_TAMANIO_BYTES = 1000000; // 1MB = 1 millón de bytes

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Grid}`,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  backgroundColor: 'transparent'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  color: '#595959',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(-1),
  },
}));

const PetPhotospsAccordion = (props) => {
  const navigate = useNavigate()
  const countRef = useRef(null)
  const [indice, setIndice] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [expanded, setExpanded] = React.useState('');
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };


  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: '1px solid rgba(0, 0, 0, 0)',

  }));


  useEffect(() => {
    props.setClose(openPhotoModal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPhotoModal]);

  useEffect(() => {
    setOpenPhotoModal(props.close)
  }, [props.close]);

  const handleClickModal = () => {
    handleClickOpen();
  }
  const structureData = (data, email) => {
    let response = performanceData(data, props.config.plans, email);
    props.addInfoToPreOrder({ ...response })
    let responseNavigation = navigatePage(data);
    navigate(responseNavigation)
  }

  ///Obtener los datos del servicio de cotización
  const getData = () => {
    let params = {
      "business": "medipet",
      "insurance": "gmx",
      "folio": parseInt(props.preOrder.folio),
      "correo": props.preOrder.email
    }
    apiWeb.getLead(params).then(res => {
      if (res.data.success) {
        if (validatePetPhoto(res.data.data.mascotas)) {
          setOpenPhotoModal(false);
          handlePauseInterval25Second();
          structureData(res.data.data, props.preOrder.email);
        }
      }
    }).catch(e => {
      console.log(e)
    })
  }

  ///Timer  que le pega al servicio cada 25 segundos para obtener la infirmación nueva de la cotizaión
  const handleStartInterval25Second = () => {
    countRef.current = setInterval(() => {
      getData();
    }, 10000)
  }

  const handlePauseInterval25Second = () => {
    clearInterval(countRef.current)
  }


  const handleClickOpen = () => {
    setOpenPhotoModal(true);
    handleStartInterval25Second();
  };

  const handleClickClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenPhotoModal(false);
    }
  };

  const handleChange = (panel, index) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setIndice(index)
    setSelectedIndex(index)
  }


  return (
    <>
      {props.preOrder.addPets.length === 1 ?

        <PetPhoto close={props.close} setClose={props.setClose} mascota={props.preOrder.addPets[0]} />

        :

        <>
          <Dialog open={openPhotoModal} onClose={(event, reason) => handleClickClose(event, reason)} disableEscapeKeyDown={true}
            maxWidth="sm" fullWidth={false}>
            <>
              <ModalPhotosCel handleClose={handleClickClose} folio={props.preOrder.folio} email={props.preOrder.email} />
            </>
          </Dialog>
          <>
            <div className="content-photo">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {
                  props.preOrder.addPets.map((perro, i) => (
                    <>
                      {
                        !props.preOrder.step2GoBack ?
                          <React.Fragment key={i}>
                            <Accordion
                              key={i}
                              expanded={i === indice}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="paso2-content"
                                id="paso2-header"
                              >
                                <div className="linea-amarilla">
                                  <img src={lineaAmarillaPhoto} alt='' />
                                  <div className="titulo1-accordion">{"Mascosta " + (i + 1)}</div>
                                  <div className="titulo2-accordion">{perro.nombre}</div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>

                                <PetPhotos index={i} selectedIndex={selectedIndex} indice={indice} setIndice={setIndice} mascota={perro} handleClickModal={handleClickModal} />
                              </AccordionDetails>
                            </Accordion>
                          </React.Fragment>
                          :
                          <React.Fragment key={i}>
                            <Accordion
                              key={i}

                              expanded={expanded === `panel${i}`}
                              onChange={handleChange(`panel${i}`, i)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="paso2-content"
                                id="paso2-header"
                              >
                                <div className="linea-amarilla">
                                  <img src={lineaAmarillaPhoto} alt='' />
                                  <div className="titulo1-accordion">{"Mascosta " + (i + 1)}</div>
                                  <div className="titulo2-accordion">{perro.nombre}</div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <PetPhotos setExpanded={setExpanded} index={i} selectedIndex={selectedIndex} indice={indice} setIndice={setIndice} mascota={perro} handleClickModal={handleClickModal}
                                  setOpenSnack={setOpenSnack}
                                />
                              </AccordionDetails>
                            </Accordion>
                          </React.Fragment>

                      }
                    </>


                  ))}
              </Grid>
            </div>
            <Stack spacing={0} sx={{ width: '100%' }}>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                open={openSnack}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Actualizado con éxito
                </Alert>

              </Snackbar>
            </Stack>
          </>
        </>

      }
    </>
  );

}
const mapStateToProps = state => ({
  preOrder: state.preOrder,
  config: state.config
})
export default connect(mapStateToProps, { addInfoToPreOrder })(PetPhotospsAccordion);