import React from "react";
import { useRoutes } from "react-router";
import { Navigate } from 'react-router-dom';
import Home from "../../pages/home/Home";
import PreguntasFrecuentesView from "../../pages/home/questions/PreguntasFrecuentesView";
import InsuranceDetails from "../../pages/insurance_details/InsuranceDetails";
import AddPets from "../../pages/add_pets/AddPets";
import ConfirmPayment from "../../pages/confirm_payment/ConfirmPayment";
import YaEstaSeguro from "../../pages/YaEstaSeguro/YaEstaSeguro";
import FoundationInter from "../../pages/foundation_inter/FoundationInter";
import ConfigMobile from "../../pages/config_mobile/ConfigMobile";
import ConfigPayment from "../../pages/config_payment/ConfigPayment";
const Pages = (props) => {

  let routes = useRoutes([
    { path: "/*", element: <Navigate to="/" /> },
    { path: "/", element: <Home isMobile={props.isMobile}/> },
    { path: "/home", element: <Home isMobile={props.isMobile}/> },
    { path: "/questions", element: <PreguntasFrecuentesView isMobile={props.isMobile}/> },
    { path: "/insurance-detail", element: <InsuranceDetails isMobile={props.isMobile}/> },
    { path: "/add-pets", element: <AddPets isMobile={props.isMobile}/> },
    { path: "/confirm-payment", element: <ConfirmPayment isMobile={props.isMobile}/> },
    { path: "/ya-seguro", element: <YaEstaSeguro isMobile={props.isMobile}/> },
    { path: "/foundation-inter", element: <FoundationInter isMobile={props.isMobile}/> },
    { path: "/config-mobile/:folio/:email", element: <ConfigMobile isMobile={props.isMobile}/> },
    { path: "/config-payment", element: <ConfigPayment isMobile={props.isMobile}/> },


    // ...
  ]);
  return routes;
}
export default Pages;
