import React, {useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import PetAddForm from "./PetAddForm";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion from "@mui/material/Accordion";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PetUpdateForm from "./PetUpdateForm";
import './PetForm.css'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Grid}`,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  backgroundColor: 'transparent'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  color: '#595959',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(-1),
  },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, 0)',

}));

const AccordionFormArray = (props) => {
  const [name, setName] = useState("");
  const [expanded, setExpanded] = useState('');
  const [selectedIndex, setSelectedIndex] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setSelectedIndex(panel)
    setExpanded(newExpanded ? panel : props.preOrder.addPets.length + 1);
  }

  useEffect(()=>{
    if (props.preOrder.addPets.length>0){
      handleChange('panel'+props.preOrder.addPets.length + 1)
    }
  },[props.preOrder.addPets.length])

  return (<div>
    {
      !props.preOrder.step1GoBack &&
        props.preOrder.addPets.length > 0 ?
        props.preOrder.addPets.map((item, index) => {
          return (
            <Accordion key={index} expanded={true} onChange={handleChange(`panel${index}`)}>
              <AccordionSummary aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={expanded === `panel${index}` ?
                  <ExpandLessIcon />
                  :
                  <ExpandMoreIcon />}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', width: 'auto', height: '100%' }}>
                      <div
                        style={{
                          backgroundColor: "#FFD60E",
                          width: 7,
                          borderRadius: 7,
                          height: '100%',
                        }} />
                      <div style={{
                        paddingLeft: 10,
                        display: 'block',
                        width: '50%',
                        height: '100%'
                      }}>
                        <span className={'title-accord'}>{"Mascota " + (index + 1)}</span>
                        <br />
                        <span className={'subtitle-accord'}>{item.nombre}</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <PetUpdateForm
                    handledScroll={props.handledScroll}
                    selectedIndex={selectedIndex} index={`panel${index}`} pet={item} setTypePet={props.setTypePet} setNamePet={props.setNamePet} setErrorModal={props.setErrorModal} />
              </AccordionDetails>
            </Accordion>
          )
        })
        :
        !props.preOrder.step1GoBack &&
        props.preOrder.addPets.length === 0 &&
        <div style={{ marginTop: 20 }}>
          <PetAddForm
              handledScroll={props.handledScroll}
              setTypePet={props.setTypePet} setNamePet={props.setNamePet} setErrorModal={props.setErrorModal} />
        </div>
    }

   {/* {
      !props.preOrder.step1GoBack &&
      props.preOrder.addPets.length >= 1 && props.preOrder.addPets.length <= 4 &&
      <Accordion
          expanded={expanded ==='' ? true : expanded ===  `panel${props.preOrder.addPets.length + 1}` ? true:false}
        onChange={handleChange(`panel${props.preOrder.addPets.length + 1}`)}>
        <AccordionSummary aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={expanded === `panel${props.preOrder.addPets.length + 1}` ?
            <ExpandLessIcon /> : <ExpandMoreIcon />}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                <div
                  style={{
                    backgroundColor: "#FFD60E",
                    width: 7,
                    borderRadius: 7,
                    height: '100%',
                  }} />
                <div style={{ paddingLeft: 10, display: 'block', width: '50%', height: '100%' }}>
                  <span className={'title-accord'}>{"Mascota " + (props.preOrder.addPets.length + 1)}</span>
                  <br />
                  <span className={'subtitle-accord'}>{name}</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <PetAddForm
              handledScroll={props.handledScroll}
              setName={setName} setTypePet={props.setTypePet} setNamePet={props.setNamePet} setErrorModal={props.setErrorModal} />
        </AccordionDetails>
      </Accordion>
    }*/}

    {
      props.preOrder.step1GoBack &&
      props.preOrder.addPets.length === 1 &&
      <div style={{ marginTop: 20 }}>
        <PetUpdateForm
            handledScroll={props.handledScroll}
            selectedIndex={`panel${0}`} index={`panel${0}`} pet={props.preOrder.addPets[0]} setTypePet={props.setTypePet} setNamePet={props.setNamePet} setErrorModal={props.setErrorModal} />
      </div>

    }
    {
      props.preOrder.step1GoBack &&
      props.preOrder.addPets.length > 1 &&
      props.preOrder.addPets.map((item, index) => {
        return (
          <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
            <AccordionSummary aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={expanded === `panel${index}` ?
                <ExpandLessIcon />
                :
                <ExpandMoreIcon />}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', width: 'auto', height: '100%' }}>
                    <div
                      style={{
                        backgroundColor: "#FFD60E",
                        width: 7,
                        borderRadius: 7,
                        height: '100%',
                      }} />
                    <div style={{
                      paddingLeft: 10,
                      display: 'block',
                      width: '50%',
                      height: '100%'
                    }}>
                      <span className={'title-accord'}>{"Mascota " + (index + 1)}</span>
                      <br />
                      <span className={'subtitle-accord'}>{item.nombre}</span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <PetUpdateForm
                  handledScroll={props.handledScroll}
                  selectedIndex={selectedIndex} index={`panel${index}`} pet={item} setTypePet={props.setTypePet} setNamePet={props.setNamePet} setErrorModal={props.setErrorModal} />
            </AccordionDetails>
          </Accordion>
        )
      })
    }

  </div>)

}
const mapStateToProps = state => ({
  preOrder: state.preOrder
})
export default connect(mapStateToProps)(AccordionFormArray);
