import React, {useEffect, useState} from "react";
import {Box, Container, Grid, IconButton, Stack} from "@mui/material";
import {connect} from "react-redux";
import './AddPets.css';
import {addInfoToPreOrder, removeLocalStorageAction} from "../../redux/preOrderDuck";
import catDesk from "../../assets/landing/avatar-gato-vertical.png";
import catTablet from "../../assets/landing/avatar-gato-vertical.png";
import planDesk from "../../assets/landing/avatar-perro-vertical.png";
import planTablet from "../../assets/landing/avatar-perro-vertical.png";
import catMobile from "../../assets/landing/gato-landing-planes-mobile.png";
import planMobile from "../../assets/landing/perro-landing-planes-mobile.png";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import EditIcon from '@mui/icons-material/Edit';
import PlanesModal from "../../components/PetForm/PlansModal";
import AccordionFormArray from "../../components/PetForm/AccordionFormArray";
import PetPhotospsAccordion from "../../components/PetPhotos/PetPhotosAccordion";
import TitlesStepsOne from "../../components/TitlesSteps/TitlesStepsOne";
import TitleStepOneDisabled from "../../components/TitlesSteps/TitleStepOneDisabled";
import TitleStepTwo from "../../components/TitlesSteps/TitleStepTwo";
import TitleStepTwoDisabled from "../../components/TitlesSteps/TitleStepTwoDisabled";
import TitleStepThreeDisabled from "../../components/TitlesSteps/TitleStepThreeDisabled";
import BlueTooltip from "../../components/Utils/BlueTooltip";
import FormStepTres from "../../components/FormContratante/FormStepTres";
import {currencyFormat, planPercentage, preInfoToPreOrder, setIdleTimeout} from "../../components/Utils/utils";
import TitleStepThree from "../../components/TitlesSteps/TitleStepThree";
import TitleStepTwoSuccess from "../../components/TitlesSteps/TitleStepTwoSuccess";
import PetAccordionArray from "../../components/ConfirmPayment/PetAccordionArray";
import ModalEditPet from "../../components/ConfirmPayment/ModalEditPet";
import ModalFollowLater from "../../components/ModalsToActivate/ModalFollowLater";
import {useNavigate} from "react-router-dom";
import {getTokenAction} from "../../redux/configDuck";
import ModalErrorPet from "../../components/ModalsToActivate/ModalErrorPet";
import ApiWeb from "../../lib/apiConfig/apiWeb";

import ClickAwayListener from '@mui/material/ClickAwayListener';
import {PORCENTAJE_DECUENTO} from "../../components/Utils/Constantes";
import {blue} from "@mui/material/colors";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {textAlign} from "@mui/system";
import {Element, scroller} from "react-scroll";
import TypePetPlans from "../../components/PetQuote/TypePetPlans";

const Item = styled(Paper)(({theme}) => ({
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(1),

}));

const AddPets = (props) => {
    const navigate = useNavigate()
    const [visiblePlans, setVisiblePlans] = useState(false);
    const [editModalPet, setEditModalPet] = useState(false);
    const [currentPet, setCurrentPet] = useState(null);
    const [visibleAlert, setVisibleAlert] = useState(false)

    const [errorModal, setErrorModal] = useState(false)
    const [typePet, setTypePet] = useState(null)
    const [namePet, setNamePet] = useState(null)
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const [close, setClose] = useState(false)

    const handledScroll = () => {
        setTimeout(function () {
            scroller.scrollTo("topSection"
                , {
                    duration: 500,
                    delay: 50,
                    smooth: true,
                    offset: -10,
                }
            )
        }, 100);
    }

    const inactivityTime = () => {
        let time;
        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onkeypress = resetTimer;

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(validatePetsPayment, 300000);
        }
    }
    const validatePetsPayment = () => {
        modalInactivity()
    }

    const modalInactivity = () => {
        setVisibleAlert(true)
        setVisiblePlans(false)
        setEditModalPet(false)
    }


    useEffect(() => {
        inactivityTime();
    }, [])

    const setClick = (email) => {
        if (props.preOrder) {
            props.preOrder.email = email;
            let pre_order = {
                ...props.preOrder,
                ...{
                    isEmailNeeded: true,
                    "etapa": "1",
                    "subEtapa": props.preOrder.fromContratante !== null ? props.preOrder.fromContratante.nombres ? "1" : "3" : props.preOrder.steps === 1 ? "1" : props.preOrder.steps === 2 ? "2" : "3",
                    "pantalla": props.preOrder.fromContratante !== null ? props.preOrder.fromContratante.nombres ? "4" : "3" : "3",
                }
            }
            let params = preInfoToPreOrder(pre_order)
            ApiWeb.saveLead(params).then(res => {
                if (res.data.code === 200) {
                    //Acción al dar 200
                }
            }).catch(e => {
                console.log(e)
            })
        }
    }

    const postFollowLater = (email) => {
        setClick(email);
        setVisibleAlert(false)
        //Limpia el session storage
        sessionStorage.clear()
        //Limpia el reducer de preOrder
        props.removeLocalStorageAction()
        //Generar un nuevo token
        props.getTokenAction()
        //Regresa al Home
        navigate('/')

    }

    const handleTooltipClose = () => {
        setTooltipVisible(false);
    };


    const validatePetsPrices = (costoPlan, planMidificado, cantidad) => {
        let baseTotal = 0
        let resultado = planMidificado;
        if (cantidad > 0) {
            baseTotal = costoPlan * cantidad
            resultado = planPercentage(baseTotal, PORCENTAJE_DECUENTO).cobro
        }

        return resultado
    }

    const goBackSteps = (step) => {
        if (step === 1) {
            props.addInfoToPreOrder({step1GoBack: true})
        } else if (step === 2) {
            props.addInfoToPreOrder({step2GoBack: true})
        } else if (step === 3) {
            props.addInfoToPreOrder({step3GoBack: true})
        }
        props.addInfoToPreOrder({steps: step})
    }

    const goForwardSteps = (step) => {
        props.addInfoToPreOrder({steps: step})
    }

    const SpaceDiv = () => {
        return (
            <>
                {/** Next 2  pero si tiene go back activo el paso 1 **/}

                {
                    props.preOrder.step1GoBack && props.preOrder.steps === 1 &&
                    <div style={{flex: 1}}/>
                }
                {
                    props.preOrder.step1GoBack && props.preOrder.steps === 1 &&
                    <div style={{flex: 1}}/>
                }
                {
                    props.preOrder.step1GoBack && props.preOrder.steps === 1 &&
                    <div style={{flex: 1}}/>
                }
                {/** FInal Next 2  pero si tiene go back activo el paso 1 **/}


                {/** Go back  paso 2  pero sin el next 3 **/}


                {
                    props.preOrder.step2GoBack === false && props.preOrder.steps === 2 &&
                    <div style={{flex: 1}}/>
                }
                {
                    props.preOrder.step2GoBack === false && props.preOrder.steps === 2 &&
                    <div style={{flex: 1}}/>
                }
                {
                    props.preOrder.step2GoBack === false && props.preOrder.steps === 2 &&
                    <div style={{flex: 1}}/>
                }

                {/**Final  go back  paso 2  pero sin el next 3 **/}


                {/** Go back  paso 2 Tiene  next 3 **/}

                {
                    props.preOrder.step2GoBack && props.preOrder.steps === 2 &&
                    <div style={{flex: 1}}/>
                }
                {
                    props.preOrder.step2GoBack && props.preOrder.steps === 2 &&
                    <div style={{flex: 1}}/>
                }

                {/** Final Go back  paso 2 Tiene  next 3 **/}


                {
                    props.preOrder.steps === 3 &&
                    <div style={{flex: 1}}/>
                }
                {
                    props.preOrder.steps === 3 &&
                    <div style={{flex: 1}}/>
                }
                {
                    props.preOrder.steps === 3 &&
                    <div style={{flex: 1}}/>
                }


            </>

        )
    }

    const MainDiv = () => {
        return (
            <>
                <div style={{width: '100%', height: "8px"}}></div>
                <div style={{
                    display: "flex",
                    width: '100%',
                    height: "24px",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    {
                        props.preOrder.steps !== 1 &&
                        <div style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                            <IconButton disableRipple='true'
                                        onClick={() => {
                                            let step = 1;
                                            if (props.preOrder.steps === 2) {
                                                step = 1
                                            } else if (props.preOrder.steps === 3) {
                                                step = 2
                                            }
                                            goBackSteps(step);
                                        }}
                            >
                                <ArrowBackIosNewIcon
                                    style={{marginLeft: '16px'}}
                                    fontSize="small"
                                    sx={{color: blue[500]}}
                                />
                                {
                                    props.preOrder.steps === 2 ?
                                        <span
                                            onClick={() => window.dataLayer.push({
                                                event: 'mascota_seguro_datos_foto',
                                                boton_contacto: 'Ir al paso 1',
                                                no_cotizacion: props.preOrder.folio,
                                            })}
                                            style={{
                                                color: "#039ECC",
                                                marginLeft: '16px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                fontFamily: 'Montserrat',
                                                fontStyle: 'normal'
                                            }}>Ir al paso 1</span>
                                        :
                                        props.preOrder.steps === 3 &&
                                        <span
                                            style={{
                                                color: "#039ECC",
                                                marginLeft: '16px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                fontFamily: 'Montserrat',
                                                fontStyle: 'normal'
                                            }}>Ir al paso 2</span>
                                }
                            </IconButton>
                        </div>
                    }
                    <SpaceDiv/>
                    {
                        props.preOrder.step1GoBack && props.preOrder.steps === 1 &&
                        <div style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>

                            <IconButton disableRipple='true'
                                        onClick={() => {
                                            let step = 1;
                                            if (props.preOrder.steps === 1) {
                                                step = 2
                                            } else if (props.preOrder.steps === 2) {
                                                step = 3
                                            }
                                            goForwardSteps(step)
                                        }}
                            >
                                {
                                    props.preOrder.steps === 1 ?
                                        <span
                                            style={{
                                                color: "#039ECC",
                                                marginRight: '16px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                fontFamily: 'Montserrat',
                                                fontStyle: 'normal'
                                            }}>Ir al paso 2</span>
                                        :
                                        props.preOrder.steps === 2 &&
                                        <span
                                            style={{
                                                color: "#039ECC",
                                                marginRight: '16px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                fontFamily: 'Montserrat',
                                                fontStyle: 'normal'
                                            }}>Ir al paso 3</span>

                                }
                                <ArrowForwardIosIcon
                                    style={{marginRight: '16px'}}

                                    fontSize="small"
                                    sx={{color: blue[500]}}/>
                            </IconButton>
                        </div>
                    }
                    {
                        props.preOrder.step2GoBack && props.preOrder.steps === 2 &&
                        <div style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                            <IconButton disableRipple='true'
                                        onClick={() => {
                                            let step = 1;
                                            if (props.preOrder.steps === 1) {
                                                step = 2
                                            } else if (props.preOrder.steps === 2) {
                                                step = 3
                                            }
                                            goForwardSteps(step)
                                        }}
                            >
                                {
                                    props.preOrder.steps === 1 ?
                                        <span
                                            style={{
                                                color: "#039ECC",
                                                marginRight: '16px',
                                                fontSize: '14px', fontWeight: 400,
                                                fontFamily: 'Montserrat',
                                                fontStyle: 'normal'
                                            }}>Ir al paso 2</span>
                                        :
                                        props.preOrder.steps === 2 &&
                                        <span
                                            style={{
                                                color: " #039ECC",
                                                marginRight: '16px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                fontFamily: 'Montserrat',
                                                fontStyle: 'normal'
                                            }}>Ir al paso 3</span>

                                }
                                < ArrowForwardIosIcon
                                    style={{marginRight: '16px'}}

                                    fontSize="small"
                                    sx={{color: blue[500]}}/>
                            </IconButton>
                        </div>
                    }
                </div>
                <div style={{display: "flex", width: '100%', height: "8px", backgroundColor: "#F4F4F4"}}>
                    <div style={{
                        display: "flex",
                        width: '100%',
                        height: "10px",
                        backgroundColor: "#FFFFFF",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }}/>
                </div>
                <div style={{height: "auto", width: "100%"}}>
                    <div style={{display: "flex", width: '100%', height: "8px", backgroundColor: "#f4f4f4"}}/>
                    <div style={{display: "flex", width: '100%', height: "8px", backgroundColor: "#f4f4f4"}}>
                        <div style={{
                            display: "flex",
                            width: '100%',
                            height: "10px",
                            backgroundColor: "#ffffff",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px"
                        }}/>
                    </div>
                </div>
            </>
        )
    }

    return (<>
        <div className='content-add'>
            <div className="circulo-gde"></div>
            <div className="circulo"></div>


            <Container maxWidth="xl" className="container-add-pets">
                <Box sx={{pt: 0}}>
                    <div className="title-pets ">
                        {
                            props.preOrder && props.preOrder.steps === 1 &&
                            <TitlesStepsOne/>
                        }
                        {
                            props.preOrder && props.preOrder.steps === 2 &&
                            <div>
                                <TitleStepOneDisabled/>
                                <TitleStepTwo/>
                            </div>
                        }
                        {
                            props.preOrder && props.preOrder.steps === 3 &&
                            <div>
                                <TitleStepOneDisabled/>
                                <TitleStepTwoSuccess/>
                                <TitleStepThree/>
                            </div>
                        }
                    </div>
                </Box>
                <Box sx={{mt: 3}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Stack
                            direction={{xs: 'column-reverse', sm: 'column-reverse', md: 'column-reverse', lg: 'row'}}
                            spacing={2}>
                            {/*** INICIO DEL CONTENEDOR DEL FORMULARIO ***/}
                            <Stack
                                className={'card-content-form-add'}
                                direction="column"
                                alignItems="center"
                            >
                                {
                                    props.preOrder.step1GoBack && props.preOrder.steps === 1 ?
                                        <MainDiv/>
                                        :
                                        props.preOrder.step1GoBack === false && props.preOrder.steps === 2 ?
                                            <MainDiv/>
                                            :
                                            props.preOrder.step1GoBack && props.preOrder.steps === 2 ?
                                                <MainDiv/>
                                                :
                                                props.preOrder.steps === 3 &&
                                                <MainDiv/>
                                }
                                {
                                    props.preOrder && props.preOrder.steps === 1 ?
                                        <AccordionFormArray setErrorModal={setErrorModal} setTypePet={setTypePet}
                                                            handledScroll={handledScroll}
                                                            setNamePet={setNamePet}/>
                                        :
                                        null
                                }

                                {
                                    props.preOrder.steps === 2 &&
                                    <Element name="topSection">
                                        <PetPhotospsAccordion close={close} setClose={setClose}/>
                                    </Element>
                                }

                                {
                                    props.preOrder.steps === 3 &&
                                    <Element name="topSection">
                                        <FormStepTres/>
                                    </Element>
                                }


                            </Stack>

                            {/*** FIN DEL CONTENEDOR DEL FORMULARIO ***/}


                            {/***INICIO Detalle de tu seguro***/}
                            <Stack
                                direction="column"
                                alignItems="flex-start"
                                spacing={2}>

                                <Stack className={'card-folio-add'}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{height: 'auto'}}>
                                            <span className={"text-title-folio"}>Número de cotización</span>
                                        </div>
                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                            <div style={{height: 'auto'}}>
                                                <BlueTooltip visible={tooltipVisible}
                                                             setVisible={setTooltipVisible}
                                                             title="Con este número podrás completar más tarde tu proceso de contratación, para que no tengas que llenar otra vez todos tus datos."/>
                                            </div>
                                        </ClickAwayListener>
                                    </div>
                                    <div style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        paddingLeft: 28,
                                        paddingRight: 20,
                                        backgroundColor: 'white', borderRadius: 10
                                    }}>
                    <span

                        style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'italic',
                            textAlign: 'center'
                        }}>{props.preOrder.folio ? props.preOrder.folio : '000000000000'}</span>
                                    </div>
                                </Stack>
                                <Grid
                                    container
                                    direction="row"
                                >
                                    <Stack
                                        alignItems={"center"}
                                        direction={{xs: 'column-reverse', sm: 'column-reverse', md: 'row'}}>
                                        <div className={'card-detail-plan-add'}>
                                            <Stack direction={{xs: 'row'}}>
                                                <Grid item xs={22}>
                                                    <span className={"title-detail"}>Detalle de tu seguro</span>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <EditIcon className={'edit-button'} onClick={() => {
                                                        setVisiblePlans(true)
                                                    }} color={'disabled'}/>
                                                </Grid>
                                            </Stack>
                                            <div style={{
                                                marginTop: 20,
                                                width: '100%',
                                                height: 1,
                                                backgroundColor: '#0A3949',
                                                opacity: 0.3,
                                                marginBottom: 20,
                                            }}/>

                                            <Grid container spacing={0}>
                                                <Grid item xs={6}>
                                                    <Item elevation={0} className={'title-detail-text'}>Plan</Item>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Item elevation={0}
                                                          className={'title-detail-description'}>{props.preOrder.selectedPlan && props.preOrder.selectedPlan.PlanNombreInter}</Item>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Item elevation={0} className={'title-detail-text'}>Total</Item>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Item elevation={0}
                                                          className={'title-detail-description'}>{props.preOrder.selectedPlan &&
                                                        currencyFormat(validatePetsPrices(props.preOrder.selectedPlan.PlanPrimaTotal2, props.preOrder.selectedPlan.PlanPrimaTotal, props.preOrder.addPets.length), 2)}</Item>
                                                </Grid>


                                                <Grid item xs={6}>
                                                    <Item elevation={0} className={'title-detail-text'}>Modalidad de
                                                        pago</Item>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Item elevation={0}
                                                          className={'title-detail-description'}>{"De contado"}</Item>
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <div className={"card-none-detail"}>
                                            {props.preOrder.selectedTypePet === 2 ?
                                                <picture>
                                                    <source media="(min-width: 1199px)" srcSet={catDesk}/>
                                                    <source media="(min-width: 1440px)" srcSet={catDesk}/>
                                                    <source media="(min-width: 1024px)" srcSet={catDesk}/>
                                                    <source media="(min-width: 768px)" srcSet={catTablet}/>
                                                    <source media="(min-width: 320px)" srcSet={catMobile}/>
                                                    <img src={catDesk} alt="gato"/>
                                                </picture>
                                                :
                                                <picture>
                                                    <source media="(min-width: 1199px)" srcSet={planDesk}/>
                                                    <source media="(min-width: 1440px)" srcSet={planDesk}/>
                                                    <source media="(min-width: 1024px)" srcSet={planDesk}/>
                                                    <source media="(min-width: 768px)" srcSet={planTablet}/>
                                                    <source media="(min-width: 320px)" srcSet={planMobile}/>
                                                    <img src={planDesk} alt="perro"/>
                                                </picture>
                                            }
                                        </div>

                                        <div className={"card-none-detail2"}>
                                            {props.preOrder.selectedTypePet === 2 ?
                                                <picture>
                                                    <source media="(min-width: 1199px)" srcSet={catDesk}/>
                                                    <source media="(min-width: 1440px)" srcSet={catDesk}/>
                                                    <source media="(min-width: 1024px)" srcSet={catDesk}/>
                                                    <source media="(min-width: 768px)" srcSet={catTablet}/>
                                                    <source media="(min-width: 320px)" srcSet={catMobile}/>
                                                    <img src={catDesk} alt="gato"/>
                                                </picture>
                                                :
                                                <picture>
                                                    <source media="(min-width: 1199px)" srcSet={planDesk}/>
                                                    <source media="(min-width: 1440px)" srcSet={planDesk}/>
                                                    <source media="(min-width: 1024px)" srcSet={planDesk}/>
                                                    <source media="(min-width: 768px)" srcSet={planTablet}/>
                                                    <source media="(min-width: 320px)" srcSet={planMobile}/>
                                                    <img src={planDesk} alt="perro"/>
                                                </picture>
                                            }
                                        </div>

                                    </Stack>
                                    {props.preOrder.steps === 3 &&

                                        <Grid xs={12} sm={12} md={6} lg={8.5}>
                                            <div className={'card-detail-plan-add-dos'}>
                                                <PetAccordionArray vistatresphotos={true} setOpen={setEditModalPet}
                                                                   selectedPet={setCurrentPet}/>
                                                <br/>
                                            </div>
                                        </Grid>

                                    }
                                </Grid>

                            </Stack>

                            {/***FIN Detalle de tu seguro***/}

                        </Stack>
                    </Grid>
                </Box>
                <div className="title-pets">
                    {
                        props.preOrder && props.preOrder.steps === 1 &&
                        < TitleStepTwoDisabled/>

                    }
                    {
                        props.preOrder && props.preOrder.steps === 2 &&
                        <TitleStepThreeDisabled/>

                    }
                </div>
            </Container>
        </div>

        <PlanesModal selectedPlan={props.preOrder.selectedPlan && props.preOrder.selectedPlan} visible={visiblePlans}
                     setVisible={setVisiblePlans}/>
        {
            editModalPet &&
            <ModalEditPet isAdd={true} open={editModalPet} setOpen={setEditModalPet} pet={currentPet}
                          setErrorModal={setErrorModal} setTypePet={setTypePet}
                          setNamePet={setNamePet}
            />
        }


        {
            visibleAlert &&
            <ModalFollowLater open={visibleAlert}
                              close={setVisibleAlert}
                              postFollowLater={postFollowLater}
                              folio={props.preOrder.folio}
                              selectedPlan={props.preOrder.selectedPlan.PlanNombreInter}
            />
        }

        {
            errorModal &&
            <ModalErrorPet typePet={typePet} name={namePet} open={errorModal} close={() => {
                setErrorModal(false)
            }}/>
        }

    </>)

}
const mapStateToProps = state => ({
    preOrder: state.preOrder
});
export default connect(mapStateToProps, {addInfoToPreOrder, removeLocalStorageAction, getTokenAction})(AddPets);
