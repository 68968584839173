import {
  Box,
  Button,
  Dialog,
  Grid, IconButton,
  Stack,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import * as React from "react";
import './ModalDiscountForYou.css'
import { Form, Formik } from "formik";
import { ValidationModalFollowLater } from "./ValidationModalFollowLater";
import { validateEmail } from "../Utils/utils";
import CustomTextField from "../CustomFields/CustomTextField";


const ModalFollowLater = (props) => {
  
  const handleGuardar = (values) => {
    props.postFollowLater(values.email)
      window.dataLayer.push({
        event : "mascota_seguro_seguir_luego",
        plan_elegido: props.selectedPlan,
        no_cotizacion: props.folio,
        correo: values.email,
        boton_contacto:'Continuar más tarde',         
      })
  }

  return (<>
    <Dialog open={props.open}
      disableEscapeKeyDown={true}
      maxWidth={'xs'} fullWidth={false}>
      <div className={"modal-w"}>
        <IconButton
          onClick={() => props.close()}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelIcon style={{ color: '#000000', fontSize: 28 }} />
        </IconButton>

        <Box sx={{ mt: 4, width: '100%', mb: 2 }}>
          <Grid
            className='modal-individual-padding'
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ width: '100%', mb: 2 }}>
              <div style={{ width: '100%' }}>
                <Box sx={{ mt: 2, width: '100%' }} textAlign={'center'}>
                  <span style={{ textAlign: 'center' }}
                    className='title-modal-discount-form'>{'¿Quieres seguir luego?'}</span>
                </Box>

                <div style={{
                  marginTop: '5%',
                  paddingLeft: 10, paddingRight: 10
                }}>
                  <span style={{ textAlign: 'center' }}
                    className='text-description-modal'>{'Para que no tengas que volver a empezar, pásanos tu correo para que te enviemos tu número de cotización.'}</span>
                </div>

              </div>
              <Box sx={{ mt: 2, width: '100%' }}>
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    email: ''

                  }}
                  validationSchema={ValidationModalFollowLater}
                  onSubmit={(values, { setSubmitting }) => {
                    handleGuardar(values);
                  }}
                >
                  {({
                    values,
                    submitForm,
                    setFieldValue,
                    handleChange,
                    errors,
                    isSubmitting,
                    setFieldError,
                    touched,
                    setFieldTouched,
                  }) => (
                    <Form>
                      <div style={{ width: '100%', marginTop: '5%', marginBottom: '5%' }}>
                        <CustomTextField id="email" name="email" type="text" placeholder="Correo*" validate={(value) => validateEmail(value)} />
                        <Stack
                          style={{ marginTop: '5%' }}
                          direction={{ xs: 'column', sm: 'column', md: 'row' }}
                          spacing={0}
                        >
                          <Button
                            style={{ height: 48 }}
                            fullWidth variant="contained"
                            /*disabled={isSubmitting}*/
                            onClick={submitForm}
                          >
                            {'Continuar más tarde'}
                          </Button>
                        </Stack>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Grid>
        </Box>
      </div>
    </Dialog>
  </>)
}
export default ModalFollowLater;