
import * as yup from "yup";
import {VALOR_NAME, VALOR_NECESARIO} from "../Utils/Constantes";


export const ValidationModalEditPet= yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NAME)
    .typeError(VALOR_NAME)
    .min(1, VALOR_NAME),
  raza: yup
      .string()
      .required("Raza de la mascota requerida")
      .typeError("Ingrese la raza de la mascota")
      .min(1, VALOR_NECESARIO),

  meses: yup
      .string()
      .required("Seleccione los meses de su mascota")
      .typeError("Seleccione los meses de su mascota"),

  anios: yup
      .string()
      .required("Seleccione los años de su mascota")
      .typeError("Seleccione los años de su mascota")
});



