import * as yup from "yup";
import {
  CORREO_INVALIDO,
  CP_INVALIDO,
  FECHA_VALIDA,
  MAYOR_EDAD,
  REQUERIDO,
  TELEFONO_INVALIDO,
  VALOR_NECESARIO,
  VALOR_NUMERO,
} from "../Utils/Constantes";
import { validaMayorEdad, validateFormat, dateFormat } from "../Utils/utils";


export const validationFormContratante = yup.object().shape({
  nombres: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  paterno: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  materno: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  rfc: yup
    .string()
    .min(13, "Debe tener como mínimo 13 caracteres")
    .max(13, 'Debe tener como máximo 13 caracteres'),


  fiscal: yup
    .string()
    .required("Seleccione el régimen fiscal")
    .min(3, "Seleccione el régimen fiscal"),


  fechanacimiento: yup
    .date()
    .required(VALOR_NECESARIO)
    //.nullable()
    .test(
      "formatoFecha",
      "El valor debe ser una fecha (dd-mm-aaaa)",
      (valor) => {
        if (validateFormat(dateFormat(valor)) === false) {
          return false;
        } else {
          return true;
        }
      }
    )
    .test(
      "mayorEdad",
      MAYOR_EDAD,
      (val) => {
        if (validaMayorEdad(val)) {
          return true;
        } else {
          return false;
        }
      }
    )
    .typeError('El valor debe ser una fecha (dd-mm-aaaa)')
  ,

  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(4, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres'),


  telefono: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO)
    .test(
      "formato",
      TELEFONO_INVALIDO,
      (val) => {
        var regex = /^\D*\d{10}$/;
        return regex.test(val + "");
      }
    ),

  cp: yup
    .string()
    .required("Ingrese el código postal")
    .typeError(CP_INVALIDO)
    .length(5, CP_INVALIDO)
    .test(
      "formato",
      CP_INVALIDO,
      (val) => {
        var regex = /^\D*\d{5}$/;
        return regex.test(val);
      }
    ),

  estado: yup
    .string()
    .required("Seleccione el estado")
    .min(1, "Seleccione el estado"),

  municipio: yup
    .string()
    .required("Seleccione el municipio")
    .min(1, "Seleccione el municipio"),


  colonia: yup
    .string()
    .required("Seleccione la colonia")
    .min(1, "Seleccione la colonia"),

  calle: yup
    .string()
    .required("Ingrese la calle")
    .min(1, "Ingrese la calle"),

  exterior: yup
    .string()
    .required("Ingrese el número exterior")
    .min(1, "Ingrese el número exterior"),

  numin: yup
    .string()
    .min(1, "Ingrese el número interior"),

  terminos: yup
    .boolean()
    .required(REQUERIDO)
    .test(
      "terminos",
      REQUERIDO,
      (valor) => {
        return valor === true;
      }
    ),


});

export const validationFormDueñoMayorEdad = yup.object().shape({

  nombresOwner: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  paternoOwner: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  maternoOwner: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  fechanacimientoOwner: yup
    .date()
    .required(VALOR_NECESARIO)
    .test(
      "formatoFecha",
      "El valor debe ser una fecha (dd-mm-aaaa)",
      (valor) => {
        if (validateFormat(dateFormat(valor)) === false) {
          return false;
        } else {
          return true;
        }
      }
    )
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if (fecha instanceof Date && !isNaN(fecha)) {
          return true;
        } else {
          return false;
        }
      }
    )
    .typeError('El valor debe ser una fecha (dd-mm-aaaa)')
  ,


  emailOwner: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email("El email no tiene un formato válido")
    .min(1, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres')
  ,

  telefonoOwner: yup
    .number()
    .required("Ingrese el celular")
    .min(10, "Minimo 10 numeros")
    .typeError(VALOR_NUMERO),


  /**** contratante ***/

  nombres: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  paterno: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  materno: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  rfc: yup
    .string()
    .min(13, "Debe tener como mínimo 13 caracteres")
    .max(13, 'Debe tener como máximo 13 caracteres'),


  fiscal: yup
    .string()
    .required("Seleccione el régimen fiscal")
    .min(3, "Seleccione el régimen fiscal"),


  fechanacimiento: yup
    .date()
    .required(VALOR_NECESARIO)
    .test(
      "formatoFecha",
      "El valor debe ser una fecha (dd-mm-aaaa)",
      (valor) => {
        if (validateFormat(dateFormat(valor)) === false) {
          return false;
        } else {
          return true;
        }
      }
    )
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if (fecha instanceof Date && !isNaN(fecha)) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test(
      "mayorEdad",
      MAYOR_EDAD,
      (val) => {
        if (validaMayorEdad(val)) {
          return true;
        } else {
          return false;
        }
      }
    )
    .typeError('El valor debe ser una fecha (dd-mm-aaaa)')
  ,

  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(1, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres'),


  telefono: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO)
    .test(
      "formato",
      TELEFONO_INVALIDO,
      (val) => {
        var regex = /^\D*\d{10}$/;
        return regex.test(val + "");
      }
    ),

  cp: yup
    .string()
    .required("Ingrese el código postal")
    .typeError(CP_INVALIDO)
    .length(5, CP_INVALIDO)
    .test(
      "formato",
      CP_INVALIDO,
      (val) => {
        var regex = /^\D*\d{5}$/;
        return regex.test(val);
      }
    ),

  estado: yup
    .string()
    .required("Seleccione el estado")
    .min(1, "Seleccione el estado"),

  municipio: yup
    .string()
    .required("Seleccione el municipio")
    .min(1, "Seleccione el municipio"),


  colonia: yup
    .string()
    .required("Seleccione la colonia")
    .min(1, "Seleccione la colonia"),

  calle: yup
    .string()
    .required("Ingrese la calle")
    .min(1, "Ingrese la calle"),

  exterior: yup
    .string()
    .required("Ingrese el número exterior")
    .min(1, "Ingrese el número exterior"),

  numin: yup
    .string()
    .min(1, "Ingrese el número interior"),

  terminos: yup
    .boolean()
    .required(REQUERIDO)
    .test(
      "terminos",
      REQUERIDO,
      (valor) => {
        return valor === true;
      }
    ),
});

export const validationFormDueño = yup.object().shape({

  nombresOwner: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  paternoOwner: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  maternoOwner: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  fechanacimientoOwner: yup
    .date()
    .required(VALOR_NECESARIO)
    .test(
      "formatoFecha",
      "El valor debe ser una fecha (dd-mm-aaaa)",
      (valor) => {
        if (validateFormat(dateFormat(valor)) === false) {
          return false;
        } else {
          return true;
        }
      }
    )
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if (fecha instanceof Date && !isNaN(fecha)) {
          return true;
        } else {
          return false;
        }
      }
    )
    .typeError('El valor debe ser una fecha (dd-mm-aaaa)')
  ,


  emailOwner: yup
    .string()
    .nullable()
    .email("El email no tiene un formato válido")
    .min(1, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres')
  ,

  telefonoOwner: yup
    .number()
    .nullable()
    .min(10, "Minimo 10 numeros")
    .typeError(VALOR_NUMERO),


  /**** contratante ***/

  nombres: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  paterno: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  materno: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, 'Debe tener como máximo 20 caracteres'),

  rfc: yup
    .string()
    .min(13, "Debe tener como mínimo 13 caracteres")
    .max(13, 'Debe tener como máximo 13 caracteres'),


  fiscal: yup
    .string()
    .required("Seleccione el régimen fiscal")
    .min(3, "Seleccione el régimen fiscal"),


  fechanacimiento: yup
    .date()
    .required(VALOR_NECESARIO)
    .test(
      "formatoFecha",
      "El valor debe ser una fecha (dd-mm-aaaa)",
      (valor) => {
        if (validateFormat(dateFormat(valor)) === false) {
          return false;
        } else {
          return true;
        }
      }
    )
    .test(
      "mayorEdad",
      MAYOR_EDAD,
      (val) => {
        if (validaMayorEdad(val)) {
          return true;
        } else {
          return false;
        }
      }
    )
    .typeError('El valor debe ser una fecha (dd-mm-aaaa)')
  ,

  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(1, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres'),


  telefono: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO)
    .test(
      "formato",
      TELEFONO_INVALIDO,
      (val) => {
        var regex = /^\D*\d{10}$/;
        return regex.test(val + "");
      }
    ),

  cp: yup
    .string()
    .required("Ingrese el código postal")
    .typeError(CP_INVALIDO)
    .length(5, CP_INVALIDO)
    .test(
      "formato",
      CP_INVALIDO,
      (val) => {
        var regex = /^\D*\d{5}$/;
        return regex.test(val);
      }
    ),

  estado: yup
    .string()
    .required("Seleccione el estado")
    .min(1, "Seleccione el estado"),

  municipio: yup
    .string()
    .required("Seleccione el municipio")
    .min(1, "Seleccione el municipio"),


  colonia: yup
    .string()
    .required("Seleccione la colonia")
    .min(1, "Seleccione la colonia"),

  calle: yup
    .string()
    .required("Ingrese la calle")
    .min(1, "Ingrese la calle"),

  exterior: yup
    .string()
    .required("Ingrese el número exterior")
    .min(1, "Ingrese el número exterior"),

  numin: yup
    .string()
    .min(1, "Ingrese el número interior"),

  terminos: yup
    .boolean()
    .required(REQUERIDO)
    .test(
      "terminos",
      REQUERIDO,
      (valor) => {
        return valor === true;
      }
    ),


});
