import { IconButton, styled, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export default function BlueTooltip({ visible, setVisible, ...props }) {
  const BlueTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
  ))(`
      color: black;
      background-color: #EBF8FB;
  `);

  return (
    <BlueTooltip placement="bottom"
      open={visible}
      PopperProps={{
        disablePortal: true,
      }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClose={() => setVisible(false)}
      title={props.title}>
      <IconButton onClick={() => {
        setVisible(!visible)
      }}><InfoIcon
          color="primary" /></IconButton>
    </BlueTooltip>
  );
}

