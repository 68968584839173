import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import generateStore from "./redux/store";
let store = generateStore();
let WithStrictMode =()=> <App />;
let WithStore =()=> <Provider store={store}> <WithStrictMode/> </Provider>;

 ReactDOM.render(<WithStore/>, document.getElementById('root'));
reportWebVitals();
