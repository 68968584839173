import './ModalLoader.css';
import { CircularProgress, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import logo from '../../assets/img/Animation.gif';

export default function ModalLoader(props) {

  const handleClickCloseLoader = (event, reason) => {
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => handleClickCloseLoader(event, reason)} disableEscapeKeyDown={true}
      maxWidth="sm" fullWidth={true}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          height: '100%'
        },
      }}
    >
      <DialogContent>
        <div className="modal-contenedor">
          <div className="modal-loading">
            <img className="" src={logo} width="200px" height="160px" alt="" />
            <CircularProgress
              size={200}
              sx={{
                color: "#039ECC",
                position: 'absolute',
                top: -20,
                left: 2,
                zIndex: 1,
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}