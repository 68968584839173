
import { Grid, Stack } from "@mui/material";
import React from "react";
import './TitleSteps.css';
import lineaAmarilla from '../../assets/img/lineaAmarilla/decorative.svg'
import { connect } from "react-redux";

const TitleStepThree = (props) => {

  return (

    <>
      <div className="">
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className="mX-25">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
          >
            <div className="numero">{props.preOrder.steps}</div>
            <div className="linea-amarilla">
              <img src={lineaAmarilla} alt='' />
              <div className="text-queremos">Ahora queremos conocerte a ti</div>
              <div className="text-asi">Para que podamos seguir con tu contrataci&oacute;n</div>
            </div>
            </Stack>
        </Grid>
      </div>
    </>

  );

}
const mapStateToProps = state => ({
  preOrder: state.preOrder
});
export default connect(mapStateToProps)(TitleStepThree)