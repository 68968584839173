
import * as yup from "yup";
import {
  CORREO_INVALIDO,
} from "../Utils/Constantes";


export const ValidationModalFollowLater = yup.object().shape({
  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(1, "Ingrese el correo electrónico")
    .max(80, 'Debe tener como máximo 80 caracteres'),

});



