import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { useFormik } from 'formik';
import {
  Button, FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Checkbox,
  RadioGroup,
  Select,
  Stack,
  TextField, FormHelperText, FormLabel, Box
} from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import {
  keyPressSoloLetrasNumeros,
  listNumbers,
  noPaste,
  OnlyAlpha,
  preInfoToPreOrder,
  TagManagerLayer
} from '../Utils/utils'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { validationFormAddPet, validationFormAddPetNoEnfermedad } from "./ValidationAddPet";
import _ from 'lodash';
import './PetForm.css'
import { getFavoriteFood, getRaces } from "../../redux/configDuck";
import apiWeb from "../../lib/apiConfig/apiWeb";
import { ALPHA } from '../../components/Utils/Constantes'

const array_check = ["Pedigree", "Chip"];
const filter = createFilterOptions();

const PetAddForm = ({ setName = null, ...props }) => {
  const [click, setClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idRandom, setIdRandom] = useState(null);
  const [isDisease, setIsDisease] = useState(null);
  const [errorDisease, setErrorDisease] = useState(false);

  const blanksPetAdd= <>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;</>

  const sendTagManagerArgs = (data) => {

    let leyendaAnios = data.anios === 1 ? data.anios + " año " : data.anios + " años"
    let leyendaMeses = data.meses === 1 ? data.meses + " mes " : data.meses + " meses"


    let tagManagerArgs = {
      event: "mascota_seguro_datos_contratante",
      boton_cargar_foto: "false",
      sexo: data.sexo,
      raza: data.raza,
      no_se_que_raza_es: "No Aplica",
      edad: leyendaAnios + "y " + leyendaMeses,
      color_pelo: "No Aplica",
      color_ojos: "No Aplica",
      enfermedades: data.enfermedades,
      otras_señas: "No Aplica",
      datos_adicionales: data.descripcionMascota,
      boton_mascota: "continuar",
      cotizacion: "No Aplica",

    }
    TagManagerLayer(tagManagerArgs)
  }


  const onclickSet = (pre_order) => {
    let params = preInfoToPreOrder(pre_order)
    apiWeb.saveLead(params).then(res => {
    }).catch(e => {
      console.log(e)
    })
  }

  const validatorPet = (meses, anios, typePet, name, enfermedades) => {
    let error = false;
    let anios_ = parseInt(anios)
    let meses_ = parseInt(meses)


    if (anios_ === 0 && meses_ < 2) {
      props.setErrorModal(true)
      props.setNamePet(name)
      props.setTypePet(typePet === "Perro" ? 1 : 2)
      error = true
    } else if (anios_ >= 9 && meses_ > 0) {
      props.setErrorModal(true)
      props.setNamePet(name)
      props.setTypePet(typePet === "Perro" ? 1 : 2)
      error = true
    } else if (enfermedades.toLowerCase() === "displasia de codo" ||
      enfermedades.toLowerCase() === "displasia de cadera" ||
      enfermedades.toLowerCase() === "luxación patelar congénita" ||
      enfermedades.toLowerCase() === "síndrome del braquicéfalo" ||
      enfermedades.toLowerCase() === "criptorquidismo") {
      props.setErrorModal(true)
      props.setNamePet(name)
      props.setTypePet(typePet === "Perro" ? 1 : 2)
      error = true
    } else {
      error = false
      props.setErrorModal(false)
      props.setNamePet(null)
      props.setTypePet(null)
    }

    return error
  }

  const getCodeRace = (raza = "") => {
    let response = ""
    if (raza !== "") {
      let arr_races = props.config.races;
      let elementIndex = arr_races.findIndex((obj => obj.txt_desc_raza === raza));
      if (elementIndex ===-1){
        let code_raza_new = formik.values.especie === "Gato" ? "311" : "265"
        response = code_raza_new
      }else {
        response = arr_races[elementIndex].cod_raza
      }
    }

    return response;
  }

  const formik = useFormik({
    initialValues: {
      id: idRandom,
      nombre: '',
      especie: '',
      sexo: '',
      raza: '',
      meses: '',
      anios: '',
      enfermedades: '',
      comidaFavorita: 'Carne',
      descripcionMascota: '',
      pedigreeOrChip: []
    },
    validationSchema: isDisease === null ? validationFormAddPetNoEnfermedad : validationFormAddPet,
    onSubmit: (values) => {
      window.dataLayer.push({
        event : "mascota_seguro_datos_mascota",
        tipo_animal: values.especie,
        sexo: values.sexo,
        raza: values.raza,
        edad: `${values.anios} años y ${values.meses} meses`,
        enfermedades: values.enfermedades,
        boton_contacto: 'continuar',
        no_cotizacion: props.preOrder.folio,

      })
      if (isDisease === null) {
        setErrorDisease(true);
        return true
      }
      if (validatorPet(values.meses, values.anios, values.especie, values.nombre, values.enfermedades)) {
        return true
      }

      let array_ = [];
      if (props.preOrder.addPets.length === 0) {
        if (values.pedigreeOrChip.length === 1) {
          let name = values.pedigreeOrChip[0]
          if (name === "Pedigree") {
            values.pedigree = true
            values.chip = false
          } else if (name === "Chip") {
            values.pedigree = false
            values.chip = true
          } else {
            values.pedigree = false
            values.chip = false
          }
        }
        else if (values.pedigreeOrChip.length > 1) {
          let value_1 = {
            [values.pedigreeOrChip[0].toLowerCase()]: true,
            [values.pedigreeOrChip[1].toLowerCase()]: true,
          }
          values = Object.assign(value_1, values);
        } else {
          values.pedigree = false
          values.chip = false
        }

        let cod_raza = getCodeRace(values.raza);
        values.cod_raza = cod_raza
        values.referencia = ""
        values.addPhoto = false;
        sendTagManagerArgs(values)
        props.addInfoToPreOrder({ addPets: [values] })
        array_ = [values];
        restForm()
      } else if (props.preOrder.addPets.length > 0) {
        let arr = props.preOrder.addPets;
        if (values.pedigreeOrChip.length === 1) {
          let name = values.pedigreeOrChip[0]
          if (name === "Pedigree") {
            values.pedigree = true
            values.chip = false
          } else if (name === "Chip") {
            values.pedigree = false
            values.chip = true
          } else {
            values.pedigree = false
            values.chip = false
          }
        } else if (values.pedigreeOrChip.length > 1) {
          let value_1 = {
            [values.pedigreeOrChip[0].toLowerCase()]: true,
            [values.pedigreeOrChip[1].toLowerCase()]: true,
          }
          values = Object.assign(value_1, values);
        } else {
          values.pedigree = false
          values.chip = false
        }

        let cod_raza = getCodeRace(values.raza);
        values.cod_raza = cod_raza
        values.referencia = ""
        values.addPhoto = false;
        sendTagManagerArgs(values)
        arr.push(values)
        array_ = arr;
        props.addInfoToPreOrder({ addPets: arr })
        restForm()
      }
      if (click === false) {
        props.addInfoToPreOrder({ steps: props.preOrder.steps + 1 })
        /***
         * Servicio para guardar el paso en que estamos
         * **/

        let pre_order = {
          ...props.preOrder,
          addPets: array_,
          ...{
            "etapa": "1",
            "subEtapa": "2",
            "pantalla": "3",
            isEmailNeeded: false,
          }
        }

        if (props.preOrder.email !== "hola@inter.mx") {
          pre_order.email = props.preOrder.email
        }
        onclickSet(pre_order)

      }
      setLoading(!loading)
      setIsDisease(null)
    },
  });


  const namePet = _.get(props.preOrder, "petName", null);
  const typePet = _.get(props.preOrder, "selectedTypePet", null);
  const arrayPet = _.get(props.preOrder, "addPets", []);

  useEffect(() => {
    if (arrayPet.length === 0) {
      formik.setFieldValue('nombre', namePet);
      if (typePet === 2) {
        formik.setFieldValue('especie', "Gato");
      } else if (typePet === 1) {
        formik.setFieldValue('especie', "Perro");
      }
      getRaces_(typePet)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePet, typePet, arrayPet])

  const getRaces_ = (typePet) => {
    props.getRaces(typePet)
  }


  useEffect(() => {
    if (formik.values.especie) {
      let tipo = formik.values.especie === "Gato" ? 2 : 1
      getRaces_(tipo)
      formik.setFieldValue("raza", "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.especie])


  const restForm = () => {
    formik.resetForm();
    if (setName) {
      setName("");
    }
    props.handledScroll()
  }



  const onClick_render = (isAdd = false) => {
    setClick(isAdd)
    if (isDisease === null) {
      setErrorDisease(true);
    }
    setTimeout(function () {
      formik.handleSubmit()
    },
      1000);

  }

  const handleChangeN = (e) => {
    const index = formik.values.pedigreeOrChip.indexOf(e.target.name);
    const new_selected = [...formik.values.pedigreeOrChip];
    const indexs = formik.values.pedigreeOrChip.indexOf("Ninguno");
    if (indexs === 0) {
      new_selected.splice(indexs, 1);
    }
    if (index === -1) {
      new_selected.push(e.target.name)
    } else {
      new_selected.splice(index, 1);
    }
    formik.setFieldValue("pedigreeOrChip", new_selected)

  }


  const handleChangeDelete = (e) => {
    const seleted = formik.values.pedigreeOrChip.length;
    if (seleted !== 0) {
      formik.setFieldValue("pedigreeOrChip", [])
    }

    if (e.target.checked) {
      formik.setFieldValue("pedigreeOrChip", [e.target.name])
    }
  }

  const returnSelectFood = (id_Text) => {
    let item = props.config.favoriteFood.find(item => item.value === id_Text)
    if (item) {
      return item.value;
    } else {
      if (props.config.fetchingFavoriteFood) {
        return (
          <div
            style={{
              color: '#c5c5c5',
              fontFamily: "Montserrat"
            }}>{"Cargando Comidas..."}</div>
        )
      } else {
        return (
          <div
            style={{
              color: '#c5c5c5',
              fontFamily: "Montserrat"
            }}>{props.config.favoriteFood.length > 0 ? "Seleccionar" : 'Seleccionar'}</div>
        )
      }
    }

  }

  useEffect(() => {
    props.getFavoriteFood();
    let ramd = Math.floor(Math.random() * 100)
    setIdRandom(ramd)
    formik.setFieldValue('id', ramd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])


  useEffect(() => {
    if (isDisease === "No") {
      setErrorDisease(false);
      formik.setFieldValue("enfermedades", "No")
    } else if (isDisease === "Si") {
      setErrorDisease(false);
      formik.setFieldValue("enfermedades", "")
    }
  }, [isDisease]);


  return (
    <>
      <div style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}>
        <Box sx={{ pt: 2, pb: 3 }}>
          <span className={"title-form"} >{"Datos de tu mascota"}</span>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <InputLabel
            htmlFor={"name"}
            size={"normal"}
            variant="standard"
            style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, marginBottom: 10, color: 'black' }}>
            {"¿Cómo se llama?*"}
          </InputLabel>
          <TextField
            fullWidth
            id="nombre"
            name="nombre"
            placeholder="Nombre"
            value={formik.values.nombre}
            onKeyPress={(event) => OnlyAlpha(event)}
             onKeyUp={(event) => OnlyAlpha(event)}
             onChange={(type) => {
                formik.setFieldValue("nombre", type.target.value)
                if (setName) {
                  setName(type.target.value)
              }
            }}
            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
            helperText={formik.touched.nombre && formik.errors.nombre}
          />
          <Stack
            marginTop={'5%'}
            spacing={2}
            direction={{ xs: 'column', sm: 'column', md: 'row' }}>
            <div style={{ width: '100%' }}>
              <FormControl error={formik.touched.especie && Boolean(formik.errors.especie)} variant="standard">
                <FormLabel
                  style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, marginBottom: 10, color: 'black' }}
                  id="especie">{"¿Perro o gato?*"}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formik.values.especie}
                  onChange={(type) => {
                    formik.setFieldValue("especie", type.target.value)
                  }}
                >
                  <FormControlLabel value="Perro" control={<Radio />} label="Perro" />
                  <FormControlLabel value="Gato" control={<Radio />} label="Gato" />
                </RadioGroup>
                <FormHelperText>{formik.touched.especie && formik.errors.especie}</FormHelperText>
              </FormControl>
            </div>
            <div style={{ width: '100%' }}>
              <FormControl error={formik.touched.sexo && Boolean(formik.errors.sexo)} variant="standard">
                <FormLabel
                  style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, marginBottom: 10, color: 'black' }}
                  id="sexo">
                  {"¿Es macho o hembra?*"}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formik.values.sexo}
                  onChange={(gender) => {
                    formik.setFieldValue("sexo", gender.target.value)
                  }}
                >
                  <FormControlLabel value="Macho" control={<Radio />} label="Macho" />
                  <FormControlLabel value="Hembra" control={<Radio />} label="Hembra" />
                </RadioGroup>
                <FormHelperText>{formik.touched.sexo && formik.errors.sexo}</FormHelperText>
              </FormControl>
            </div>
          </Stack>
          <Stack
            marginTop={'5%'}
            marginBottom={'5%'}
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}>
            <div style={{ width: '100%' }}>
              <InputLabel
                htmlFor={"breed"}
                size={"normal"}
                variant="standard"
                style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, marginBottom: 10, color: 'black' }}>
                {"¿Qué raza es?*"}
              </InputLabel>

              <Autocomplete
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                disablePortal
                noOptionsText={'Raza no encontrada.'}
                options={props.config.races.map((option) => option.txt_desc_raza)}
                value={formik.values.raza}

                onChange={(e, value) => {
                  if (typeof value === 'string') {
                    formik.setFieldValue('raza', value)

                  } else if (value && value.inputValue) {
                    // Create a new value from the user input
                    formik.setFieldValue('raza', value)

                  } else {
                    formik.setFieldValue('raza', value)

                  }
                }}

                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push(inputValue);
                  }

                  return filtered;
                }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option;
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  renderInput={(params) => (
                  <TextField
                    id="raza"
                    name="raza"
                    {...params}
                    label=""
                    error={formik.touched.raza && Boolean(formik.errors.raza)}
                    helperText={formik.touched.raza && formik.errors.raza}
                    {...props}
                  />
                )} />
            </div>
            <div style={{ width: '100%' }}>
              <InputLabel
                htmlFor={"monthAge"}
                size={"normal"}
                variant="standard"
                style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, marginBottom: 10, color: 'black' }}>
                {"¿Qué edad tiene?*"}
              </InputLabel>

              <Stack
                direction={{ xs: 'row' }}>
                <FormControl sx={{ minWidth: '50%' }} error={formik.touched.anios && Boolean(formik.errors.anios)}>
                  <Select
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    labelId="anios"
                    id="anios"
                    name={"anios"}
                    value={formik.values.anios}
                    onChange={formik.handleChange}
                    displayEmpty
                    renderValue={
                      formik.values.anios !== "" ? undefined : () => <div
                        style={{ color: '#c5c5c5', fontFamily: "Montserrat" }}>Años</div>
                    }
                  >
                    <MenuItem
                      value=""
                      disabled>
                      Años
                    </MenuItem>
                    {
                      listNumbers(8).map((y, index) => {
                        return (<MenuItem key={index} value={y}>{y}</MenuItem>)
                      })
                    }
                  </Select>
                  <FormHelperText>{formik.touched.anios && formik.errors.anios}</FormHelperText>
                </FormControl>
                <FormControl sx={{ minWidth: '50%' }} error={formik.touched.meses && Boolean(formik.errors.meses)}>
                  <Select
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    labelId="meses"
                    id="meses"
                    name={"meses"}
                    displayEmpty
                    value={formik.values.meses}
                    onChange={formik.handleChange}
                    renderValue={
                      formik.values.meses !== "" ? undefined : () => <div
                        style={{ color: '#c5c5c5', fontFamily: "Montserrat" }}>Meses</div>
                    }
                  >
                    <MenuItem
                      value=""
                      disabled>
                      Meses
                    </MenuItem>
                    {
                      listNumbers().map((m, index) => {
                        return (<MenuItem key={index} value={m}>{m}</MenuItem>)
                      })
                    }
                  </Select>
                  <FormHelperText>{formik.touched.meses && formik.errors.meses}</FormHelperText>
                </FormControl>
              </Stack>
            </div>
          </Stack>

          <div style={{ width: '100%' }}>
            <FormControl error={Boolean(errorDisease)} variant="standard">
              <FormLabel
                style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, marginBottom: 10, color: "black" }}>
                {"¿Tiene o ha tenido alguna enfermedad?*"}
              </FormLabel>
              <RadioGroup
                row
                value={isDisease}
                onChange={(type) => {
                  setErrorDisease(false);
                  setIsDisease(type.target.value)
                }}
              >
                <FormControlLabel value="Si" control={<Radio />} label="Si" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
              <FormHelperText error={errorDisease}>{errorDisease === true ? "Seleccione una opción" : ""}</FormHelperText>
            </FormControl>
          </div>

          {
            isDisease && isDisease === "Si" &&
            <div style={{ width: '100%', marginTop: '5%' }}>
              <InputLabel
                htmlFor={"disease"}
                size={"normal"}
                variant="standard"
                style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, marginBottom: 10, color: 'black' }}>
                {"¿Qué enfermedad?*"}
              </InputLabel>
              <TextField
                fullWidth
                id="enfermedades"
                name="enfermedades"
                placeholder="Enfermedad"
                value={formik.values.enfermedades}
                onChange={formik.handleChange}
                error={formik.touched.enfermedades && Boolean(formik.errors.enfermedades)}
                helperText={formik.touched.enfermedades && formik.errors.enfermedades}
              />
            </div>
          }
          {/*
          <div style={{ width: '100%', marginTop: '5%' }}>
            <InputLabel
              style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, marginBottom: 10, color: 'black' }}
              size={"normal"}
              variant="standard"
            >
              {"¿Qué le súper encanta comer?"}
            </InputLabel>
            <Select
              fullWidth
              labelId="comidaFavorita"
              id="comidaFavorita"
              name={"comidaFavorita"}
              displayEmpty
              value={formik.values.comidaFavorita}
              onChange={formik.handleChange}
              renderValue={(val) => {
                return returnSelectFood(val)
              }}
            >
              {
                props.config.fetchingFavoriteFood &&
                <MenuItem
                  value=""
                  disabled>
                  {"Cargando Favorite Food ..."}
                </MenuItem>
              }

              {
                props.config.favoriteFood.map((item, index) => {
                  return (<MenuItem key={index}
                    value={item.value}>{item.value}</MenuItem>)
                })}
            </Select>
          </div>
*/}

          <div style={{ width: '100%', marginTop: '5%' }}>
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'column' }}>
                <span
                  style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, color: 'black'}}>
                 {" Descríbenos, ¿cómo es físicamente tu cuatropatitas?*"}{blanksPetAdd}
                </span>
            </Stack>
            <TextField
              fullWidth
              id="descripcionMascota"
              name="descripcionMascota"
              placeholder="Color de ojos, nariz, pelo, rasgos físicos..."
              value={formik.values.descripcionMascota}
              onChange={formik.handleChange}
              error={formik.touched.descripcionMascota && Boolean(formik.errors.descripcionMascota)}
              helperText={formik.touched.descripcionMascota && formik.errors.descripcionMascota}
              onKeyPress={(event) => keyPressSoloLetrasNumeros(event)} onKeyUp={(event) => keyPressSoloLetrasNumeros(event)}
              onPaste={(event) => noPaste(event)}
            />
          </div>

          {/*
          <div style={{ width: '100%', marginTop: '5%' }}>
            <InputLabel
              htmlFor={"pedigreeOrChip"}
              size={"normal"}
              variant="standard"
              style={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: 18, marginBottom: 10, color: 'black' }}>
              {"¿Tu amigo tiene pedigree o chip?"}
            </InputLabel>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={0}
            >
              {
                array_check.map((c) => {
                  return (
                    <FormControlLabel
                      style={{ width: '100%' }}
                      name={c}
                      onChange={handleChangeN}
                      value={c}
                      defaultChecked={formik.values.pedigreeOrChip.indexOf(c) !== -1}
                      checked={formik.values.pedigreeOrChip.indexOf(c) !== -1}
                      control={<Checkbox />} label={c} />
                  )
                })

              }

              <FormControlLabel
                style={{ width: '100%' }}
                name={"Ninguno"}
                onChange={handleChangeDelete}
                value={"Ninguno"}
                checked={formik.values.pedigreeOrChip[0] === "Ninguno"}
                control={<Checkbox />} label={'Ninguno'} />

            </Stack>
          </div>
*/}
          {/*

            props.preOrder.addPets.length <= 3 &&
            <div style={{ width: '100%', textAlign: 'right' }}>
              <Button
                startIcon={<AddCircleOutlineOutlinedIcon />}
                size={"medium"}
                onClick={() => {

                  onClick_render(true)
                }}
                disableElevation={true}
                style={{
                  marginTop: '5%',
                  color: "#039ECC",
                  backgroundColor: 'transparent'
                }} color="primary" variant="contained"  >
                Agregar otra mascosta
              </Button>
            </div>
          */}

          <Button style={{
            marginTop: '5%',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 16,
            height: 48
          }} color="primary" variant="contained" fullWidth
            onClick={() => {
              onClick_render(false)
            }}
          >
            {"CONTINUAR"}
          </Button>
        </form>
      </div>

    </>
  )
}

const mapStateToProps = state => ({
  preOrder: state.preOrder,
  config: state.config

})
export default connect(mapStateToProps, { addInfoToPreOrder, getFavoriteFood, getRaces })(PetAddForm);
