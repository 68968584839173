import { Box, Button, Dialog, Grid } from "@mui/material";
import AniGif from "../../assets/img/Animation.gif";

export default function ModalTryAgain(props) {

  const handledClickClose = () => {
    props.setContador(props.contador + 1)
    props.onClick()
  }

  return (
    <>
      <Dialog open={props.visible}
        disableEscapeKeyDown={true}
        maxWidth={"md"} fullWidth={false}>

        <Box
          sx={{
            px: 4,
            my: 3
          }}>

          <div style={{
            maxWidth: '354px', height: 'auto', textAlign: 'center'
          }}>
            <img src={AniGif} style={{ width: '100%', height: 'auto' }} alt='gifMichi' />
            <Box sx={{ mt: 2 }} >
              <span
                style={{ fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '700', fontSize: '20px', lineHeight: '28px', textAlign: 'center', letterSpacing: '0.25px', color: '#424242' }}
              >&iexcl;Oops, estamos teniendo algunos problemas!</span>
            </Box>
            <Box sx={{ mt: 2 }} >
              <span style={{ fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }}>
                Muchas veces esto pasa porque la conexi&oacute;n con la aseguradora esta fallando.
              </span>
            </Box>
            <Box sx={{ mt: 2 }} >
              <Button
                style={{ height: '48px' }}
                variant="contained"
                fullWidth
                onClick={() => {
                  props.setContador(props.contador + 1)
                  props.onClick()
                }}>
                <span style={{ fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '20px' }}>
                  {props.contador <= 1 ? "Reintentar" : "Reintentar mas tarde"}</span>
              </Button>
            </Box>
          </div>
        </Box>
      </Dialog >
    </>
  )
}
