import {getLocalStorageData, setLocalStorageData} from "../components/Utils/utils";

let initialData = {
    fetching: false,
    isFolio: false,
    objectNumCotiza: null,
    selectedTypePet: null,
    petName: null,
    selectedPlan: null,
    addPets: [],
    fromContratante: null,
    steps: 1,
    folio: null,
    email: 'hola@inter.mx',
    collaborator_number:'',
    discount: false,
    paymentType: null,
    coupon: null,
    step1GoBack: false,
    step2GoBack: false,
    step3GoBack: false,
}

//Type
const ADD_INFO_TO_PRE_ORDER = 'ADD_INFO_TO_PRE_ORDER';

const preOrderReducer = (state = initialData, action) => {
    switch (action.type) {
        case ADD_INFO_TO_PRE_ORDER:
            saveLocalStore(state, action.payload)
            return {...state, ...action.payload};
        default:
            return state;
    }
}

export default preOrderReducer;


export let saveLocalStore = async (state, storage) => {
    try {
        await setLocalStorageData('preOrder', {...state, ...storage});
    } catch (error) {
        // Error saving data
    }
};


export let removeLocalStorageAction = () => async dispatch => {
    try {
        dispatch({
            type: ADD_INFO_TO_PRE_ORDER, payload: {
                fetching: false,
                isFolio: false,
                objectNumCotiza: null,
                selectedTypePet: null,
                petName: null,
                selectedPlan: null,
                addPets: [],
                fromContratante: null,
                steps: 1,
                folio: null,
                email: 'hola@inter.mx',
                collaborator_number:'',
                discount: false,
                paymentType: null,
                coupon: null,
                step1GoBack: false,
                step2GoBack: false,
                step3GoBack: false,
            }
        });
    } catch (e) {
        console.error(e)
    }
}

export let getLocalStorageAction = () => async dispatch => {
    try {
        let storage = await getLocalStorageData("preOrder")
        if (storage) {
            dispatch({type: ADD_INFO_TO_PRE_ORDER, payload: storage})
        }
    } catch (e) {
        console.error(e)
    }
}

export let addInfoToPreOrder = (json_info, postAction = null) => {

    return async (dispatch) => {
        dispatch({type: ADD_INFO_TO_PRE_ORDER, payload: json_info});

        if (postAction) {
            try {
                postAction()
            } catch (e) {
                console.error(e)
            }
        };
    };
}
