import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import './ConfirmPayment.css'
import { Alert, Box, Button, Collapse, Container, Grid, IconButton, Stack } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from "@mui/icons-material/Edit";
import catDesk from "../../assets/landing/avatar-gato-vertical.png";
import catTablet from "../../assets/landing/avatar-gato-vertical.png";
import planDesk from "../../assets/landing/avatar-perro-vertical.png";
import planTablet from "../../assets/landing/avatar-perro-vertical.png";
import catMobile from "../../assets/landing/gato-landing-planes-mobile.png";
import planMobile from "../../assets/landing/perro-landing-planes-mobile.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CouponForm from "../../components/CouponForm/CouponForm";
import PetAccordionArray from "../../components/ConfirmPayment/PetAccordionArray";
import BlueTooltip from "../../components/Utils/BlueTooltip";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlanesModal from "../../components/PetForm/PlansModal";
import ModalEditPet from "../../components/ConfirmPayment/ModalEditPet";
import {
  calcularEdad,
  currencyFormat, emissionStructure,
  getLocalStorageData, getUrlParams, navigatePageForce, planPercentage,
  preInfoToPreOrder, TagManagerLayer,
} from "../../components/Utils/utils";
import { useNavigate } from "react-router-dom";
import { validatePreOrderFun } from "../../redux/temporalDuck";
import { addInfoToPreOrder } from '../../redux/preOrderDuck';
import ModalPaymentType from "../../components/Modals/ModalPaymentType";
import apiWeb from "../../lib/apiConfig/apiWeb";
import ModalFollowLater from "../../components/ModalsToActivate/ModalFollowLater";
import { removeLocalStorageAction } from "../../redux/preOrderDuck";
import { getTokenAction } from "../../redux/configDuck";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ModalDiscountForYou from "../../components/ModalsToActivate/ModalDiscountForYou";
import _ from "lodash";
import axios from "axios";
import { PORCENTAJE_DECUENTO } from "../../components/Utils/Constantes";
import ModalErrorPet from "../../components/ModalsToActivate/ModalErrorPet";
import ModalEditOwner2 from "../../components/ConfirmPayment/ModalEditOwner2";
import moment from "moment";
import ModalTryAgain from "../../components/Modals/ModalTryAgain";
import ModalLoader from "../../components/Modals/ModalLoader";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {blue} from "@mui/material/colors";

const AppProduct = "INT1N7P37"
const app_key = process.env
  ? process.env.REACT_APP_KEY
  : "";

const isProduction = process.env.REACT_APP_PROD === "1" ? true : false;

const URL_BASE_PAYMENT = isProduction ? 'https://pvl.gmx.com.mx/Multipagos/SSV/SSVRequest.aspx' : 'https://desarrolloservicios.gmx.com.mx:8085/MPEnero/SSV/SSVRequest.aspx';

const Item = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  paddingBottom: theme.spacing(1),
}));

const TitleStep = () => {
  return (<>
    <div style={{
      display: 'flex',
      width: '100%', height: 'auto',
      alignItems: 'center'
    }}>
      <div style={{ display: 'flex' }}>
        <span style={{
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 58,
          color: '#212121',
          paddingRight: 5
        }}>4</span>
        <div
          style={{
            backgroundColor: "#FFD60E",
            width: 5,
            borderRadius: 5,
            height: 'auto',
          }} />
      </div>
      <div style={{
        alignItems: ' center',
        paddingLeft: 10,
        display: 'block',
        height: '100%'
      }}>
        <span className={'title-step'}>{"Confirma tu compra"}</span>
        <br />
        <span
          className={'subtitle-step'}>{"¡Ya casi terminamos! Checa bien tus datos, ¿están correctos?"}</span>
      </div>
    </div>
  </>)
}

const OwnerDetail = ({ owner = null, ...props }) => {
  const DateOfBirth = (date_) => {
    let newData = date_.replace(/(\d+[/])(\d+[/])/, '$2$1');
    let data_ = new Date(newData);
    return calcularEdad(data_);
  }

  let dataGTM;
  useEffect(() => {
    dataGTM = JSON.parse(sessionStorage.getItem('preOrder'))
    if (dataGTM && dataGTM.folio !== null && dataGTM.fromContratante !== null){
      window.dataLayer.push({
        event: "mascota_seguro_confirmar_compra",      //Static data,
        no_cotizacion: dataGTM.folio,
        plan_elegido: dataGTM.selectedPlan.PlanNombreInter,
        tipo_mascota: dataGTM.addPets[0].especie,
        raza: dataGTM.addPets[0].raza,
        edad_mascota: `${dataGTM.addPets[0].anios} años y ${dataGTM.addPets[0].meses} meses`,
        edad_contratante: DateOfBirth(dataGTM.fromContratante.fechanacimiento),
        sexo: dataGTM.fromContratante.genero,
        total_pago_antes_descuento: dataGTM.selectedPlan.PlanPrimaTotal,
        codigo_cupon: dataGTM.coupon?.coupon,
        no_colaborador: dataGTM.collaborator_number,
        descuento: dataGTM.coupon?.value,
        total_pago_despues_descuento: dataGTM.selectedPlan.PlanPrimaTotal2,
        correo: dataGTM.fromContratante.email,
        telefono: dataGTM.fromContratante.telefono,
      })
    }
  }, [dataGTM])


  return (<>
    <div style={{ width: '90%', height: "auto", marginTop: 10, te: 'center', justifyContent: 'center' }}>
      <Stack style={{ marginTop: 20 }} direction={{ xs: 'row' }}>
        <Grid
          style={{ paddingTop: 3 }}
          item xs={18} sm={22}>
          <span className={"title-detail"}>Dueño</span>
        </Grid>
        <Grid item
          style={{ width: '100%', textAlign: 'right' }}
          justifyContent={"center"}
          xs={6} sm={2}>
          <EditIcon className={'edit-button-pay-two'} onClick={() => {
            props.setOpen(true)
            props.setOwnerEdit(owner)
          }} color={'disabled'} />
        </Grid>
      </Stack>
      <Stack style={{ marginTop: 30 }} direction={{ xs: 'column', sm: 'column', md: 'row' }}>
        <Grid
          md={8}
          container
          direction="column"
          justifyContent="center">
          <span className={'title-owner'}>{'Nombre:'}</span>
          <span className={'text-owner'}>
            {owner ? owner.roleType === "no" ? owner.nombresOwner : owner.nombres : ""}
            {" "}
            {owner ? owner.roleType === "no" ? owner.paternoOwner : owner.paterno : ""}
            {" "}
            {owner ? owner.roleType === "no" ? owner.maternoOwner : owner.materno : ""}
          </span>
        </Grid>
        <Grid
          md={8}
          container
          direction="column"
          justifyContent="center">
          <span className={'title-owner'}>{'Sexo de Nacimiento:'}</span>
          <span className={'text-owner'}>
            {owner ? owner.roleType === "no" ? owner.generoOwner : owner.genero : ""}
          </span>
        </Grid>
        <Grid
          md={8}
          container
          direction="column"
          justifyContent="center">
          <span className={'title-owner'}>{'Edad:'}</span>
          <span className={'text-owner'}>
            {owner ? owner.roleType === "no" ? owner.fechanacimientoOwner ? DateOfBirth(owner.fechanacimientoOwner) : "" : owner.fechanacimiento ? DateOfBirth(owner.fechanacimiento) : "" : ""}
          </span>
        </Grid>
      </Stack>
      <Stack style={{ marginTop: 15 }} direction={{ xs: 'column', sm: 'column', md: 'row' }}>
        <Grid
          md={9} lg={9} xl={8}
          container
          direction="column"
          justifyContent="center">
          <span className={'title-owner'}>{'Correo electrónico:'}</span>
          <span
            className={'text-owner'}>{owner ? owner.roleType === "no" ? owner.emailOwner === "" ? owner.email : owner.emailOwner : owner.email : ""}</span>
        </Grid>
        <Grid
          md={8}
          container
          direction="column"
          justifyContent="center">
          <span className={'title-owner'}>{'Teléfono:'}</span>
          <span
            className={'text-owner'}>{owner ? owner.roleType === "no" ? owner.telefonoOwner === "" ? owner.telefono : owner.telefonoOwner : owner.telefono : ""}</span>
        </Grid>
        <Grid
          md={7} lg={8} xl={8}
          container
          direction="column"
          justifyContent="center">
        </Grid>
      </Stack>
    </div>
  </>)
}

const DetailPlan = (props) => {
  const navigate = useNavigate();
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const handleTooltipClose = () => {
    setTooltipVisible(false);
  };


  const getNextPet = () => {
    let pets = props.preOrder.addPets;
    let elementIndex = null;
    for (let i = 0; i < pets.length; i++) {
      if (pets[i].referencia === "") {
        elementIndex = pets[i]
        break;
      } else if (pets[i].referencia !== "" && pets[i].isPago === false) {
        elementIndex = pets[i]
        break
      }
    }
    return elementIndex;
  }

  const validatePetsPrices = (costoPlan, planMidificado, cantidad) => {
    let baseTotal = 0
    let resultado = planMidificado;
    if (cantidad > 0) {
      baseTotal = costoPlan * cantidad
      resultado = planPercentage(baseTotal, PORCENTAJE_DECUENTO).cobro
    }
    return resultado
  }

  const getNone = () => {
    let pets = props.preOrder.addPets;
    let displayNone = true;
    for (let i = 0; i < pets.length; i++) {
      if (pets[i].isPago === true) {
        displayNone = false;
        break;
      }
      return displayNone;
    }

  }

  const dataGTM = JSON.parse(sessionStorage.getItem('preOrder'));

  return (
    <div>
      <div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 5,
          justifyContent: 'space-between'
        }} className={'card-folio-pay'}>
          <div style={{ height: 'auto' }}>
            <span className={"text-title-folio"}>Número de cotización</span>
          </div>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div style={{ height: 'auto' }}>
              <BlueTooltip
                visible={tooltipVisible}
                setVisible={setTooltipVisible}
                title="Con este número podrás completar más tarde tu proceso de contratación, para que no tengas que llenar otra vez todos tus datos." />
            </div>
          </ClickAwayListener>
        </div>
        <div style={{
          paddingTop: 10,
          paddingBottom: 10,
          marginBottom: 15,
          backgroundColor: 'white', borderRadius: 10
        }} className={'card-folio-pay'}>
          <span
            style={{
              fontFamily: 'Montserrat',
              fontStyle: 'italic',
              paddingLeft: 15,
              textAlign: 'center'
            }}>{props.preOrder.folio ? props.preOrder.folio : '000000000000'}</span>
        </div>
      </div>
      <Stack
        alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
        direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}>
        <div className={'card-detail-plan-pay'}>
          <Stack
            direction={{ xs: 'row' }}
          >
            <Grid item xs={22}>
              <span className={"title-detail"}>Detalle de tu seguro</span>
            </Grid>
            <Grid item xs={2}>
              {getNone() === true &&
                <EditIcon className={'edit-button-pay'} onClick={() => {
                  props.setVisiblePlans(true)
                }} color={'disabled'} />
              }
            </Grid>
          </Stack>
          <div style={{
            marginTop: 20,
            marginBottom: 20,
            width: '100%',
            height: .5,
            backgroundColor: '#0A3949',
            opacity: 0.3,
          }} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Item elevation={0} className={'title-detail-text'}>Plan</Item>
            </Grid>
            <Grid item xs={6}>
              <Item elevation={0}
                className={'title-detail-description'}>{props.preOrder.selectedPlan && props.preOrder.selectedPlan.PlanNombreInter}</Item>
            </Grid>

            <Grid item xs={6}>
              <Item elevation={0} className={'title-detail-text'}>Total</Item>
            </Grid>
            <Grid item xs={6}>
              <Item elevation={0}
                className={'title-detail-description'}>
                {props.preOrder.selectedPlan &&
                  currencyFormat(validatePetsPrices(props.preOrder.selectedPlan.PlanPrimaTotal2, props.preOrder.selectedPlan.PlanPrimaTotal, props.preOrder.addPets.length), 2)}</Item>
            </Grid>


            <Grid item xs={6}>
              <Item elevation={0} className={'title-detail-text'}>Modalidad de
                pago</Item>
            </Grid>
            <Grid item xs={6}>
              <Item elevation={0}
                className={'title-detail-description'}>{"De contado"}</Item>
            </Grid>
          </Grid>

          <div style={{
            marginTop: 20,
            marginBottom: 20,
            width: '100%',
            height: .5,
            backgroundColor: '#0A3949',
            opacity: 0.3,
          }} />

          <div style={{ display: 'block', backgroundColor: 'white', height: "auto", width: '100%' }}>
            <span className={"text-title-coupon"}>¡Cupón de descuento!</span>
            <CouponForm setOpenAlert={props.setOpenAlert} />
            <div style={{ marginLeft: 17, marginRight: 17 }}>
              <Button
                disabled={props.loading}
                onClick={() => {
                  getNextPet() === null ?
                    navigate("/foundation-inter")
                    :
                    props.onClick()
                  window.dataLayer.push({
                    event : "mascota_seguro_confirmar_compra_pago",
                    no_cotizacion: dataGTM.folio,
                    plan_elegido: dataGTM.selectedPlan.PlanNombreInter,
                    tipo_mascota: dataGTM.addPets[0].especie,
                    raza: dataGTM.addPets[0].raza,
                    edad_mascota: `${dataGTM.addPets[0].anios} años y ${dataGTM.addPets[0].meses} meses`,
                    // edad_contratante: DateOfBirth(dataGTM.fromContratante.fechanacimiento),
                    sexo: dataGTM.fromContratante.genero,
                    total_pago_antes_descuento: dataGTM.selectedPlan.PlanPrimaTotal,
                    codigo_cupon: dataGTM.coupon?.coupon,
                    no_colaborador: dataGTM.collaborator_number,
                    descuento: dataGTM.coupon?.value,
                    total_pago_despues_descuento: dataGTM.selectedPlan.PlanPrimaTotal2,
                    correo: dataGTM.fromContratante.email,
                    telefono: dataGTM.fromContratante.telefono,
                  })
                }}

                style={{
                  marginTop: '5%',
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 16,
                  height: 48
                }} color="primary" variant="contained" fullWidth>
                {props.loading ? "Cargando..." :
                  //irving pago
                  props.preOrder.addPets.length > 0 ?
                    getNextPet() === null ?
                      "¡VAMOS aver la orden"
                      :
                      "VAMOS AL PAGO" /*+ getNextPet().nombre + ""*/
                    :
                    "VAMOS AL PAGO"
                }
              </Button>
            </div>
          </div>
        </div>

        {/*INICIO Imagen*/}
        <div className={"card-img-desk-tablet"}>
          {props.preOrder.selectedTypePet === 2 ?
            <picture>
              <source media="(min-width: 1199px)" srcSet={catDesk} />
              <source media="(min-width: 1440px)" srcSet={catDesk} />
              <source media="(min-width: 1024px)" srcSet={catDesk} />
              <source media="(min-width: 768px)" srcSet={catTablet} />
              <source media="(min-width: 320px)" srcSet={catMobile} />
              <img src={catDesk} alt="gato" />
            </picture>
            :
            <picture>
              <source media="(min-width: 1199px)" srcSet={planDesk} />
              <source media="(min-width: 1440px)" srcSet={planDesk} />
              <source media="(min-width: 1024px)" srcSet={planDesk} />
              <source media="(min-width: 768px)" srcSet={planTablet} />
              <source media="(min-width: 320px)" srcSet={planMobile} />
              <img src={planDesk} alt="perro" />
            </picture>
          }
        </div>
        <div className={"card-img-mobile"}>
          {props.preOrder.selectedTypePet === 2 ?
            <picture>
              <source media="(min-width: 1199px)" srcSet={catDesk} />
              <source media="(min-width: 1440px)" srcSet={catDesk} />
              <source media="(min-width: 1024px)" srcSet={catDesk} />
              <source media="(min-width: 768px)" srcSet={catTablet} />
              <source media="(min-width: 320px)" srcSet={catMobile} />
              <img src={catDesk} alt="gato" />
            </picture>
            :
            <picture>
              <source media="(min-width: 1199px)" srcSet={planDesk} />
              <source media="(min-width: 1440px)" srcSet={planDesk} />
              <source media="(min-width: 1024px)" srcSet={planDesk} />
              <source media="(min-width: 768px)" srcSet={planTablet} />
              <source media="(min-width: 320px)" srcSet={planMobile} />
              <img src={planDesk} alt="perro" />
            </picture>
          }
        </div>
        {/*FIN Imagen*/}

      </Stack>
    </div>
  )
}

const ConfirmPayment = (props) => {
  const navigate = useNavigate();

  const [loadingPayment, setLoadingPayment] = useState(false);

  const [visiblePlans, setVisiblePlans] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);


  const [editModalPet, setEditModalPet] = useState(false);
  const [petEdit, setPetEdit] = useState(null);

  const [editModalOwner, setEditModalOwner] = useState(false);
  const [ownerEdit, setOwnerEdit] = useState(null);

  const [modalPaymentType, setModalPaymentType] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);

  const [visibleModalCoupon, setVisibleModalCoupon] = useState(false)

  const [hash, setHash] = useState('')
  const [reference, setReference] = useState('')

  const [errorModal, setErrorModal] = useState(false)
  const [typePet, setTypePet] = useState(null)
  const [namePet, setNamePet] = useState(null)

  const coupon_ = _.get(props.preOrder, 'coupon', null)

  const [modalTryAgain, setModalTryAgain] = useState(false)
  const [contador, setContador] = useState(0)


  const inactivityTime = () => {
    let time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(validatePetsPayment, 300000);
    }
  }

  const validatePetsPayment = () => {
    let pets = props.preOrder.addPets;
    let visible = true;
    for (let i = 0; i < pets.length; i++) {
      if (pets[i].isPago === true) {
        visible = false
        break;
      }
    }

    if (visible) {
      modalInactivity()
    }
  }
  const modalInactivity = () => {
    setVisibleAlert(true)
    setVisiblePlans(false)
    setEditModalOwner(false)
    setEditModalPet(false)
    setModalPaymentType(false)
  }

  const getCoupon = async () => {
    let storage = await getLocalStorageData("preOrder");
    if (storage) {
      if (storage.coupon === null) {
        setVisibleModalCoupon(true)
      }
    }
  }

  useEffect(() => {
    getCoupon()
    inactivityTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupon_]);


  useEffect(() => {
    if (props.preOrder.fromContratante) {
      if (props.preOrder.fromContratante.rfc === "") {

        navigate('/add-pets')

      } else if (props.preOrder.fromContratante.roleType === "no") {
        if (props.preOrder.fromContratante.nombresOwner === "") {
          navigate('/add-pets')
        }
      }
    }
  }, [props.preOrder.fromContratante])






  const sendSaveLead = (email) => {
    if (props.preOrder) {
      let pre_order = {
        ...props.preOrder,
        ...{
          "etapa": "1",
          "subEtapa": "1",
          "pantalla": "4",
          email,
          isEmailNeeded: true,
        }
      }
      let params = preInfoToPreOrder(pre_order)
      apiWeb.saveLead(params).then(res => {
        if (res.data.code === 200) {

        }
      }).catch(e => {
        console.error(e)
      })
    }
  }

  const postFollowLater = (email) => {
    sendSaveLead(email);
    setVisibleAlert(false)
    //Limpia el session storage
    sessionStorage.clear()
    //Limpia el reducer de preOrder
    props.removeLocalStorageAction()
    //Generar un nuevo token
    props.getTokenAction()
    //Regresa al Home
    navigate('/')

  }

  const sendTemporalData = () => {
    sendEmission();
  }

  const sendPost = () => {
    setTimeout(() => {
      let myForm = document.getElementById('mediForm');
      myForm.submit();
    }, 1000);

  }

  const getUrlPlatform = () => {
    if (window.location.hostname === 'localhost') {
      return 'http://' + window.location.hostname + ':' + window.location.port + '/';
    } else {
      return 'https://' + window.location.hostname + '/';
    }
  }


  const getQuattroCodificacionAPI = (params) => {
    let S4_KEY = 'uLmdvb2dsZS5jb20vY2hhdC1kYXRhYmFzZS05NTdjMSIsIm5hbWUiOiJIZWJlc';
    let m = "zl8mIfg3UrGeD1CvQu7UddMRtZ9eljnfGDTy+oOne/8=";
    let URL_PRO = "https://lb3.copsis.com/quattro-aseguradoras/QuattroCodificacionAPI";
    let URL_QA = "https://skynet.copsis.com:8181/quattro-aseguradoras/QuattroCodificacionAPI";
    let Production = window.location.hostname !== 'localhost' ? true : false;


    const itemsParam = params;
    const message = itemsParam[0].message;
    const opcion = itemsParam[1].opcion;
    const secret = itemsParam[2].secret;
    const referencia = itemsParam[3].referencia;
    const retorno = itemsParam[4].retorno;

    let stringParams = "?m=" + m + "&";
    stringParams += "s4_key=" + S4_KEY + "&"
    stringParams += "message=" + message + "&"
    stringParams += "opcion=" + opcion + "&"
    stringParams += "secret=" + secret + "&"
    stringParams += "referencia=" + referencia + "&"
    stringParams += "retorno=" + retorno


    let BASEURL = Production ? isProduction ? URL_PRO : URL_QA : URL_QA

    let response = axios.get(BASEURL + stringParams).then(response => {
      if (response.status === 200) {
        return { success: true, response: response.data.sha256_HMAC }
      } else {
        return { error: true, response: response }
      }
    }).catch(e => {
      console.error(e)
      return { error: true, response: e }
    })

    return response;
  }


  const validateUltimoObject = () => {
    let tamanio = props.preOrder.addPets.length;
    let array_pets = props.preOrder.addPets
    let count = 0;
    for (let i = 0; i < array_pets.length; i++) {
      if (array_pets[i].isPago === true) {
        count = count + 1
      }
    }

    let resultado = tamanio - count;

    return resultado;
  }
  const encryptData = async (reference = "") => {
    let url_retorno = props.preOrder.addPets.length > 1 ? validateUltimoObject() === 1 ? getUrlPlatform() + 'config-payment' : getUrlPlatform() + 'confirm-payment' : getUrlPlatform() + 'config-payment'
    if (reference !== "") {
      let params = [{ "message": AppProduct + reference }, { "opcion": 1 }, { "secret": app_key },
      { "referencia": reference }, { "retorno": url_retorno }];
      let res = await getQuattroCodificacionAPI(params)
      if (res.success) {
        setHash(res.response)
        sendPost()
      }
      // setLoadingPayment(false)
    } else {
      setLoadingPayment(false)
    }
  }

  const structurePet2 = (referencias, elementIndex) => {
    let osArray = props.preOrder.addPets;
    let pet = props.preOrder.addPets[elementIndex];
    pet.referencia = referencias
    pet.isPago = false
    osArray[elementIndex] = pet;
    props.addInfoToPreOrder({ addPets: osArray }, getFolio())
  }

  let params = (new URL(document.location)).hash;

  const saveData = () => {
    if (props.preOrder) {
      let pre_order = {
        ...props.preOrder,
        ...{
          "etapa": "1",
          "subEtapa": "1",
          "pantalla": "4",
          'collaborator_number': props.preOrder.collaborator_number,
          isEmailNeeded: false,
          "email": props.preOrder.email === "hola@inter.mx" ?
            props.preOrder.fromContratante ?
              props.preOrder.fromContratante.roleType === 'si' ?
                props.preOrder.fromContratante.email
                :
                props.preOrder.fromContratante.emailOwner === "" ? props.preOrder.fromContratante.email : props.preOrder.fromContratante.emailOwner
              : props.preOrder.email : props.preOrder.email
        }
      }
      let params = preInfoToPreOrder(pre_order)
      apiWeb.saveLead(params).then(res => {
        if (res.data.code === 200) {
        }
      }).catch(e => {
        console.error(e)
      });
    }
  }

  const saveLeadLocal = (data) => {
    setTimeout(() => {
      sendPayment(data);
      saveData();
    }, 5000)
  }
  const sendTagManagerArgs = (data) => {
    const D = new Date();
    let dd = D.getDate().toString();
    let mm = (D.getMonth() + 1).toString();
    D.setFullYear(D.getFullYear() + 1)
    let yyyy = D.getFullYear().toString();
    const result = (dd[1] ? dd : "0" + dd[0]) + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + yyyy;
    let tagManagerArgs = {
      event: "mascota_seguro_pago_finalizado",      //Static data,
      no_poliza: data.poliza.Poliza,
      vigencia: result,
      boton_finalizado: "Descargar póliza"
    }
    //alert(JSON.stringify(tagManagerArgs))
    TagManagerLayer(tagManagerArgs)
  }
  const sendPayment = (data) => {

    const D = new Date();
    let dd = D.getDate().toString();
    let mm = (D.getMonth() + 1).toString();
    D.setFullYear(D.getFullYear() + 1)
    let yyyy = D.getFullYear().toString();
    let today = new Date()
    let initDate = moment(today).format("DD/MM/YYYY")
    let endDate = (dd[1] ? dd : "0" + dd[0]) + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + yyyy;

    let params = {
      "business": "medipet",
      "insurance": "gmx",
      "reference": data.poliza.ReferenciaSSV,
      "encReference": data.poliza.Referencia,
      "startDate": initDate + "",
      "endDate": endDate + "",
      'collaborator_number': props.preOrder.collaborator_number,
      "correo": props.preOrder.email === "hola@inter.mx" ?
        props.preOrder.fromContratante ?
          props.preOrder.fromContratante.roleType === 'si' ?
            props.preOrder.fromContratante.email
            :
            props.preOrder.fromContratante.emailOwner === "" ? props.preOrder.fromContratante.email : props.preOrder.fromContratante.emailOwner
          : props.preOrder.email : props.preOrder.email,
      "nombrePersona": props.preOrder.fromContratante.roleType === "si" ? props.preOrder.fromContratante.nombres : props.preOrder.fromContratante.nombresOwner,
      "nombreMascota": data.nombre,
      "planElegido": props.preOrder.selectedPlan.PlanNombreInter,
      folio: props.preOrder.folio
    }

    apiWeb.postPayments(params).then(response => {
    }).catch(e => {
      console.error(e)
    });
  }

  const findPet = (queryString, pets) => {
    let osArray = pets;
    let elementIndex = osArray.findIndex((obj => obj.referencia === queryString.ReferenciaSSV));
    osArray[elementIndex].isPago = queryString.isPago === "true" ? true : false;
    osArray[elementIndex].poliza = queryString;
    sendTagManagerArgs(osArray[elementIndex]);
    props.addInfoToPreOrder({ addPets: osArray }, saveLeadLocal(osArray[elementIndex]))
  }

  useEffect(() => {
    if (props.preOrder.addPets.length > 0) {
      if (getUrlParams(params).success) {
        findPet(getUrlParams(params).response, props.preOrder.addPets)
      }
    }
  }, [props.preOrder.addPets])

  const getFolio = () => {
    setTimeout(() => {
      let mascotas = props.preOrder.addPets
      for (let i = 0; i < mascotas.length; i++) {
        if (mascotas[i].referencia && mascotas[i].isPago === false) {
          setReference(mascotas[i].referencia)
          encryptData(mascotas[i].referencia)
          break;
        }
      }
    }, 2000)
  }

  const closeModalTryAgain = () => {
    setModalTryAgain(false);
    if (contador <= 1) {
      sendEmission();
    } else {
      setContador(0);
    }
  }

  const sendEmission = () => {
    //validateUltimoObject()

    let pets = props.preOrder.addPets;
    let variante = false
    let elementIndex = null
    for (let i = 0; i < pets.length; i++) {
      if (pets[i].referencia === "") {
        variante = false
        elementIndex = i
        break;
      } else if (pets[i].referencia !== "" && pets[i].isPago === false) {
        variante = true;
        break;
      }
    }
    setLoadingPayment(true)
    if (variante) {
      getFolio()
      return
    } else {
      let contador = 0;
      let osArray = props.preOrder.addPets;
      if (props.preOrder) {
        let pre_order = {
          ...props.preOrder,
          ...{
            "etapa": "1",
            "subEtapa": "1",
            "pantalla": "4",
            "email": props.preOrder.email === "hola@inter.mx" ?
              props.preOrder.fromContratante ?
                props.preOrder.fromContratante.roleType === 'si' ?
                  props.preOrder.fromContratante.email
                  :
                  props.preOrder.fromContratante.emailOwner === "" ? props.preOrder.fromContratante.email : props.preOrder.fromContratante.emailOwner
                : props.preOrder.email : props.preOrder.email
          }
        }
        pre_order.addPets = [osArray[elementIndex]]

        let params = emissionStructure(pre_order)
        apiWeb.postEmission(params).then(response => {
          if (response.data.success) {
            if (response.data.data.Referencia[0] !== "") {
              structurePet2(response.data.data.Referencia[0], elementIndex)
            } else {
              setTimeout(() => {
                setLoadingPayment(false)
                setModalTryAgain(true)
              }, 10000)
            }
          }
        }).catch(e => {
          setTimeout(() => {
            setLoadingPayment(false)
            setModalTryAgain(true)
          }, 10000)
          console.error(e)
        });

      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (openAlert) {
        setOpenAlert(false)
      }
    }, 6000)
  }, [openAlert])

  return (<>
    {
      openAlert &&
      <Box sx={{ width: '100%', marginBottom: 0, position: 'static', backgroundColor: '#F4F4F4' }}>
        <Collapse in={openAlert}>
          <Alert
            style={{
              backgroundColor: '#C3E2BE',
              padding: 0,
              alignItems: 'center', borderRadius: 0, height: 70
            }}
            severity="success"
            icon={<Stack style={{
              width: 70, height: 70, backgroundColor: '#5AB52F',
              justifyContent: 'center',
              alignItems: 'center'
            }} spacing={0}>
              <CheckCircleOutlineIcon style={{ color: 'white', fontSize: 33 }} fontSize="inherit" />
            </Stack>}

            action={
              <IconButton
                style={{ color: '#5AB52F' }}
                aria-label="close"
                size="large"
                onClick={() => {
                  setOpenAlert(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }

          >
            <span style={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 16
            }}>{'¡Listo! Ya tienes tu descuento :)'}</span>
          </Alert>
        </Collapse>
      </Box>
    }
    <div className='content-pay'>
      <Box sx={{ pb: 3 }}>
        <div className="circulo-gde" />
        <div className="circulo" />
        <Container className="container-payment">
          <TitleStep />
          <Box sx={{ mt: 2 }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Stack
                direction={{
                  xs: 'column-reverse',
                  sm: 'column-reverse',
                  md: 'column-reverse',
                  lg: 'row'
                }}
                spacing={2}>
                {/*** INICIO DEL CONTENEDOR PRINCIPAL ***/}
                <div>


                  <Stack>
                    <Box sx={{
                      marginBottom:2,
                      backgroundColor:'white',
                      display: "flex",
                      width: '100%',
                      borderRadius:1,
                      height: "34px",
                      justifyContent: "",
                      alignItems: "center"
                    }}>
                      {
                        <div style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                          <IconButton disableRipple='true'
                                      onClick={() => {
                                        navigate('/add-pets')
                                      }}
                          >
                            <ArrowBackIosNewIcon
                                style={{marginLeft: '16px'}}
                                fontSize="small"
                                sx={{color: blue[500]}}
                            />
                            <span
                                style={{
                                  color: "#039ECC",
                                  marginLeft: '16px',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  fontFamily: 'Montserrat',
                                  fontStyle: 'normal'
                                }}>Ir al paso 3</span>
                          </IconButton>
                        </div>
                      }
                    </Box>
                  </Stack>
                  <Stack
                    style={{ width: '100%', backgroundColor: '#F2FAFC' }}
                    className={'card-content-form-pay'}
                    direction="column"
                    alignItems="center"
                    spacing={0}>
                    <PetAccordionArray setOpen={setEditModalPet} selectedPet={setPetEdit} />
                  </Stack>

                  <Stack
                    style={{ width: '100%', paddingBottom: '3%', marginBottom: 13 }}
                    className={'card-content-form-pay'}
                    direction="column"
                    alignItems="center"
                    spacing={0}>
                    <OwnerDetail setOpen={setEditModalOwner} setOwnerEdit={setOwnerEdit}
                      owner={props.preOrder.fromContratante} />
                  </Stack>
                </div>
                {/*** FIN DEL CONTENEDOR PRINCIPAL ***/}

                {/***INICIO DEL CONTENEDOR SECUNDARIO***/}
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={2}>
                  <Grid
                    container
                    direction="row"
                    className={'grid-card-detail-insurance'}>
                    <DetailPlan
                      loading={loadingPayment}
                      setOpenAlert={setOpenAlert}
                      visiblePlans={visiblePlans}
                      setVisiblePlans={setVisiblePlans}
                      preOrder={props.preOrder}
                      onClick={sendTemporalData}
                    />

                  </Grid>
                </Stack>
                {/***FIN DEL CONTENEDOR SECUNDARIO***/}

              </Stack>
            </Grid>
          </Box>
        </Container>
      </Box>

    </div>
    <PlanesModal selectedPlan={props.preOrder.selectedPlan && props.preOrder.selectedPlan} visible={visiblePlans}
      setVisible={setVisiblePlans} folio={props.preOrder.folio}/>
    {
      editModalPet &&
      <ModalEditPet open={editModalPet} setOpen={setEditModalPet} pet={petEdit}
        setErrorModal={setErrorModal} setTypePet={setTypePet}
        setNamePet={setNamePet}
      />
    }

    {
      errorModal &&
      <ModalErrorPet typePet={typePet} name={namePet} open={errorModal} close={() => {
        setErrorModal(false)
      }} />
    }

    {
      editModalOwner &&
      <ModalEditOwner2 open={editModalOwner} setOpen={setEditModalOwner} owner={ownerEdit} />
    }
    {
      visibleAlert &&
      <ModalFollowLater open={visibleAlert}
        close={setVisibleAlert}
        postFollowLater={postFollowLater}
        folio={props.preOrder.folio}
        selectedPlan={props.preOrder.selectedPlan.PlanNombreInter}
      />
    }
    {
      modalTryAgain &&
      <ModalTryAgain visible={modalTryAgain} onClick={closeModalTryAgain}
        contador={contador}
        setContador={setContador}
      />
    }

    {
      modalPaymentType &&
      <ModalPaymentType open={modalPaymentType} accept={() => {
        setModalPaymentType(false)
        if (props.preOrder) {
          let pre_order = {
            ...props.preOrder,
            ...{
              "etapa": "1",
              "subEtapa": "1",
              "pantalla": "4",
              isEmailNeeded: true,
            }
          }
          let params = preInfoToPreOrder(pre_order)
          apiWeb.saveLead(params).then(res => {
            if (res.data.code === 200) {
              //Acción al dar 200
            }
          }).catch(e => {
            console.error(e)
          })
          navigate('/foundation-inter');

        }
      }
      } close={() => {
        setModalPaymentType(false)
      }} />
    }


    {
      visibleModalCoupon &&
      <ModalDiscountForYou
        disableClose={true}
        isDiscount={true}
        accept={() => {
          setVisibleModalCoupon(false);
        }
        }
        typePet={props.preOrder.selectedTypePet}
        open={visibleModalCoupon}
        close={setVisibleModalCoupon} />
    }

    {
      loadingPayment &&
      <ModalLoader open={loadingPayment} />
    }
    <div style={{ display: "none" }}>
      <form action={URL_BASE_PAYMENT} method={'POST'} id={'mediForm'}>
        <input name="AppProduct" id="AppProduct" value={AppProduct} />
        <input name="Referencia" id="Referencia" value={reference} />
        <input name="HASH" id="HASH" value={hash} />
      </form>
    </div>
  </>)

}

const mapStateToProps = state => ({
  preOrder: state.preOrder,
  temporal: state.temporal
})
export default connect(mapStateToProps, {
  addInfoToPreOrder,
  validatePreOrderFun,
  removeLocalStorageAction,
  getTokenAction
})(ConfirmPayment);
