import * as React from 'react';
import { Box, Grid, Stack } from "@mui/material";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import logoInter from '../../assets/img/yaSeguro/Logo-Inter-blanco.svg';
import logoGmx from '../../assets/img/yaSeguro/logo-gmx-blanco.svg';
import DetailPetCredential from "./DetailPetCredential";


const DetailDescriptionCredential = (props) => {

  const D = new Date();
  let dd = D.getDate().toString();
  let mm = (D.getMonth() + 1).toString();
  D.setFullYear(D.getFullYear() + 1)
  let yyyy = D.getFullYear().toString();
  const result = (dd[1] ? dd : "0" + dd[0]) + "/" + (mm[1] ? mm : "0" + mm[0]) + "/" + yyyy;

  const lineaBreak = <><br /><br /></>;
  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >

        <div className="descripcion-plan">
          <div className="div-poliza">
            <Box sx={{ mt: 4, mx: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={{ xs: 2, sm: 2, md: 12 }}
              >
                <img style={{
                  width: 'auto',
                  height: 'auto',
                  marginLeft: '-16px'
                }} src={logoInter} alt='logoInter' />
                <img style={{
                  width: 'auto',
                  height: 'auto',
                }} src={logoGmx} alt='logoInter' />
              </Stack>
            </Box>
            <Box sx={{ mt: 4, mx: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={{ xs: 0, md: 15 }}
              >
                <Grid item xs={6}>
                  <span className="text-descripcion">No. de p&oacute;liza</span>
                </Grid>
                <Grid container  xs={6} justifyContent="flex-end">
                  <span className="text-descripcion-cont">{props.pet.poliza.Poliza}</span>
                </Grid>
              </Stack>
            </Box>
            <Box sx={{ mt: 2, mx: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={{ xs:5, md: 36 }}
              >
                <Grid item xs={6}>
                  <span className="text-descripcion">Tel&eacute;fono para siniestros</span>
                </Grid>
                <Grid container xs={6} justifyContent="flex-end">
                  <span className="text-descripcion-cont">{'55-44-24-68-37'}</span>
                </Grid>
              </Stack>
            </Box>
            <Box sx={{ mt: 2, mx: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={{ xs: 10, md: 39 }}
              >
                <Grid item xs={6}>
                  <span className="text-descripcion">Plan</span>
                </Grid>
                <Grid container xs={6}  justifyContent="flex-end">
                  <span  className="text-descripcion-cont">{props.preOrder.selectedPlan && props.preOrder.selectedPlan.PlanNombreInter}</span>
                </Grid>
              </Stack>
            </Box>
            <Box sx={{ mt: 2, mx: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={{ xs: 14, md: 43 }}
              >
                <Grid item xs={6}>
                  <span className="text-descripcion">Vigencia</span>
                </Grid>
                <Grid container xs={6} justifyContent="flex-end">
                  <span className="text-descripcion-cont">{result}</span>
                </Grid>
              </Stack>
            </Box>
            <Box sx={{ mt: 2, mx: 1, pb: 3 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={{ xs: 1, md: 14 }}
              >
                <Grid item xs={6}>
                  <span className="text-descripcion">Contratante</span>
                </Grid>
                <Grid container xs={6} justifyContent="flex-end">
                  <span className="text-descripcion-cont">
                    {props.preOrder.fromContratante ? props.preOrder.fromContratante.roleType === "no" ? props.preOrder.fromContratante.nombresOwner : props.preOrder.fromContratante.nombres : ""}
                    {" "}
                    {props.preOrder.fromContratante ? props.preOrder.fromContratante.roleType === "no" ? props.preOrder.fromContratante.paternoOwner : props.preOrder.fromContratante.paterno : ""}
                    {" "}
                    {props.preOrder.fromContratante ? props.preOrder.fromContratante.roleType === "no" ? props.preOrder.fromContratante.maternoOwner : props.preOrder.fromContratante.materno : ""}
                  </span>
                </Grid>
              </Stack>
            </Box>
          </div>
        </div>
        <Stack>
          <div className="div-detail">
            <div className="data-pet">
              <DetailPetCredential pet={props.pet} />
            </div>
          </div>
          {lineaBreak}
        </Stack>
      </Stack>
    </>
  );
}

const mapState = (state) => {
  return {
    preOrder: state.preOrder
  }
}
export default connect(mapState, { addInfoToPreOrder })(DetailDescriptionCredential);