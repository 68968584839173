import { array_foundations_tablet, causeParams } from "../Utils/utils";
import './FoundationInterMobile.css'
import { Box, Button, Dialog, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import ModalFundacion from "../ModalsToActivate/ModalFundacion";
import ApiWeb from "../../lib/apiConfig/apiWeb";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const DetailFoundation = (props) => {

  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const navigate = useNavigate();

  const handleClickClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenPhotoModal(false);
    }
  };

  const handleClickModal = () => {
    handleClickOpen();
    ApiWeb.postCause(causeParams(props.preOrder, props.current.name)).then(response => {
    }).catch(e => {
      console.log("error", e)
    });
  }

  const dataGTM = JSON.parse(sessionStorage.getItem("preOrder"))

  const handleClickOpen = () => {
    setOpenPhotoModal(true);
    window.dataLayer.push({
      event: "mascota_seguro_fundacion",
      option: props.current.name.split("\n").join(" "),
      boton_contacto: "Apoyar causa",
      correo: dataGTM.fromContratante.email,
      telefono: dataGTM.fromContratante.telefono
    })
  };

  const download = (link) => {
    let element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('target', '_blank')
    element.setAttribute('download', 'download')
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  return (<>
    <Dialog open={openPhotoModal} onClose={(event, reason) => handleClickClose(event, reason)}
      disableEscapeKeyDown={true}
      maxWidth="sm" fullWidth={false}>
      <>
        <ModalFundacion handleClose={handleClickClose} item={props.current} />
      </>
    </Dialog>
    <div style={{ width: '80%', paddingLeft: '10%', paddingRight: '10%' }}>
      <Grid container>
        <Grid item>
          <div style={{ textAlign: "left", width: '100%' }}>
            {props.current.description}
          </div>
          <div style={{ width: '100%', textAlign: "right", marginTop: '3%', marginBottom: '3%' }}>
          <Stack
              direction={{ xs: 'column', sm: 'column',md:'column' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
            <Button
              onClick={() => {
                download(props.current.url);
              }}
              style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12,
              }} variant="text">{'Visitar página web'}</Button>
               <Button fullWidth style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 16,
                height: 48
              }}variant="outlined" 
              onClick={() => {
                navigate('/ya-seguro')
                window.dataLayer.push({
                  event: "mascota_seguro_fundacion",
                  option: props.current.name.split("\n").join(" "),
                  boton_contacto: "Tal vez despues",
                  correo: dataGTM.fromContratante.email,
                  telefono: dataGTM.fromContratante.telefono
                })
              }}
              >{'Tal vez después'}</Button>
            </Stack>
          </div>
          <div style={{ width: '100%' }}>
            <Button fullWidth style={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 16,
              height: 48
            }} variant="contained" onClick={handleClickModal}>{'Apoyar causa'}</Button>
          </div>
        </Grid>
      </Grid>
    </div>
  </>)
}

const FoundationInterMobile = (props) => {
  const [current, setCurrent] = useState(null)

  return (<>
    <div style={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}>
      {
        array_foundations_tablet.map((item, index) => {
          return (
            <div
              style={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}
              className={'card-item-mobile'}>

              <div
                onClick={() => {
                  if (current && current.id === item.id) {
                    setCurrent(null)
                  } else {
                    setCurrent(item)
                  }
                }}
                style={{
                  display: 'block',
                  paddingTop: 24,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                  textAlign: 'center',
                }}
                key={index}>
                <div
                  style={{
                    width: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: '5%'
                  }}>

                  <span style={{
                    width: '40%',
                    textAlign: 'center',
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: 20,
                  }}>
                    {item.name}
                  </span>

                </div>

                <Box>
                  <div
                    className={'img-mobile'}
                    style={{
                      backgroundImage: `url(${item.id === 2 ? item.image_detail_2 : item.image_detail})`
                    }}
                  />
                </Box>
                <div style={{ width: '100%', marginTop: '5%' }}>
  <Button
      onClick={() => {
        if (current && current.id === item.id){
          setCurrent(null)
        }else {
          setCurrent(item)
        }
      }}
      fullWidth style={{
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14
  }}
      variant="text"
      endIcon={
        current ?
            current.id === item.id ?
                <KeyboardArrowUpIcon
                    style={{ paddingLeft: 10, fontSize: 25 }} />
                :
                <KeyboardArrowDownIcon
                    style={{ paddingLeft: 10, fontSize: 25 }} />
            :
            <KeyboardArrowDownIcon style={{ paddingLeft: 10, fontSize: 25 }} />
      }>
    {'Conocer más'}
  </Button>
</div>
              </div>
              <div style={{ paddingBottom: 24 }}>
                {
                  current &&
                  current.id === item.id &&
                  <DetailFoundation current={current} preOrder={props.preOrder} />

                }
              </div>

            </div>
          )
        })
      }
    </div>

  </>)
}

export default FoundationInterMobile;
