import {
  Box,
  Button, Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
} from "@mui/material";
import * as React from "react";
import './ModalDiscountForYou.css'
import { Form, Formik } from "formik";
import { ValidationModalDiscountForm2 } from "./ValidationModalDiscountForm2";
import ApiWeb from "../../lib/apiConfig/apiWeb";
import CustomTextField from "../CustomFields/CustomTextField";
import { preInfoToPreOrder, validateEmail } from "../Utils/utils";
import CustomErrorMessage from "../CustomFields/CustomErrorMessage";
import moment from "moment";
import apiWeb from "../../lib/apiConfig/apiWeb";

const ModalDiscountForm2 = (props) => {
  const tab = <>&nbsp;</>;



  const getDate = () => {
    var dias = 2; // Número de días a agregar
    let today = new Date()
    today.setDate(today.getDate() + dias);

    let format = moment(today).format("DD-MM-YYYY")

    return format;
  }


  const sendDataLead = async (email) => {
    let next = false;
    let pre_order = {
      ...props.preOrder,
      email,
      ...{
        "etapa": "1",
        "subEtapa": "1",
        "pantalla": "3",
      }
    }
    let params = preInfoToPreOrder(pre_order)
    await apiWeb.saveLead(params).then(res => {
      if (res.data.code === 200) {
        next = true
      }
    }).catch(e => {
      console.log(e)
      next = false
    })

    return next;
  }

  const sendEmail = (data) => {
    const { email } = data;
    props.addInfoToPreOrder({ email: email })
    if (sendDataLead(email) === false) {
      return
    }
    let params = {
      "business": "medipet",
      "insurance": "gmx",
      "service": "coupon",
      "util": "send",
      "email": email,
      'folio': props.preOrder.folio,
      "name": data.name,
      "coupon": props.preOrder.coupon.coupon,
      "no": props.preOrder.selectedTypePet === 1 ? "0" : "2",
      "petName": props.preOrder.petName ? props.preOrder.petName : "",
      "planName": props.preOrder.selectedPlan?.PlanNombreInter,
      "percent": props.preOrder.coupon.value,
      "endDate": getDate(),
      "expires": getDate(),
      "price": props.preOrder.selectedPlan?.PlanPrimaTotal + "",
      "discountPrice": props.preOrder.selectedPlan?.PlanPrimaTotal2 + "",
    }
    ApiWeb.genericApi(params).then(response => {
      if (response.data.code === 200) {
      }
      props.accept()
    }).catch(error => {
      console.log("error:", error)
      props.accept()
    });
  }

  const handleGuardar = (values) => {
    sendEmail(values)
  }

  return (<>
    <Dialog open={props.open}
      disableEscapeKeyDown={true}
      maxWidth={'xs'} fullWidth={false}>
      <div className={"modal-w"}>

        <Box sx={{ mt: 0, width: '100%', mb: 2 }}>
          <Grid
            className='modal-individual-padding'
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ width: '100%', mb: 2 }}>
              <div style={{ width: '100%' }}>
                <span style={{ textAlign: 'right' }}
                  className='title-modal-discount-form'>{'¡Te enviaremos tu descuento!'}</span>
              </div>
              <Box sx={{ mt: 2, width: '100%' }}>
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    name: '',
                    email: '',
                    terminos: false

                  }}
                  validationSchema={ValidationModalDiscountForm2}
                  onSubmit={(values, { setSubmitting }) => {
                    handleGuardar(values);
                  }}>
                  {({
                    values,
                    submitForm,
                    setFieldValue,
                    handleChange,
                    errors,
                    isSubmitting,
                    setFieldError,
                    touched,
                    setFieldTouched,
                  }) => (
                    <Form>
                      <div style={{ width: '100%', marginBottom: '5%' }}>
                        <InputLabel
                          htmlFor={"name"}
                          size={"normal"}
                          variant="standard"
                          style={{ marginBottom: 10, color: 'black' }}>
                          {"¿Cómo se llama?*"}
                        </InputLabel>
                        <CustomTextField
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Nombre"
                        />
                      </div>
                      <div style={{ width: '100%', marginTop: '5%', marginBottom: '5%' }}>
                        <InputLabel
                          size={"normal"}
                          variant="standard"
                          style={{ marginBottom: 10, color: 'black' }}>
                          {"Pásanos tu Email"}
                        </InputLabel>
                        <CustomTextField
                          id="email" name="email" type="text" placeholder="Correo*" validate={(value) => validateEmail(value)} />
                      </div>


                      <div className="espacio">
                        <FormControlLabel
                          name='terminos'
                          control={<Checkbox />}
                          label=''
                          style={{ marginRight: 0 }}
                          onChange={(event) => {
                            setFieldValue('terminos', event.target.checked)
                          }}
                        />
                        Acepto{tab}<a className="href-terminos" target="_blank"
                          href="https://inter.mx/aviso-de-privacidad"
                          rel="noreferrer">Aviso de privacidad</a>{tab}y{tab}
                        <a className="href-terminos" target="_blank"
                          href="https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/TYC%20APP%20Intermx.pdf" rel="noreferrer">Términos
                          y Condiciones</a>
                        <CustomErrorMessage
                          name='terminos'
                        />
                      </div>


                      <Stack
                        style={{ marginTop: '5%' }}
                        direction={{ xs: 'column', sm: 'column', md: 'row' }}
                        spacing={2}
                      >
                        <Button
                          onClick={() => props.close()}

                          style={{
                            width: '100%',
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: 14,
                            height: 48
                          }}
                          variant={"outlined"}
                        >{'MEJOR DESPUÉS'}</Button>
                        <Button
                          disabled={!values.terminos}
                          style={{
                            width: '100%',
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: 14,
                            height: 48
                          }}
                          onClick={submitForm}
                          variant="contained"
                        >{'Quiero mi cupón'}</Button>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Grid>
        </Box>
      </div>
    </Dialog>
  </>)
}
export default ModalDiscountForm2;
