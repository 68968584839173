import * as React from "react";
import {
  Box, Button,
  Dialog,
  Grid,
  InputLabel,
  Stack,
  TextField
} from "@mui/material";
import './ModalEditPet.css'
import './ModalOwner.css'
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import esLocale from "date-fns/locale/es";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { ValidationModalEditOwner, ValidationModalEditOwnerMenorEdad } from "./ValidationModalEditOwner";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { dateFormat, OnlyAlpha, validateEmail, validatePhoneNumber } from '../Utils/utils'
import CustomTextField from "../CustomFields/CustomTextField";
import { handleMaxLength } from "../Utils/FieldValidationFunctions";

function CustomDatePickerOwner(props) {
  const color = "#039ECC";
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <DesktopDatePicker
          inputFormat="dd/MM/yyyy"
          disableFuture
          openTo="year"
          views={['year', 'month', 'day']}
          {...props}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{
                  svg: { color },
                }}
                className="w-owner"
                helperText={props.helperText}
                error={!!props.error}
              />
            );
          }}
        />
      </LocalizationProvider>
    </>
  );
}

const ModalEditOwner2 = (props) => {
  const [gender, setGender] = useState('Hombre');
  const handleClickGender = (event, newGender) => {
    setGender(newGender);
  };
  const DateFormat_ = (date_) => {
    var dateString = date_; // Oct 23
    var newData = dateString.replace(/(\d+[/])(\d+[/])/, '$2$1');
    var data_ = new Date(newData);
    return data_
  }


  const replaceDataContratante = (values) => {
    let contratante = props.preOrder.fromContratante;

    if (contratante.roleType === "no") {
      contratante.nombresOwner = values.name
      contratante.paternoOwner = values.pLastName
      contratante.maternoOwner = values.mLastName
      contratante.generoOwner = values.gender
      contratante.fechanacimientoOwner = dateFormat(values.birthday)
      if (contratante.emailOwner === "") {
        contratante.email = values.email
      } else {
        contratante.emailOwner = values.email
      }
      if (contratante.telefonoOwner === "") {
        contratante.telefono = values.cellPhone
      } else {
        contratante.telefonoOwner = values.cellPhone
      }
    } else {
      contratante.nombres = values.name
      contratante.paterno = values.pLastName
      contratante.materno = values.mLastName
      contratante.genero = values.gender
      contratante.fechanacimiento = dateFormat(values.birthday)
      contratante.email = values.email
      contratante.telefono = values.cellPhone
    }


    props.addInfoToPreOrder({ fromContratante: contratante })
    handleClickClose();
  }

  const handleClickClose = () => {
    props.setOpen(false);
  };


  const setDataOwner = () => {
    if (props.owner && props.owner.roleType === "no") {
      setGender(props.owner && props.owner.generoOwner ? props.owner.generoOwner : "hombre")
    } else {
      setGender(props.owner && props.owner.genero ? props.owner.genero : "hombre")
    }
  }

  useEffect(() => {
    setDataOwner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.owner])


  const handleGuardar = (values) => {
    let person = {
      ...values,
      gender: gender
    }
    replaceDataContratante(person)
  }


  return (
    <>
      <Dialog open={props.open} onClose={() => handleClickClose()}
        disableEscapeKeyDown={true}
        maxWidth={"sm"} fullWidth={false}>
        <>
          <Box sx={{ mt: 0 }}>
            <Grid
              className='modal-individual-padding'
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Stack
                direction="column"
                justifyContent="center"
                style={{ width: '100%' }}
                alignItems="center"
              >
                <span className='title-modal-pet'>{'Actualizar datos del dueño'}</span>
                <Box sx={{ mt: 1, width: '100%' }}>
                  <Stack
                    style={{ width: '100%' }}
                    direction="column"
                    spacing={2}
                  >
                    <Formik
                      validateOnChange={true}
                      initialValues={{
                        name: props.owner && props.owner.roleType === "no" ? props.owner.nombresOwner : props.owner && props.owner.nombres,
                        pLastName: props.owner && props.owner.roleType === "no" ? props.owner.paternoOwner : props.owner.paterno,
                        mLastName: props.owner && props.owner.roleType === "no" ? props.owner.maternoOwner : props.owner.materno,
                        birthday: props.owner && props.owner.roleType === "no" ? DateFormat_(props.owner.fechanacimientoOwner) : DateFormat_(props.owner.fechanacimiento),
                        email: props.owner && props.owner.roleType === "no" ? props.owner.emailOwner === "" ? props.owner.email : props.owner.emailOwner : props.owner.email,
                        cellPhone: props.owner && props.owner.roleType === "no" ? props.owner.telefonoOwner === "" ? props.owner.telefono : props.owner.telefonoOwner : props.owner.telefono
                      }}

                      onSubmit={(values, { setSubmitting }) => {
                        handleGuardar(values);
                      }}

                      validationSchema={props.preOrder.fromContratante.roleType === "no" ?
                        props.preOrder.fromContratante.emailOwner === "" ?
                          ValidationModalEditOwnerMenorEdad : ValidationModalEditOwner
                        :
                        ValidationModalEditOwner}

                    >
                      {({
                        values,
                        submitForm,
                        setFieldValue,
                        handleChange,
                        errors,
                        isSubmitting,
                        setFieldError,
                        touched,
                        setFieldTouched,
                      }) => (
                        <Form>
                          <InputLabel
                            htmlFor={"name"}
                            size={"normal"}
                            variant="standard"
                            style={{ marginBottom: 10, color: 'black' }}>
                            {"¿Cómo se llama?"}
                          </InputLabel>
                          <CustomTextField
                            onKeyPress={(event) => OnlyAlpha(event)}
                            onKeyUp={(event) => OnlyAlpha(event)}

                            className="w-100-step" id="name" name="name" type="text"
                            placeholder="Nombre(s)*" />
                          <div style={{ width: '100%', marginTop: '4%' }}>
                            <CustomTextField
                              onKeyPress={(event) => OnlyAlpha(event)}
                              onKeyUp={(event) => OnlyAlpha(event)}

                              className="w-100-step" id="pLastName" name="pLastName" type="text"
                              placeholder="Apellido paterno*" />
                          </div>
                          <div style={{ width: '100%', marginTop: '4%' }}>
                            <CustomTextField
                              onKeyPress={(event) => OnlyAlpha(event)}
                              onKeyUp={(event) => OnlyAlpha(event)}

                              className="w-100-step"
                              id="mLastName"
                              name="mLastName"
                              placeholder="Apellido materno*"
                            />
                          </div>
                          <div style={{ width: '100%', marginTop: '4%' }}>
                            <InputLabel
                              size={"normal"}
                              variant="standard"
                              style={{ marginBottom: 10, color: 'black' }}>
                              {"¿Cuál es su sexo de nacimiento?"}
                            </InputLabel>
                          </div>
                          <Stack spacing={2} direction="row" className="w-owner">
                            <ToggleButtonGroup
                              color="primary"
                              value={gender}
                              exclusive
                              style={{ width: '100%' }}
                              onChange={handleClickGender}
                            >
                              <ToggleButton fullWidth
                                className="boton-si-owner"
                                value="hombre"
                              >
                                Hombre
                              </ToggleButton>
                              <ToggleButton fullWidth
                                className="boton-no-owner"
                                value="mujer"
                              >Mujer
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Stack>

                          <div style={{ width: '100%', marginTop: '4%' }}>
                            <span className="modal-preguntas-contratante">¿Cuándo nació?</span>
                            <CustomDatePickerOwner id="birthday" name="birthday" type="text" placeholder="Fecha Nacimiento"
                              value={values.birthday}
                              onChange={(type) => {
                                setFieldValue("birthday", type)
                              }}
                            />
                          </div>

                          <div style={{ width: '100%', marginTop: '4%' }}>
                            <InputLabel
                              size={"normal"}
                              variant="standard"
                              style={{ marginBottom: 10, color: 'black' }}>
                              {"¿Dónde lo podemos contactar?"}
                            </InputLabel>
                          </div>

                          <div style={{ width: '100%', marginTop: '0%' }}>
                            <CustomTextField
                              className="w-100-step"
                              id="email"
                              name="email"
                              placeholder="Correo*"
                              validate={(value) => validateEmail(value)}
                            />
                          </div>
                          <div style={{ width: '100%', marginTop: '5%' }}>
                            <CustomTextField id="cellPhone" name="cellPhone" type="number"
                              placeholder="Teléfono*"
                              onChange={(event) => handleMaxLength(event, setFieldValue, "cellPhone", 10)} validate={(value) => validatePhoneNumber(value)} />

                          </div>

                          <div style={{ width: '100%', marginTop: '4%' }}>
                            <Stack
                              justifyContent="center"
                              alignItems="center"
                              spacing={{ xs: 2, sm: 2, md: 4 }}
                              direction={{ xs: "column", sm: "column", md: "row" }}>
                              <Button
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  fontSize: 16,
                                  height: 48
                                }}
                                fullWidth
                                variant="outlined"
                                onClick={() => handleClickClose()}
                              >Cancelar</Button>

                              <Button
                                onClick={submitForm}
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontStyle: 'normal',
                                  fontWeight: 700,
                                  fontSize: 16,
                                  height: 48
                                }}
                                fullWidth
                                variant="contained"
                              >Actualizar</Button>
                            </Stack>
                          </div>

                        </Form>
                      )}
                    </Formik>
                  </Stack>
                </Box>
              </Stack>
              <div>

              </div>
            </Grid>
          </Box>
        </>
      </Dialog>
    </>
  )
}
const mapStateToProps = state => ({
  preOrder: state.preOrder
})
export default connect(mapStateToProps, { addInfoToPreOrder })(ModalEditOwner2);
