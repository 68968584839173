import React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { useField } from "formik";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      color: "red", // or black
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'unset',
    },
    '& .MuiInputBase-root': {
      borderWidth: '1px',
      borderColor: '#C7C7C7',
      borderRadius: '8px',
      height: '48px',
    },

  
  }
}));


export default function CustomTextField({ placeholder, ...props }) {
  const [field, meta] = useField(props);
  const classes = useStyles();
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <TextField
      size="small"
      placeholder={placeholder}
      {...field}
      {...props}
      helperText={errorText}
      error={!!errorText}
      className={classes.root}
      InputProps={{ classes: {input: classes.root['input'] } }}
      fullWidth={true}
    />
  );
}

CustomTextField.defaultProps = {
  variant: "outlined",
};
