
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { blue } from "@mui/material/colors";
import React from "react";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import '../../pages/home/Home.css';
import planDesk from "../../assets/landing/perro-landing-planes-desk.png";
import planTablet from "../../assets/landing/perro-landing-planes-tablet.png";
import planMobile from "../../assets/landing/perro-landing-planes-mobile.png";
import catDesk from "../../assets/landing/gato-landing-planes-desk.png";
import catTablet from "../../assets/landing/gato-landing-planes-tablet.png";
import catMobile from "../../assets/landing/gato-landing-planes-mobile.png";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import {currencyFormat, getLocalStorageDataUTM, TagManagerLayer} from "../Utils/utils";
import ApiWeb from "../../lib/apiConfig/apiWeb";
import HeadBand from "../HeadBand/HeadBand";


const listDescriptions = [
  { id: 1, desc: "Por si tiene un accidente o se pone malito" },
  { id: 2, desc: "Por si daña algo o muerde a alguien" },
  { id: 3, desc: "Por si estira la patita (hasta $2000)" },
  { id: 4, desc: "Por si requiere eutanasia (hasta $800)" }]

const TypePetPlans = (props) => {
  const navigate = useNavigate();

  const tab = <><br /></>;


  const resetTypePet = () => {
    props.addInfoToPreOrder({ petName: null })
  }


  const sendTagManagerArgs = (data) => {
    let tagManagerArgs = {
      event: "mascota_seguro_cotizar",
      costo_plan: data.PlanPrimaTotal,
      boton_cotizar: data ? data.PlanNombreInter : "",
      cotizacion: "No Aplica" // duda de que va en el parametro
    }
    TagManagerLayer(tagManagerArgs)
  }

  const getUrlPlatform = () => {
    if (window.location.hostname === 'localhost') {
      return 'http://' + window.location.hostname + ':3000';
    } else {
      return 'https://' + window.location.hostname;
    }
  }

  const sendUTM = async () => {
    let utmSource = await getLocalStorageDataUTM("utm_source")
    let utmMedium = await getLocalStorageDataUTM("utm_medium")
    let utmCampaign = await getLocalStorageDataUTM("utm_campaign")
    let utmContent = await getLocalStorageDataUTM("utm_content")
    let num_finsus= '';
    let utm = '';
    if (utmContent === 'undefined') {
      return
    }

    if (await getLocalStorageDataUTM("num_finsus") != null){
      num_finsus= await getLocalStorageDataUTM("num_finsus")
      utm = getUrlPlatform() + "?utm_source=" + utmSource + "&utm_medium=" + utmMedium + "&utm_campaign=" + utmCampaign + "&utm_content=" + utmContent + "&num_finsus=" + num_finsus;
    } else {
      utm = getUrlPlatform() + "?utm_source=" + utmSource + "&utm_medium=" + utmMedium + "&utm_campaign=" + utmCampaign + "&utm_content=" + utmContent;
    }    
    props.addInfoToPreOrder({ url: utm })
    let params = {
      business: "medipet",
      insurance: "gmx",
      url: utm
    }


    if (props.preOrder.folio !== null){
      return
    }
    ApiWeb.saveLead(params).then(response => {
      if (response.data.code===200){
          props.addInfoToPreOrder({ folio: response.data.data })
      }
    }).catch(e => { console.log("error", e) })
  }

  const selectPlan = (plan) => {
    sendTagManagerArgs(plan)
    sendUTM();
    props.addInfoToPreOrder({ selectedPlan: plan })
    go(plan);
    window.dataLayer.push({
      event: 'mascota_seguro_planes',
      plan_elegido: plan.PlanNombreInter,
      boton_contacto: 'Lo quiero',
    })
  }

  const go = (plan) => {
    navigate(`/insurance-detail`)
  }
  return (
    <>
      <div className='div-azul2'>
        <Grid
          className={'margin-content'}
          style={{ padding: '5%', display: 'flex' }}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ width: '100%', extAlign: 'center' }} className='content-component'>
            <div style={{ textAlign: 'left', paddingBottom: 20 }}>
              <ArrowBackIcon
                className={"icon-arrow"}
                onClick={() => {
                  resetTypePet()
                }}
                sx={{ color: blue[500] }}

              />
            </div>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <div>
                <Stack
                    alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
                    direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}>

                  <div style={{width:"100%"}}>
                    {props.preOrder.selectedTypePet === 2 ?
                        <div className={'widthMobile'}>
                        <picture>
                          <source media="(min-width: 1199px)" srcSet={catDesk}/>
                          <source media="(min-width: 1440px)" srcSet={catDesk}/>
                          <source media="(min-width: 1024px)" srcSet={catDesk}/>
                          <source media="(min-width: 768px)" srcSet={catTablet}/>
                          <source media="(min-width: 320px)" srcSet={catMobile}/>
                          <img src={catDesk} alt="gato"/>
                        </picture>
                        </div>

                        :
                        <picture>
                          <source media="(min-width: 1199px)" srcSet={planDesk}/>
                          <source media="(min-width: 1440px)" srcSet={planDesk}/>
                          <source media="(min-width: 1024px)" srcSet={planDesk}/>
                          <source media="(min-width: 768px)" srcSet={planTablet}/>
                          <source media="(min-width: 320px)" srcSet={planMobile}/>
                          <img src={planDesk} alt="perro"/>
                        </picture>
                        }
                  </div>
                  <HeadBand/>
                </Stack>
              </div>
              <div className={'card-content-plans-home'}>
                <Box
                  textAlign={'center'}
                  sx={{ mt: 2 }}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Box sx={{ mt: 0, mb: 0 }}>
                      <span style={{ textAlign: 'center' }}
                        className='titulo-primero'>{"Descubre los tres planes que tenemos para"}{tab}{props.preOrder.petName && props.preOrder.petName}</span>
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack
                      direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
                      justifyContent="center"
                      style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 20 }}
                      alignItems="center" spacing={2}>
                      {
                        props.config.fetchingPlans ?
                          <div style={{ width: '100%', marginBottom: '55%' }}>
                            <CircularProgress disableShrink />
                          </div>
                          :
                          props.config.plans.length > 0 && props.config.plans.map((item, index) => {
                            return (
                              <Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={'card-plan'}>
                                  <div className={'card-plan-toolbar'}>
                                    <span className='text-title-plan'>Plan </span><span
                                      className='text-title-plan2'>{item.PlanNombreInter}</span>
                                  </div>
                                  <br />
                                  <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                                    <span className='text-title-cost-plan'>
                                      {currencyFormat(item.PlanPrimaTotal, 0, true)}
                                    </span>

                                    <span
                                      className='text-title-plan-annual'>{"Anuales (IVA incluído)"}</span>
                                    <br />
                                    <br />
                                    <div style={{ textAlign: 'left' }}>
                                      <span
                                        className='text-title-plan-gastos-med'>{"Cobertura por año: "}</span>
                                      <br />
                                      <span
                                        className='text-title-plan-gastos-med'>{`${currencyFormat(item.PlanSumaAsegurada, 0, true)} que te reembolsa: `}</span>
                                    </div>
                                    <br />
                                    <div style={{ textAlign: 'left' }}>
                                      {
                                        listDescriptions.map((c, idx) => {
                                          return (
                                            <div style={{
                                              width: 'auto',
                                              gridTemplateColumns: '0fr 1fr',
                                              gridGap: ' 0px',
                                              display: 'grid'
                                            }}>
                                              <div
                                                style={{ display: 'inline-block' }}>
                                                <span
                                                  className='text-title-plan-description'>&middot;&nbsp;
                                                </span>
                                              </div>
                                              <div
                                                style={{ display: 'inline-block' }}>
                                                <span
                                                  className='text-title-plan-description'> {c.desc}
                                                </span>
                                              </div>
                                            </div>


                                          )
                                        })
                                      }

                                    </div>
                                  </div>
                                  <div style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    paddingTop: 10,
                                    paddingBottom: 0
                                  }}>
                                    <Button
                                      onClick={() => {
                                        selectPlan(item)
                                      }}
                                      variant="contained"
                                      style={{ height: 48, fontWeight: 700 }}
                                      size={"large"} fullWidth>&iexcl;LO
                                      QUIERO!</Button>
                                  </div>
                                </div>
                              </Grid>
                            )
                          })
                      }
                    </Stack>
                  </Grid>
                </Box>
                <Box
                  style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: 0 }}
                  textAlign={'center'}
                  sx={{ mt: 0 }}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Box>
                      <span style={{ textAlign: 'center' }} className='text-home-description-plan'>
                        {"El monto de la cobertura es anual. Eso significa que si tu mascota se la termina en una sola travesura o en un mal día, no podrás contar con más reembolsos ese año."}
                      </span>
                      <br />
                      <br />
                      <span style={{ textAlign: 'center' }} className='text-home-description-plan'>
                        {"Si quieres checar a detalle cada uno de los paquetes, puedes descargar las "}
                      </span>
                      <a style={{ textAlign: 'center', textDecoration: 'none' }}
                        href="https://storage.googleapis.com/inter-public-cloudstorage-prod/mascota_segura/Condiciones%20Generales%20Mascota.pdf"
                        className='text-home-description-plan2' target="_blank" rel="noreferrer">
                        {"Condiciones Generales"}
                      </a>
                    </Box>
                  </Stack>
                </Box>
              </div>

            </Grid>
          </div>
        </Grid>
      </div>

    </>
  )
}
const mapState = (state) => {
  return {
    preOrder: state.preOrder,
    config: state.config
  }
}
export default connect(mapState, { addInfoToPreOrder })(TypePetPlans);
