import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  styled,
  Switch,
  Typography,
  Checkbox,
  MenuItem,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react"
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { getZipCode } from "../../redux/configDuck";
import { getTax } from "../../redux/configDuck";
import CustomTextField from '../CustomFields/CustomTextField';
import './FormStepTres.css';
import { Form, Formik } from 'formik';
import {
  validationFormContratante,
  validationFormDueño,
  validationFormDueñoMayorEdad
} from "./ValidationFormContratante";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useField } from "formik";
import esLocale from "date-fns/locale/es";
import TextField from '@mui/material/TextField';
import {
  calcularEdad,
  dateFormat,
  dateFormatObj,
  keyPressSoloLetrasNumeros,
  noPaste,
  preInfoToPreOrder, TagManagerLayer,
  validaMayorEdad, validateEmail,
  validatePhoneNumber,
  validateFormat, OnlyAlpha
} from '../Utils/utils'
import { FECHA_VALIDA, MAYOR_EDAD } from "../Utils/Constantes";
import BlueTooltip from "../Utils/BlueTooltip";
import CustomSelect from '../CustomFields/CustomSelect';
import CustomErrorMessage from "../CustomFields/CustomErrorMessage";
import { handleMaxLength, handleMaxLengthRfc } from "../Utils/FieldValidationFunctions";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import apiWeb from "../../lib/apiConfig/apiWeb";
import RfcFacil from "rfc-facil";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { format } from "date-fns";


function CustomDatePickerPasoTres(props) {
  const [field, meta] = useField(props);
  const errorText = meta.error ? meta.error : "";
  const color = "#039ECC";
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <DesktopDatePicker
          disableFuture
          openTo="year"
          views={['year', 'month', 'day']}
          inputFormat="dd/MM/yyyy"
          {...field}
          {...props}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{
                  svg: { color },
                }}
                className="w-100"
                helperText={meta.error === "Debe ser mayor de edad" ? meta.error : meta.touched && meta.error ? errorText : ""}
                error={meta.error === "Debe ser mayor de edad" ? true : meta.touched && meta.error ? true : false}
              />
            );
          }}
        />
      </LocalizationProvider>
    </>
  );
}

function CustomDatePickerDueño(props) {
  const [field, meta] = useField(props);
  const errorText = meta.error ? meta.error : "";
  const color = "#039ECC";
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <DesktopDatePicker
          disableFuture
          openTo="year"
          views={['year', 'month', 'day']}
          inputFormat="dd/MM/yyyy"
          {...field}
          {...props}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{
                  svg: { color },
                }}
                className="w-100"
                helperText={errorText}
                error={!!errorText}
              />
            );
          }}
        />
      </LocalizationProvider>
    </>
  );
}


const FormStepTres = (props) => {

  const DateFormat_ = (date_) => {
    var dateString = date_; // Oct 23
    var newData = dateString.replace(/(\d+[/])(\d+[/])/, '$2$1');
    var data_ = new Date(newData);
    return data_
  }
  const [checked, setChecked] = useState(props.preOrder.fromContratante ? props.preOrder.fromContratante.isfactura : false);
  const [alignmentSi, setAlignmentSi] = useState('si');
  const [alignmentGenero, setAlignmentGenero] = useState(props.preOrder.fromContratante ? props.preOrder.fromContratante.genero : 'Mujer');
  const [dueñoGenero, setDueñoGenero] = useState(props.preOrder.fromContratante ? props.preOrder.fromContratante.generoOwner : 'mujer');
  const [fechaSeleccionada, setFechaSeleccionada] = useState(props.preOrder.fromContratante ? props.preOrder.fromContratante.fechanacimientoOwner !== "" ? DateFormat_(props.preOrder.fromContratante.fechanacimientoOwner) : new Date() : new Date());
  const [selectedColonia, setSelectedColonia] = useState(null);

  const navigate = useNavigate();
  const tab = <>&nbsp;</>;
  const [tooltipVisible, setTooltipVisible] = useState(false)


  useEffect(() => {
    if (props.preOrder.fromContratante) {
      setAlignmentSi(props.preOrder.fromContratante ? props.preOrder.fromContratante.roleType : 'si')
    }
  }, [props.preOrder.fromContratante])

  const handleClickGeneroDueño = (event, newDueñoGenero) => {
    setDueñoGenero(newDueñoGenero);
  };


  const handleToggle = (event, newAlignmentSi) => {
    setAlignmentSi(newAlignmentSi);
  };

  const handleClickGenero = (event, newAlignmentGenero) => {
    setAlignmentGenero(newAlignmentGenero);
  };

  const handleChanges = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("fiscal", "Sin obligaciones fiscales")
      props.getTax();
    } else {
      setFieldValue("fiscal", "Sin obligaciones fiscales")
    }
  };


  const setClick = (params_) => {
    let pre_order = {
      ...props.preOrder,
      "fromContratante": params_,
      ...{
        "etapa": "1",
        "subEtapa": "1",
        "pantalla": "4",
        isEmailNeeded: false,
      }
    }
    if (props.preOrder.email !== "hola@inter.mx") {
      pre_order.email = props.preOrder.email
    } else {
      pre_order.email = params_.email
    }

    props.addInfoToPreOrder({ email: pre_order.email })
    let params = preInfoToPreOrder(pre_order)

    apiWeb.saveLead(params).then(res => {
      if (res.data.code === 200) {
        //Acción al dar 200
      }
    }).catch(e => {
      console.log(e)
    })
    navigate('/confirm-payment');

  }


  const sendTagManagerArgs = (data) => {
    let tagManagerArgs = {
      event: "mascota_seguro_datos_personales",      //Static data,
      fecha_nacimiento: data.fecha_nacimiento,
      cp: data.cp,
      boton_datospersonales: "continuar"
    }
    TagManagerLayer(tagManagerArgs)
  }

  const findFiscal = (id_Text) => {
    let response = '12'
    let item = props.config.tax.find(item => item.value.toLowerCase() === id_Text.toLowerCase())
    if (item) {
      response = item.id + ""
    }
    return response
  }

  const findColonia = (id,revertir = false) =>{
        let value = ""
      let item = props.config.zipCode.find(item => item.cod_colonia === id);
        if (revertir){
          if (item){
            value = item.COLONIA
          }
        }else{
          if (item){
            value = item.cod_colonia
          }
        }


        return value

  }
  const handleGuardar = (values, setSubmitting, setFieldError) => {
    if (validateFormat(dateFormat(values.fechanacimiento)) === false) {
      setFieldError("fechanacimiento", "El valor debe ser una fecha (dd-mm-aaaa)")
      return true;
    }
    window.dataLayer.push({
      event: 'mascota_seguro_queremos_conocerte',
      boton: 'Continuar',
      no_cotizacion: props.preOrder.folio,
      es_dueno: values.roleType,
      requiero_factura: values.isfactura ? 'Si' : 'No',
      fecha_nacimiento: format(values.fechanacimiento, 'dd/MM/yyyy'),
      sexo: values.genero,
      cp: values.cp,
      colonia: values.colonia,
      estado: values.estado,
      municipio: values.municipio,
      correo: values.email,
      telefono: values.telefono,
    })
    let fromContratante = {
      roleType: alignmentSi,
      nombres: values.nombres,
      paterno: values.paterno,
      materno: values.materno,
      rfc: values.rfc.toUpperCase(),
      genero: alignmentGenero,
      isfactura: checked,
      fiscal: values.fiscal,
      fechanacimiento: dateFormat(values.fechanacimiento),
      email: values.email,
      telefono: values.telefono,
      cp: values.cp,
      estado: values.estado,
      municipio: values.municipio,
      colonia: findColonia(values.colonia,true),
      calle: values.calle,
      exterior: values.exterior,
      interior: values.interior ? values.interior : "NA",
      factura: values.factura,
      terminos: values.terminos,
      //dueño
      nombresOwner: values.nombresOwner,
      paternoOwner: values.paternoOwner,
      maternoOwner: values.maternoOwner,
      generoOwner: alignmentSi !== 'no' ? "" : dueñoGenero,
      emailOwner: values.emailOwner,
      telefonoOwner: values.telefonoOwner,
      fechanacimientoOwner: alignmentSi !== 'no' ? "" : dateFormat(fechaSeleccionada),
      "cod_colonia": selectedColonia ? selectedColonia.cod_colonia : "",
      "cod_ciudad": selectedColonia ? selectedColonia.cod_ciudad : "",
      "cod_municipio": selectedColonia ? selectedColonia.cod_municipio : "",
      "cod_dpto": selectedColonia ? selectedColonia.cod_dpto : "",
      "idFiscal": findFiscal(values.fiscal),
    }
    props.addInfoToPreOrder({ fromContratante });
    setClick(fromContratante);
    sendTagManagerArgs(fromContratante)
  }


  const handleChangeFechaNacimiento = (fecha, setFieldValue, setFieldError, values) => {
    if (fecha instanceof Date && !isNaN(fecha)) {
      setFieldValue("fechanacimiento", fecha);

      if (validaMayorEdad(fecha)) {
        debounceGeneric("fechanacimiento", fecha, values, setFieldValue);
      } else {
        setFieldError("fechanacimiento", MAYOR_EDAD);
      }
    } else {
      setFieldError("fechanacimiento", FECHA_VALIDA);
    }
  };

  // const handleChangeFechaNacimiento2 = (fecha, setFieldValue, setFieldError, values) => {
  //   if (fecha instanceof Date && !isNaN(fecha)) {
  //     setFieldValue("fechanacimientoOner", fecha);
  //   } else {
  //     setFieldError("fechanacimientoOner", FECHA_VALIDA);
  //   }
  // };

  const handleChangeTerminos = (event, setFieldValue) => {
    setFieldValue('terminos', event.target.checked);
  };


  const selectMunicipioEstado=(values,setFieldValue)=>{
    setFieldValue("estado",values.ESTADO)
    setFieldValue("municipio",values.MUNICIPIO)
  }
  const handleOnChangeColonia = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value+"");
    let item = props.config.zipCode.find(item => item.cod_colonia === value);
    if (item){
      selectMunicipioEstado(item,setFieldValue)
    }

  }

  const handleOnChangeEstado = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value);
  }


  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 23,
    padding: 0,
    display: 'flex',
    margin: theme.spacing(1),
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(18px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 20,
      height: 20,
      borderRadius: 26 / 2,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const validaEdad = (fechaSeleccionada) => {
    const dates = fechaSeleccionada;
    let date = new Date(dates);
    const edad = calcularEdad(date);
    if (edad >= 18) {
      return true;
    } else {
      return false;
    }
  }

  const handleChangeCp = (event, setFieldValue, setFieldError) => {
    debounceCp(event.target.value, setFieldValue);
    setFieldValue("cp", event.target.value);
  };

  const handleChangeGeneric = (event, setFieldValue, setFieldError, values) => {
    const { target: { value, name } } = event;
    setFieldValue(name, event.target.value);
    debounceGeneric(name, value, values, setFieldValue);

  }

  const debounceGeneric = useCallback(
    _.debounce(async (key, _searchVal, values, setFieldValue) => {
      rfc(key, _searchVal, values, setFieldValue);
      // send the server request here
    }, 1000),
    []
  );
  const rfc = (key, value, values, setFieldValue) => {
    let response = RfcFacil.forNaturalPerson({
      name: key === "nombres" ? value : values.nombres,
      firstLastName: key === "paterno" ? value : values.paterno,
      secondLastName: key === "materno" ? value : values.materno,
      day: key === "fechanacimiento" ? dateFormatObj(value).Day : values.fechanacimiento ? dateFormatObj(values.fechanacimiento).Day : 0,
      month: key === "fechanacimiento" ? dateFormatObj(value).Month : values.fechanacimiento ? dateFormatObj(values.fechanacimiento).Month : 0,
      year: key === "fechanacimiento" ? dateFormatObj(value).Year : values.fechanacimiento ? dateFormatObj(values.fechanacimiento).Year : 0
    });

    setFieldValue("rfc", response.toUpperCase())
    return response
  }



  const renderViewsColonia = (list = []) => {
    let newArray = []
    let hash = {};
    newArray = list.filter(o => hash[o.cod_colonia] ? false : hash[o.cod_colonia] = true);
    return newArray
  }

  const debounceCp = useCallback(
    _.debounce(async (_searchVal, setFieldValue) => {
      await searchCp(_searchVal, setFieldValue);
      // send the server request here
    }, 1000),
    []
  );

  const searchCp = async (text, setFieldValue) => {
    try {
      if (text.length >= 4) {
        props.getZipCode(text).then(response => {
         /* if (response.success && response.data.length > 0) {
            setFieldValue("estado", response.data[0].ESTADO)
            setFieldValue("municipio", renderViewsMunicipio(response.data)[0].MUNICIPIO)
          } else if (response.error) {
            setFieldValue("estado", -1)
            setFieldValue("municipio", -1)
            setFieldValue("colonia", -1)
          }*/
        }).catch(e => {
          console.error("error", e)
        })
      } else {

      }
    } catch (error) {
      console.log("Error", error)
    }

  }





  const returnSelectColonia =(id_text,setFieldValue)=>{
    let item = props.config.zipCode.find(item => item.cod_colonia === id_text);
    if (item) {
      setSelectedColonia(item)
      return item.COLONIA;
    } else {
      if (props.config.fetchingZipCode) {
        return (
            <div
                style={{
                  color: '#c5c5c5',
                  fontFamily: "Montserrat"
                }}>{"Cargando Colonia..."}</div>
        )
      } else {
        return (
            <div
                style={{
                  color: '#c5c5c5',
                  fontFamily: "Montserrat"
                }}>{props.config.zipCode.length > 0 ? "Selecciona un colonia*" : 'Colonia*'}</div>
        )
      }
    }
  }

  const returnSelectEstado = (id_Text, type,setFieldValue) => {
    if (type === 1) {
      let item = props.config.zipCode.find(item => item.ESTADO === id_Text);
      if (item) {
        return item.ESTADO;
      } else {
        if (props.config.fetchingZipCode) {
          return (
            <div
              style={{
                color: '#c5c5c5',
                fontFamily: "Montserrat"
              }}>{"Cargando Estado..."}</div>
          )
        } else {
          return (
            <div
              style={{
                color: '#c5c5c5',
                fontFamily: "Montserrat"
              }}>{props.config.zipCode.length > 0 ? "Estado*" : 'Estado*'}</div>
          )
        }
      }
    } else if (type === 2) {
      let item = props.config.zipCode.find(item => item.MUNICIPIO === id_Text);
      if (item) {
        return item.MUNICIPIO;
      } else {
        if (props.config.fetchingZipCode) {
          return (
            <div
              style={{
                color: '#c5c5c5',
                fontFamily: "Montserrat"
              }}>{"Cargando Municipio..."}</div>
          )
        } else {
          return (
            <div
              style={{
                color: '#c5c5c5',
                fontFamily: "Montserrat"
              }}>{props.config.zipCode.length > 0 ? "Municipio*" : 'Municipio*'}</div>
          )
        }
      }
    }  else if (type === 4) {
      let item = props.config.tax.find(item => item.value === id_Text)
      if (item) {
        return item.value;
      } else {
        if (props.config.fetchingTax) {
          return (
            <div
              style={{
                color: '#c5c5c5',
                fontFamily: "Montserrat"
              }}>{"Cargando Regimen Fiscal..."}</div>
          )
        } else {
          return (
            <div
              style={{
                color: '#c5c5c5',
                fontFamily: "Montserrat"
              }}>{props.config.tax.length > 0 ? "Selecciona un Regimen Fiscal*" : 'Regimen Fiscal*'}</div>
          )
        }
      }

    }
  }
  const handleTooltipClose = () => {
    setTooltipVisible(false);
  };

  useEffect(() => {
    if (props.preOrder.fromContratante) {

      let object = {
        "cod_colonia": props.preOrder.fromContratante ? props.preOrder.fromContratante.cod_colonia : "",
        "cod_ciudad": props.preOrder.fromContratante ? props.preOrder.fromContratante.cod_ciudad : "",
        "cod_municipio": props.preOrder.fromContratante ? props.preOrder.fromContratante.cod_municipio : "",
        "cod_dpto": props.preOrder.fromContratante ? props.preOrder.fromContratante.cod_dpto : ""
      }
      setSelectedColonia(object)

      props.getZipCode(props.preOrder.fromContratante.cp).then(response => {
        if (response.success && response.data.length > 0) {
          props.getTax();
        }
      }).catch(e => {
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preOrder.fromContratante])





  return (
    <>
      <Box sx={{ mb: 3 }} >
        <Formik
          validateOnChange={true}
          initialValues={{
            roleType: props.preOrder.fromContratante ? props.preOrder.fromContratante.roleType : 'si',
            nombres: props.preOrder.fromContratante ? props.preOrder.fromContratante.nombres : '',
            paterno: props.preOrder.fromContratante ? props.preOrder.fromContratante.paterno : '',
            materno: props.preOrder.fromContratante ? props.preOrder.fromContratante.materno : '',
            rfc: props.preOrder.fromContratante ? props.preOrder.fromContratante.rfc.toUpperCase() : '',
            genero: props.preOrder.fromContratante ? props.preOrder.fromContratante.genero : 'Mujer',
            isfactura: props.preOrder.fromContratante ? props.preOrder.fromContratante.isfactura : checked !== true ? "" : checked,
            fiscal: props.preOrder.fromContratante ? props.preOrder.fromContratante.fiscal : checked !== true && "Sin obligaciones fiscales",
            fechanacimiento: props.preOrder.fromContratante ? DateFormat_(props.preOrder.fromContratante.fechanacimiento) : null,
            email: props.preOrder.fromContratante ? props.preOrder.fromContratante.email : '',
            telefono: props.preOrder.fromContratante ? props.preOrder.fromContratante.telefono : '',
            cp: props.preOrder.fromContratante ? props.preOrder.fromContratante.cp : '',
            estado: props.preOrder.fromContratante ? props.preOrder.fromContratante.estado : -1,
            municipio: props.preOrder.fromContratante ? props.preOrder.fromContratante.municipio : -1,
            colonia: props.preOrder.fromContratante ? props.preOrder.fromContratante.cod_colonia : -1,
            calle: props.preOrder.fromContratante ? props.preOrder.fromContratante.calle : '',
            exterior: props.preOrder.fromContratante ? props.preOrder.fromContratante.exterior : '',
            interior: props.preOrder.fromContratante ? props.preOrder.fromContratante.interior : '',
            factura: props.preOrder.fromContratante ? props.preOrder.fromContratante.factura : false,
            terminos: props.preOrder.fromContratante ? props.preOrder.fromContratante.terminos : false,
            //dueño
            nombresOwner: props.preOrder.fromContratante ? props.preOrder.fromContratante.nombresOwner : '',
            paternoOwner: props.preOrder.fromContratante ? props.preOrder.fromContratante.paternoOwner : '',
            maternoOwner: props.preOrder.fromContratante ? props.preOrder.fromContratante.maternoOwner : '',
            generoOwner: props.preOrder.fromContratante ? props.preOrder.fromContratante.generoOwner : "mujer",
            emailOwner: props.preOrder.fromContratante ? props.preOrder.fromContratante.emailOwner : '',
            telefonoOwner: props.preOrder.fromContratante ? props.preOrder.fromContratante.telefonoOwner : '',
            fechanacimientoOwner: props.preOrder.fromContratante ? props.preOrder.fromContratante.fechanacimientoOwner !== "" ? DateFormat_(props.preOrder.fromContratante.fechanacimientoOwner) : new Date() : new Date()
          }}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);

            handleGuardar(values, setSubmitting, setFieldError);
          }}
          validationSchema={alignmentSi !== 'no' ? validationFormContratante : validaEdad(fechaSeleccionada) ? validationFormDueñoMayorEdad : validationFormDueño}
        >
          {({
            values,
            submitForm,
            setFieldValue,
            handleChange,
            errors,
            isSubmitting,
            setFieldError,
            touched,
            setFieldTouched,
          }) => (
            <Form>
              <Box sx={{ mt: 4 }}>
                <div className="form-contratante">
                  <span className="title-contratante">&iquest;Nos ayudas con esta informaci&oacute;n?</span>
                  <Box sx={{ mt: 4 }}>
                    <Stack
                      direction="column"
                      spacing={2}
                    >

                      <span
                        className="preguntas-contratante">&iquest;El dueño de {props.preOrder.petName} es el contratante?</span>
                      <Stack spacing={0} direction="row" className="es-contratante">
                        <ToggleButtonGroup
                          fullWidth
                          id="roleType"
                          name="roleType"
                          color="primary"
                          value={alignmentSi}
                          exclusive
                          onChange={handleToggle}
                        >
                          <ToggleButton fullWidth
                            className="boton-si"
                            value="si"
                            style={{ textTransform: 'none', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '24px' }}
                          >
                            Sí
                          </ToggleButton>
                          <ToggleButton fullWidth
                            className="boton-no"
                            value="no"
                            style={{ textTransform: 'none', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '24px' }}
                          ><span>No, es alguien m&aacute;s</span>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>

                      {/*si no es dueño */}
                      {alignmentSi === "no" &&

                        <>

                          <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                          >

                            <span
                              className="title-contratante">Datos del dueño</span>
                            <span className="preguntas-contratante">&iquest;C&oacute;mo se llama?</span>

                            <CustomTextField
                              onKeyPress={(event) => OnlyAlpha(event)}
                              onKeyUp={(event) => OnlyAlpha(event)}
                              id="nombresOwner" name="nombresOwner" type="text"
                              placeholder="Nombre(s)*" />
                            <Stack spacing={2}
                              direction={{ xs: 'column', sm: 'column', md: 'row' }}
                              className="w-100">
                              <CustomTextField id="paternoOwner" name="paternoOwner"
                                               onKeyPress={(event) => OnlyAlpha(event)}
                                               onKeyUp={(event) => OnlyAlpha(event)}
                                type="text" placeholder="Apellido Paterno*" />
                              <CustomTextField id="maternoOwner" name="maternoOwner"
                                               onKeyPress={(event) => OnlyAlpha(event)}
                                               onKeyUp={(event) => OnlyAlpha(event)}
                                type="text" placeholder="Apellido Materno*" />
                            </Stack>
                            <span
                              className="preguntas-contratante">&iquest;Cu&aacute;ndo nació?</span>
                            <FormControl fullWidth>
                              <CustomDatePickerDueño id="fechanacimientoOwner"
                                name="fechanacimientoOwner" type="text"
                                placeholder="Fecha Nacimiento"
                                value={fechaSeleccionada}
                                onChange={(type) => {
                                  setFechaSeleccionada(type)
                                }}
                              />
                            </FormControl>
                            <span className="preguntas-contratante">&iquest;Cu&aacute;l es su sexo de nacimiento?</span>
                            <Stack spacing={2} direction="row" className="w-100">
                              <ToggleButtonGroup
                                fullWidth
                                color="primary"
                                value={dueñoGenero}
                                exclusive
                                onChange={handleClickGeneroDueño}
                              >
                                <ToggleButton fullWidth
                                  className="boton-si"
                                  value="hombre"
                                  style={{ textTransform: 'none', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '24px' }}
                                >
                                  Hombre
                                </ToggleButton>
                                <ToggleButton fullWidth
                                  className="boton-no"
                                  value="mujer"
                                  style={{ textTransform: 'none', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '24px' }}
                                >Mujer
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Stack>

                            {validaEdad(fechaSeleccionada) &&
                              <>
                                <span className="preguntas-contratante">&iquest;D&oacute;nde lo podemos contactar?</span>
                                <Stack spacing={2}
                                  direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                  className="w-100">
                                  <CustomTextField id="emailOwner" name="emailOwner"
                                    type="text"
                                    validate={(value) => validateEmail(value)}
                                    placeholder="Correo*" />
                                  <CustomTextField id="telefonoOwner" name="telefonoOwner"
                                    type="number" placeholder="Teléfono*"
                                    validate={(value) => validatePhoneNumber(value)}
                                    onChange={(event) => handleMaxLength(event, setFieldValue, "telefonoOwner", 10)} />
                                </Stack>
                              </>

                            }
                          </Stack>

                          <span className="title-contratante">Datos del contratante</span>


                        </>
                      }


                      {/*el dueño es contratante si */}
                      <span className="preguntas-contratante">&iquest;Requiere factura para esta compra?</span>
                      <Stack spacing={0} direction="row">
                        <Typography>NO</Typography>
                        <AntSwitch checked={checked}
                          onChange={(event) => handleChanges(event, setFieldValue)}
                          inputProps={{ 'aria-label': 'controlled' }} />
                        <Typography>SI</Typography>
                      </Stack>
                      <span className="preguntas-contratante">&iquest;C&oacute;mo te llamas?</span>
                      <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row' }}
                        className="w-100-step">
                        <CustomTextField
                          onKeyPress={(event) => OnlyAlpha(event)}
                          onKeyUp={(event) => OnlyAlpha(event)}
                          onChange={(event) => handleChangeGeneric(event, setFieldValue, setFieldError, values)}
                          className="w-100-step" id="nombres" name="nombres" type="text"
                          placeholder="Nombre(s)*" />
                      </Stack>
                      <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row' }}
                        className="w-100-step">

                        <CustomTextField
                          onKeyPress={(event) => OnlyAlpha(event)}
                          onKeyUp={(event) => OnlyAlpha(event)}
                          onChange={(event) => handleChangeGeneric(event, setFieldValue, setFieldError, values)}
                          className="w-100-step" id="paterno" name="paterno" type="text"
                          placeholder="Apellido Paterno*" />
                        <CustomTextField
                          onKeyPress={(event) => OnlyAlpha(event)}
                          onKeyUp={(event) => OnlyAlpha(event)}
                          onChange={(event) => handleChangeGeneric(event, setFieldValue, setFieldError, values)}
                          id="materno" name="materno" type="text"
                          placeholder="Apellido Materno*" />
                      </Stack>
                      <span className="preguntas-contratante">&iquest;Cu&aacute;ndo naciste?</span>
                      <FormControl fullWidth>
                        <CustomDatePickerPasoTres id="fechanacimiento" name="fechanacimiento"
                          type="text" label="Fecha Nacimiento*"
                          className="grid-item-input w-100"
                          onChange={(newValue) =>
                            handleChangeFechaNacimiento(newValue, setFieldValue, setFieldError, values)}
                        />
                      </FormControl>
                      <Stack spacing={22} direction="row" className="w-100-step">
                        <span className="preguntas-contratante s-genero-tooltip">&iquest;Cu&aacute;l es tu sexo de nacimiento?
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                            <div>
                              <BlueTooltip
                                visible={tooltipVisible}
                                setVisible={setTooltipVisible}
                                title="Sabemos que esta pregunta puede ser incómoda, pero es un dato importante que la aseguradora nos pide.">
                              </BlueTooltip>
                            </div>
                          </ClickAwayListener>
                        </span>
                      </Stack>
                      <Stack spacing={0} direction="row" className="es-contratante">
                        <ToggleButtonGroup
                          fullWidth
                          color="primary"
                          value={alignmentGenero}
                          exclusive
                          onChange={handleClickGenero}
                        >
                          <ToggleButton value="hombre" className="boton-si"
                            style={{ textTransform: 'none', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '24px' }}
                          >Hombre</ToggleButton>
                          <ToggleButton value="Mujer" className="boton-no"
                            style={{ textTransform: 'none', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '24px' }}
                          >Mujer</ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>
                      <span
                        className="preguntas-contratante">¿Cuál es tu RFC?</span>

                      <CustomTextField id="rfc" name="rfc" type="text" placeholder="RFC"
                        onChange={(event) => handleMaxLengthRfc(event, setFieldValue, "rfc", 13)}
                      />
                      {/*si requiere factura */}
                      {checked === true &&
                        <>

                          <span
                            className="preguntas-contratante">&iquest;Con qu&eacute; r&eacute;gimen fiscal quieres que hagamos tu factura?</span>
                          <FormControl fullWidth>
                            <CustomSelect id="fiscal" name="fiscal"
                              value={values.fiscal}
                              onChange={(event) => handleOnChangeEstado(event, setFieldValue)}
                              renderValue={(val) => {
                                return returnSelectEstado(val, 4)
                              }}
                            >
                              {
                                props.config.fetchingTax &&
                                <MenuItem
                                  value=""
                                  disabled>
                                  {"Cargando Regimen Fiscal..."}
                                </MenuItem>
                              }
                              {props.config.tax.map((item, index) => {
                                return (<MenuItem key={index}
                                  value={item.value}>{item.value}</MenuItem>)
                              })}
                            </CustomSelect>
                          </FormControl>
                        </>
                      }
                      <span
                        className="preguntas-contratante">{checked !== true ? "¿Cuál es tu domicilio?" : "¿Cuál es tu domicilio fiscal?"}</span>
                      <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row' }}
                        className="w-100">
                        <CustomTextField id="cp" name="cp" type="number"
                          value={values.cp}
                          onChange={(event) => handleChangeCp(event, setFieldValue, setFieldError)}
                          placeholder="Código Postal*" />
                        <FormControl fullWidth>
                          <CustomSelect id="colonia" name="colonia"
                                        value={values.colonia}
                                        onChange={(event) => handleOnChangeColonia(event, setFieldValue)}
                                        renderValue={(val) => {
                                          return returnSelectColonia(val,  setFieldValue)
                                        }}
                          >
                            {
                                props.config.fetchingZipCode &&
                                <MenuItem
                                    value=""
                                    disabled>
                                  {"Cargando Colonia..."}
                                </MenuItem>
                            }
                            {
                                props.config.zipCode.length > 0 &&
                                renderViewsColonia(props.config.zipCode).map((item, index) => {
                                  return (<MenuItem key={index}
                                                    value={item.cod_colonia+""}>{item.COLONIA}</MenuItem>)
                                })
                            }
                          </CustomSelect>
                        </FormControl>
                      </Stack>
                      <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row' }}
                        className="w-100-step">
                        <FormControl fullWidth disabled>
                          <CustomSelect id="estado" name="estado"
                                        disabled={true}
                                        value={values.estado}
                                        onChange={(event) => handleOnChangeEstado(event, setFieldValue)}
                                        renderValue={(val) => {
                                          return returnSelectEstado(val, 1)
                                        }}
                          >
                            {
                                props.config.fetchingZipCode &&
                                <MenuItem
                                    value=""
                                    disabled>
                                  {"Cargando Estado..."}
                                </MenuItem>
                            }

                            {
                                props.config.zipCode.length > 0 &&
                                props.config.zipCode.map((item, index) => {
                                  return (<MenuItem key={index}
                                                    value={item.ESTADO}>{item.ESTADO}</MenuItem>)
                                })
                            }

                          </CustomSelect>
                        </FormControl>
                        <FormControl fullWidth  disabled >
                          <CustomSelect id="municipio" name="municipio"
                                        disabled={true}
                                        value={values.municipio}
                                        onChange={(event) => handleOnChangeEstado(event, setFieldValue)}
                                        renderValue={(val) => {
                                          return returnSelectEstado(val, 2)
                                        }}
                          >
                            {
                                props.config.fetchingZipCode &&
                                <MenuItem
                                    value=""
                                    disabled>
                                  {"Cargando Municipio..."}
                                </MenuItem>
                            }
                            {
                                props.config.zipCode.length > 0 &&
                                props.config.zipCode.map((item, index) => {
                                  return (<MenuItem key={index}
                                                    value={item.MUNICIPIO}>{item.MUNICIPIO}</MenuItem>)
                                })
                            }
                          </CustomSelect>
                        </FormControl>
                      </Stack>
                      <CustomTextField id="calle" name="calle" type="text" onKeyPress={(event) => keyPressSoloLetrasNumeros(event)} onKeyUp={(event) => keyPressSoloLetrasNumeros(event)} placeholder="Calle*" onPaste={(event) => noPaste(event)} />
                      <Stack spacing={2} direction="row" className="w-100">
                        <CustomTextField id="exterior" name="exterior" type="text"
                          onKeyPress={(event) => keyPressSoloLetrasNumeros(event)} onKeyUp={(event) => keyPressSoloLetrasNumeros(event)}
                          onPaste={(event) => noPaste(event)}
                          placeholder="No. Exterior*" />
                        <CustomTextField id="interior" name="interior" type="text"
                          onKeyPress={(event) => keyPressSoloLetrasNumeros(event)} onKeyUp={(event) => keyPressSoloLetrasNumeros(event)}
                          onPaste={(event) => noPaste(event)}
                          placeholder="No. Interior" />
                      </Stack>
                      <span className="preguntas-contratante">&iquest;D&oacute;nde te podemos contactar?</span>
                      <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row' }}
                        className="w-100">
                        <CustomTextField id="email" name="email" type="text" placeholder="Correo*" validate={(value) => validateEmail(value)} />
                        <CustomTextField id="telefono" name="telefono" type="number"
                          placeholder="Teléfono*"
                          onChange={(event) => handleMaxLength(event, setFieldValue, "telefono", 10)} validate={(value) => validatePhoneNumber(value)} />
                      </Stack>
                      <div className="espacio">
                        <FormControlLabel
                          name='terminos'
                          id='terminos'
                          control={<Checkbox checked={values.terminos} />}
                          label=''
                          value={values.terminos}
                          onChange={(event) =>
                            handleChangeTerminos(event, setFieldValue)}
                        />
                        Acepto{tab}<a className="href-terminos" target="_blank"
                          href="https://inter.mx/aviso-de-privacidad" rel="noreferrer">Aviso
                          de privacidad</a>{tab}y{tab}
                        <a className="href-terminos" target="_blank"
                          href="https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/TYC%20APP%20Intermx.pdf"
                          rel="noreferrer">T&eacute;rminos y Condiciones</a>
                        <CustomErrorMessage
                          name='terminos'
                        />
                      </div>
                      <Button
                        style={{ height: 48 }}
                        fullWidth variant="contained"
                        onClick={submitForm}
                      >
                        Continuar
                      </Button>
                    </Stack>
                  </Box>
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}

const mapStateToProps = state => ({
  preOrder: state.preOrder,
  config: state.config
})
export default connect(mapStateToProps, { addInfoToPreOrder, getZipCode, getTax })(FormStepTres);
