import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { Box, Grid, Stack, TextField } from "@mui/material";
import '../../pages/home/Home.css';
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { blue } from "@mui/material/colors";
import { OnlyAlpha } from '../../components/Utils/utils'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#77CAE3',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#77CAE3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#77CAE3',
    },
    '&:hover fieldset': {
      borderColor: '#77CAE3',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#77CAE3',
    },
  },
});



const InputName = (props) => {

  const [name, setName] = useState("");
  const [isError, setIsError] = useState(false);

  const resetTypePet = () => {
    props.addInfoToPreOrder({ selectedTypePet: null })
  }

  const addPetName = () => {

    if (name.trim() === "") {
      setIsError(true)
      return
    } else {
      setIsError(false)
    }

    window.dataLayer.push({
      event: 'mascota_seguro_nombre',
      boton_contacto: 'Listo',
    })

    props.addInfoToPreOrder({ petName: name })
  }

  const handleChange = (event) => {
    setIsError(false)
    setName(event.target.value);
  };

  return (
    <>
      <div className='div-azul2'>
        <Grid
          style={{ width: '100%', padding: '5%', display: 'flex' }}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ textAlign: 'center' }} className='content-component'>

            <div style={{ textAlign: 'left', paddingBottom: 20 }}>
              <ArrowBackIcon
                className={"icon-arrow"}
                onClick={() => {
                  resetTypePet()
                }}
                fontSize="large"
                sx={{ color: blue[500] }} />
            </div>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <div className={"card-input-home"}>
                <Box
                  textAlign={'center'}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Box sx={{ mb: 3 }}>
                      <span className='titulo-primero'>Y, &iquest;c&oacute;mo se llama?</span>
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
                      justifyContent="center"
                      style={{ width: '100%' }}
                      spacing={2}>
                      <Grid item xs={12} sm={12} md={16} lg={16} xl={16}>
                        <CssTextField
                          onKeyPress={(event) => OnlyAlpha(event)}
                          onKeyUp={(event) => OnlyAlpha(event)}
                          error={isError}
                          value={name}
                          onChange={handleChange}
                          fullWidth
                          helperText={isError ? "Ingrese el nombre" : ""}
                          label="Nombre" id="custom-name" />
                      </Grid>
                      <Grid style={{ width: '100%', paddingBottom: isError ? 30 : 0 }}
                        item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div>
                          <Button
                            onClick={() => {
                              addPetName()
                            }}
                            variant="contained" style={{ height: 48, fontWeight: 700 }} size={"large"} fullWidth>&iexcl;LISTO!</Button>
                        </div>
                      </Grid>
                    </Stack>
                  </Grid>
                </Box>
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
    </>
  )
}
const mapState = (state) => {
  return {
    preOrder: state.preOrder
  }
}
export default connect(mapState, { addInfoToPreOrder })(InputName);
