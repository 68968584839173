import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react"
import './PetPhotos.css'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { grey } from "@mui/material/colors";
import { connect } from "react-redux";
import { addInfoToPreOrder } from "../../redux/preOrderDuck";
import { preInfoToPreOrder, TagManagerLayer } from "../Utils/utils";
import ApiWeb from "../../lib/apiConfig/apiWeb";
import apiWeb from "../../lib/apiConfig/apiWeb";



const MAXIMO_TAMANIO_BYTES = 1000000; // 1MB = 1 millón de bytes


const PetPhotos = (props) => {
  const [disable, setDisable] = useState(false);
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [currentPet, setCurrentPet] = useState(props.preOrder.addPets[0]);
  const [error, setError] = useState(false);
  const [errorFormat, setErrorFormart] = useState(false)



  const sendTagManagerArgs = (data, isPhoto = false) => {

    let leyendaAnios = data.anios === 1 ? data.anios + " año " : data.anios + " años"
    let leyendaMeses = data.meses === 1 ? data.meses + " mes " : data.meses + " meses"


    let tagManagerArgs = {
      event: "mascota_seguro_datos_contratante",
      boton_cargar_foto: isPhoto ? "Done" : "false",
      sexo: data.sexo,
      raza: data.raza,
      no_se_que_raza_es: "No Aplica",
      edad: leyendaAnios + "y " + leyendaMeses,
      color_pelo: "No Aplica",
      color_ojos: "No Aplica",
      enfermedades: data.enfermedades,
      otras_señas: "No Aplica",
      datos_adicionales: data.descripcionMascota,
      boton_mascota: "continuar",
      cotizacion: "No Aplica",

    }
    TagManagerLayer(tagManagerArgs)
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click()

  };

  const handleChangeFile = event => {
    setErrorFormart(false)
    let fileUploaded = event.target.files;
    if (fileUploaded.length === 1 && validateUpload(event)) {
      setErrorFormart(false)
      upload(event)
      setButtonClicked(!upload(event) ? true : false);
      setDisable(!upload(event) ? true : false);
    } else {
      event.target.value = ""
      setErrorFormart(true)
    }
  };

  const addPhoto = (photo, petItem) => {
    let osArray = props.preOrder.addPets;
    let elementIndex = props.index// osArray.findIndex((obj => obj.id === petItem.id));
    osArray[elementIndex].foto = photo;
    osArray[elementIndex].addPhoto = true;
    props.addInfoToPreOrder({ addPets: osArray }, () => {
      if (props.preOrder.step2GoBack) {
      }
    })
  }

  const validateUpload = (e) =>
    e.target.files[0].type === "image/png" ||
    e.target.files[0].type === "image/jpg" ||
    e.target.files[0].type === "image/jpeg";

  const upload = (e) => {
    let isError = false
    setError(false)
    if (e.target.files[0].size <= MAXIMO_TAMANIO_BYTES) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        setFile(e.target.result)
      }
      reader.readAsDataURL(file)
      isError = false;
    } else {
      setError(true)
      isError = true
    }

    return isError
  }

  const setClick = () => {
    if (props.preOrder) {
      let pre_order = {
        ...props.preOrder,
        ...{
          "etapa": "1",
          "subEtapa": "3",
          "pantalla": "3",
          isEmailNeeded: false,
        }
      }
      let params = preInfoToPreOrder(pre_order)
      ApiWeb.saveLead(params).then(res => {
        if (res.data.code === 200) {
          //Acción al dar 200
        }
      }).catch(e => {
        console.log(e)
      })
    }
  }

  useEffect(() => {
    if (props.preOrder.addPets.length > 0) {
      const max = props.preOrder.addPets.length
      let count = 0;
      for (let i = 0; i < props.preOrder.addPets.length; i++) {
        if (props.preOrder.addPets[i].addPhoto === true) {
          count = count + 1
        }
      }

      if (count === max) {
        props.setIndice(count - 1);
        setCurrentPet(props.preOrder.addPets[count - 1]);
      } else {
        props.setIndice(count);
        setCurrentPet(props.preOrder.addPets[count]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preOrder]);


  const handleButtonClick = (i) => {
    if (buttonClicked === true) {
      if (file) {
        addPhoto(file, currentPet)
        sendTagManagerArgs(props.mascota, true)
        if (props.preOrder.step2GoBack) {
          props.setOpenSnack(true)
        }

      }

      if (i === props.indice) {
        props.setIndice(i + 1);
        setFile("");
        setDisable(false);
        let count = i + 1;
        setCurrentPet(props.preOrder.addPets[count]);
      }
      if (props.preOrder.addPets.length - 1 === props.indice) {
        setClick();
        if (!props.preOrder.step2GoBack) {
          props.addInfoToPreOrder({ steps: props.preOrder.steps + 1 })
        } else {
          props.setExpanded(-1);
        }
      }
    }
  }

  const handleMejorNo = (i, perro) => {
    setError(false)
    if (perro?.foto && perro.foto !== "") {
      addPhoto(perro.foto, perro)
      sendTagManagerArgs(perro, true)
    } else {
      addPhoto("", perro)
      sendTagManagerArgs(perro, false)
    }
    if (i === props.indice) {
      props.setIndice(i + 1);
      setFile("");
      let count = i + 1;
      setCurrentPet(props.preOrder.addPets[count]);
    }
    if (props.preOrder.addPets.length - 1 === props.indice) {
      setClick();
      if (!props.preOrder.step2GoBack) {
        props.addInfoToPreOrder({ steps: props.preOrder.steps + 1 })
      } else {
        props.setExpanded(-1);
      }
    }
  }




  const onclickSet = (pre_order) => {
    let params = preInfoToPreOrder(pre_order)
    apiWeb.saveLead(params).then(res => {
      props.handleClickModal()
    }).catch(e => {
      console.log(e)
    })
  }
  const generateData = () => {
    let pre_order = {
      ...props.preOrder,
      ...{
        "etapa": "1",
        "subEtapa": "2",
        "pantalla": "3",
        isEmailNeeded: false,
      }
    }
    onclickSet(pre_order)
  }

  return (
    <>
      <Container maxWidth="lg" className="container-pet-photos ">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div className="">
                <Box sx={{ py: 2 }}>
                  <span className="sube-foto">
                    Sube su foto

                  </span>
                </Box>
                <Box sx={{ pt: 4 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {
                      file === "" && !props.mascota?.foto &&
                      <>
                        <div className="circle-photo">
                          <div className="div-circle">
                            <input
                              ref={hiddenFileInput}
                              type="file" style={{ display: 'none' }}
                              onChange={handleChangeFile}
                            />
                            <IconButton onClick={handleClick}>
                              <AddAPhotoIcon style={{ color: grey[600], fontSize: 160 }} />
                            </IconButton>
                          </div>
                        </div>
                      </ >
                    }

                    {
                      file === "" && props.mascota?.foto && props.mascota.foto !== "" &&
                      <div className="mascotas-photos">
                        <img
                          onClick={handleClick}
                          style={{
                            width: "200px",
                            objectPosition: "center",
                            objectFit: "cover",
                            borderRadius: "50%",
                            height: "200px",
                          }}
                          src={props.mascota.foto} alt='LogoInter' />
                        <input
                          ref={hiddenFileInput}
                          type="file" accept={"image/*"} style={{ display: 'none' }}
                          onChange={handleChangeFile}
                        />
                      </div>
                    }

                    {
                      file !== "" &&
                      <div className="mascotas-photos">
                        <img
                          onClick={handleClick}
                          style={{
                            width: "200px",
                            objectPosition: "center",
                            objectFit: "cover",
                            borderRadius: "50%",
                            height: "200px",
                          }}
                          src={file} alt='LogoInter' />
                        <input
                          ref={hiddenFileInput}
                          type="file" accept={"image/*"} style={{ display: 'none' }}
                          onChange={handleChangeFile}
                        />
                      </div>
                    }

                  </Grid>
                  {
                    error &&
                    <div style={{ textAlign: 'center' }}>
                      <span style={{
                        color: 'red', fontSize: 14,
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 300
                      }}>{`El tamaño máximo es ${MAXIMO_TAMANIO_BYTES / 1000000} MB`}</span>
                    </div>
                  }

                  {
                    errorFormat &&
                    <div style={{ textAlign: 'center' }}>
                      <span style={{
                        color: 'red', fontSize: 14,
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 300
                      }}>{`El tipo de formato de la imagen no es valida.`}</span>
                    </div>
                  }
                </Box>
                <Box sx={{ mt: 4 }} className={'hideButtonDescktop'}>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Button style={{ height: 48 }} fullWidth variant="outlined" onClick={() => handleMejorNo(props.index, props.mascota)}>MEJOR NO 🙈</Button>
                    <Button fullWidth variant="contained"
                      disabled={!disable}
                      style={{ fontWeight: 700, height: 48 }}
                      onClick={() => handleButtonClick(props.index)}
                    >&iexcl;LISTO!</Button>
                  </Stack>
                </Box>
                <Box sx={{ mt: 4 }} className={'hidde-app-add-pet'}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="text-photos">&iquest;No tienes una foto a la mano? </span>
                    <span className="text-photos">Haz click
                      {
                        props.preOrder.step2GoBack ?
                          <Button variant="text" disabled={!disable} onClick={() => {
                            generateData()
                          }} style={{ textTransform: 'lowercase' }}>aqu&iacute;</Button>
                          :
                          <Button variant="text" onClick={() => {
                            generateData()
                          }} style={{ textTransform: 'lowercase' }}>aqu&iacute;</Button>
                      }
                      ,escanea el c&oacute;digo y sigue desde tu celular</span>
                  </Stack>
                </Box>
              </div>
            </Grid>
          </Stack>
        </Grid>
      </Container>
      <Box sx={{ mt: 4 }} className={'hideButtonMobile'}>
        <Stack
          width={'100%'}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Button fullWidth variant="outlined" onClick={handleMejorNo}>MEJOR NO
            🙈
          </Button>
          <Button style={{ fontWeight: 700 }} fullWidth disabled={!disable} onClick={handleButtonClick}
            variant="contained">&iexcl;LISTO!
          </Button>
        </Stack>
      </Box>
    </>
  );
}


const mapStateToProps = state => ({
  preOrder: state.preOrder,
  config: state.config
})
export default connect(mapStateToProps, { addInfoToPreOrder })(PetPhotos);