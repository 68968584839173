import React from "react";
import './HeadBand.css';
import Desk from "../../assets/img/headBand/lanzamientodesk.png";
import Tablet from "../../assets/img/headBand/lanzamientotablet.png";
import Mobile from "../../assets/img/headBand/lanzamientomobile.png";
import GifAnimation from "../../assets/img/headBand/animation.gif";

const HeadBand = (props) => {

    return (
        <div style={{width:'100%'}}>
            <div className={'headBand'}>
                <picture>
                    <source media="(min-width: 1199px)" srcSet={Desk} />
                    <source media="(min-width: 1440px)" srcSet={Desk} />
                    <source media="(min-width: 1024px)" srcSet={Desk} />
                    <source media="(min-width: 768px)" srcSet={Tablet} />
                    <source media="(min-width: 320px)" srcSet={Mobile} />
                    <img className={'resizeImgText'} src={Desk} alt="web" />
                </picture>
                <div style={{height:0,width:'100%'}}>
                <picture>
                        <img className={"resizeImg"} src={GifAnimation} alt="animation" />
                    </picture>
                </div>
            </div>
        </div>

    )
}
export default HeadBand;
